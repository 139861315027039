/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddBonusInput = {|
  endDate: string,
  images?: ?$ReadOnlyArray<?InputBonusImage>,
  name: string,
  onProducts: $ReadOnlyArray<?string>,
  products: $ReadOnlyArray<?InputBonusProduct>,
  quantityRequired?: ?number,
  startDate: string,
  status: boolean,
  stealthMode?: ?boolean,
|};
export type InputBonusImage = {|
  file?: ?any,
  id?: ?string,
|};
export type InputBonusProduct = {|
  id?: ?string,
  optional: boolean,
  price: number,
  productId: string,
  quantity: number,
|};
export type AddBonusMutationVariables = {|
  input: AddBonusInput
|};
export type AddBonusMutationResponse = {|
  +addBonus: ?{|
    +bonusEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +status: ?boolean,
        +stealthMode: ?boolean,
        +quantityRequired: ?number,
        +onProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +products: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +product: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |},
              +price: ?number,
              +quantity: ?number,
              +optional: ?boolean,
            |}
          |}>
        |},
        +images: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +imageUrl: ?string,
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type AddBonusMutation = {|
  variables: AddBonusMutationVariables,
  response: AddBonusMutationResponse,
|};
*/


/*
mutation AddBonusMutation(
  $input: AddBonusInput!
) {
  addBonus(input: $input) {
    bonusEdge {
      cursor
      node {
        id
        name
        startDate
        endDate
        status
        stealthMode
        quantityRequired
        onProducts(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        products(first: 999) {
          edges {
            node {
              product {
                id
                name
                mainImage {
                  id
                  url
                }
              }
              price
              quantity
              optional
              id
            }
          }
        }
        images(first: 999) {
          edges {
            node {
              id
              imageUrl
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stealthMode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityRequired",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v11 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": (v10/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "onProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "onProducts(first:999)"
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optional",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v10/*: any*/),
  "concreteType": "BonusImageConnection",
  "kind": "LinkedField",
  "name": "images",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BonusImageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BonusImage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "images(first:999)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddBonusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBonusPayload",
        "kind": "LinkedField",
        "name": "addBonus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BonusEdge",
            "kind": "LinkedField",
            "name": "bonusEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Bonus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "BonusProductConnection",
                    "kind": "LinkedField",
                    "name": "products",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BonusProductEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BonusProduct",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "products(first:999)"
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddBonusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBonusPayload",
        "kind": "LinkedField",
        "name": "addBonus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BonusEdge",
            "kind": "LinkedField",
            "name": "bonusEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Bonus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "BonusProductConnection",
                    "kind": "LinkedField",
                    "name": "products",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BonusProductEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BonusProduct",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "products(first:999)"
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c41eb51c1b67443bc955587bc30b150",
    "id": null,
    "metadata": {},
    "name": "AddBonusMutation",
    "operationKind": "mutation",
    "text": "mutation AddBonusMutation(\n  $input: AddBonusInput!\n) {\n  addBonus(input: $input) {\n    bonusEdge {\n      cursor\n      node {\n        id\n        name\n        startDate\n        endDate\n        status\n        stealthMode\n        quantityRequired\n        onProducts(first: 999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        products(first: 999) {\n          edges {\n            node {\n              product {\n                id\n                name\n                mainImage {\n                  id\n                  url\n                }\n              }\n              price\n              quantity\n              optional\n              id\n            }\n          }\n        }\n        images(first: 999) {\n          edges {\n            node {\n              id\n              imageUrl\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '22488e1517ce509ce29742d6fc3b729f';

module.exports = node;
