import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, groupBy, startCase } from 'lodash';
import moment from 'moment-timezone';
import { BarChart, Bar, Cell, LabelList, Legend, XAxis, YAxis, Tooltip, PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Affix, Alert, Button, Col, message, Row, Select, Switch, Table, Tabs, Tooltip as ADToolTip} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { DatePicker } from '~/components/form';
import GetByDate from '~/components/sales/GetByDate';
import { STATUS_STYLES } from '~/components/sales/order/Status';

import BreakDown from './BreakDown';
import SendEmail from './SendEmail';
import Status from './Status';
import { TIMEZONE } from "./helper";

const { Option } = Select;
const { TabPane } = Tabs;

// https://github.com/lodash/lodash/issues/3383
const SHIPPING_STATUSES = Object.entries(STATUS_STYLES).map(([k]) => {
  const text = startCase(k).replace("Ampersand", "&");
  return {
    text,
    value: text,
    meaning: STATUS_STYLES[k].meaning,
  }
});

export const getShippingDay = (node) => {
  if (node.shipping.shipped_at) {
    return moment(node.shipping.shipped_at).local().endOf('day').diff(moment(node.insertedAt).local().endOf('day'), 'days');
  }
  return null;
};

export const getBranches = (stores, shipping) => {
  const { locations } = shipping;
  if (locations) {
    return stores.filter(({node}) => locations.includes(node.branchCode))
      .map(({node}) => node.name)
      .join(", ");
  }
  return null;
}

const RADIAN = Math.PI / 180;
const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const getShippingKey = (node) => {
  let key;

  const day = getShippingDay(node);

  if (day === null) {
    if (node.shipping.status === 'Shipped') {
      key = 'N/A';
    } else {
      key = undefined;
    }
  } else if (day >= 0 && day <= 1) {
    key = '0-1 Days';
  } else if (day >= 2 && day <= 3) {
    key = '2-3 Days';
  } else if (day >= 4 && day <= 5) {
    key = '4-5 Days';
  } else if (day >= 6 && day <= 10) {
    key = '6-10 Days';
  } else if (day >= 11 && day <= 19) {
    key = '11-19 Days';
  } else if (day >= 20) {
    key = '20+ Days';
  }

  return key;
}

export const groupOrders = (orders, {excludeNonStockPreorder = false, groupByBranch = false} = {}) => {
  const branches = {};

  const shippingDays = {
    "0-1 Days": 0,
    "2-3 Days": 0,
    "4-5 Days": 0,
    "6-10 Days": 0,
    "11-19 Days": 0,
    "20+ Days": 0,
    'N/A': 0,
    "Total Shipped": 0,
    "Total Orders": 0,
  };

  orders.forEach(({node}) => {
    // filter out orders contain Non Stock and Pre Order items, conditionally
    let skipped = false;

    if (excludeNonStockPreorder && node.lines.find(({name}) => name.includes('[Non Stock]') || name.includes('[Pre-Order]'))) {
      skipped = true;
    }

    const key = getShippingKey(node);

    if (typeof key !== 'undefined' && !skipped) {
      if (groupByBranch) {
        if (node.shipping.locations) {
          node.shipping.locations.forEach(code => {
            // count by shipping days
            if (!branches[code]) {
              branches[code] = {};
            }

            if (!branches[code][key]) {
              branches[code][key] = 0;
            }
            branches[code][key] += 1;

            if (!branches[code]['Total Shipped']) {
              branches[code]['Total Shipped'] = 0;
            }
            branches[code]['Total Shipped'] += 1;
          });
        }
      }

      shippingDays[key] += 1;
      shippingDays['Total Shipped'] += 1;
    }

    if (groupByBranch) {
      const { status } = node.shipping;
      if (node.shipping.locations) {
        node.shipping.locations.forEach(code => {
          // count by shipping status
          if (!branches[code]) {
            branches[code] = {};
          }

          if (!branches[code][status]) {
            branches[code][status] = 0;
          }
          branches[code][status] += 1;

          if (!branches[code]['Total Orders']) {
            branches[code]['Total Orders'] = 0;
          }
          branches[code]['Total Orders'] += 1;
        });
      } else {
        // default location to 05
        const code = '05';

        if (!branches[code]) {
          branches[code] = {};
        }

        if (!branches[code][status]) {
          branches[code][status] = 0;
        }
        branches[code][status] += 1;

        if (!branches[code]['Total Orders']) {
          branches[code]['Total Orders'] = 0;
        }
        branches[code]['Total Orders'] += 1;
      }

    }
  });

  return groupByBranch ? branches : shippingDays;
};

const { RangePicker } = DatePicker;

const entityName = 'Sales Report';

const expandedRowRender = ({node}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, { priorityShipping }) => {
        return (
          <>
            <div>{name}</div>
            <GetByDate date={get(priorityShipping, 'getByDate')} />
          </>
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Row Total',
      dataIndex: 'rowTotal',
      render: (rowTotal) => `$${rowTotal}`,
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={record => record.id}
      dataSource={node.lines}
      pagination={false}
    />
  );
};

expandedRowRender.propTypes = {
  node: PropTypes.objectOf(PropTypes.any).isRequired
};

class SalesReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      roles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      salesReport: PropTypes.arrayOf(PropTypes.object),
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      params: PropTypes.shape({
      }).isRequired,
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);


    // get default date range from router
    const { match } = this.props;
    const { filterBy: [ { filter: dateFilter }, { filter: locationFilter } ] } = match.route.prepareVariables(match.params, match);
    this.dateRange = dateFilter.split(',').map(d => moment(d));
    this.fetchedDateRange = this.dateRange;

    const branchCodes = JSON.parse(locationFilter);
    const locations = props.viewer.stores.edges
      .filter(({node}) => branchCodes.includes(node.branchCode))
      .map(({node}) => node.branchCode);

    this.state = {
      loading: false,
      filter: null,
      activeFilters: {},
      activeKey: "Overview",
      totalOrders: get(props.viewer, 'salesReport', []),
      expandedRowKeys: this.getRowKeys(),
      locations,
      excludeNonStockPreorder: false,
      barchartFilter: null,
      codeToName: this.getCodeToName(),
      selectedRows: [],
    };
  }

  componentDidUpdate(_, prevState) {
    if (this.state.filter !== prevState.filter) {
      this.updateExpandKeys();
    }
  }

  onChange = (dateRange) => {
    this.dateRange = dateRange;
  }

  getRowKeys = () => this.props.viewer.salesReport.map(edge => edge.node.id)

  getCodeToName = () => {
    const { viewer } = this.props;
    const stores = get(viewer, 'stores.edges', []);
    const codeToName = {};

    stores.forEach(({node}) => {
      codeToName[node.branchCode] = node.name;
    });

    return codeToName;
  }

  refetch = (date, { force = false, activeFilters = {}} = {}) => {
    const filter = [date[0].startOf('day').local().format(), date[1].endOf('day').local().format()].join(",");

    if (!force && this.fetchedDateRange && this.fetchedDateRange[0].isSame(date[0]) && this.fetchedDateRange[1].isSame(date[1])) {
      return;
    }

    const refetchVariables = {
      filterBy: [
        {
          field: "inserted_at",
          filter,
          filterType: "text",
          type: "inRange",
        }, {
          field: "shipments.lines.location_code",
          filter: JSON.stringify(this.state.locations),
          filterType: "text",
          type: "in",
        }
      ]
    };

    const loading = true;
    this.setState({loading});

    this.props.relay.refetch(
      refetchVariables,
      null,
      ()=> {
        this.fetchedDateRange = date;

        this.setState({
          filter,
          totalOrders: get(this.props.viewer, 'salesReport', []),
          loading: false,
          activeFilters,
          expandedRowKeys: this.getRowKeys(),
        });

      },
      refetchVariables
    );
  }

  updateExpandKeys = () => {
    const expandedRowKeys = this.getRowKeys();
    this.setState({expandedRowKeys});
  }

  sumGrandTotal = (orders) => orders.reduce((acc, edge) => acc + edge.node.grandTotal, 0).toFixed(2)

  viewOrders = (location, shippingStatus) => {
    const { activeFilters } = this.state;

    this.setState({
      activeKey: "Overview",
      locations: [location],
    }, () => {
      this.refetch(this.dateRange, { force: true, activeFilters: {...activeFilters, shippingStatus: [shippingStatus]}});
      const bleh = document.getElementById('salesReportOverview');
      const target = get(bleh, 'children[1]');

      if (target) {
        target.scrollIntoView();
      }
    });
  }

  isSalesRole = (userRoles) => !!userRoles.find(({node}) => node.name === 'Sales')
  isSuperAdminRole = (userRoles) => !!userRoles.find(({node}) => node.name === 'Super Administrator')

  renderHeader = (orders, userRoles) => {
    const isSalesRole = this.isSalesRole(userRoles);
    const statuses = {};

    SHIPPING_STATUSES.forEach(({text, meaning}) => {
      statuses[text] = {
        text,
        meaning,
        count: 0
      };
    });

    orders.forEach(({node}) => {
      statuses[node.shipping.status].count +=1;
    });


    const shippingDays = groupOrders(orders, {excludeNonStockPreorder: this.state.excludeNonStockPreorder, barchartFilter: this.state.barchartFilter});

    const statusSummary = Object.keys(statuses).map(key => (
      {
        key,
        status: statuses[key].text,
        total: statuses[key].count,
        percentage: `${(statuses[key].count / orders.length * 100.0).toFixed(2)}%`,
      }
    ));

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => {
          return (
            <ADToolTip title={statuses[text].meaning}>
              {text}
            </ADToolTip>
          );
        }
      },
      {
        title: 'Total',
        dataIndex: 'total',
      },
      {
        title: 'Percentage',
        dataIndex: 'percentage',
      },
    ];

    const totalParcels = Object.keys(shippingDays).filter(key => !['Total Shipped', 'Total Orders'].includes(key)).reduce((sum, key) => sum + shippingDays[key], 0);
    const barData = Object.keys(shippingDays).filter(key => !['Total Orders'].includes(key)).map(key => ({name: key, 'Parcels Shipped': shippingDays[key]}));

    let pieData = groupBy(orders, ({node}) => {
      switch (node.shipping.status) {
        case "Processing":
        case "Allocating Stock":
        case "Being Picked":
        case "Partially Picked":
            return "Not Shipped";
        case "Cancelled":
        case "Back Order":
        case "Click & Collect":
            return"N/A";
        default:
            return "Shipped";
      }
    });

    pieData = Object.keys(pieData)
      .filter(key => key !== "N/A")
      .map(key => ({name: key, value: pieData[key].length}));

    return (
      <div>
        {!isSalesRole && <div>Grand Totals <b>${this.sumGrandTotal(orders)}</b> For <b>{orders.length}</b> Orders</div>}

        {orders.length > 0 && (
          <Row>
            <Col xs={24}>
              <Alert style={{textAlign: 'right'}} message="The barchart only includes parcels from Partially Shipped and Shipped orders" type="warning" />
            </Col>
            <Col xs={8}>
              <Table
                columns={columns}
                dataSource={statusSummary}
                pagination={false}
                rowClassName={(record) => {
                  if (["Processing", "Allocating Stock", "Being Picked"].includes(record.status)) {
                    return 'ant-alert-error';
                  }
                  return '';
                }}
                size="small"
              />
            </Col>
            <Col xs={16} style={{borderLeft: '1px solid black'}}>
              <Tabs>
                <TabPane tab="Bar Chart" key="BarChart" style={{borderLeft: '1px solid black', width: '100%', height: '390px'}}>
                  {this.state.barchartFilter !== null && (
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({barchartFilter: null})
                      }}
                    >
                      Clear Filter <b><i>{this.state.barchartFilter}</i></b>
                    </Button>
                  )}
                  <ResponsiveContainer>
                    <BarChart
                      data={barData}
                      layout="vertical"
                      barCategoryGap={1}
                      margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                    >
                      <XAxis type="number" hide />
                      <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" />

                      <Tooltip
                        formatter={(value) => {
                          if (value >= 0 && totalParcels > 0) {
                            return `${value} (${(value / totalParcels * 100.0).toFixed(2)}%)`;
                          }
                          return null;
                        }}
                      />

                      <Bar
                        dataKey="Parcels Shipped"
                        fill="green"
                        onClick={({name}) => {
                          if (name.includes('Days')) {
                            this.setState({
                              barchartFilter: name,
                            });
                          } else {
                            message.error('this bar is not supported');
                          }
                        }}
                      >
                        <LabelList
                          formatter={(value) => {
                            if (value >= 0 && totalParcels > 0) {
                              return `${value} (${(value / totalParcels * 100.0).toFixed(2)}%)`;
                            }
                            return null;
                         }}
                          position="right"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </TabPane>
                <TabPane tab="Pie Chart" key="PieChart" style={{borderLeft: '1px solid black', maxHeight: '390px'}}>
                  <Alert
                    message={
                      <div>
                        <div>
                          <span style={{color: PIE_COLORS[0]}}>Processing, Allocating Stock, Being Picked, Partially Picked</span> <b>Versus</b> <span style={{color: PIE_COLORS[1]}}>Partially Shipped, Shipped</span>
                        </div>
                        <small>Excluding Cancelled, Back Order, Click & Collect</small>
                      </div>
                  }
                    type="warning"
                  />

                  <div style={{height: '250px'}}>
                    <ResponsiveContainer style={{width: '100%', height: '250px'}}>
                      <PieChart width={300} height={200}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={pieData}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          labelLine={false}
                          label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
                            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                            const y = cy + radius * Math.sin(-midAngle * RADIAN);

                            return (
                              <text x={x} y={y} fill="gba(33, 33, 33)" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                                {`${(percent * 100).toFixed(0)}% (${value})`}
                              </text>
                            );
                          }}
                        >
                          {pieData.map((entry, index) => (
                            <Cell key={entry.name} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                          ))}
                        </Pie>
                        <Legend verticalAlign="top" height={36} />
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  renderSendEmail = (roles, stores) => {
    const { locations, selectedRows } = this.state;

    if (!this.isSuperAdminRole(roles)) {
      return null;
    }

    const sendEmail = (
      <SendEmail locations={locations} stores={stores} selectedRows={selectedRows} />
    );

    return (
      <>
        {selectedRows.length === 0 ? (
          sendEmail
        ) : (
          <Affix offsetTop={10}>
            {sendEmail}
          </Affix>
        )}
      </>
    );
  }

  render() {
    const { viewer } = this.props;
    const { activeKey, excludeNonStockPreorder, expandedRowKeys, loading } = this.state;

    const orders = get(this.props.viewer, 'salesReport', []).filter(({node}) => {
      let retValue = true;

      if (this.state.barchartFilter !== null) {
        // only include orders shipped in targeted range
        retValue = getShippingKey(node) === this.state.barchartFilter;
      }

      if (retValue && excludeNonStockPreorder && node.lines.find(({name}) => name.includes('[Non Stock]') || name.includes('[Pre-Order]'))) {
        return false;
      }
      return retValue;
    });
    const stores = get(viewer, 'stores.edges', []);
    const roles = get(viewer, 'roles.edges', []);

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        <RangePicker
          defaultValue={this.dateRange}
          onChange={this.onChange}
          onOpenChange={open => {
            // if popup panel is closing
            if (!open) {
              this.refetch(this.dateRange);
            }
          }}
          timezone={TIMEZONE}
        />

        <Select
          allowClear
          mode="multiple"
          showSearch
          onChange={locations => {
            this.setState({locations}, () => {
              this.refetch(this.dateRange, { force: true});
            })
          }}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          value={this.state.locations}
          placeholder="Select a location"
          style={{marginLeft: '20px', minWidth: '200px'}}
        >
          {stores.map(({node}) => {
            const style={};

            if (!node.distributionHub && node.branchCode !== '05') {
              style.color = 'red';
            }

            return <Option key={node.id} value={node.branchCode} style={style}>{node.name}</Option>
          })}
        </Select>

        <div style={{display: 'inline-block', marginLeft: '20px', color: 'green'}}>
          Exclude Non Stock/Pre Orders: <Switch onChange={exclude => {
            const totalOrders = get(this.props.viewer, 'salesReport', []).filter(({node}) => {
              if (exclude && node.lines.find(({name}) => name.includes('[Non Stock]') || name.includes('[Pre-Order]'))) {
                return false;
              }
              return true;
            });

            this.setState({
              totalOrders,
              excludeNonStockPreorder: exclude
            });

          }}
                                        />
        </div>

        {this.renderSendEmail(roles, stores)}

        <Tabs activeKey={activeKey} onChange={(key) => { this.setState({ activeKey: key }); }}>
          <TabPane tab="Overview" key="Overview">
            <Table
              id="salesReportOverview"
              sticky
              title={() => this.renderHeader(orders, roles)}
              dataSource={orders}
              rowKey={record => record.node.id}
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedRows });
                }
              }}
              onChange={(_pagination, filters, _sorter, extra) => {
                this.setState({
                  totalOrders: get(extra, 'currentDataSource', []),
                  activeFilters: filters,
                });
              }}
              pagination={{
                position: 'both',
                defaultPageSize: 50,
              }}
              expandedRowRender={expandedRowRender}
              expandedRowKeys={expandedRowKeys}
              expandRowByClick
              loading={loading}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'node',
                  key: 'name',
                  render: node => {
                    return (
                      <span>
                        <a href={`/sales/order/${node.id}`} rel="noopener noreferrer" target="_blank">{node.name}</a>
                        <CopyToClipboard text={node.name} onCopy={() => message.success("copied")}>
                          <CopyOutlined style={{marginLeft: '5px', fontSize: '20px', cursor: 'pointer' }} title="Copy" />
                        </CopyToClipboard>
                      </span>
                    );
                  }
                },
                {
                  title: 'Status',
                  dataIndex: 'node',
                  filters: SHIPPING_STATUSES,
                  sorter: ({node: a}, {node: b}) => {
                    const aStatus = a.shipping.status + getShippingDay(a);
                    const bStatus = b.shipping.status + getShippingDay(b);

                    if (aStatus > bStatus) {
                      return 1;
                    }

                    if (aStatus < bStatus) {
                      return -1;
                    }

                    return 0;
                  },
                  filteredValue: this.state.activeFilters.shippingStatus || null,
                  onFilter: (value, { node: { shipping: { status }} }) => value === status,
                  key: 'shippingStatus',
                  render: (node) => {
                    return (
                      <Status node={node} />
                    );
                  }
                },
                {
                  key: 'branches',
                  title: 'Branches',
                  dataIndex: 'node',
                  sorter: ({node: a}, {node: b}) => {
                    const { codeToName } = this.state;
                    const aBranchCode = get(a, 'shipping.locations[0]');
                    const bBranchCode = get(b, 'shipping.locations[0]');
                    const aName = (codeToName[aBranchCode] || '').toUpperCase();
                    const bName = (codeToName[bBranchCode] || '').toUpperCase();

                    if (aName < bName) {
                      return -1;
                    }

                    if (aName > bName) {
                      return 1;
                    }

                    return 0;
                  },
                  render: ({shipping}) => {
                    return getBranches(stores, shipping);
                  }
                },
                {
                  title: 'Purchased On',
                  dataIndex: ['node', 'insertedAt'],
                  sorter: (rowA, rowB) => Date.parse(rowA.node.insertedAt) - Date.parse(rowB.node.insertedAt),
                  render: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
                },
                {
                  title: 'Grand Total',
                  dataIndex: 'node',
                  defaultSortOrder: 'descend',
                  sorter: (rowA, rowB) => rowA.node.grandTotal - rowB.node.grandTotal,
                  render: ({grandTotal}) => `$${grandTotal}`
                },
              ]}
              footer={() => {
                if (this.isSalesRole(roles)) {
                  return null;
                }

                return (
                  <Button
                    onClick={() => {
                      if (this.state.totalOrders.length === 0) {
                        message.error('Nothing to export');
                        return;
                      }

                      const exportOrders = this.state.totalOrders.map(({node}) => {
                        return {
                          name: node.name,
                          grand_total: node.grandTotal,
                          inserted_at: node.insertedAt,
                          shipping_status: node.shipping.status,
                          branches: get(node.shipping, 'locations', []).join(","),
                        };
                      });

                      const headers = Object.keys(exportOrders[0]);

                      const data = [headers].concat(exportOrders.map(o => (
                        headers.map(h => {
                          const val = o[h];

                          if (Array.isArray(val)) {
                            return val.toString();
                          }
                            return val;

                        })
                      )))
                      .map(e => e.flat().map(i => {
                        if ( i != null ) {
                          const escape = String(i).replace(/"/g, '\"\"'); // eslint-disable-line no-useless-escape
                            return `"${escape}"`;
                          }
                        return `""`;
                      }
                      ).join(",")
                      ).join("\n");

                      const csv = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;

                      const link = document.createElement("a");
                      link.setAttribute("href", csv);

                      const timeNow = moment().format('YYYY-MM-DD-HH:mm:ss');

                      link.setAttribute("download", `Export-Orders-${timeNow}.csv`);

                      document.body.appendChild(link);

                      link.click();
                    }}
                  >
                    Download
                  </Button>
                );
              }}
            />
          </TabPane>
          <TabPane tab="Break Down" key="Break Down">
            <BreakDown
              loading={this.state.loading}
              orders={this.state.totalOrders}
              stores={this.props.viewer.stores}
              excludeNonStockPreorder={excludeNonStockPreorder}
              viewOrders={this.viewOrders}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default createRefetchContainer(
  SalesReport, {
    viewer: graphql`
    fragment SalesReport_viewer on Admin {
      roles(first: 99){
        edges {
          node {
            name
          }
        }
      }
      salesReport(orderBy: $orderBy, filterBy: $filterBy)
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            canPickup
            state
            status
            branchCode
            distributionHub
            emails
            managerEmails
            stateManager {
              emails
            }
          }
        }
      }
    }
  `,
  },
  graphql`
    query SalesReportRefetchQuery($orderBy: OrderBy, $filterBy: [FilterBy]) {
      viewer {
        ...SalesReport_viewer
      }
    }
  `,
);
