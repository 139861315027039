import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, Popconfirm } from 'antd';

import { UpdateVerificationMutation } from './mutations';

const NOT_YET = 0;
const VERIFIED = 1;
const VOIDED = 2;
const FAILED = 3;

class SalesOrderVerification extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    order: PropTypes.shape({
      id: PropTypes.string,
      verified: PropTypes.number,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  updateVerification = (order, status) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: order.id,
          status,
        },
      },
      viewer: this.props.viewer,
    };
    UpdateVerificationMutation.commit(mutation);
  }

  render() {
    const { order } = this.props;

    if (order.verified !== null) {
      return (
        <div>
          {order.verified !== VERIFIED && (
          <Popconfirm
            title="Are you sure to verify this order?"
            onConfirm={() => { this.updateVerification(order, VERIFIED);}}
          >
            <Button type="primary">Verify</Button>
          </Popconfirm>
          )}

          {(order.verified === NOT_YET || order.verified === FAILED) && (
          <Popconfirm
            title="Are you sure to void this order?"
            onConfirm={() => {this.updateVerification(order, VOIDED);}}
          >
            <Button type="danger">Void</Button>
          </Popconfirm>
          )}
        </div>
      );
    }
    return null;
  }
}

export default createFragmentContainer(SalesOrderVerification, {
  viewer: graphql`
    fragment SalesOrderVerification_viewer on Admin {
      id
    }
  `,
  order: graphql`
    fragment SalesOrderVerification_order on SalesOrder {
      id
      verified
    }
  `,
});

