import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';

import { fromGlobalId, getLink } from '../../helper';
import Grid from './Grid';
import DropdownFilter from './DropdownFilter';

const valueGetter = function valueGetter(fieldName, { data }) {
  const val = data && (Object.prototype.hasOwnProperty.call(data, 'node') ? get(data.node, fieldName) : get(data, fieldName));

  return val;
};

const dateGetter = function dateGetter(fieldName, { data }) {
  const val = data && (Object.prototype.hasOwnProperty.call(data, 'node') ? get(data.node, fieldName) : get(data, fieldName));
  return val ? moment(val).format('DD-MM-YYYY') : null;
};

const dateTimeGetter = function dateTimeGetter(fieldName, { data }) {
  const val = data && (Object.prototype.hasOwnProperty.call(data, 'node') ? get(data.node, fieldName) : get(data, fieldName));
  return val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : null;

};

const iDGetter = function iDGetter(fieldName, { data }) {
  const val = data && (Object.prototype.hasOwnProperty.call(data, 'node') ? get(data.node, fieldName) : get(data, fieldName));
  if (val) {
    return fromGlobalId(val).id;
  }
  return null;
};

class ImageCellRenderer extends React.Component {
  static propTypes = {
    value: PropTypes.string,
  }

  static defaultProps = {
    value: null,
  }

  render() {
    const imageUrl = this.props.value;
    if (imageUrl) {
      return (
        <img alt="" style={{ maxWidth: '100%', height: 'auto' }} width="100" src={imageUrl} />
      );
    }
    return <span>N/A</span>;
  }
}

const WebsiteRefCellRender = (props) => {
  const { id, country } = get(props, 'value') || {};

  if (country && id) {
    const url = getLink("product", country, id);

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">{id}</a>
    )
  }

  return null;
}

const currencyFormatter = (params) => {
  const auFormat = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
  });
  return params.value ? auFormat.format(params.value) : null;
};

const BOOLEANS = {
  true: 'True',
  false: 'False',
};

const BooleanDropdownFilter = {
  floatingFilterComponent: 'DropdownFilter',
  floatingFilterComponentParams: { dropdownData: BOOLEANS, suppressFilterButton: true },
};

module.exports = {
  Grid,
  valueGetter,
  dateGetter,
  dateTimeGetter,
  iDGetter,
  getPageSize: Grid.getPageSize,
  ImageCellRenderer,
  WebsiteRefCellRender,
  currencyFormatter,
  DropdownFilter,
  BooleanDropdownFilter,
};
