/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SearchCanonicalView_viewer$ref = any;
export type routes_SearchCanonicalView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_SearchCanonicalView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SearchCanonicalView_viewer$ref
  |}
|};
export type routes_SearchCanonicalView_Query = {|
  variables: routes_SearchCanonicalView_QueryVariables,
  response: routes_SearchCanonicalView_QueryResponse,
|};
*/


/*
query routes_SearchCanonicalView_Query(
  $ids: [ID]
) {
  viewer {
    ...SearchCanonicalView_viewer
    id
  }
}

fragment SearchCanonicalView_viewer on Admin {
  id
  searchCanonicals(first: 1, ids: $ids) {
    edges {
      node {
        id
        queries
        url
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SearchCanonicalView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchCanonicalView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_SearchCanonicalView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "SearchCanonicalConnection",
            "kind": "LinkedField",
            "name": "searchCanonicals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchCanonicalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchCanonical",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "queries",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd71bbb567a3a05b54ccae8f0e39d144",
    "id": null,
    "metadata": {},
    "name": "routes_SearchCanonicalView_Query",
    "operationKind": "query",
    "text": "query routes_SearchCanonicalView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...SearchCanonicalView_viewer\n    id\n  }\n}\n\nfragment SearchCanonicalView_viewer on Admin {\n  id\n  searchCanonicals(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        queries\n        url\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2daf329bf8741f3a35b64dfe6e12b899';

module.exports = node;
