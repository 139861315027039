import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Divider, Form, Input, InputNumber, message, Select, Switch, Table, Tabs } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { fromGlobalId } from '~/helper';
import CouponHistory from './CouponHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class CouponForm extends FormBase {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    coupon: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
    }),
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    coupon: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      showActualRelatedOrders: false
    };
  }

  componentDidCatch = () => {
    message.error('an error crashed the page, please try again')
  }

  disabledDate = (current) => current && current < moment().endOf('day')

  toggleOrderFilter = () => {
    const { showActualRelatedOrders } = this.state;
    this.setState({showActualRelatedOrders: !showActualRelatedOrders});
  }

  renderOrders = orders => { // eslint-disable-line react/display-name
    const { showActualRelatedOrders } = this.state;
    if (showActualRelatedOrders) {
      orders = orders.filter(({ node }) => node.lines.edges.find(({node: line}) => line.unitDiscount));
    }

    const g = orders.reduce((acc, { node }) => acc + node.grandTotal , 0.0);

    if (orders) {
      return (
        <Table
          dataSource={orders}
          rowKey={record => record.node.id}
          title={() => (
            <div>
              <div>Show Actual Related Orders <Switch checked={this.state.showActualRelatedOrders} onChange={this.toggleOrderFilter} /></div>
              <small>There are orders that used the coupon but none of the items are related to this promotion</small>
            </div>
          )}
          columns={[
            {
              title: 'View',
              dataIndex: 'node',
              render: node => <a href={`/sales/order/${fromGlobalId(node.id).id}`} rel="noopener noreferrer" target="_blank">{node.name}</a>, // eslint-disable-line react/display-name
            },
            {
              title: 'Grand Total',
              dataIndex: ['node', 'grandTotal'],
              defaultSortOrder: 'descend',
              sorter: (rowA, rowB) => rowA.node.grandTotal - rowB.node.grandTotal,
              render: value => `$${value}`,
            },
            {
              title: 'Inserted At',
              dataIndex: ['node', 'insertedAt'],
              sorter: (rowA, rowB) => moment(rowA.node.insertedAt).isAfter(rowB.node.insertedAt),
              render: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
            },
          ]}
          footer={() => `Grand Total Sum: $${Intl.NumberFormat().format(g)}`}
        />
      );
    }

    return null;
  }

  getContent = (content) => content.map((c) => {
    const conditions = {
      ...c.conditions,
      dataType: get(c, 'conditions.data_type'),
      value: [].concat(get(c, 'conditions.entities', [])).map(SelectProduct.productOptions),
    };

    const actions = {
      ...c.actions,
      dataType: get(c, 'actions.data_type'),
      value: [].concat(get(c, 'actions.entities', [])).map(SelectProduct.productOptions),
    };

    delete conditions.entities;
    delete conditions.data_type;
    delete actions.entities;
    delete actions.data_type;

    return {
      id: c.id,
      discountType: c.discountType,
      discountAmount: c.discountAmount,
      conditions,
      actions,
      removalDate: c.removalDate ? moment(c.removalDate) : null,
      removalProductIds: [].concat(get(c, 'removalProducts', [])).map(SelectProduct.productOptions),
      additionDate: c.additionDate ? moment(c.additionDate) : null,
      additionProductIds: [].concat(get(c, 'additionProducts', [])).map(SelectProduct.productOptions),
    }
  })

  render() {
    const { match, viewer } = this.props;
    const coupon = get(this.props, 'coupon', {});
    const orders = get(coupon, 'salesOrders.edges', []);

    const defaultGroup = {
      discountAmount: coupon.discountAmount,
      discountType: coupon.discountType,
      conditions: coupon.conditions || { key: 'id', value: [], comparator: 'eq', dataType: 'string' },
      actions: coupon.actions || { key: 'id', value: [], comparator: 'eq', dataType: 'string' },
    };

    const initialValue = {
      content: get(coupon, 'content', []).length ? this.getContent(coupon.content) : [defaultGroup],
    };

    return (
      <Tabs defaultActiveKey="general">
        <TabPane tab="General" key="general">
          <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values, { setLoading: this.setLoading }); }}>

            <Affix>
              <div>
                <Presence match={match} disableButton={this.handleDisableBtn} />
                {this.renderSaveButton()}
                {coupon.id && (
                <React.Fragment>
                  <a href={`/api/promotion/download?id=${coupon.id}`} target="_blank" rel="noopener noreferrer">Download</a>
                </React.Fragment>
                )}
              </div>
            </Affix>

            <h2>DO NOT RE-CYCLE PROMOTION</h2>
            <FormItem
              name="id"
              initialValue={coupon.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={coupon.name}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Coupon Codes"
              name="codes"
              rules={[{ required: true, message: 'required' }]}
              initialValue={coupon.codes}
              extra="Codes must be unique, no coupons can share a code"
            >
              <Select
                mode="tags"
                placeholder="Codes"
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Auto Apply Coupon Codes"
              name="autoApply"
              rules={[{ required: true, message: 'required' }]}
              valuePropName="checked"
              initialValue={get(coupon, 'autoApply', false)}
              extra="Codes will automatically applied to products, without customers doing anything"
            >
              <Switch />
            </FormItem>

            <Divider />

            <Form.List name="content" initialValue={initialValue.content}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }, i) => (
                    <div key={key}>
                      <FormItem noStyle shouldUpdate>
                        {({ getFieldValue }) => getFieldValue(["content", name, "id"]) ? (
                          <FormItem
                            name={[name, "id"]}
                            rules={[{ required: true, message: 'required' }]}
                            hidden
                          >
                            <Input />
                          </FormItem>
                        ) : null}
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label=" "
                      >
                        <h2>Group {i+1} <Button shape="circle" onClick={() => remove(name)}><DeleteOutlined /></Button></h2>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Discount Type"
                        name={[name, "discountType"]}
                        rules={[{ required: true, message: 'required' }]}
                      >
                        <Select placeholder="Discount Type">
                          <Option value={0}>Fixed Amount</Option>
                          <Option value={1}>Percentage</Option>
                        </Select>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Discount Amount"
                        name={[name, "discountAmount"]}
                        rules={[{ required: true, message: 'required' }]}
                        extra="If discount type is percentage, 5 means 5%. If discount type is fixed amount, 5 means $5."
                      >
                        <InputNumber min={0} placeholder="Discount Amount" />
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Conditions"
                        required
                      >
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          <span>When &nbsp;</span>
                          <FormItem
                            name={[name, "conditions", "key"]}
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Select style={{width: '100px'}} placeholder="Properties">
                              <Option value="id">ID</Option>
                            </Select>
                          </FormItem>
                          <span>&nbsp; is &nbsp;</span>
                        </div>

                        <FormItem
                          name={[name, "conditions", "value"]}
                          rules={[{ required: true, message: 'required' }]}
                        >
                          <SelectProduct
                            placeholder="Products"
                            mode="multiple"
                            labelInValue
                            filterOption={false}
                            viewer={viewer}
                          />
                        </FormItem>

                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            const conditions = getFieldValue(["content", name, "conditions"]);
                            const count = get(conditions, 'value', []).length;

                            return <>({count} selected)</>
                          }}
                        </FormItem>

                        <FormItem
                          name={[name, "conditions", "comparator"]}
                          rules={[{ required: true, message: 'required' }]}
                          hidden
                        >
                          <Input />
                        </FormItem>

                        <FormItem
                          name={[name, "conditions", "dataType"]}
                          rules={[{ required: true, message: 'required' }]}
                          hidden
                        >
                          <Input />
                        </FormItem>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Actions"
                        required
                      >
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          <span>Applies on when &nbsp;</span>
                          <FormItem
                            name={[name, "actions", "key"]}
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Select style={{width: '100px'}} placeholder="Properties">
                              <Option value="id">ID</Option>
                            </Select>
                          </FormItem>
                          <span>&nbsp; is &nbsp;</span>
                        </div>

                        <FormItem
                          name={[name, "actions", "value"]}
                          rules={[{ required: true, message: 'required' }]}
                        >
                          <SelectProduct
                            placeholder="Products"
                            mode="multiple"
                            labelInValue
                            filterOption={false}
                            viewer={viewer}
                          />
                        </FormItem>

                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            const actions = getFieldValue(["content", name, "actions"]);
                            const count = get(actions, 'value', []).length;

                            return <>({count} selected)</>
                          }}
                        </FormItem>

                        <FormItem
                          name={[name, "actions", "comparator"]}
                          rules={[{ required: true, message: 'required' }]}
                          hidden
                        >
                          <Input />
                        </FormItem>

                        <FormItem
                          name={[name, "actions", "dataType"]}
                          rules={[{ required: true, message: 'required' }]}
                          hidden
                        >
                          <Input />
                        </FormItem>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Remove Products At"
                      >
                        <FormItem name={[name, "removalDate"]}>
                          <DatePicker placeholder="Removal Date" disabledDate={this.disabledDate} />
                        </FormItem>

                        <FormItem name={[name, "removalProductIds"]}>
                          <SelectProduct
                            placeholder="Products"
                            mode="multiple"
                            labelInValue
                            filterOption={false}
                            viewer={viewer}
                          />
                        </FormItem>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Add Products At"
                      >
                        <FormItem name={[name, "additionDate"]}>
                          <DatePicker placeholder="Addition Date" disabledDate={this.disabledDate} />
                        </FormItem>

                        <FormItem name={[name, "additionProductIds"]}>
                          <SelectProduct
                            placeholder="Products"
                            mode="multiple"
                            labelInValue
                            filterOption={false}
                            viewer={viewer}
                          />
                        </FormItem>
                      </FormItem>

                      <Divider />
                    </div>
                  ))}
                  <FormItem
                    {...formItemLayout}
                    label="Add Discount Group"
                  >
                    <Button onClick={() => add(defaultGroup)}>
                      <PlusOutlined />
                    </Button>
                  </FormItem>
                </>
              )}
            </Form.List>

            <FormItem
              {...formItemLayout}
              label="Usage Limit"
              name="usageLimit"
              initialValue={coupon.usageLimit}
              extra="0 means unlimited"
            >
              <InputNumber min={0} placeholder="Limit" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Usage Limit Per Customer"
              name="usagePerCustomer"
              initialValue={coupon.usagePerCustomer}
              extra="0 means unlimited"
            >
              <InputNumber min={0} placeholder="Limit" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="How Many Times Used"
            >
              {coupon.timesUsed}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={coupon.startDate ? moment(coupon.startDate) : null}
            >
              <DatePicker placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={coupon.endDate ? moment(coupon.endDate) : null}
            >
              <DatePicker placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Show Label"
              name="showLabel"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(coupon, 'showLabel', 0) ? 1 : 0}
            >
              <Select placeholder="Show Label">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(coupon, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>

          </Form>
        </TabPane>
        <TabPane tab="Orders" key="orders" >
          {this.renderOrders(orders)}
        </TabPane>
        {coupon.id && (
          <TabPane tab="History" key="history">
            <CouponHistory viewer={viewer} coupon={coupon} />
          </TabPane>
        )}
      </Tabs>
    );
  }
}
export default createFragmentContainer(CouponForm, {
  viewer: graphql`
    fragment CouponForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...CouponHistory_viewer
      ...SelectProduct_viewer
    }
  `,
});
