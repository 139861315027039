import { Socket, Presence } from 'phoenix';
import React from 'react';
import { Button, message, notification } from 'antd';
import { SyncOutlined } from '@ant-design/icons'
import { throttle, get } from 'lodash';
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('auth_token');

const socketName = "backend";
const placement = "topRight";

const phoenixSocket = new Socket(`${((window.location.protocol === 'https:') ? 'wss://' : 'ws://') + window.location.host}/${socketName}-socket`, {
  params: {user_id: token}
});

const openNotification = function openNotification(key, title, goBack = false) {
  let description = (
    <Button onClick={() => window.location.reload()}>Refresh Now</Button>
  );

  if (goBack) {
    description = (
      <Button
        onClick={() => {
          window.history.back();
          notification.close(key);
        }}
      >
        Go Back
      </Button>
    );
  }

  notification.open({
    key,
    message: title,
    icon: <SyncOutlined />,
    description,
    duration: 0,
    placement,
    onClose: () => {
      if (goBack) {
        window.history.back();
      } else {
        window.location.reload();
      }
    },
  });
}

const init = function connect(VERSION) {
  phoenixSocket.connect();

  const channel = phoenixSocket.channel(`${socketName}:lobby`, {});
  channel.join()
    .receive('ok', () => {
    })
    .receive('error', (resp) => { console.error('Unable to join', resp); });

  channel.on('new_version', () => {
    fetch('/static/app_version').then((response) => {
      if (response.status === 404) {
        // force reload if 404
        return 'dummy_version';
      }
      return response.text();
    })
    .then((version) => {
      if (VERSION !== version) {
        notification.close('version');

        notification.open({
          key: 'version',
          message: 'Website Updated!',
          icon: <SyncOutlined />,
          description: <Button onClick={() => window.location.reload()}>Refresh Now</Button>,
          duration: 0,
          placement,
          onClose: () => window.location.reload(),
        });
      }
    });

  });
};

const notifyPage = function notifyPage(channel, props) {
  const { disableButton } = props;

  channel.on('page_updated', (payload) => {
    const firstname = get(payload, 'user.firstname', "");
    const lastname = get(payload, 'user.lastname', "");
    const time = get(payload, 'updated_at');

    let updatedAt = moment().format('HH:mm:ss DD-MM-YYYY');

    if (time) {
      updatedAt = moment(time).format('HH:mm:ss DD-MM-YYYY');
    }

    if (disableButton && typeof disableButton === 'function') {
      disableButton(true);
    }

    const title = (
      <div>
        This page was updated by {firstname} {lastname},
        <br />
        on: {updatedAt}
        <br />
        Please refresh the page before making any changes.
      </div>
    );

    openNotification('pageUpdated', title);
  });

  channel.on('page_deleted', (payload) => {
    const firstname = get(payload, 'user.firstname', "");
    const lastname = get(payload, 'user.lastname', "");
    const time = get(payload, 'updated_at');

    let updatedAt = moment().format('HH:mm:ss DD-MM-YYYY');

    if (time) {
      updatedAt = moment(time).format('HH:mm:ss DD-MM-YYYY');
    }

    if (disableButton && typeof disableButton === 'function') {
      disableButton(true);
    }

    const title = (
      <div>
        This page was <b>Deleted</b> by {firstname} {lastname},
        <br />
        on: {updatedAt}
      </div>
    );

    openNotification('pageDeleted', title, true);
  });

  channel.on('message', (payload) => {
    const type = get(payload, 'type');
    const content = get(payload, 'content');

    if (Object.hasOwnProperty.call(message, type) && content) {
      message[type](content);
    }
  });
}

const frontendStatus = function frontendStatus(props, callback) {
  const channel = phoenixSocket.channel(`backend:status`, {});

  const presence = new Presence(channel);

  presence.onSync(throttle(() => {
    if (typeof callback === 'function') {
      callback(presence.list());
    }
  }, 5000));

  channel.join()
    .receive('ok', () => {
    })
    .receive('error', (resp) => { console.error("frontendStatus Unable to join", resp); });
}

const userPresence = function userPresence(props, callback) {
  notification.close('pageUpdated');
  notification.close('pageDeleted');

  let pathname = get(props, 'match.location.pathname', "");
  pathname = pathname.substring(1).replaceAll("/", "-");

  const channel = phoenixSocket.channel(`backend:${pathname}`, {});

  const presence = new Presence(channel);

  presence.onSync(() => {
    if (typeof callback === 'function') {
      callback(presence.list());
    }
  });

  channel.join()
    .receive('ok', () => {
    })
    .receive('error', (resp) => { console.error("userPresence Unable to join", resp); });

  notifyPage(channel, props);
};

const userPresenceUpdate = function userPresenceUpdate(event = "page_updated") {
  let pathname = get(window.location, 'pathname', "");
  pathname = pathname.substring(1).replaceAll("/", "-");

  const channel = phoenixSocket.channels.find(c => c.topic === `backend:${pathname}`);

  if (channel) {
    channel.push(event);
  }
}

const userPresenceLeaves = function userPresenceLeaves(props, newProps = null, callback = null) {
  notification.close('pageUpdated');
  notification.close('pageDeleted');

  let pathname = get(props, 'match.location.pathname', "");
  pathname = pathname.substring(1).replaceAll("/", "-");

  let channel = phoenixSocket.channel(`backend:${pathname}`, {});
  channel.leave().receive('ok', () => {});

  if (newProps) {
    pathname = get(newProps, 'match.location.pathname', "");
    pathname = pathname.substring(1).replaceAll("/", "-");

    channel = phoenixSocket.channel(`backend:${pathname}`, {});

    const presence = new Presence(channel);

    presence.onSync(() => {
      if (typeof callback === 'function') {
        callback(presence.list());
      }
    });

    channel.join()
      .receive('ok', () => {
      })
      .receive('error', (resp) => { console.error("userPresenceLeaves Unable to join", resp); });

    notifyPage(channel, newProps);
  }
}

const socket = {
  init,
  frontendStatus,
  userPresence,
  userPresenceLeaves,
  userPresenceUpdate,
};

export default socket;
