/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LabelHistory_label$ref = any;
export type UpdateLabelInput = {|
  bonusId?: ?string,
  brandId?: ?string,
  categoryId?: ?string,
  creditId?: ?string,
  endDate?: ?string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  freebieId?: ?string,
  fuseImage?: ?boolean,
  id: string,
  image?: ?any,
  name?: ?string,
  placement?: ?string,
  position?: ?number,
  productPageImage?: ?any,
  products?: ?$ReadOnlyArray<?string>,
  redemptionId?: ?string,
  startDate?: ?string,
  status?: ?boolean,
  type?: ?number,
|};
export type UpdateLabelMutationVariables = {|
  input: UpdateLabelInput
|};
export type UpdateLabelMutationResponse = {|
  +updateLabel: ?{|
    +label: ?{|
      +name: ?string,
      +brandId: ?string,
      +imageUrl: ?string,
      +productPageImageUrl: ?string,
      +placement: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +type: ?number,
      +position: ?number,
      +bonusId: ?string,
      +categoryId: ?string,
      +redemptionId: ?string,
      +creditId: ?string,
      +freebieId: ?string,
      +excludeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +status: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +products: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +sku: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
            +status: ?string,
          |}
        |}>
      |},
      +status: ?boolean,
      +fuseImage: ?boolean,
      +fuseImageJobs: ?$ReadOnlyArray<?{|
        +id: ?string,
        +args: ?any,
        +state: ?string,
        +insertedAt: ?string,
      |}>,
      +$fragmentRefs: LabelHistory_label$ref,
    |}
  |}
|};
export type UpdateLabelMutation = {|
  variables: UpdateLabelMutationVariables,
  response: UpdateLabelMutationResponse,
|};
*/


/*
mutation UpdateLabelMutation(
  $input: UpdateLabelInput!
) {
  updateLabel(input: $input) {
    label {
      name
      brandId
      imageUrl
      productPageImageUrl
      placement
      startDate
      endDate
      type
      position
      bonusId
      categoryId
      redemptionId
      creditId
      freebieId
      excludeProducts(first: 99999) {
        edges {
          node {
            id
            name
            status
            mainImage {
              id
              url
            }
          }
        }
      }
      products(first: 99999) {
        edges {
          node {
            id
            name
            sku
            mainImage {
              id
              url
            }
            status
          }
        }
      }
      status
      fuseImage
      fuseImageJobs {
        id
        args
        state
        insertedAt
      }
      ...LabelHistory_label
      id
    }
  }
}

fragment LabelHistory_label on Label {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productPageImageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placement",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bonusId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "redemptionId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creditId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freebieId",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": (v16/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "excludeProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v2/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "excludeProducts(first:99999)"
},
v21 = {
  "alias": null,
  "args": (v16/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sku",
              "storageKey": null
            },
            (v19/*: any*/),
            (v18/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "products(first:99999)"
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fuseImage",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "ObanJob",
  "kind": "LinkedField",
  "name": "fuseImageJobs",
  "plural": true,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "args",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v23/*: any*/)
  ],
  "storageKey": null
},
v25 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateLabelPayload",
        "kind": "LinkedField",
        "name": "updateLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "label",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v18/*: any*/),
              (v22/*: any*/),
              (v24/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LabelHistory_label"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateLabelPayload",
        "kind": "LinkedField",
        "name": "updateLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "label",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v18/*: any*/),
              (v22/*: any*/),
              (v24/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": (v25/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v25/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "LabelHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "444b2a3568d4d265db20fddbd55c692d",
    "id": null,
    "metadata": {},
    "name": "UpdateLabelMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLabelMutation(\n  $input: UpdateLabelInput!\n) {\n  updateLabel(input: $input) {\n    label {\n      name\n      brandId\n      imageUrl\n      productPageImageUrl\n      placement\n      startDate\n      endDate\n      type\n      position\n      bonusId\n      categoryId\n      redemptionId\n      creditId\n      freebieId\n      excludeProducts(first: 99999) {\n        edges {\n          node {\n            id\n            name\n            status\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      products(first: 99999) {\n        edges {\n          node {\n            id\n            name\n            sku\n            mainImage {\n              id\n              url\n            }\n            status\n          }\n        }\n      }\n      status\n      fuseImage\n      fuseImageJobs {\n        id\n        args\n        state\n        insertedAt\n      }\n      ...LabelHistory_label\n      id\n    }\n  }\n}\n\nfragment LabelHistory_label on Label {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a72fd59d3006ec6297f01310c1bce11b';

module.exports = node;
