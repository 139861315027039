import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateProductMutation($input: UpdateProductInput!) {
    updateProduct(input:$input) {
      product {
        alerts(first: 99) @connection(key: "ProductView_alerts") {
          edges {
            node {
              id
              message
              startDate
              endDate
              status
            }
          }
        }
        brandId
        partialName
        name
        model
        adenCode
        odooCode
        secondModel
        barcode
        sortingFactor
        enableDynamicSorting
        visibility
        sku
        urlSlug
        type
        description
        specification
        warranty
        finalPrice
        callForPrice
        priceDrop {
          enable
          startDate
          endDate
        }
        supersededBy {
          id
          name
          status
          mainImage {
            id
            url
          }
        }
        superseding {
          id
          name
          status
          mainImage {
            id
            url
          }
        }
        inStoreOnly
        onlineOnly
        nonStock
        price
        tierPrices
        specialPrice
        specialPriceStartDate
        specialPriceEndDate
        flyerPrice
        flyerPriceStartDate
        flyerPriceEndDate
        videos
        promoCard
        weight
        width
        length
        height
        bulkyGood
        backInStockDate
        preorderDate
        hideStockDate
        stockAvailable
        convertTo
        freeShipping
        freeShippingStartDate
        freeShippingEndDate
        hideGlobalFreeShipping
        excludeGlobalFreeShipping
        excludeExpressShipping
        dangerousGood
        status
        enableStartDate
        enableEndDate
        attributes
        metaDescription
        linkOnlyDays
        mainImage {
          id
          url
        }
        feedImage {
          id
          url
        }
        files(first: 99) @connection(key: "ProductView_files") {
          edges {
            node {
              id
              url
              name
            }
          }
        }
        images(first: 99) @connection(key: "ProductView_images") {
          edges {
            node {
              id
              url
              position
              showInKit
              status
              mainImageStartDate
              mainImageEndDate
            }
          }
        }
        related(first: 99) {
          edges {
            node {
              id
              name
              sku
              status
            }
          }
        }
        bundles(first: 99) {
          edges {
            node {
              id
              product {
                id
                name
                sku
                status
              }
              position
              quantity
            }
          }
        }
        configurableAttributes {
          id
          code
          name
        }
        configurables(first: 99) {
          edges {
            node {
              id
              position
              product {
                id
                name
                attributes
                visibility
                sku
                status
                sellable
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        categoryIds
        subcategoryIds
        searchScoreMultiplier
        ...ProductHistory_product
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
