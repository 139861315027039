import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Editor } from '~/components/form';

const { Item: FormItem } = Form;
const { Option } = Select;

const SubcategoryDescription = (props) => {
  const form = Form.useFormInstance();
  const { subcategory: { descriptions }, viewer } = props;
  const brands = get(viewer, 'brands.edges', []);
  const categories = get(viewer, 'categories.edges', []);

  const defaultValue = {
    categoryIds: [],
    brandIds: [],
    description: "",
    description2: "",
  };

  const initialValue = descriptions.edges.length ? descriptions.edges.slice(0).map(({ node }) => ({
    id: node.id,
    categoryIds: get(node, 'categories', []).map(p => ({ key: p.id, label: p.name })),
    brandIds: get(node, 'brands', []).map(p => ({ key: p.id, label: p.name })),
    description: node.description,
    description2: node.description2,
  })) : [];

  return (
    <Form.List name="descriptions" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => {
            const b = form.getFieldValue(["descriptions", name]) || {};

            return (
              <div key={key}>
                {b.id && (
                  <FormItem
                    name={[name, "id"]}
                    rules={[{ required: true, message: 'required' }]}
                    hidden
                  >
                    <Input />
                  </FormItem>
                )}

                <Row>
                  <Col xs={24}>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        style={{ cursor: 'pointer' }}
                        disabled={fields.length === 1}
                        onClick={() => remove(name)}
                      />
                  ) : null}
                  </Col>
                  <Col xs={12}>
                    <FormItem
                      label="On Categories"
                      name={[name, "categoryIds"]}
                      rules={[{ required: false, message: 'required' }]}
                      style={{ margin: '0px' }}
                    >
                      <Select
                        placeholder="Categories"
                        mode="multiple"
                        optionFilterProp="children"
                        optionLabelProp="children"
                        labelInValue
                      >
                        {categories.map(({ node: o}) => (
                          <Option key={o.id} value={o.id}>{o.name}</Option>
                      ))}
                      </Select>
                    </FormItem>
                  </Col>

                  <Col xs={12}>
                    <FormItem
                      label="On Brands"
                      name={[name, "brandIds"]}
                      rules={[{ required: false, message: 'required' }]}
                      style={{ margin: '0px' }}
                    >

                      <Select
                        placeholder="Brands"
                        mode="multiple"
                        optionFilterProp="children"
                        optionLabelProp="children"
                        labelInValue
                      >
                        {brands.map(({ node: o}) => (
                          <Option key={o.id} value={o.id}>{o.name}</Option>
                      ))}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>

                <FormItem
                  label="Description"
                >
                  <Editor
                    parentName="descriptions"
                    name={[name, "description"]}
                    editorState={b.description}
                    height={200}
                  />
                </FormItem>
                <FormItem
                  label="Description 2"
                >
                  <Editor
                    parentName="descriptions"
                    name={[name, "description2"]}
                    editorState={b.description2}
                    height={200}
                  />
                </FormItem>
                <Divider />
              </div>
          )})}
          <Button onClick={() => add(defaultValue)}>
            <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  )
};

SubcategoryDescription.propTypes = {
  viewer: PropTypes.shape({
    brands: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  subcategory: PropTypes.shape({
    descriptions: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}

export default createFragmentContainer(SubcategoryDescription, {
  viewer: graphql`
    fragment SubcategoryDescription_viewer on Admin {
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
