import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { importScript } from '~/helper';
import { get } from 'lodash';

const GOOGLE_URL = "https://accounts.google.com/gsi/client";

const getClientId = () => {
  const { COUNTRY } = process.env;

  if (COUNTRY === 'AU') {
    return '1075087343475-avpu6rl7k4f5pc5ntnjuei80kc6fpgmk.apps.googleusercontent.com';
  } else if (COUNTRY === 'NZ') {
    return '634602695617-vm6h55c8mls9j6n0o2aq50pk9i5p8p9u.apps.googleusercontent.com';
  }
  throw new Error(`recognised tld ${COUNTRY}`);
};

/*
 * Prefilled the domain.
 */
const getHd = () => {
  const { COUNTRY } = process.env;
  if (COUNTRY === 'AU') {
    return "sydneytools.com.au";
  } else if (COUNTRY === 'NZ') {
    return "sydneytools.co.nz";
  }

  throw new Error(`recognised tld ${COUNTRY}`);
};

const GoogleSignIn = (props) => {
  const { id, onSuccess, onFailure } = props;

  useEffect(() => {
    importScript(GOOGLE_URL, () => {
      const { accounts } = window.google ?? {};

      if (accounts) {
        accounts.id.initialize({
          client_id: getClientId(),
          hd: getHd(),
          callback: (resp) => {
            const credential = get(resp, "credential");

            if (credential) {
              onSuccess(credential);
            } else {
              onFailure("Google login failed.");
            }
          },
        });

        const element = document.getElementById(id);

        accounts.id.renderButton(element, {
          theme: 'outline',
          size: 'large',
          width: element.offsetWidth,
        });
      } else {
        console.error("Failed to load google sign-in")
      }
    });
  }, []);

  return (
    <div id={id} />
  )
};

GoogleSignIn.propTypes = {
  id: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

GoogleSignIn.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
};

export default GoogleSignIn;
