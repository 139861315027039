import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '~/mutation';

const mutation = graphql`
  mutation RemoveSearchCanonicalMutation($input: RemoveSearchCanonicalInput!) {
    removeSearchCanonical(input:$input) {
      removedSearchCanonicalId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeSearchCanonical', edgeName: 'removedSearchCanonicalId', connectionKey: 'SearchCanonicalList_searchCanonicals' }),
});
export default { commit };
