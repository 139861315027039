import React from 'react';
import { Route } from 'found';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/quote"
    getComponent={() => (
      import(/* webpackChunkName: "QuoteList" */'./QuoteList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_QuoteList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...QuoteList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >
    <Route />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "QuoteView" */'./QuoteView').then(module => module.default)
        .catch(() => {
          // window.location.reload();
        })
      )}
      query={graphql`
        query routes_QuoteView_Query ($ids: [ID]) {
          viewer {
            ...QuoteView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('SalesQuote', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
