import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation CompleteOrderPaymentMutation($input: CompleteOrderPaymentInput!) {
    completeOrderPayment(input:$input) {
      salesOrder {
        status
        verified
        verification {
          firstAmount
          secondAmount
          verifiedAt
          verifiedBy
          completedBy
          attempts
        }
        ...SalesOrderHistory_salesOrder
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
