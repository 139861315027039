/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SearchCanonicalNew_viewer$ref = any;
export type routes_SearchCanonicalNew_QueryVariables = {||};
export type routes_SearchCanonicalNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SearchCanonicalNew_viewer$ref
  |}
|};
export type routes_SearchCanonicalNew_Query = {|
  variables: routes_SearchCanonicalNew_QueryVariables,
  response: routes_SearchCanonicalNew_QueryResponse,
|};
*/


/*
query routes_SearchCanonicalNew_Query {
  viewer {
    ...SearchCanonicalNew_viewer
    id
  }
}

fragment SearchCanonicalNew_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SearchCanonicalNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchCanonicalNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_SearchCanonicalNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b4ef33ca1daff31913e94bdb902f026",
    "id": null,
    "metadata": {},
    "name": "routes_SearchCanonicalNew_Query",
    "operationKind": "query",
    "text": "query routes_SearchCanonicalNew_Query {\n  viewer {\n    ...SearchCanonicalNew_viewer\n    id\n  }\n}\n\nfragment SearchCanonicalNew_viewer on Admin {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '17f59f3a4dcb1404433acbd27db7c0a4';

module.exports = node;
