import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation OauthLoginMutation($input: OauthLoginInput!) {
    oauthLogin(input: $input) {
      token
    }
  }
`;

const commit = buildMutation(mutation);
export default { commit };
