/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchCustomer_viewer$ref: FragmentReference;
declare export opaque type SearchCustomer_viewer$fragmentType: SearchCustomer_viewer$ref;
export type SearchCustomer_viewer = {|
  +getCustomerSuggestion: ?any,
  +$refType: SearchCustomer_viewer$ref,
|};
export type SearchCustomer_viewer$data = SearchCustomer_viewer;
export type SearchCustomer_viewer$key = {
  +$data?: SearchCustomer_viewer$data,
  +$fragmentRefs: SearchCustomer_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchCustomer_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "kind": "ScalarField",
      "name": "getCustomerSuggestion",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '97d439f6a34477463a10a35fa23e91dd';

module.exports = node;
