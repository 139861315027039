import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const CategorySuggestion = (props) => {
  if (props.suggestion.length === 0) {
    return null;
  }

  const { fieldId, form, formIds, suggestion } = props;

  return (
    <small style={{marginTop: '5px'}}>
      Suggested: {
        suggestion.sort((a, b) => formIds.includes(b.id) - formIds.includes(a.id)).map(s => {
          return (
            <Tag.CheckableTag
              key={s.id}
              checked={formIds.includes(s.id)}
              onChange={checked => {
                if (checked) {
                  form.setFieldValue(fieldId, formIds.concat([s.id]));
                } else {
                  form.setFieldValue(fieldId, formIds.filter(id => id !== s.id));
                }
              }}
            >{s.name}
            </Tag.CheckableTag>
          );
        })
      }
    </small>
  );
}

CategorySuggestion.propTypes = {
  suggestion: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  formIds: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  fieldId: PropTypes.string.isRequired,
};

export default CategorySuggestion;
