import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../../mutation';

const mutation = graphql`
  mutation RemoveLabelMutation($input: RemoveLabelInput!) {
    removeLabel(input:$input) {
      removedLabelId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeLabel', edgeName: 'removedLabelId', connectionKey: 'LabelList_labels' }),
});
export default { commit };
