/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveAddressInput = {|
  customerId: string,
  id: string,
|};
export type RemoveAddressMutationVariables = {|
  input: RemoveAddressInput
|};
export type RemoveAddressMutationResponse = {|
  +removeAddress: ?{|
    +removedAddressId: ?string
  |}
|};
export type RemoveAddressMutation = {|
  variables: RemoveAddressMutationVariables,
  response: RemoveAddressMutationResponse,
|};
*/


/*
mutation RemoveAddressMutation(
  $input: RemoveAddressInput!
) {
  removeAddress(input: $input) {
    removedAddressId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveAddressPayload",
    "kind": "LinkedField",
    "name": "removeAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedAddressId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveAddressMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "978d75d2639ab95f934e94311e99b7f0",
    "id": null,
    "metadata": {},
    "name": "RemoveAddressMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveAddressMutation(\n  $input: RemoveAddressInput!\n) {\n  removeAddress(input: $input) {\n    removedAddressId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c5e8247d0747456d6b3579d7cdeb54c';

module.exports = node;
