/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type GiftCardHistory_viewer$ref = any;
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type GiftCardForm_viewer$ref: FragmentReference;
declare export opaque type GiftCardForm_viewer$fragmentType: GiftCardForm_viewer$ref;
export type GiftCardForm_viewer = {|
  +$fragmentRefs: GiftCardHistory_viewer$ref & SelectProduct_viewer$ref,
  +$refType: GiftCardForm_viewer$ref,
|};
export type GiftCardForm_viewer$data = GiftCardForm_viewer;
export type GiftCardForm_viewer$key = {
  +$data?: GiftCardForm_viewer$data,
  +$fragmentRefs: GiftCardForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GiftCardForm_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GiftCardHistory_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3521ba0b6251fb415c4ee105f7c4b0e0';

module.exports = node;
