/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddLabelInput = {|
  bonusId?: ?string,
  brandId?: ?string,
  categoryId?: ?string,
  creditId?: ?string,
  endDate: string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  freebieId?: ?string,
  fuseImage?: ?boolean,
  image: any,
  name: string,
  placement: string,
  position?: ?number,
  productPageImage?: ?any,
  products?: ?$ReadOnlyArray<?string>,
  redemptionId?: ?string,
  startDate: string,
  status: boolean,
  type?: ?number,
|};
export type AddLabelMutationVariables = {|
  input: AddLabelInput
|};
export type AddLabelMutationResponse = {|
  +addLabel: ?{|
    +labelEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +brandId: ?string,
        +imageUrl: ?string,
        +productPageImageUrl: ?string,
        +placement: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +type: ?number,
        +position: ?number,
        +bonusId: ?string,
        +categoryId: ?string,
        +redemptionId: ?string,
        +creditId: ?string,
        +freebieId: ?string,
        +excludeProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +status: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +products: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +sku: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
              +status: ?string,
            |}
          |}>
        |},
        +status: ?boolean,
        +fuseImage: ?boolean,
      |},
    |}
  |}
|};
export type AddLabelMutation = {|
  variables: AddLabelMutationVariables,
  response: AddLabelMutationResponse,
|};
*/


/*
mutation AddLabelMutation(
  $input: AddLabelInput!
) {
  addLabel(input: $input) {
    labelEdge {
      cursor
      node {
        id
        name
        brandId
        imageUrl
        productPageImageUrl
        placement
        startDate
        endDate
        type
        position
        bonusId
        categoryId
        redemptionId
        creditId
        freebieId
        excludeProducts(first: 99999) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        products(first: 99999) {
          edges {
            node {
              id
              name
              sku
              mainImage {
                id
                url
              }
              status
            }
          }
        }
        status
        fuseImage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddLabelPayload",
    "kind": "LinkedField",
    "name": "addLabel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LabelEdge",
        "kind": "LinkedField",
        "name": "labelEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productPageImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "placement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bonusId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categoryId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "redemptionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creditId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freebieId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "excludeProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "excludeProducts(first:99999)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sku",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "products(first:99999)"
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fuseImage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddLabelMutation",
    "selections": (v6/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddLabelMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "7195a76d0963fb480772e6c97c35905a",
    "id": null,
    "metadata": {},
    "name": "AddLabelMutation",
    "operationKind": "mutation",
    "text": "mutation AddLabelMutation(\n  $input: AddLabelInput!\n) {\n  addLabel(input: $input) {\n    labelEdge {\n      cursor\n      node {\n        id\n        name\n        brandId\n        imageUrl\n        productPageImageUrl\n        placement\n        startDate\n        endDate\n        type\n        position\n        bonusId\n        categoryId\n        redemptionId\n        creditId\n        freebieId\n        excludeProducts(first: 99999) {\n          edges {\n            node {\n              id\n              name\n              status\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        products(first: 99999) {\n          edges {\n            node {\n              id\n              name\n              sku\n              mainImage {\n                id\n                url\n              }\n              status\n            }\n          }\n        }\n        status\n        fuseImage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d713f1ac19761a804dcbba1a03b792a';

module.exports = node;
