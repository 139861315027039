/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateConfigInput = {|
  configs?: ?string
|};
export type UpdateConfigMutationVariables = {|
  input: UpdateConfigInput
|};
export type UpdateConfigMutationResponse = {|
  +updateConfig: ?{|
    +configs: ?any
  |}
|};
export type UpdateConfigMutation = {|
  variables: UpdateConfigMutationVariables,
  response: UpdateConfigMutationResponse,
|};
*/


/*
mutation UpdateConfigMutation(
  $input: UpdateConfigInput!
) {
  updateConfig(input: $input) {
    configs
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateConfigPayload",
    "kind": "LinkedField",
    "name": "updateConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "configs",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateConfigMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "19cd8b3ef428a252fe3f3993070b7e6c",
    "id": null,
    "metadata": {},
    "name": "UpdateConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateConfigMutation(\n  $input: UpdateConfigInput!\n) {\n  updateConfig(input: $input) {\n    configs\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99f2956463a027d89b9b531cb2b688db';

module.exports = node;
