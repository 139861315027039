/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CouponHistory_viewer$ref = any;
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CouponForm_viewer$ref: FragmentReference;
declare export opaque type CouponForm_viewer$fragmentType: CouponForm_viewer$ref;
export type CouponForm_viewer = {|
  +$fragmentRefs: CouponHistory_viewer$ref & SelectProduct_viewer$ref,
  +$refType: CouponForm_viewer$ref,
|};
export type CouponForm_viewer$data = CouponForm_viewer;
export type CouponForm_viewer$key = {
  +$data?: CouponForm_viewer$data,
  +$fragmentRefs: CouponForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CouponForm_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CouponHistory_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'bd19a59cd8fb0e6d2b1bae9ee101bccc';

module.exports = node;
