import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { Helmet } from 'react-helmet';
import { fromGlobalId } from '~/helper';

import { AddSearchCanonicalMutation } from './mutations';
import SearchCanonicalForm from './SearchCanonicalForm';

// eslint-disable-next-line camelcase
const SearchCanonicalNew_viewer = graphql`
  fragment SearchCanonicalNew_viewer on Admin {
    id
  }
`
const SearchCanonicalNew = (props) => {
  const viewer = useFragment(SearchCanonicalNew_viewer, props.viewer);
  const environment = useRelayEnvironment();

  const handleSubmit = (form, values) => {
    const mutation = {
      environment,
      variables: { input: values },
      viewer,
      onCompleted: (resp) => {
        window.location.href = `/search/canonical/${fromGlobalId(resp.addSearchCanonical.searchCanonicalEdge.node.id).id}`;
      },
    }

    AddSearchCanonicalMutation.commit(mutation);
  };

  return (
    <div>
      <Helmet title="New Canonical" />

      <h1>New Canonical</h1>
      <SearchCanonicalForm viewer={viewer} match={props.match} onSubmit={handleSubmit} />
    </div>
  )
};

SearchCanonicalNew.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default SearchCanonicalNew;
