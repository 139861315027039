import React from 'react';
import { Route } from 'found';

import SalesOrderRoutes from './order/routes';
import SalesQuoteRoutes from './quote/routes';

const routes = (
  <Route
    path="/sales"
  >
    {SalesOrderRoutes}
    {SalesQuoteRoutes}
  </Route>
);

export default routes;
