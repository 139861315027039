import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import moment from 'moment-timezone';

import { fromGlobalId } from '../../../helper';
import { AddCouponMutation } from './mutations';
import CouponForm from './CouponForm';
import { checkDuplicated } from './helper';

class CouponNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues, { setLoading }) => {
    checkDuplicated(formValues.content);

    const values = {
      ...formValues,
      status: !!formValues.status,
      showLabel: !!formValues.showLabel,
      content: formValues.content.map((c) => {
        const group = {
          ...c,
          conditions: { ...c.conditions, value: get(c, 'conditions.value', []).map(opt => opt.key)},
          actions: { ...c.actions, value: get(c, 'actions.value', []).map(opt => opt.key)},
        };

        if (c.additionProductIds) {
          group.additionProductIds = c.additionProductIds.map(p => p.key);
        }

        if (c.removalProductIds) {
          group.removalProductIds = c.removalProductIds.map(p => p.key);
        }

        return group;
      }),
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: { insert: setLoading },
      onCompleted: (resp) => {
        window.location.href = `/promotion/coupon/${fromGlobalId(resp.addCoupon.couponEdge.node.id).id}`;
      },
      onError: (errors) => {
        message.error(errors[0].message);
        form.setFields([{
          name: 'startDate', value: moment(values.startDate),
        }, {
          name: 'endDate', value: moment(values.endDate),
        }]);
      },
    };

    AddCouponMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Coupon" />

        <h1>New Coupon</h1>
        <CouponForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(CouponNew, {
  viewer: graphql`
    fragment CouponNew_viewer on Admin {
      id
      ...CouponForm_viewer
    }
  `,
});
