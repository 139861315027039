import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, isEmpty } from 'lodash';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Item: FormItem } = Form;
const { Option } = Select;

const SubcategoryMetadata = (props) => {
  const form = Form.useFormInstance();
  const { subcategory: { metadata, name: subName, id: subId }, viewer } = props;
  const brands = get(viewer, 'brands.edges', []);
  const categories = get(viewer, 'categories.edges', []);
  const allSubcategories = get(viewer, 'allSubcategories.edges', []);
  const [canonicalUrls, setCanonicalUrls] = useState({});

  const defaultValue = {
    categoryIds: [],
    brandIds: [],
    metaTitle: '',
    metaDescription: '',
    canonicalUrl: {
      category: undefined,
      subcategories: [],
      status: undefined,
    }
  }

  const initialValue = metadata.edges.length ? metadata.edges.slice(0).map(({ node }) => ({
    id: node.id,
    categoryIds: get(node, 'categories', []).map(p => ({ key: p.id, label: p.name })),
    brandIds: get(node, 'brands', []).map(p => ({ key: p.id, label: p.name })),
    metaTitle: get(node, 'metaTitle', ''),
    metaDescription: get(node, 'metaDescription', ''),
    canonicalUrl: {
      category: {
        key: get(node, 'canonicalUrl.category.id', null),
        label: get(node, 'canonicalUrl.category.name', null),
      },
      subcategories: get(node, 'canonicalUrl.subcategories', []).map(p => ({ key: p.id, label: p.name })),
      status: get(node, 'canonicalUrl.status') || undefined,
    }
  })) : [];

  const toUrlSlug = (id, list) => {
    const matchedSubcategory = list.find(({ node }) => node.id === id);
    return matchedSubcategory ? matchedSubcategory.node.urlSlug : null;
  };

  useEffect(() => {
    initialValue.forEach((node, index) => {
      const category = node.canonicalUrl?.category?.key;
      const subcategories = (node.canonicalUrl?.subcategories || [])
        .map(subcat => toUrlSlug(subcat.key, allSubcategories))
        .join('/');

      const newCanonicalUrl = category ? `/${toUrlSlug(category, categories)}/${subcategories}` : undefined;

      setCanonicalUrls(prevUrls => ({
        ...prevUrls,
        [index]: newCanonicalUrl,
      }));
    });
  }, []);

  const canonicalLabel = () => (<span style={{ visibility: "hidden" }}>Canonical Url</span>);

  const canonicalNote = (key) => (
    <>
      The canonical URL is{' '}
      {canonicalUrls[key] ? (
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          {canonicalUrls[key]}
        </span>
      ) : (
        'undefined'
      )}
      . Selecting category and subcategories will update it.
    </>
  );

  const handleUrlChange = (name, key) => {
    const subcatPath = ["metadata", name, "canonicalUrl", "subcategories"];
    const category = form.getFieldValue(["metadata", name, "canonicalUrl", "category"])?.key;
    const subcategoryPart = (form.getFieldValue(subcatPath) || []).map(subcat => toUrlSlug(subcat.key, allSubcategories)).join('/');
    const newCanonicalUrl = category ? `/${toUrlSlug(category, categories)}/${subcategoryPart}` : undefined;

    setCanonicalUrls(prevUrls => ({
      ...prevUrls,
      [key]: newCanonicalUrl,
    }));
  }

  const onCategoryChange = (value, key, name) => {
    const subcatPath = ["metadata", name, "canonicalUrl", "subcategories"];
    const subcatValues = form.getFieldValue(subcatPath) || [];

    if (!value) {
      form.setFieldValue(subcatPath, []);
    } else if (isEmpty(subcatValues)) {
      form.setFieldValue(subcatPath, [{key: subId, label: subName}]);
    }

    handleUrlChange(name, key)
  };

  const showAddSubcatBtn = (name) => (get(form.getFieldValue(["metadata", name, "canonicalUrl", "category"]), "key", null));

  return (
    <Form.List name="metadata" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => (
            <div key={key}>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => getFieldValue(["metadata", name, "id"]) ? (
                  <FormItem
                    name={[name, "id"]}
                    rules={[{ required: true, message: 'required' }]}
                    hidden
                  >
                    <Input />
                  </FormItem>
                ) : null}
              </FormItem>

              <Row>
                <Col xs={24}>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      style={{ cursor: 'pointer' }}
                      disabled={fields.length === 1}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Col>
                <Col xs={12}>
                  <FormItem
                    label="On Categories"
                    name={[name, "categoryIds"]}
                    rules={[{ required: false, message: 'required' }]}
                    style={{ margin: '0px' }}
                  >

                    <Select
                      placeholder="Categories"
                      mode="multiple"
                      optionFilterProp="children"
                      optionLabelProp="children"
                      labelInValue
                    >
                      {categories.map(({ node: o }) => (
                        <Option key={o.id} value={o.id}>{o.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>

                <Col xs={12}>
                  <FormItem
                    label="On Brands"
                    name={[name, "brandIds"]}
                    rules={[{ required: false, message: 'required' }]}
                    style={{ margin: '0px' }}
                  >
                    <Select
                      placeholder="Brands"
                      mode="multiple"
                      optionFilterProp="children"
                      optionLabelProp="children"
                      labelInValue
                    >
                      {brands.map(({ node: o }) => (
                        <Option key={o.id} value={o.id}>{o.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
              </Row>

              <FormItem
                label="Meta Title"
                name={[name, "metaTitle"]}
              >
                <Input placeholder="Meta Title" />
              </FormItem>
              <FormItem
                label="Meta Description"
                name={[name, "metaDescription"]}
              >
                <Input placeholder="Meta Description" />
              </FormItem>
              <FormItem
                label="Canonical Url"
                name={[name, "canonicalUrl", "category"]}
                rules={[{ required: false, message: 'required' }]}
                style={{ margin: '0px' }}
                help={canonicalNote(key)}
              >

                <Select
                  placeholder="Category"
                  optionFilterProp="children"
                  optionLabelProp="children"
                  allowClear
                  labelInValue
                  showSearch
                  onChange={(value) => {onCategoryChange(value, key, name)}}
                >
                  {categories.map(({ node: o }) => (
                    <Option key={o.id} value={o.id}>{o.name}</Option>
                  ))}
                </Select>
              </FormItem>
              <Form.List name={[name, "canonicalUrl", "subcategories"]}>
                {(subcatFields, { add: subcatAdd, remove: subcatRemove }) => (
                  <>
                    {subcatFields.map(({key: subcatKey, name: subcatName}) => (
                      <div key={subcatKey} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormItem
                          label={canonicalLabel()}
                          colon={false}
                          name={subcatName}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ flexGrow: 1, margin: '0px' }}
                        >
                          <Select
                            placeholder="Subcategories"
                            optionFilterProp="children"
                            optionLabelProp="children"
                            labelInValue
                            showSearch
                            onChange={() => {handleUrlChange(name, key)}}
                          >
                            {allSubcategories.map(({ node: o }) => (
                              <Option key={o.id} value={o.id}>{o.name}</Option>
                            ))}
                          </Select>
                        </FormItem>
                        {subcatFields.length > 1 && (
                        <MinusCircleOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() =>{
                            subcatRemove(subcatName);
                            handleUrlChange(name, key);
                          }}
                        />
)}
                      </div>
                    ))}
                    <FormItem label={canonicalLabel()} colon={false}>
                      {showAddSubcatBtn(name) && (
                        <Button style={{marginTop: '5px'}} onClick={() => subcatAdd(undefined)}>
                          Add Subcategories
                        </Button>
                      )}
                    </FormItem>
                  </>
                )}
              </Form.List>
              <Divider />
            </div>
          ))}
          <Button onClick={() => add(defaultValue)}>
            <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  )
};

SubcategoryMetadata.propTypes = {
  viewer: PropTypes.shape({
    brands: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  subcategory: PropTypes.shape({
    metadata: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default createFragmentContainer(SubcategoryMetadata, {
  viewer: graphql`
    fragment SubcategoryMetadata_viewer on Admin {
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            urlSlug
          }
        }
      }
      allSubcategories: subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            urlSlug
          }
        }
      }
    }
  `,
});
