import React from 'react';
import PropTypes from 'prop-types';
import { get, startCase } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, Popconfirm, Rate, Select } from 'antd';
import { FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

class ReviewForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      productReviews: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    addReply: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const review = get(viewer, 'productReviews.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={review.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Ratings"
        >
          {review.ratings.edges.map((edge) => {
            const rate = edge.node;
            return (
              <div key={rate.id} >
                <b>{rate.ratingType.code}</b>
                <Rate disabled value={rate.star} />
              </div>
            );
          })}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Product"
        >
          <a href={review.product.fullUrl} target="_blank" rel="noopener noreferrer">{review.product.name}</a>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Customer"
        >
          {review.customer.firstname} {review.customer.lastname}
          <br />
          {review.customer.email}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Content"
          name="content"
          rules={[{ required: true, message: 'required' }]}
          initialValue={review.content}
        >
          <TextArea placeholder="Content" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Replies"
        >
          {review.replies.edges.map((edge) => {
            const reply = edge.node;
            const customerName = get(reply.customer, 'firstname', reply.customerName) || 'Anonymous';
            return (
              <div key={reply.id} style={{ backgroundColor: '#f3f3f3', whiteSpace: 'pre-line' }}>
                <u><b>{startCase(customerName)}:</b></u> {moment(reply.insertedAt).format('Do MMM YY')}<br />
                {reply.content}
              </div>
            );
          })}

          <FormItem name="newReply">
            <TextArea placeholder="Reply" />
          </FormItem>

          <Popconfirm
            title="Are you sure? It will send a notification to customer."
            onConfirm={(e) => { this.props.addReply(this.formRef.current, e); }}
            okText="Yes"
            cancelText="No"
          >
            <Button>Add Reply</Button>
          </Popconfirm>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(review, 'status', 1)}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

      </Form>
    );
  }
}
export default ReviewForm;
