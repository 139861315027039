import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Editor } from '@tinymce/tinymce-react';

const FormItem = Form.Item;
// chung@sydneytools.com.au
const APIKEY="fsv6cjn1xj42bet6vnnk16m7ns5snp5jqvykhactvptcnndq";

export const EditorInput = (props) => {
  const { parentName, name, height } = props;

  const [text, setText] = useState(props.editorState);

  const form = Form.useFormInstance();
  const entityId = form.getFieldValue('id');

  useEffect(() => {
    setText(props.editorState);
  }, [props.editorState]);

  const init = {
    height,
    plugins: [
      'autolink advlist lists link image anchor media paste',
      'table code',
    ],
    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
  }

  if (entityId) {
    init.images_upload_url = `/api/upload-image?id=${entityId}`;
    init.paste_data_images = true;
  }

  const handleChange = (e) => {
    const content = e.target.getContent();

    if (Array.isArray(name)) {
      form.setFieldValue([parentName].concat(name), content);
    } else {
      form.setFieldValue(name, content);
    }
  }

  return(
    <div>
      {Array.isArray(name) ? (
        <FormItem
          name={name}
          hidden
        >
          <div />
        </FormItem>
      ) : (
        <FormItem
          name={name}
          initialValue={text}
          hidden
        >
          <div />
        </FormItem>
      )}

      <Editor
        value={text}
        apiKey={APIKEY}
        init={init}
        onChange={handleChange}
      />
    </div>
  )
};

EditorInput.propTypes = {
  parentName: (props, propName, componentName) => {
    if (Array.isArray(props.name) && typeof props.parentName !== 'string') {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${props.parentName}\``
      );
    }
    return null;
  },
  name: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
  ]).isRequired,
  editorState: PropTypes.string,
  height: PropTypes.number,
};

EditorInput.defaultProps = {
  parentName: null,
  editorState: "",
  height: 500,
};

export default EditorInput;
