import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddCreditMutation($input: AddCreditInput!) {
    addCredit(input:$input) {
      creditEdge {
        cursor
        node {
          id
          fromBrandIds
          name
          tiers(first: 999) {
            edges {
              node {
                id
                price
                creditAmount
                imageUrl
              }
            }
          }
          promoItems(first: 999) {
            edges {
              node {
                id
                name
                status
              }
            }
          }
          creditFromProducts(first: 999) {
            edges {
              node {
                id
                product {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
                creditAmount
              }
            }
          }
          percentage
          minSpent
          applyBrandIds
          applyCreditMode
          excludeBrandIds
          includeProducts(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
          excludeProducts(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
          excludeProductsSpend(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
          startDate
          endDate
          expiry
          stealthMode
          excludeNonStock
          includeNonStockBrands {
            id
            name
          }
          excludePriceDrop
          includePriceDropBrands {
            id
            name
          }
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
