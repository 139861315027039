import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateSalesAddressMutation($input: UpdateSalesAddressInput!) {
    updateSalesAddress(input:$input) {
      address {
        id
        firstname
        lastname
        street
        city
        postcode
        region
        country {
          alpha2
          name
        }
        telephone
        company
        fax
        editable
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

