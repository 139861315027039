import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';

import { fromGlobalId } from '../../../helper';
import { AddCommonPromoMutation } from './mutations';
import CommonPromoForm from './CommonPromoForm';

class CommonPromoNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
    };

    if (values.type === null) {
      if (!isEmpty(values.brandIds)) {
        values.excludeProducts = formValues.excludeProducts.map(p => p.key);
      } else {
        values.onProducts = formValues.onProducts.map(p => p.key);
      }
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/promotion/common-promo/${fromGlobalId(resp.addCommonPromo.commonPromoEdge.node.id).id}`;
      },
    };
    AddCommonPromoMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New CommonPromo" />

        <h1>New CommonPromo</h1>
        <CommonPromoForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(CommonPromoNew, {
  viewer: graphql`
    fragment CommonPromoNew_viewer on Admin {
      id
      ...CommonPromoForm_viewer
    }
  `,
});
