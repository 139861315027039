import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateFreebieMutation($input: UpdateFreebieInput!) {
    updateFreebie(input:$input) {
      freebie {
        brandIds
        name
        tiers(first: 999) {
          edges {
            node {
              id
              price
              quantity
              mode
              products {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        startDate
        endDate
        stealthMode
        status
        excludeProducts(first: 99999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        includeProducts(first: 99999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        ...FreebieHistory_freebie
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
