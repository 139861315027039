import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddSubcategoryMutation($input: AddSubcategoryInput!) {
    addSubcategory(input:$input) {
      subcategoryEdge {
        cursor
        node {
          id
          name
          urlSlug
          position
          aliases
          attributes
          metaTitle
          metaDescription
          descriptions (first: 999) {
            edges {
              node {
                id
                description
                description2
                brands {
                  id
                  name
                }
                categories {
                  id
                  name
                }
              }
            }
          }
          metadata (first: 999) {
            edges {
              node {
                id
                metaTitle
                metaDescription
                canonicalUrl {
                  url
                  status
                  category {
                    id
                    name
                  }
                  subcategories {
                    id
                    name
                  }
                }
                brands {
                  id
                  name
                }
                categories {
                  id
                  name
                }
              }
            }
          }
          categories {
            id
            name
          }
          childSubcategories (first: 999) {
            edges {
              node {
                id
                name
              }
            }
          }
          subcategories {
            id
            name
          }
          autoAssignCategory {
            categoryId
            attributeId
            option
          }
          images (first: 999) {
            edges {
              node {
                id
                urlSlug
                brands {
                  id
                  name
                }
                categories {
                  id
                  name
                }
                subcategories {
                  id
                  name
                }
              }
            }
          }
          status
          displayMode
          visibleFirstDepthOnly
          nestBrands {
            id
            name
          }
          nests {
            id
            name
          }
          parents {
            id
            name
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
