import CancelOrderMutation from './CancelOrderMutation';
import CompleteOrderPaymentMutation from './CompleteOrderPaymentMutation';
import MarkShippedMutation from './MarkShippedMutation';
import SubmitOrderMutation from './SubmitOrderMutation';
import UpdateSalesAddressMutation from './UpdateSalesAddressMutation';
import UpdateVerificationMutation from './UpdateVerificationMutation';
import AddSalesOrderHistoryMutation from './AddSalesOrderHistoryMutation';

module.exports = {
  CancelOrderMutation,
  CompleteOrderPaymentMutation,
  MarkShippedMutation,
  SubmitOrderMutation,
  UpdateSalesAddressMutation,
  UpdateVerificationMutation,
  AddSalesOrderHistoryMutation
};
