import UpdateAttributeMutation from './UpdateAttributeMutation';
import AddAttributeMutation from './AddAttributeMutation';
import RemoveAttributeOptionMutation from './RemoveAttributeOptionMutation';
import RenameAttributeOptionMutation from './RenameAttributeOptionMutation';

module.exports = {
  UpdateAttributeMutation,
  AddAttributeMutation,
  RemoveAttributeOptionMutation,
  RenameAttributeOptionMutation,
};
