import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation UpdateGiftCardUsageMutation($input: UpdateGiftCardUsageInput!) {
    updateGiftCardUsage(input:$input) {
      giftCardUsage {
        amount
        remainingAmount
        expiry
        status
        ...GiftCardUsageHistory_giftCardUsage
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
