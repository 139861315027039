import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';

import { Affix, Button, Form, Input, InputNumber, Tabs } from 'antd';

import { FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import GiftCardHistory from './GiftCardHistory';

const { Item: FormItem } = Form;
const { TabPane } = Tabs;

class GiftCardForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
    }).isRequired,
    giftCard: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      amount: PropTypes.number,
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    giftCard: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const giftCard = get(this.props, 'giftCard', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={giftCard.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={giftCard.name}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Amount"
              name="amount"
              rules={[{ required: true, message: 'required' }]}
              initialValue={giftCard.amount}
              extra="Make sure this amount is in sync with the associated products"
            >
              <InputNumber placeholder="Amount" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Expire In X Months"
              name="expiryMonths"
              rules={[{ required: true, message: 'required' }]}
              initialValue={giftCard.expiryMonths}
              extra="How many months?"
            >
              <InputNumber placeholder="Months" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="On Products"
              name="products"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(giftCard, 'products.edges', []).map(({ node }) => SelectProduct.productOptions(node))}
            >
              <SelectProduct
                placeholder="On Products"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
              />
            </FormItem>
          </TabPane>

          <TabPane tab="History" key="history">
            <GiftCardHistory giftCard={giftCard} viewer={viewer} />
          </TabPane>
        </Tabs>

      </Form>
    );
  }
}
export default createFragmentContainer(GiftCardForm, {
  viewer: graphql`
    fragment GiftCardForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...GiftCardHistory_viewer
      ...SelectProduct_viewer
    }
  `,
});
