import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Divider, Form, Input, Popconfirm, Select, Tabs, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, fileValidator, formItemLayout, QuickLink, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { fromGlobalId } from '~/helper';

import CommonPromoHistory from './CommonPromoHistory';
import { TYPE } from '../label/LabelForm';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class CommonPromoForm extends FormBase {
  static propTypes = {
    commonPromo: PropTypes.shape({
      id: PropTypes.string,
      brandIds: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
      excludeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    commonPromo: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  updateDates = (value, option) => (
    this.formRef.current.setFieldsValue({
      startDate: moment(option.node.startDate),
      endDate: moment(option.node.endDate),
    })
  )

  isBrandIdsEmpty = ({ getFieldValue }) => {
    const brandIds = getFieldValue('brandIds') || [];

    return isEmpty(brandIds);
  }

  render() {
    const { match, viewer } = this.props;
    const commonPromo = get(this.props, 'commonPromo', {});
    const onProducts = get(commonPromo.onProducts, 'edges', []);
    const images = get(commonPromo.images, 'edges', []);
    const brands = get(viewer, 'brands.edges', []);
    const freebies = get(viewer, 'freebies.edges', []);

    const initialValue = {
      images: images.length ? images.slice(0).map(({ node }) => node) : [{}],
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
            {commonPromo.id && (
            <Popconfirm
              title="Are you sure delete this promo?"
              onConfirm={() => { this.props.remove(commonPromo); }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={commonPromo.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={commonPromo.name}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Type"
              name="type"
              initialValue={get(commonPromo, 'type', null)}
              extra="Depends on the type, some fields will be automated"
            >
              <Select placeholder="Type">
                <Option value={null}>None</Option>
                <Option value={3}>Freebie</Option>
              </Select>
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {(form) => {
                const type = form.getFieldValue('type');

                return (
                  <>
                    {type === 3 && (
                      <FormItem
                        {...formItemLayout}
                        label="Freebie"
                        extra={<span>To update products, simply click the <b>Save</b> button.</span>}
                      >
                        <FormItem
                          name="freebieId"
                          rules={[{ required: true, message: 'required' }]}
                          initialValue={commonPromo.freebieId}
                        >
                          <Select placeholder="Freebie" showSearch optionFilterProp="children" onSelect={this.updateDates}>
                            {
                              freebies.map(({ node }) => <Option key={node.id} value={node.id} node={node}>{node.name}</Option>)
                            }
                          </Select>
                        </FormItem>
                        <QuickLink form={form} fieldName="freebieId" />
                      </FormItem>
                    )}

                    <FormItem
                      {...formItemLayout}
                      label="Start Date"
                      name="startDate"
                      rules={[{ required: true, message: 'required' }]}
                      initialValue={commonPromo.startDate ? moment(commonPromo.startDate) : null}
                    >
                      <DatePicker
                        disabled={form.getFieldValue('type') !== null}
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        placeholder="Start Date"
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="End Date"
                      name="endDate"
                      rules={[{ required: true, message: 'required' }]}
                      initialValue={commonPromo.endDate ? moment(commonPromo.endDate) : null}
                      help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
                    >
                      <DatePicker
                        disabled={form.getFieldValue('type') !== null}
                        showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                        placeholder="End Date"
                      />
                    </FormItem>

                    {type === null && (
                      <FormItem
                        {...formItemLayout}
                        label="Brands"
                        name="brandIds"
                        initialValue={commonPromo.brandIds}
                      >
                        <Select
                          placeholder="Select Brands"
                          showSearch
                          optionFilterProp="children"
                          mode="multiple"
                          allowClear
                        >
                          {
                            brands.map((edge) => {
                              const brand = edge.node;
                              return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                            })
                          }
                        </Select>
                      </FormItem>
                    )}

                    {type === null && !this.isBrandIdsEmpty(form) && (
                      <FormItem
                        {...formItemLayout}
                        label="Exclude Only Products"
                        extra="These products will be excluded from selected brands"
                        name="excludeProducts"
                        initialValue={get(commonPromo, 'excludeProducts.edges', []).map(edge => SelectProduct.productOptions(edge.node))}
                      >
                        <SelectProduct
                          placeholder="Exclude Products"
                          mode="multiple"
                          labelInValue
                          filterOption={false}
                          viewer={viewer}
                          importExport={{
                            onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'excludeProducts'); },
                            listToExport: get(commonPromo, 'excludeProducts.edges', [])
                          }}
                        />
                      </FormItem>
                    )}

                    {type === null && this.isBrandIdsEmpty(form) && (
                      <FormItem
                        {...formItemLayout}
                        label="Common Images On Products"
                        required
                        name="onProducts"
                        rules={[{ required: true, message: 'required' }]}
                        initialValue={get(commonPromo, 'onProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                      >
                        <SelectProduct
                          placeholder="On Products"
                          mode="multiple"
                          labelInValue
                          filterOption={false}
                          viewer={viewer}
                          importExport={{
                            onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'onProducts'); },
                            listToExport: get(commonPromo, 'onProducts.edges', [])
                          }}
                        />
                      </FormItem>
                    )}
                  </>
                )
              }}
            </FormItem>

            {commonPromo.id && (
            <FormItem
              {...formItemLayout}
              label="Images"
              extra="Images show across all related products"
            >
              <Form.List name="images" initialValue={initialValue.images}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, i) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["images", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <FormItem
                          label={`Image ${i}`}
                        >
                          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                            <FormItem noStyle shouldUpdate>
                              {({ getFieldValue }) => {
                                const imageUrl = getFieldValue(["images", name, "imageUrl"]);
                                return imageUrl ? <img alt="" src={imageUrl} width="120" height="120" /> : null;
                              }}
                            </FormItem>

                            <MinusCircleOutlined
                              style={{ cursor: 'pointer' }}
                              disabled={fields.length === 1}
                              onClick={() => remove(name)}
                            />

                            <FormItem
                              name={[name, "file"]}
                              valuePropName="fileList"
                              getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                  return e;
                                }
                                return e && e.fileList;
                              }}
                              rules={[
                                { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) }
                              ]}
                              style={{ marginBottom: '0px' }}
                            >
                              <Upload
                                accept="image/gif,image/png,image/jpeg"
                                beforeUpload={() => false}
                                listType="picture"
                              >
                                <Button>
                                  <UploadOutlined /> Upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </div>
                        </FormItem>

                        <Divider />

                      </div>
                    ))}
                    <Button onClick={() => add()}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>
            )}

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <FormItem
                  {...formItemLayout}
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'required' }]}
                  initialValue={get(commonPromo, 'status', 0) ? 1 : 0}
                >
                  <Select placeholder="Status" disabled={![TYPE.None].includes(getFieldValue('type'))} >
                    <Option value={1}>Enabled</Option>
                    <Option value={0}>Disabled</Option>
                  </Select>
                </FormItem>
              )}
            </FormItem>

          </TabPane>

          <TabPane tab="Products" key="products">
            Common images on {onProducts.length} products
            {onProducts.map((edge) => {
              const p = edge.node;
              return (
                <div key={p.id}>
                  <a href={`/product/${fromGlobalId(p.id).id}`} target="_blank" rel="noopener noreferrer" >{p.name}</a>
                </div>
              );
            })}
          </TabPane>

          {commonPromo.id && (
            <TabPane tab="History" key="history">
              <CommonPromoHistory commonPromo={commonPromo} viewer={viewer} />
            </TabPane>
          )}

        </Tabs>

      </Form>
    );
  }
}
export default createFragmentContainer(CommonPromoForm, {
  viewer: graphql`
    fragment CommonPromoForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...SelectProduct_viewer
      ...CommonPromoHistory_viewer
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      freebies(first: 9999, orderBy: {field: "id", direction: "desc"}) {
        edges {
          node {
            id
            name
            startDate
            endDate
          }
        }
      }
    }
  `,
});
