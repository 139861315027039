/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UrlRewriteNew_viewer$ref = any;
export type routes_UrlRewriteNew_QueryVariables = {||};
export type routes_UrlRewriteNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UrlRewriteNew_viewer$ref
  |}
|};
export type routes_UrlRewriteNew_Query = {|
  variables: routes_UrlRewriteNew_QueryVariables,
  response: routes_UrlRewriteNew_QueryResponse,
|};
*/


/*
query routes_UrlRewriteNew_Query {
  viewer {
    ...UrlRewriteNew_viewer
    id
  }
}

fragment UrlRewriteForm_viewer on Admin {
  id
}

fragment UrlRewriteNew_viewer on Admin {
  id
  ...UrlRewriteForm_viewer
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_UrlRewriteNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UrlRewriteNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_UrlRewriteNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1681e2b4f576655cd763533efbf1049c",
    "id": null,
    "metadata": {},
    "name": "routes_UrlRewriteNew_Query",
    "operationKind": "query",
    "text": "query routes_UrlRewriteNew_Query {\n  viewer {\n    ...UrlRewriteNew_viewer\n    id\n  }\n}\n\nfragment UrlRewriteForm_viewer on Admin {\n  id\n}\n\nfragment UrlRewriteNew_viewer on Admin {\n  id\n  ...UrlRewriteForm_viewer\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'cfd9b06094aecef020efb71fd6e41506';

module.exports = node;
