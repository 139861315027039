/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSearchRedirectInput = {|
  id: string,
  query: string,
  status: boolean,
  url: string,
|};
export type UpdateSearchRedirectMutationVariables = {|
  input: UpdateSearchRedirectInput
|};
export type UpdateSearchRedirectMutationResponse = {|
  +updateSearchRedirect: ?{|
    +searchRedirect: ?{|
      +query: ?string,
      +url: ?string,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateSearchRedirectMutation = {|
  variables: UpdateSearchRedirectMutationVariables,
  response: UpdateSearchRedirectMutationResponse,
|};
*/


/*
mutation UpdateSearchRedirectMutation(
  $input: UpdateSearchRedirectInput!
) {
  updateSearchRedirect(input: $input) {
    searchRedirect {
      query
      url
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "query",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSearchRedirectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSearchRedirectPayload",
        "kind": "LinkedField",
        "name": "updateSearchRedirect",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchRedirect",
            "kind": "LinkedField",
            "name": "searchRedirect",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSearchRedirectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSearchRedirectPayload",
        "kind": "LinkedField",
        "name": "updateSearchRedirect",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchRedirect",
            "kind": "LinkedField",
            "name": "searchRedirect",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1bdc61c7518dc718a83d5b3bc87ca9a",
    "id": null,
    "metadata": {},
    "name": "UpdateSearchRedirectMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSearchRedirectMutation(\n  $input: UpdateSearchRedirectInput!\n) {\n  updateSearchRedirect(input: $input) {\n    searchRedirect {\n      query\n      url\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13f1efbe8007f5e4c1db72aa7068370d';

module.exports = node;
