import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateFooterMutation($input: UpdateFooterInput!) {
    updateFooter(input: $input) {
      footer {
        id
        name
        position
        urls {
          name
          type
          url
          properties
          position
        }
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
