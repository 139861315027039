/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePriceMatchInput = {|
  expiryDate?: ?string,
  id: string,
  matchedPrice?: ?number,
  reason?: ?string,
  status: number,
|};
export type UpdatePriceMatchMutationVariables = {|
  input: UpdatePriceMatchInput
|};
export type UpdatePriceMatchMutationResponse = {|
  +updatePriceMatch: ?{|
    +priceMatch: ?{|
      +matchedPrice: ?number,
      +expiryDate: ?string,
      +code: ?string,
      +status: ?number,
      +decline: ?{|
        +reason: ?string
      |},
    |}
  |}
|};
export type UpdatePriceMatchMutation = {|
  variables: UpdatePriceMatchMutationVariables,
  response: UpdatePriceMatchMutationResponse,
|};
*/


/*
mutation UpdatePriceMatchMutation(
  $input: UpdatePriceMatchInput!
) {
  updatePriceMatch(input: $input) {
    priceMatch {
      matchedPrice
      expiryDate
      code
      status
      decline {
        reason
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "matchedPrice",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiryDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceMatchDecline",
  "kind": "LinkedField",
  "name": "decline",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePriceMatchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePriceMatchPayload",
        "kind": "LinkedField",
        "name": "updatePriceMatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceMatch",
            "kind": "LinkedField",
            "name": "priceMatch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePriceMatchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePriceMatchPayload",
        "kind": "LinkedField",
        "name": "updatePriceMatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceMatch",
            "kind": "LinkedField",
            "name": "priceMatch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "404381fb1199f0120ff4e6ddf5a94adb",
    "id": null,
    "metadata": {},
    "name": "UpdatePriceMatchMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePriceMatchMutation(\n  $input: UpdatePriceMatchInput!\n) {\n  updatePriceMatch(input: $input) {\n    priceMatch {\n      matchedPrice\n      expiryDate\n      code\n      status\n      decline {\n        reason\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82dd17e1d2c8a06a8f320a45ccc0f9dc';

module.exports = node;
