/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCmsPageInput = {|
  content?: ?string,
  excludeFromSitemap?: ?boolean,
  metaDescription?: ?string,
  status: boolean,
  title: string,
  urlSlug: string,
|};
export type AddCmsPageMutationVariables = {|
  input: AddCmsPageInput
|};
export type AddCmsPageMutationResponse = {|
  +addCmsPage: ?{|
    +cmsPageEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +title: ?string,
        +content: ?string,
        +urlSlug: ?string,
        +excludeFromSitemap: ?boolean,
        +status: ?boolean,
        +metaDescription: ?string,
      |},
    |}
  |}
|};
export type AddCmsPageMutation = {|
  variables: AddCmsPageMutationVariables,
  response: AddCmsPageMutationResponse,
|};
*/


/*
mutation AddCmsPageMutation(
  $input: AddCmsPageInput!
) {
  addCmsPage(input: $input) {
    cmsPageEdge {
      cursor
      node {
        id
        title
        content
        urlSlug
        excludeFromSitemap
        status
        metaDescription
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCmsPagePayload",
    "kind": "LinkedField",
    "name": "addCmsPage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsPageEdge",
        "kind": "LinkedField",
        "name": "cmsPageEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsPage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "excludeFromSitemap",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaDescription",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCmsPageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCmsPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc142e300cee81c94db32a62d9755a73",
    "id": null,
    "metadata": {},
    "name": "AddCmsPageMutation",
    "operationKind": "mutation",
    "text": "mutation AddCmsPageMutation(\n  $input: AddCmsPageInput!\n) {\n  addCmsPage(input: $input) {\n    cmsPageEdge {\n      cursor\n      node {\n        id\n        title\n        content\n        urlSlug\n        excludeFromSitemap\n        status\n        metaDescription\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1d9945b95d608a0ab81e5926a358229';

module.exports = node;
