import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddLabelMutation($input: AddLabelInput!) {
    addLabel(input:$input) {
      labelEdge {
        cursor
        node {
          id
          name
          brandId
          imageUrl
          productPageImageUrl
          placement
          startDate
          endDate
          type
          position
          bonusId
          categoryId
          redemptionId
          creditId
          freebieId
          excludeProducts(first: 99999) {
            edges {
              node {
                id
                name
                status
                mainImage {
                  id
                  url
                }
              }
            }
          }
          products(first: 99999) {
            edges {
              node {
                id
                name
                sku
                mainImage {
                  id
                  url
                }
                status
              }
            }
          }
          status
          fuseImage
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addLabel', edgeName: 'labelEdge', connectionKey: 'LabelList_labels' }),
});

export default { commit };
