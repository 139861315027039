import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button } from 'antd';

import { getNodeIds } from '~/helper';
import { GenPromoCardPdfMutation } from './mutations';

class GenPromoCardPdf extends React.Component {
  static propTypes = {
    pageSize: PropTypes.string.isRequired,
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    gridApi: PropTypes.shape({
      getSelectedRows: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = () => {
    const { pageSize } = this.props;
    const ids = getNodeIds(this.props.gridApi.getSelectedRows());

    GenPromoCardPdfMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { pageSize, ids } },
      viewer: this.props.viewer,
    });
  }

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <Button
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
};

export default createFragmentContainer(GenPromoCardPdf, {
  viewer: graphql`
    fragment GenPromoCardPdf_viewer on Admin {
      id
    }
  `,
});
