import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { fromGlobalId } from '../../../helper';
import { BooleanDropdownFilter, Grid, iDGetter, dateTimeGetter, valueGetter } from '../../grid';

const entityName = 'Credit';

class CreditList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      credits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/promotion/credit/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false,
      },
      { colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 350 },
      {
        colId: 'startDate',
        headerName: 'Start Date',
        valueGetter: dateTimeGetter.bind(this, 'startDate'),
        filter: 'agTextColumnFilter',
        filterParams: { caseSensitive: true },
        floatingFilterComponent: 'DateFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        width: 150
      },
      {
        colId: 'endDate',
        headerName: 'End Date',
        valueGetter: dateTimeGetter.bind(this, 'endDate'),
        filter: 'agTextColumnFilter',
        filterParams: { caseSensitive: true },
        floatingFilterComponent: 'DateFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        width: 150
      },
      { colId: 'status', headerName: 'Status', valueGetter: valueGetter.bind(this, 'status'), ...BooleanDropdownFilter },
    ];

    this.datasource = Grid.getDataSource.call(this, 'credits');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/promotion/credit/new"><Button>Add Credit</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  CreditList, {
    viewer: graphql`
    fragment CreditList_viewer on Admin {
      credits(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "CreditList_credits") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            startDate
            endDate
            status
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.credits;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query CreditListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...CreditList_viewer
      }
    }
  `,
  },
);
