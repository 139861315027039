/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InputAttributeList = {|
  id: string,
  options?: ?$ReadOnlyArray<?string>,
  required?: ?boolean,
  value?: ?string,
|};
export type ProductFormRefetchQueryVariables = {|
  subcategoryIds?: ?$ReadOnlyArray<?string>,
  attributes?: ?$ReadOnlyArray<?InputAttributeList>,
|};
export type ProductFormRefetchQueryResponse = {|
  +viewer: ?{|
    +attributes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +code: ?string,
          +options: ?any,
          +required: ?boolean,
          +multi: ?boolean,
        |}
      |}>
    |}
  |}
|};
export type ProductFormRefetchQuery = {|
  variables: ProductFormRefetchQueryVariables,
  response: ProductFormRefetchQueryResponse,
|};
*/


/*
query ProductFormRefetchQuery(
  $subcategoryIds: [ID]
  $attributes: [InputAttributeList]
) {
  viewer {
    attributes(first: 2000, subcategoryIds: $subcategoryIds, attributes: $attributes, orderBy: {field: "name", direction: "asc"}) {
      edges {
        node {
          id
          name
          code
          options
          required
          multi
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcategoryIds"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "attributes",
      "variableName": "attributes"
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 2000
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": {
        "direction": "asc",
        "field": "name"
      }
    },
    {
      "kind": "Variable",
      "name": "subcategoryIds",
      "variableName": "subcategoryIds"
    }
  ],
  "concreteType": "AttributeConnection",
  "kind": "LinkedField",
  "name": "attributes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AttributeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Attribute",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "options",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "required",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "multi",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductFormRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductFormRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "854b34d8298812152dd52e8fe45e318f",
    "id": null,
    "metadata": {},
    "name": "ProductFormRefetchQuery",
    "operationKind": "query",
    "text": "query ProductFormRefetchQuery(\n  $subcategoryIds: [ID]\n  $attributes: [InputAttributeList]\n) {\n  viewer {\n    attributes(first: 2000, subcategoryIds: $subcategoryIds, attributes: $attributes, orderBy: {field: \"name\", direction: \"asc\"}) {\n      edges {\n        node {\n          id\n          name\n          code\n          options\n          required\n          multi\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fe648fa4797eec5c651cc7ce6df9d38';

module.exports = node;
