/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesQuoteHistory_salesQuote$ref = any;
export type UpdateQuoteRecoveryStatusInput = {|
  id: string,
  status: number,
|};
export type UpdateQuoteRecoveryStatusMutationVariables = {|
  input: UpdateQuoteRecoveryStatusInput
|};
export type UpdateQuoteRecoveryStatusMutationResponse = {|
  +updateQuoteRecoveryStatus: ?{|
    +salesQuote: ?{|
      +recoveryStatus: ?number,
      +$fragmentRefs: SalesQuoteHistory_salesQuote$ref,
    |}
  |}
|};
export type UpdateQuoteRecoveryStatusMutation = {|
  variables: UpdateQuoteRecoveryStatusMutationVariables,
  response: UpdateQuoteRecoveryStatusMutationResponse,
|};
*/


/*
mutation UpdateQuoteRecoveryStatusMutation(
  $input: UpdateQuoteRecoveryStatusInput!
) {
  updateQuoteRecoveryStatus(input: $input) {
    salesQuote {
      recoveryStatus
      ...SalesQuoteHistory_salesQuote
      id
    }
  }
}

fragment SalesQuoteHistory_salesQuote on SalesQuote {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recoveryStatus",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateQuoteRecoveryStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateQuoteRecoveryStatusPayload",
        "kind": "LinkedField",
        "name": "updateQuoteRecoveryStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesQuote",
            "kind": "LinkedField",
            "name": "salesQuote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SalesQuoteHistory_salesQuote"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateQuoteRecoveryStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateQuoteRecoveryStatusPayload",
        "kind": "LinkedField",
        "name": "updateQuoteRecoveryStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesQuote",
            "kind": "LinkedField",
            "name": "salesQuote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "SalesQuoteHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41efc994f84fd43914b3bab3bcef1357",
    "id": null,
    "metadata": {},
    "name": "UpdateQuoteRecoveryStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateQuoteRecoveryStatusMutation(\n  $input: UpdateQuoteRecoveryStatusInput!\n) {\n  updateQuoteRecoveryStatus(input: $input) {\n    salesQuote {\n      recoveryStatus\n      ...SalesQuoteHistory_salesQuote\n      id\n    }\n  }\n}\n\nfragment SalesQuoteHistory_salesQuote on SalesQuote {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13b24742027140d457acf51edeb6595b';

module.exports = node;
