import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateConfigMutation($input: UpdateConfigInput!) {
    updateConfig(input:$input) {
      configs
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

