import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';

import { Checkbox } from 'antd';
import { DatePicker } from '~/components/form';

import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const { Group } = Checkbox;
const { RangePicker } = DatePicker;

const entityName = 'Sales Trend';

const SCALE_FACTOR = 300;

class SalesTrend extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      salesTrend: PropTypes.arrayOf(PropTypes.array),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { filterBy }  = this.props.match.route.prepareVariables();

    this.defaultValue = ['Order Count', 'Last Year Grand Total', 'Last Year Order Count'];

    this.options = [
      { label: 'Order Count', value: 'Order Count' },
      { label: 'Last Year Grand Total', value: 'Last Year Grand Total' },
      { label: 'Last Year Order Count', value: 'Last Year Order Count' },
    ];

    this.state = {
      selectedOption: this.defaultValue,
      filter: filterBy.filter.split(",").map(ds => moment(ds))
    };
  }

  onChange = (date) => {
    const filter = [moment(date[0]).startOf('day').utc().format(), moment(date[1]).endOf('day').utc().format()].join(",");
    const refetchVariables = { filterBy: {
      field: "inserted_at",
      filter,
      filterType: "text",
      type: "inRange",
    }};

    this.props.relay.refetch(
      refetchVariables,
      null,
      ()=> {
        this.setState({
          filter: filter.split(",").map(ds => moment(ds)),
        });
      },
      refetchVariables
    );
  }

  onCheckboxChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  renderLine = (data, name, props = {}) => {
    const { selectedOption } = this.state;

    if (selectedOption.includes(name)) {
      return <Line dataKey="value" data={data} name={name} key={name} strokeDasharray="3 4 5 2" {...props} />;
    }
    return null;
  }

  render() {
    const { viewer: { salesTrend } } = this.props;
    const { filter } = this.state;

    const orderCounts = salesTrend.map(t => ({
      category: t[0],
      value: t[1] * SCALE_FACTOR,
    }))

    const grandTotals = salesTrend.map(t => ({
      category: t[0],
      value: t[2]
    }))

    const prevOrderCounts = salesTrend.map(t => ({
      category: t[0],
      value: t[3] * SCALE_FACTOR,
    }));

    const prevGrandTotals = salesTrend.map(t => ({
      category: t[0],
      value: t[4],
    }));

    return (
      <div>
        <Helmet title={`${entityName}`} />
        <h1>{entityName}</h1>

        <RangePicker defaultValue={filter} onChange={this.onChange} />

        <Group style={{ display: 'block' }} options={this.options} defaultValue={this.defaultValue} onChange={this.onCheckboxChange} />

        <ResponsiveContainer minHeight={320}>
          <LineChart>
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="category" type="category" allowDuplicatedCategory={false} />
            <YAxis dataKey="value" />

            <Tooltip
              formatter={(value, name) => {
              if (name.includes("Order Count")) {
                return value / SCALE_FACTOR;
              }
              else if (name.includes("Grand Total")) {
                return value.toLocaleString();
              }

              return value;
            }}
            />

            <Legend verticalAlign="top" />

            <Line dataKey="value" data={grandTotals} name="Grand Total" key="Grand Total" stroke="#cb0000" />
            {this.renderLine(orderCounts, "Order Count", { strokeDasharray: "3 4 5 2" })}
            {this.renderLine(prevGrandTotals, "Last Year Grand Total", { stroke: "#ff8000" })}
            {this.renderLine(prevOrderCounts, "Last Year Order Count", { stroke: "#00A309", strokeDasharray: "3 4 5 2" })}

          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default createRefetchContainer(
  SalesTrend,
  {
    viewer: graphql`
      fragment SalesTrend_viewer on Admin {
        salesTrend(filterBy: $filterBy)
      }
    `,
  },
  graphql`
    query SalesTrendRefetchQuery($filterBy: [FilterBy]) {
      viewer {
        ...SalesTrend_viewer
      }
    }
  `,
);
