/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ComboFreebieForm_viewer$ref: FragmentReference;
declare export opaque type ComboFreebieForm_viewer$fragmentType: ComboFreebieForm_viewer$ref;
export type ComboFreebieForm_viewer = {|
  +$fragmentRefs: SelectProduct_viewer$ref,
  +$refType: ComboFreebieForm_viewer$ref,
|};
export type ComboFreebieForm_viewer$data = ComboFreebieForm_viewer;
export type ComboFreebieForm_viewer$key = {
  +$data?: ComboFreebieForm_viewer$data,
  +$fragmentRefs: ComboFreebieForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ComboFreebieForm_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e1eaba2ea9ca99e976f49ec377c4d49d';

module.exports = node;
