/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PurchaseLimitView_viewer$ref = any;
export type routes_PurchaseLimitView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_PurchaseLimitView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PurchaseLimitView_viewer$ref
  |}
|};
export type routes_PurchaseLimitView_Query = {|
  variables: routes_PurchaseLimitView_QueryVariables,
  response: routes_PurchaseLimitView_QueryResponse,
|};
*/


/*
query routes_PurchaseLimitView_Query(
  $ids: [ID]
) {
  viewer {
    ...PurchaseLimitView_viewer
    id
  }
}

fragment PurchaseLimitForm_viewer on Admin {
  id
  ...SelectProduct_viewer
}

fragment PurchaseLimitView_viewer on Admin {
  id
  purchaseLimits(first: 1, ids: $ids) {
    edges {
      node {
        id
        startDate
        endDate
        product {
          id
          name
          sku
          mainImage {
            id
            url
          }
          status
        }
        limit
        status
      }
    }
  }
  ...PurchaseLimitForm_viewer
}

fragment SelectProduct_viewer on Admin {
  searchProducts: products(first: 20, query: "") {
    edges {
      node {
        id
        name
        type
        attributes
        sku
        status
        mainImage {
          id
          url
        }
        configurables(first: 99) {
          edges {
            node {
              id
              product {
                id
                name
                sku
                status
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PurchaseLimitView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PurchaseLimitView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_PurchaseLimitView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "PurchaseLimitConnection",
            "kind": "LinkedField",
            "name": "purchaseLimits",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseLimitEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchaseLimit",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "limit",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "searchProducts",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributes",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 99
                          }
                        ],
                        "concreteType": "ProductConfigurableConnection",
                        "kind": "LinkedField",
                        "name": "configurables",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductConfigurableEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductConfigurable",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      (v5/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "configurables(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "products(first:20,query:\"\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26c20872b4b8232ee837c457726ff41d",
    "id": null,
    "metadata": {},
    "name": "routes_PurchaseLimitView_Query",
    "operationKind": "query",
    "text": "query routes_PurchaseLimitView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...PurchaseLimitView_viewer\n    id\n  }\n}\n\nfragment PurchaseLimitForm_viewer on Admin {\n  id\n  ...SelectProduct_viewer\n}\n\nfragment PurchaseLimitView_viewer on Admin {\n  id\n  purchaseLimits(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        startDate\n        endDate\n        product {\n          id\n          name\n          sku\n          mainImage {\n            id\n            url\n          }\n          status\n        }\n        limit\n        status\n      }\n    }\n  }\n  ...PurchaseLimitForm_viewer\n}\n\nfragment SelectProduct_viewer on Admin {\n  searchProducts: products(first: 20, query: \"\") {\n    edges {\n      node {\n        id\n        name\n        type\n        attributes\n        sku\n        status\n        mainImage {\n          id\n          url\n        }\n        configurables(first: 99) {\n          edges {\n            node {\n              id\n              product {\n                id\n                name\n                sku\n                status\n                mainImage {\n                  id\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fdd2dbb55032c6d8996e5ea7d17da824';

module.exports = node;
