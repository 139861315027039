import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateRedemptionMutation($input: UpdateRedemptionInput!) {
    updateRedemption(input:$input) {
      redemption {
        name
        brandIds
        startDate
        endDate
        excludeProducts(first: 999) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        onProducts(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        redeemables(first: 999) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        images (first: 999) {
          edges {
            node {
              id
              imageUrl
            }
          }
        }
        status
        ...RedemptionHistory_redemption
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
