/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUrlSlugInput = {|
  id: string,
  rewrite?: ?boolean,
  username: string,
|};
export type UpdateUrlSlugMutationVariables = {|
  input: UpdateUrlSlugInput
|};
export type UpdateUrlSlugMutationResponse = {|
  +updateUrlSlug: ?{|
    +type: ?{|
      +urlSlug?: ?string
    |}
  |}
|};
export type UpdateUrlSlugMutation = {|
  variables: UpdateUrlSlugMutationVariables,
  response: UpdateUrlSlugMutationResponse,
|};
*/


/*
mutation UpdateUrlSlugMutation(
  $input: UpdateUrlSlugInput!
) {
  updateUrlSlug(input: $input) {
    type {
      __typename
      ... on Brand {
        urlSlug
      }
      ... on Category {
        urlSlug
      }
      ... on Product {
        urlSlug
      }
      ... on Subcategory {
        urlSlug
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "urlSlug",
    "storageKey": null
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Brand",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Category",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Product",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Subcategory",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUrlSlugMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUrlSlugPayload",
        "kind": "LinkedField",
        "name": "updateUrlSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUrlSlugMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUrlSlugPayload",
        "kind": "LinkedField",
        "name": "updateUrlSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "477539bee89b90f2330f6f31d16b5ed4",
    "id": null,
    "metadata": {},
    "name": "UpdateUrlSlugMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUrlSlugMutation(\n  $input: UpdateUrlSlugInput!\n) {\n  updateUrlSlug(input: $input) {\n    type {\n      __typename\n      ... on Brand {\n        urlSlug\n      }\n      ... on Category {\n        urlSlug\n      }\n      ... on Product {\n        urlSlug\n      }\n      ... on Subcategory {\n        urlSlug\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b8b4ad1d3c0e78a8c1458d4e5000750';

module.exports = node;
