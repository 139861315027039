/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AdjacentBannerHistory_viewer$ref: FragmentReference;
declare export opaque type AdjacentBannerHistory_viewer$fragmentType: AdjacentBannerHistory_viewer$ref;
export type AdjacentBannerHistory_viewer = {|
  +id: string,
  +$refType: AdjacentBannerHistory_viewer$ref,
|};
export type AdjacentBannerHistory_viewer$data = AdjacentBannerHistory_viewer;
export type AdjacentBannerHistory_viewer$key = {
  +$data?: AdjacentBannerHistory_viewer$data,
  +$fragmentRefs: AdjacentBannerHistory_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdjacentBannerHistory_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3037550b81895fe3578176100804eca7';

module.exports = node;
