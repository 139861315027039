import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddGiftCardMutation($input: AddGiftCardInput!) {
    addGiftCard(input:$input) {
      giftCardEdge {
        cursor
        node {
          id
          name
          amount
          expiryMonths
          products(first: 99) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

