/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCmsBannerInput = {|
  categoryBig?: ?any,
  categorySmall?: ?any,
  endDate?: ?string,
  homeBig?: ?any,
  homeSmall?: ?any,
  id: string,
  link?: ?string,
  position?: ?number,
  startDate?: ?string,
  status?: ?boolean,
  title?: ?string,
  urlPaths?: ?$ReadOnlyArray<?string>,
|};
export type UpdateCmsBannerMutationVariables = {|
  input: UpdateCmsBannerInput
|};
export type UpdateCmsBannerMutationResponse = {|
  +updateCmsBanner: ?{|
    +cmsBanner: ?{|
      +title: ?string,
      +link: ?string,
      +homeBig: ?string,
      +homeSmall: ?string,
      +categoryBig: ?string,
      +categorySmall: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +urlPaths: ?$ReadOnlyArray<?string>,
      +position: ?number,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateCmsBannerMutation = {|
  variables: UpdateCmsBannerMutationVariables,
  response: UpdateCmsBannerMutationResponse,
|};
*/


/*
mutation UpdateCmsBannerMutation(
  $input: UpdateCmsBannerInput!
) {
  updateCmsBanner(input: $input) {
    cmsBanner {
      title
      link
      homeBig
      homeSmall
      categoryBig
      categorySmall
      startDate
      endDate
      urlPaths
      position
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homeBig",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homeSmall",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryBig",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categorySmall",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlPaths",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCmsBannerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCmsBannerPayload",
        "kind": "LinkedField",
        "name": "updateCmsBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsBanner",
            "kind": "LinkedField",
            "name": "cmsBanner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCmsBannerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCmsBannerPayload",
        "kind": "LinkedField",
        "name": "updateCmsBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsBanner",
            "kind": "LinkedField",
            "name": "cmsBanner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cad8c36459c24c23d7347037684c7f9e",
    "id": null,
    "metadata": {},
    "name": "UpdateCmsBannerMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCmsBannerMutation(\n  $input: UpdateCmsBannerInput!\n) {\n  updateCmsBanner(input: $input) {\n    cmsBanner {\n      title\n      link\n      homeBig\n      homeSmall\n      categoryBig\n      categorySmall\n      startDate\n      endDate\n      urlPaths\n      position\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd14bcf34b4fe39476d5bfe6dae65ae21';

module.exports = node;
