import React from 'react';
import PropTypes from 'prop-types';
import Shipment from './Shipment';

export default class Tracking extends React.Component {
  static propTypes = {
    line: PropTypes.shape({
      shipments: PropTypes.arrayOf(PropTypes.shape({
        tracking: PropTypes.shape({
          trackingUrl: PropTypes.string
        }),
        location: PropTypes.string,
        status: PropTypes.string
      }))
    }).isRequired,
  }

  render() {
    const { line: { shipments } } = this.props;

    // eslint-disable-next-line react/no-array-index-key
    return shipments.map((shipment, i) => <Shipment key={i} shipment={shipment} />);
  }
}
