import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Avatar, Button, Form, Input, message, Timeline } from 'antd';
import JSONTree from 'react-json-tree';
import moment from 'moment-timezone';
import { buildMutation, edgeUpdater } from '~/mutation';
import { mutation } from '~/components/history/mutations/AddHistoryMutation'

const { Item: FormItem } = Form;

const loadMoreSize = 10; // determined by server-side

export const AddHistoryMutation = (connectionKey, config) => {
  const historyMutation = buildMutation(mutation, {
    updater: edgeUpdater.bind(this, {
      connectionKey,
      rootField: 'addHistory',
      edgeName: 'historyEdge',
      insertPosition: 'before',
    }),
  });

  return historyMutation(config);
};

export const addHistory = ({ props, connectionKey }) => {
  return (entity, history) => {
    AddHistoryMutation.call(this, connectionKey, {
      environment: props.relay.environment,
      variables: { input: { id: entity.id, history } },
      parent: { id: entity.id },
      viewer: props.viewer,
      onCompleted: () => {
        message.success('Saved');
      },
    });
  };
};

export const historyPropTypes = ({entity}) => (
  {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    [entity]: PropTypes.shape({
      id: PropTypes.string,
      histories: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              changes: PropTypes.shape({}),
              user: PropTypes.string,
              insertedAt: PropTypes.string,
            })
          })
        )
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }
);

export default class History extends React.Component {
  static propTypes = {
    entity: PropTypes.shape({
      id: PropTypes.string,
      histories: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              changes: PropTypes.shape({}),
              user: PropTypes.string,
              insertedAt: PropTypes.string,
            })
          })
        )
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    addHistory: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.historyInput = React.createRef();

    this.state = {
      count: loadMoreSize,
    };
  }

  loadMore = () => {
    const count = this.state.count + loadMoreSize;
    const refetchVariables = () => ({
      count,
      ids: [this.props.entity.id],
    });

    this.props.relay.refetch(refetchVariables, null, () => {
      this.setState({ count });
    });
  }

  addHistory = (entity) => {
    // FIXME: text field value should be retrieved properly
    const history = this.historyInput.current.input.value;

    this.props.addHistory(entity, history);
    this.historyInput.current.input.value = '';
  }

  render() {
    const { entity } = this.props;
    const histories = get(entity, 'histories.edges', []);

    return (
      <div>
        <FormItem
          label="Add Commet"
        >
          <Input
            ref={this.historyInput}
            placeholder="Comment"
            style={{ width: '50%', display: 'inline-block' }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.addHistory(entity);
              }
            }}
          />
          <Button onClick={() => { this.addHistory(entity); }}>Add</Button>
        </FormItem>

        <Timeline>
          {histories.map((edge) => {
            const h = edge.node;
            return (
              <Timeline.Item key={h.id}>
                <JSONTree hideRoot data={h.changes} />
                <Avatar size="large">{h.user}</Avatar>
                at {moment(h.insertedAt).format('DD-MM-YYYY HH:mm:ss')}
              </Timeline.Item>
            );
          })}
        </Timeline>

        <Button onClick={this.loadMore}>Load More</Button>
      </div>
    );
  }
}
