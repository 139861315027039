/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BrandHistory_viewer$ref = any;
type UrlRefresher_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BrandForm_viewer$ref: FragmentReference;
declare export opaque type BrandForm_viewer$fragmentType: BrandForm_viewer$ref;
export type BrandForm_viewer = {|
  +id: string,
  +$fragmentRefs: BrandHistory_viewer$ref & UrlRefresher_viewer$ref,
  +$refType: BrandForm_viewer$ref,
|};
export type BrandForm_viewer$data = BrandForm_viewer;
export type BrandForm_viewer$key = {
  +$data?: BrandForm_viewer$data,
  +$fragmentRefs: BrandForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrandHistory_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UrlRefresher_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a0d23aab9da36d955e8e7844e6bc01eb';

module.exports = node;
