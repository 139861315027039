/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveSearchCanonicalInput = {|
  id: string
|};
export type RemoveSearchCanonicalMutationVariables = {|
  input: RemoveSearchCanonicalInput
|};
export type RemoveSearchCanonicalMutationResponse = {|
  +removeSearchCanonical: ?{|
    +removedSearchCanonicalId: ?string
  |}
|};
export type RemoveSearchCanonicalMutation = {|
  variables: RemoveSearchCanonicalMutationVariables,
  response: RemoveSearchCanonicalMutationResponse,
|};
*/


/*
mutation RemoveSearchCanonicalMutation(
  $input: RemoveSearchCanonicalInput!
) {
  removeSearchCanonical(input: $input) {
    removedSearchCanonicalId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveSearchCanonicalPayload",
    "kind": "LinkedField",
    "name": "removeSearchCanonical",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedSearchCanonicalId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveSearchCanonicalMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveSearchCanonicalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0d060cf26a09c7a5378f0ec75bc9c834",
    "id": null,
    "metadata": {},
    "name": "RemoveSearchCanonicalMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveSearchCanonicalMutation(\n  $input: RemoveSearchCanonicalInput!\n) {\n  removeSearchCanonical(input: $input) {\n    removedSearchCanonicalId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c1b1d026ac11ae01d5e3c6e06e06f54';

module.exports = node;
