/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSearchCanonicalInput = {|
  id: string,
  queries: $ReadOnlyArray<?string>,
  status: boolean,
  url: string,
|};
export type UpdateSearchCanonicalMutationVariables = {|
  input: UpdateSearchCanonicalInput
|};
export type UpdateSearchCanonicalMutationResponse = {|
  +updateSearchCanonical: ?{|
    +searchCanonical: ?{|
      +queries: ?$ReadOnlyArray<?string>,
      +url: ?string,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateSearchCanonicalMutation = {|
  variables: UpdateSearchCanonicalMutationVariables,
  response: UpdateSearchCanonicalMutationResponse,
|};
*/


/*
mutation UpdateSearchCanonicalMutation(
  $input: UpdateSearchCanonicalInput!
) {
  updateSearchCanonical(input: $input) {
    searchCanonical {
      queries
      url
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "queries",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSearchCanonicalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSearchCanonicalPayload",
        "kind": "LinkedField",
        "name": "updateSearchCanonical",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchCanonical",
            "kind": "LinkedField",
            "name": "searchCanonical",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSearchCanonicalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSearchCanonicalPayload",
        "kind": "LinkedField",
        "name": "updateSearchCanonical",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchCanonical",
            "kind": "LinkedField",
            "name": "searchCanonical",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a60a7e74e9c19d80ef72db772200032c",
    "id": null,
    "metadata": {},
    "name": "UpdateSearchCanonicalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSearchCanonicalMutation(\n  $input: UpdateSearchCanonicalInput!\n) {\n  updateSearchCanonical(input: $input) {\n    searchCanonical {\n      queries\n      url\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5098bf8e4201227a6d067ada32618c60';

module.exports = node;
