/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type ExportStoresQueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>
|};
export type ExportStoresQueryResponse = {|
  +viewer: ?{|
    +stores: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +branch_code: ?string,
          +address: ?string,
          +city: ?string,
          +postcode: ?string,
          +state: ?string,
          +phone: ?string,
          +fax: ?string,
          +emails: ?$ReadOnlyArray<?string>,
        |}
      |}>
    |}
  |}
|};
export type ExportStoresQuery = {|
  variables: ExportStoresQueryVariables,
  response: ExportStoresQueryResponse,
|};
*/


/*
query ExportStoresQuery(
  $filterBy: [FilterBy]
) {
  viewer {
    stores(first: 9999, filterBy: $filterBy, orderBy: {field: "name", direction: "asc"}) {
      edges {
        node {
          id
          name
          branch_code: branchCode
          address
          city
          postcode
          state
          phone
          fax
          emails
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filterBy",
      "variableName": "filterBy"
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 9999
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": {
        "direction": "asc",
        "field": "name"
      }
    }
  ],
  "concreteType": "StoreConnection",
  "kind": "LinkedField",
  "name": "stores",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Store",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": "branch_code",
              "args": null,
              "kind": "ScalarField",
              "name": "branchCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postcode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emails",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportStoresQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportStoresQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd956ce9b8efaf4e7d3928399391fe90",
    "id": null,
    "metadata": {},
    "name": "ExportStoresQuery",
    "operationKind": "query",
    "text": "query ExportStoresQuery(\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    stores(first: 9999, filterBy: $filterBy, orderBy: {field: \"name\", direction: \"asc\"}) {\n      edges {\n        node {\n          id\n          name\n          branch_code: branchCode\n          address\n          city\n          postcode\n          state\n          phone\n          fax\n          emails\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33f108cc5f7b027d4549b4a10d12e73d';

module.exports = node;
