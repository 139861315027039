/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FaqView_viewer$ref = any;
export type routes_FaqView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_FaqView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: FaqView_viewer$ref
  |}
|};
export type routes_FaqView_Query = {|
  variables: routes_FaqView_QueryVariables,
  response: routes_FaqView_QueryResponse,
|};
*/


/*
query routes_FaqView_Query(
  $ids: [ID]
) {
  viewer {
    ...FaqView_viewer
    id
  }
}

fragment FaqForm_viewer on Admin {
  brands(first: 999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  categories(first: 999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
}

fragment FaqView_viewer on Admin {
  faqs(first: 1, ids: $ids) {
    edges {
      node {
        id
        question
        answer
        position
        status
        brands(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        categories(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories(first: 9999) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ...FaqForm_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 999
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BrandEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Brand",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CategoryEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 9999
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SubcategoryEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Subcategory",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "direction": "asc",
    "field": "name"
  }
},
v10 = [
  (v2/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_FaqView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FaqView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_FaqView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "FaqConnection",
            "kind": "LinkedField",
            "name": "faqs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FaqEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Faq",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "question",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v3/*: any*/),
                        "concreteType": "BrandConnection",
                        "kind": "LinkedField",
                        "name": "brands",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": "brands(first:999)"
                      },
                      {
                        "alias": null,
                        "args": (v3/*: any*/),
                        "concreteType": "CategoryConnection",
                        "kind": "LinkedField",
                        "name": "categories",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": "categories(first:999)"
                      },
                      {
                        "alias": null,
                        "args": [
                          (v7/*: any*/)
                        ],
                        "concreteType": "SubcategoryConnection",
                        "kind": "LinkedField",
                        "name": "subcategories",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": "subcategories(first:9999)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "brands(first:999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "CategoryConnection",
            "kind": "LinkedField",
            "name": "categories",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "categories(first:999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": [
              (v7/*: any*/),
              (v9/*: any*/)
            ],
            "concreteType": "SubcategoryConnection",
            "kind": "LinkedField",
            "name": "subcategories",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "subcategories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ba398be74f4bd1540a4328d50e12970",
    "id": null,
    "metadata": {},
    "name": "routes_FaqView_Query",
    "operationKind": "query",
    "text": "query routes_FaqView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...FaqView_viewer\n    id\n  }\n}\n\nfragment FaqForm_viewer on Admin {\n  brands(first: 999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  categories(first: 999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  subcategories(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment FaqView_viewer on Admin {\n  faqs(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        question\n        answer\n        position\n        status\n        brands(first: 999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        categories(first: 999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        subcategories(first: 9999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n  ...FaqForm_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3a9f3dfc0e02aec4a7918206fe014fa';

module.exports = node;
