/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CustomerHistory_customer$ref = any;
export type UpdateCustomerInput = {|
  checkedOrders?: ?number,
  firstname?: ?string,
  id?: ?string,
  lastLoginAt?: ?string,
  lastname?: ?string,
  reviewReminder?: ?boolean,
  securityCheck?: ?boolean,
  status?: ?string,
  subscriptionStatus?: ?boolean,
|};
export type UpdateCustomerMutationVariables = {|
  input: UpdateCustomerInput
|};
export type UpdateCustomerMutationResponse = {|
  +updateCustomer: ?{|
    +customer: ?{|
      +firstname: ?string,
      +lastname: ?string,
      +email: ?string,
      +reviewReminder: ?boolean,
      +checkedOrders: ?number,
      +securityCheck: ?boolean,
      +lastLoginAt: ?string,
      +subscription: ?{|
        +status: ?number
      |},
      +status: ?string,
      +$fragmentRefs: CustomerHistory_customer$ref,
    |}
  |}
|};
export type UpdateCustomerMutation = {|
  variables: UpdateCustomerMutationVariables,
  response: UpdateCustomerMutationResponse,
|};
*/


/*
mutation UpdateCustomerMutation(
  $input: UpdateCustomerInput!
) {
  updateCustomer(input: $input) {
    customer {
      firstname
      lastname
      email
      reviewReminder
      checkedOrders
      securityCheck
      lastLoginAt
      subscription {
        status
      }
      status
      ...CustomerHistory_customer
      id
    }
  }
}

fragment CustomerHistory_customer on Customer {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reviewReminder",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkedOrders",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "securityCheck",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastLoginAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscription",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCustomerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerPayload",
        "kind": "LinkedField",
        "name": "updateCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomerHistory_customer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCustomerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerPayload",
        "kind": "LinkedField",
        "name": "updateCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CustomerHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7c14cf43424288cc229befec1f792d7",
    "id": null,
    "metadata": {},
    "name": "UpdateCustomerMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCustomerMutation(\n  $input: UpdateCustomerInput!\n) {\n  updateCustomer(input: $input) {\n    customer {\n      firstname\n      lastname\n      email\n      reviewReminder\n      checkedOrders\n      securityCheck\n      lastLoginAt\n      subscription {\n        status\n      }\n      status\n      ...CustomerHistory_customer\n      id\n    }\n  }\n}\n\nfragment CustomerHistory_customer on Customer {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '138d3fb29c42dc22b7597582259c246c';

module.exports = node;
