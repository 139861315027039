/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SynonymNew_viewer$ref = any;
export type routes_SynonymNew_QueryVariables = {||};
export type routes_SynonymNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SynonymNew_viewer$ref
  |}
|};
export type routes_SynonymNew_Query = {|
  variables: routes_SynonymNew_QueryVariables,
  response: routes_SynonymNew_QueryResponse,
|};
*/


/*
query routes_SynonymNew_Query {
  viewer {
    ...SynonymNew_viewer
    id
  }
}

fragment SynonymNew_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SynonymNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SynonymNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_SynonymNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05d71ee5b74716a37b0830314514ded7",
    "id": null,
    "metadata": {},
    "name": "routes_SynonymNew_Query",
    "operationKind": "query",
    "text": "query routes_SynonymNew_Query {\n  viewer {\n    ...SynonymNew_viewer\n    id\n  }\n}\n\nfragment SynonymNew_viewer on Admin {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '10fd60ebc64697930c6401504c0af10e';

module.exports = node;
