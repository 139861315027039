import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Select, Table, Tabs, TreeSelect } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CopyProduct, DatePicker, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { ShowLabel } from '~/components/promotion/label';
import { fromGlobalId } from '~/helper';
import FreebieHistory from './FreebieHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;
const { SHOW_PARENT } = TreeSelect;

class FreebieForm extends FormBase {
  static propTypes = {
    freebie: PropTypes.shape({
      id: PropTypes.string,
      brandId: PropTypes.string,
      name: PropTypes.string,
      tiers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      excludeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      includeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    freebie: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  renderOrders = orders => { // eslint-disable-line react/display-name
    if (orders) {
      return (
        <Table
          dataSource={orders}
          rowKey={record => record.node.id}
          columns={[
            {
              title: 'View',
              dataIndex: 'node',
              render: node => <a href={`/sales/order/${fromGlobalId(node.id).id}`} rel="noopener noreferrer" target="_blank">{node.name}</a>, // eslint-disable-line react/display-name
            },
            {
              title: 'Inserted At',
              dataIndex: ['node', 'insertedAt'],
              render: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
              title: 'Grand Total',
              dataIndex: ['node', 'grandTotal'],
              render: value => `$${value}`,
            },
          ]}
        />
      );
    }
    return null;
  }

  getTreeData = (brands) => {
    return [
      {
        value: '*',
        title: 'All Brands',
        children: brands.map(({ node }) => ({ value: node.id, title: node.name }))
      }
    ]
  }

  render() {
    const { match, viewer } = this.props;
    const freebie = get(this.props, 'freebie', {});
    const brands = get(viewer, 'brands.edges', []);
    const tiers = get(freebie, 'tiers.edges', []);

    const defaultTier = { quantity: 1, mode: 0 };

    const initialValue = {
      tiers: tiers.length ? tiers.slice(0).map(({ node }) => ({
        id: node.id,
        price: node.price,
        productIds: get(node, 'products', []).map((p) => SelectProduct.productOptions(p)),
        quantity: node.quantity || 1,
        mode: get(node, 'mode', 0),
      })) : [defaultTier],
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values, { setLoading: this.setLoading }); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            {this.renderSaveButton()}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <p>
          <b>DO NOT put a product in both Exclude Only Products </b> and <b>Include Only Products</b><br />
          i.e. if product A is exlucded and included at the same time, this basically will disable the whole promotion, read as <b>broken</b>.
        </p>

        <p> If there is promotion</p>

        <ul>
          <li>spend $250 and receive some products</li>
          <li>spend $500+ and receive a store credit</li>
        </ul>

        <p>Please setup two price tiers as</p>
        <table>
          <tbody>
            <tr>
              <th>Price</th>
              <th>Products</th>
            </tr>
            <tr>
              <td>250</td>
              <td>select the products</td>
            </tr>
            <tr>
              <td>500</td>
              <td>leave Products field empty</td>
            </tr>
          </tbody>
        </table>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">

            {freebie.id && (
              <FormItem
                name="id"
                initialValue={freebie.id}
                hidden
              >
                <Input />
              </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Name"
            >
              <FormItem
                name="name"
                rules={[{ required: true, message: 'required' }]}
                initialValue={freebie.name}
                extra={<span style={{color: 'red'}}>Public facing. Customers will see this name</span>}
              >
                <Input placeholder="Name" />
              </FormItem>

              <ShowLabel labels={freebie.labels} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Brands"
              name="brandIds"
              rules={[{ required: true, message: 'required' }]}
              initialValue={freebie.brandIds}
            >
              <TreeSelect
                placeholder="Select Brand"
                showSearch
                allowClear
                treeData={this.getTreeData(brands)}
                treeCheckable
                treeNodeFilterProp="title"
                showCheckedStrategy={SHOW_PARENT}
                treeDefaultExpandAll
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Price Tiers"
              required
            >
              <Form.List name="tiers" initialValue={initialValue.tiers}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["tiers", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <FormItem
                          label="Price"
                          name={[name, "price"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '90px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber placeholder="Price" />
                        </FormItem>

                        <FormItem
                          label="Products"
                          name={[name, "productIds"]}
                          style={{ width: '400px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <SelectProduct
                            placeholder="Products"
                            mode="multiple"
                            labelInValue
                            filterOption={false}
                            viewer={viewer}
                            style={{ width: '400px' }}
                          />
                        </FormItem>

                        <FormItem
                          label="Quantity"
                          name={[name, "quantity"]}
                          style={{ width: '90px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber placeholder="Qty" />
                        </FormItem>

                        <FormItem
                          label="Mode"
                          name={[name, "mode"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '200px', display: 'inline-block'}}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          extra="Give one or all selected product(s)"
                        >
                          <Select placeholder="Mode">
                            <Option value={0}>All</Option>
                            <Option value={1}>One</Option>
                          </Select>
                        </FormItem>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ cursor: 'pointer' }}
                            disabled={fields.length === 1}
                            onClick={() => remove(name)}
                          />
                        ) : null}

                      </div>
                    ))}
                    <Button onClick={() => add(defaultTier)}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={freebie.startDate ? moment(freebie.startDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={freebie.endDate ? moment(freebie.endDate) : null}
              help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
            >
              <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
            </FormItem>

            <CopyProduct
              viewer={viewer}
              entityId={freebie.id}
              options={['None', 'Freebie', 'Category']}
            />

            <FormItem
              {...formItemLayout}
              label="Exclude Only Products"
              shouldUpdate
            >
              {(form) => (
                <>
                  <FormItem
                    name="excludeProducts"
                    extra="Only these products will not get freebies"
                    initialValue={get(freebie, 'excludeProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                  >
                    <SelectProduct
                      placeholder="Exclude Products"
                      mode="multiple"
                      labelInValue
                      filterOption={false}
                      viewer={viewer}
                      importExport={{
                        onImport: (value) => { SelectProduct.updateSelect(form, value, 'excludeProducts'); },
                        listToExport: get(freebie, 'excludeProducts.edges', [])
                      }}
                      filters={{
                        brands: form.getFieldValue('brandIds')
                      }}
                    />
                  </FormItem>
                </>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Include Only Products"
              shouldUpdate
            >
              {(form) => (
                <>
                  <FormItem
                    name="includeProducts"
                    extra="Only these products will get freebies. If left empty, applies to all products under selected brand"
                    initialValue={get(freebie, 'includeProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                  >
                    <SelectProduct
                      placeholder="Include Products"
                      mode="multiple"
                      labelInValue
                      filterOption={false}
                      viewer={viewer}
                      importExport={{
                        onImport: (value) => { SelectProduct.updateSelect(form, value, 'includeProducts'); },
                        listToExport: get(freebie, 'includeProducts.edges', [])
                      }}
                      filters={{
                        brands: form.getFieldValue('brandIds')
                      }}
                    />
                  </FormItem>
                </>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Stealth Mode"
              name="stealthMode"
              initialValue={get(freebie, 'stealthMode', 0) ? 1 : 0}
              extra="promotional item will be hidden"
            >
              <Select placeholder="Stealth Mode">
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(freebie, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          <TabPane tab="Orders" key="orders">
            {this.renderOrders(get(freebie, 'salesOrders.edges', []))}
          </TabPane>

          {freebie.id && (
            <TabPane tab="History" key="history">
              <FreebieHistory freebie={freebie} viewer={viewer} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(FreebieForm, {
  viewer: graphql`
    fragment FreebieForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...CopyProduct_viewer
      ...SelectProduct_viewer
      ...FreebieHistory_viewer
      brands(first: 1000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
