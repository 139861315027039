/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCommonPromoInput = {|
  brandIds?: ?$ReadOnlyArray<?string>,
  endDate: string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  freebieId?: ?string,
  images?: ?$ReadOnlyArray<?InputCommonImage>,
  name: string,
  onProducts?: ?$ReadOnlyArray<?string>,
  startDate: string,
  status: boolean,
  type?: ?number,
|};
export type InputCommonImage = {|
  file?: ?any,
  id?: ?string,
|};
export type AddCommonPromoMutationVariables = {|
  input: AddCommonPromoInput
|};
export type AddCommonPromoMutationResponse = {|
  +addCommonPromo: ?{|
    +commonPromoEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +images: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +imageUrl: ?string,
            |}
          |}>
        |},
        +onProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +type: ?number,
        +brandIds: ?$ReadOnlyArray<?string>,
        +freebieId: ?string,
        +excludeProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +status: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddCommonPromoMutation = {|
  variables: AddCommonPromoMutationVariables,
  response: AddCommonPromoMutationResponse,
|};
*/


/*
mutation AddCommonPromoMutation(
  $input: AddCommonPromoInput!
) {
  addCommonPromo(input: $input) {
    commonPromoEdge {
      cursor
      node {
        id
        name
        startDate
        endDate
        images(first: 999) {
          edges {
            node {
              id
              imageUrl
            }
          }
        }
        onProducts(first: 99999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        type
        brandIds
        freebieId
        excludeProducts(first: 99999) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCommonPromoPayload",
    "kind": "LinkedField",
    "name": "addCommonPromo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CommonPromoEdge",
        "kind": "LinkedField",
        "name": "commonPromoEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CommonPromo",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 999
                  }
                ],
                "concreteType": "CommonImageConnection",
                "kind": "LinkedField",
                "name": "images",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommonImageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommonImage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "images(first:999)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "onProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "onProducts(first:99999)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freebieId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "excludeProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "excludeProducts(first:99999)"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCommonPromoMutation",
    "selections": (v6/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCommonPromoMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "2b4ac9b8b1589aab76e177804901409e",
    "id": null,
    "metadata": {},
    "name": "AddCommonPromoMutation",
    "operationKind": "mutation",
    "text": "mutation AddCommonPromoMutation(\n  $input: AddCommonPromoInput!\n) {\n  addCommonPromo(input: $input) {\n    commonPromoEdge {\n      cursor\n      node {\n        id\n        name\n        startDate\n        endDate\n        images(first: 999) {\n          edges {\n            node {\n              id\n              imageUrl\n            }\n          }\n        }\n        onProducts(first: 99999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        type\n        brandIds\n        freebieId\n        excludeProducts(first: 99999) {\n          edges {\n            node {\n              id\n              name\n              status\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d67dba310129123f0698c14f8d38adb';

module.exports = node;
