/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCouponInput = {|
  autoApply?: ?boolean,
  codes: $ReadOnlyArray<?string>,
  content?: ?$ReadOnlyArray<?InputCouponContent>,
  endDate: string,
  name: string,
  showLabel: boolean,
  startDate: string,
  status: boolean,
  usageLimit?: ?number,
  usagePerCustomer?: ?number,
|};
export type InputCouponContent = {|
  actions?: ?InputCouponCondActObject,
  additionDate?: ?string,
  additionProductIds?: ?$ReadOnlyArray<?string>,
  conditions?: ?InputCouponCondActObject,
  discountAmount?: ?number,
  discountType?: ?number,
  id?: ?string,
  removalDate?: ?string,
  removalProductIds?: ?$ReadOnlyArray<?string>,
|};
export type InputCouponCondActObject = {|
  comparator: string,
  dataType: string,
  key: string,
  value: $ReadOnlyArray<?string>,
|};
export type AddCouponMutationVariables = {|
  input: AddCouponInput
|};
export type AddCouponMutationResponse = {|
  +addCoupon: ?{|
    +couponEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +codes: ?$ReadOnlyArray<?string>,
        +content: ?$ReadOnlyArray<?{|
          +discountType: ?number,
          +discountAmount: ?number,
          +conditions: ?any,
          +actions: ?any,
          +removalDate: ?string,
          +removalProducts: ?any,
          +additionDate: ?string,
          +additionProducts: ?any,
        |}>,
        +usageLimit: ?number,
        +usagePerCustomer: ?number,
        +timesUsed: ?number,
        +startDate: ?string,
        +endDate: ?string,
        +status: ?boolean,
        +showLabel: ?boolean,
        +autoApply: ?boolean,
      |},
    |}
  |}
|};
export type AddCouponMutation = {|
  variables: AddCouponMutationVariables,
  response: AddCouponMutationResponse,
|};
*/


/*
mutation AddCouponMutation(
  $input: AddCouponInput!
) {
  addCoupon(input: $input) {
    couponEdge {
      cursor
      node {
        id
        name
        codes
        content {
          discountType
          discountAmount
          conditions
          actions
          removalDate
          removalProducts
          additionDate
          additionProducts
          id
        }
        usageLimit
        usagePerCustomer
        timesUsed
        startDate
        endDate
        status
        showLabel
        autoApply
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountAmount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conditions",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removalDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removalProducts",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionProducts",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usageLimit",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usagePerCustomer",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timesUsed",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showLabel",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoApply",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCouponPayload",
        "kind": "LinkedField",
        "name": "addCoupon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CouponEdge",
            "kind": "LinkedField",
            "name": "couponEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Coupon",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponContent",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCouponPayload",
        "kind": "LinkedField",
        "name": "addCoupon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CouponEdge",
            "kind": "LinkedField",
            "name": "couponEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Coupon",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponContent",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1589889dc51973074931b112a61db07",
    "id": null,
    "metadata": {},
    "name": "AddCouponMutation",
    "operationKind": "mutation",
    "text": "mutation AddCouponMutation(\n  $input: AddCouponInput!\n) {\n  addCoupon(input: $input) {\n    couponEdge {\n      cursor\n      node {\n        id\n        name\n        codes\n        content {\n          discountType\n          discountAmount\n          conditions\n          actions\n          removalDate\n          removalProducts\n          additionDate\n          additionProducts\n          id\n        }\n        usageLimit\n        usagePerCustomer\n        timesUsed\n        startDate\n        endDate\n        status\n        showLabel\n        autoApply\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a487b8561d6e2d7c4df7153a46a36a99';

module.exports = node;
