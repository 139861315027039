import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateQuoteRecoveryStatusMutation($input: UpdateQuoteRecoveryStatusInput!) {
    updateQuoteRecoveryStatus(input:$input) {
      salesQuote {
        recoveryStatus
        ...SalesQuoteHistory_salesQuote
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
