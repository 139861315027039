import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { Address4 } from 'ip-address';
import { Button, Form, Input, Space, Tag } from 'antd';
import { formItemLayout } from '~/components/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Item: FormItem } = Form;

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

// delete characters which not 0-9, a-z, A-Z, space or hyphen.
const sanitiseCharacters = (value) => {
  return value && value.replace(/[^0-9A-z -]/g, '');
};

const DescriptionInput = (props) => {
  const { onChange, ...restProps } = props;

  const onInputChange = (e) => {
    const chars = sanitiseCharacters(e.target.value);
    onChange(chars);
  }

  return (
    <Input {...restProps} onChange={onInputChange} />
  )
};

DescriptionInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

DescriptionInput.defaultProps = {
  value: undefined,
  onChange: () => { },
};

const IpAddressForm = (props) => {
  const { fieldName, label, ips, handleSubmit, shouldDisableBtn } = props;
  const ipAddresses = JSON.parse(ips);
  const [form] = Form.useForm();

  let initialValue = ipAddresses.length ? ipAddresses.slice(0) : [{}];
  initialValue = sortBy(initialValue, ['description']);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <FormItem>
        <Button type="primary" htmlType="submit" disabled={shouldDisableBtn}>Save</Button>
      </FormItem>

      {fieldName === "internal_ip_addresses" && (
        <p style={{ display: 'flex', flexDirection: 'column' }}>
          <span>This is for allowing Internal IP to access the following pages:</span>
          <Space size={[0, 8]} wrap>
            <Tag>/price-changes</Tag>
            <Tag>/new-arrivals</Tag>
            <Tag>/price-tag</Tag>
            <Tag>/pos</Tag>
            <Tag>/warranty</Tag>
          </Space>
          <span>
            Please ensure that the correct store names are used in the description for the corresponding IP addresses they are associated with.
          </span>
          <span>
            It will take about 5 seconds for the changes to propagate and take effect.
          </span>
          <span>
            If you encounter any issues or it fails to whitelist the ip addresses, please reach out to the developer for assistance.
          </span>
        </p>
      )}

      <Form.List name={fieldName} initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <FormItem
                key={key}
                label={index === 0 ? label : ''}
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                style={{ marginBottom: '0px' }}
              >
                <FormItem
                  name={[name, "ip"]}
                  rules={[
                    { required: !!index, message: 'required' },
                    {
                      validator: (rule, value) => {
                        if (!value) {
                          return Promise.resolve();
                        } else if (value && Address4.isValid(value)) {
                          const ip = new Address4(value);

                          if (ip.parsedSubnet === "") {
                            return Promise.resolve();
                          }
                        }

                        return Promise.reject(new Error('Invalid Ip format'));
                      }
                    },
                  ]}
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                >
                  <Input
                    allowClear
                    placeholder="Ip Address"
                    autoComplete="googleignoreautofill"
                  />
                </FormItem>
                <FormItem
                  name={[name, "description"]}
                  rules={[
                    ({ getFieldValue }) => ({ required: !!getFieldValue([fieldName, name, "ip"]), message: 'required' }),
                  ]}
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                >
                  <DescriptionInput placeholder="Description" allowClear />
                </FormItem>
                {fields.length > 1 ? (
                  <MinusCircleOutlined onClick={() => remove(name)} />
                ) : null}
              </FormItem>
            ))}
            <FormItem {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </FormItem>
          </>
        )}
      </Form.List>
    </Form>
  );
};

IpAddressForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  ips: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  shouldDisableBtn: PropTypes.bool,
};

IpAddressForm.defaultProps = {
  shouldDisableBtn: false
};

export default IpAddressForm;
