/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCustomerInput = {|
  checkedOrders?: ?number,
  email?: ?string,
  firstname?: ?string,
  lastLoginAt?: ?string,
  lastname?: ?string,
  passwordHash?: ?string,
  reviewReminder?: ?boolean,
  securityCheck?: ?boolean,
  status?: ?string,
|};
export type AddCustomerMutationVariables = {|
  input: AddCustomerInput
|};
export type AddCustomerMutationResponse = {|
  +addCustomer: ?{|
    +customerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +firstname: ?string,
        +lastname: ?string,
        +email: ?string,
        +reviewReminder: ?boolean,
        +checkedOrders: ?number,
        +securityCheck: ?boolean,
        +lastLoginAt: ?string,
        +status: ?string,
      |},
    |}
  |}
|};
export type AddCustomerMutation = {|
  variables: AddCustomerMutationVariables,
  response: AddCustomerMutationResponse,
|};
*/


/*
mutation AddCustomerMutation(
  $input: AddCustomerInput!
) {
  addCustomer(input: $input) {
    customerEdge {
      cursor
      node {
        id
        firstname
        lastname
        email
        reviewReminder
        checkedOrders
        securityCheck
        lastLoginAt
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCustomerPayload",
    "kind": "LinkedField",
    "name": "addCustomer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerEdge",
        "kind": "LinkedField",
        "name": "customerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reviewReminder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkedOrders",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "securityCheck",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastLoginAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCustomerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCustomerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3d13552b5b7f2b31ebf0de1c2197f70e",
    "id": null,
    "metadata": {},
    "name": "AddCustomerMutation",
    "operationKind": "mutation",
    "text": "mutation AddCustomerMutation(\n  $input: AddCustomerInput!\n) {\n  addCustomer(input: $input) {\n    customerEdge {\n      cursor\n      node {\n        id\n        firstname\n        lastname\n        email\n        reviewReminder\n        checkedOrders\n        securityCheck\n        lastLoginAt\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c65e5b642306ed704eb0257911553db4';

module.exports = node;
