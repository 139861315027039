/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UrlRewriteView_viewer$ref = any;
export type routes_UrlRewriteView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_UrlRewriteView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UrlRewriteView_viewer$ref
  |}
|};
export type routes_UrlRewriteView_Query = {|
  variables: routes_UrlRewriteView_QueryVariables,
  response: routes_UrlRewriteView_QueryResponse,
|};
*/


/*
query routes_UrlRewriteView_Query(
  $ids: [ID]
) {
  viewer {
    ...UrlRewriteView_viewer
    id
  }
}

fragment UrlRewriteForm_viewer on Admin {
  id
}

fragment UrlRewriteView_viewer on Admin {
  ...UrlRewriteForm_viewer
  urlRewrites(first: 1, ids: $ids) {
    edges {
      node {
        id
        from
        to
        httpCode
        lastAccessed
        urlType
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_UrlRewriteView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UrlRewriteView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_UrlRewriteView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "UrlRewriteConnection",
            "kind": "LinkedField",
            "name": "urlRewrites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UrlRewriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UrlRewrite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "from",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "to",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "httpCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastAccessed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urlType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6618b24943420bcacc4ef2b6ea6b9f61",
    "id": null,
    "metadata": {},
    "name": "routes_UrlRewriteView_Query",
    "operationKind": "query",
    "text": "query routes_UrlRewriteView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...UrlRewriteView_viewer\n    id\n  }\n}\n\nfragment UrlRewriteForm_viewer on Admin {\n  id\n}\n\nfragment UrlRewriteView_viewer on Admin {\n  ...UrlRewriteForm_viewer\n  urlRewrites(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        from\n        to\n        httpCode\n        lastAccessed\n        urlType\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2070b9311bd1e65cf88dd6b3244a2b09';

module.exports = node;
