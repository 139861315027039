import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, isEmpty, startCase } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, message, Select, Space, Switch, Tabs, Tag, TimePicker } from 'antd';

import { DatePicker, FormBase, formItemLayout, Editor, getCountry, getStates, getSuburbOrCityLabel, renderRegion } from '~/components/form';
import Presence from '~/components/Presence';
import { checkEmails } from '~/helper';
import BdmEmails from './BdmEmails';
import StoreHistory from './StoreHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const { COUNTRY } = process.env;

export const hourFormat = 'HH:mm';

const defaultHours = {
  monday: { open: "07:30", close: "17:30" },
  tuesday: { open: "07:30", close: "17:30" },
  wednesday: { open: "07:30", close: "17:30" },
  thursday: { open: "07:30", close: "17:30" },
  friday: { open: "07:30", close: "17:30" },
  saturday: { open: "08:00", close: "16:00" },
  sunday: { open: "09:00", close: "16:00" }
};

class StoreForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  disabledDate = current => current && current < moment().startOf('day')

  requiredFromTo = ({ getFieldValue }) => {
    return !isEmpty(getFieldValue('excludeAllocation[brands]'));
  }

  renderHours = (hours, day) => { // eslint-disable-line react/display-name
    if (!hours) {
      return null;
    }

    return (
      <FormItem
        {...formItemLayout}
        label={startCase(day)}
        key={day}
      >
        <Space>
          <FormItem
            name={["hours", day, "open"]}
            initialValue={get(hours, `${day}.open`) !== 'Closed' ? moment(hours[day].open, hourFormat) : null}
          >
            <TimePicker format={hourFormat} />
          </FormItem>
          <FormItem
            name={["hours", day, "close"]}
            initialValue={get(hours, `${day}.close`) !== 'Closed' ? moment(hours[day].close, hourFormat) : null}
          >
            <TimePicker format={hourFormat} />
          </FormItem>
        </Space>
      </FormItem>
    );
  }

  renderRegion = () => renderRegion.call(this)

  render() {
    const { match, viewer } = this.props;
    const store = get(this.props, 'store', {});
    const hours = get(store, 'hours', defaultHours);
    const brands = get(viewer, 'brands.edges', []);

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
            {store.id && <Button style={{margin: '20px'}} onClick={() => message.success(store.encryptedId)}>Get Encrypted ID</Button>}
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={store.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              initialValue={get(store, 'name', '')}
              rules={[
                { required: true, message: 'required' },
                { validator: (rule, value) => {
                  if (value && (value.includes('(') || value.includes(')'))) {
                    return Promise.reject(new Error('Name of Suburb only, no brackets no nothing'));
                  }
                  return Promise.resolve();
                }},
              ]}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Branch Code"
              name="branchCode"
              initialValue={get(store, 'branchCode')}
              rules={[
                { required: true, message: 'required' },
                { validator: (rule, value) => {
                  let digitRegex = /^\d{2}$/;
                  if (COUNTRY === 'NZ') {
                    digitRegex = /^\d{3}$/;
                  }
                  const capLetterDigitRegex = /^[A-Z][0-9]$/;

                  if (value.length <= 3 && (value.match(digitRegex) || value.match(capLetterDigitRegex))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('invalid Aden branch code'));
                }},
              ]}
              extra={<span style={{color: 'red'}}>DO NOT USE DUMMY VALUE</span>}
            >
              <Input placeholder="Branch Code" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="State Managers"
            >
              <Select
                mode="tags"
                style={{ width: '100%', color: 'red' }}
                placeholder="State Managers"
                tagRender={({label}) => (
                  <Tag style={{ color: 'black' }}>
                    {label}
                  </Tag>
                )}
                value={get(store, 'stateManager.emails', [])}
                disabled
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Manager Emails"
              name="managerEmails"
              initialValue={get(store, 'managerEmails', undefined) || undefined}
              rules={[
                { required: true, message: 'required' },
                { required: true, validator: checkEmails },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Manager Emails"
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Emails"
              name="emails"
              initialValue={get(store, 'emails', undefined)}
              rules={[
                { required: true, message: 'required' },
                { required: true, validator: checkEmails },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Emails"
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Phone"
              name="phone"
              initialValue={get(store, 'phone', null)}
              rules={[
                { required: true, message: 'required' },
              ]}
            >
              <Input placeholder="Phone" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Fax"
              name="fax"
              initialValue={get(store, 'fax', null)}
            >
              <Input placeholder="Fax" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Regional Store"
              name="regional"
              valuePropName="checked"
              initialValue={get(store, 'regional', false)}
              rules={[
                { required: true, message: 'required' },
              ]}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Can Store Pickup"
              name="canPickup"
              valuePropName="checked"
              initialValue={get(store, 'canPickup', true)}
              rules={[{ required: true, message: 'required' }]}
            >
              <Switch />
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => getFieldValue('canPickup') ? (
                <FormItem
                  {...formItemLayout}
                  label="Exclude Store Pickup for Bulky Good"
                  name="excludeBulkyGood"
                  valuePropName="checked"
                  initialValue={get(store, 'excludeBulkyGood', false)}
                >
                  <Switch />
                </FormItem>
                ) : null
              }
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="In Call Pool"
              name="inCallPool"
              valuePropName="checked"
              initialValue={get(store, 'inCallPool', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Can Sell Husqvarna OPEs"
              name="canHusqvarnaOpe"
              valuePropName="checked"
              initialValue={get(store, 'canHusqvarnaOpe', false)}
              extra="If disabled, customers won't be able to place Click & Collect orders that contains Husqvarna OPEs."
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Distribution Hub"
              name="distributionHub"
              valuePropName="checked"
              initialValue={get(store, 'distributionHub', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Staff Delivery"
              name="staffDelivery"
              valuePropName="checked"
              initialValue={get(store, 'staffDelivery', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Allocation Limit"
              name="allocationLimit"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'allocationLimit', 0)}
              extra="0 means unlimited (Per Day)"
            >
              <InputNumber min={0} style={{ width: '100%' }} placeholder="limit" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Exclude Allocation for Brands"
              name={["excludeAllocation", "brands"]}
              initialValue={get(store, 'excludeAllocation.brands', []).map(b => b.id)}
              rules={[{ required: false, message: 'required' }]}
              extra={<span style={{ color: 'red' }}>If left empty, <b>it will apply to all brands.</b></span>}
            >
              <Select
                allowClear
                mode="multiple"
                optionFilterProp="children"
              >
                {brands.map(({node}) => <Option key={node.id} value={node.id}>{node.name}</Option>)}
              </Select>
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const excludeAlloBrands = getFieldValue(["excludeAllocation", "brands"]);
                const required = !isEmpty(excludeAlloBrands);

                return (
                  <FormItem
                    {...formItemLayout}
                    label="Exclude Allocation From To"
                    name={["excludeAllocation", "from_to"]}
                    rules={[
                      { required, message: 'required' },
                      { validator: (rule, value) => {
                        if (required && Array.isArray(value) && (value[0] === null || value[1] === null)) {
                          return Promise.reject(new Error('required'));
                        }
                        return Promise.resolve();
                      }},
                    ]}
                    initialValue={[
                      get(store, 'excludeAllocation.startDate') ? moment(store.excludeAllocation.startDate) : null,
                      get(store, 'excludeAllocation.endDate') ? moment(store.excludeAllocation.endDate) : null
                    ]}
                    extra={
                      <div>
                        <span>It will prevent targeted brands from allocating this store in that specify timeframe.</span> <br />
                        <span>(Store Pickup, Distribution Hub and Staff Delivery)</span>
                      </div>
                    }
                  >
                    <RangePicker
                      disabledDate={this.disabledDate}
                      showTime={{
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                      }}
                    />
                  </FormItem>
                )
              }}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Address"
              name="address"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'address', '')}
            >
              <Input placeholder="Address" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label={getSuburbOrCityLabel()}
              name="city"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'city', '')}
            >
              <Input placeholder={COUNTRY === 'NZ' ? "Suburb": "City"} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="postcode"
              name="postcode"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'postcode', '')}
            >
              <Input placeholder="Postcode" />
            </FormItem>

            {COUNTRY === 'NZ' && (
              <FormItem
                {...formItemLayout}
                label="Town / City"
                name="region"
                rules={[{ required: true, message: 'required' }]}
                initialValue={get(store, 'region', '')}
              >
                <Input placeholder="Town / City" />
              </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="State"
              name='state'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'state', '')}
              extra={COUNTRY === 'NZ' ? (
                <>
                  Frontend <b>/stores</b> uses this field to group stores in the same state.
                </>
                ) : null
              }
            >
              <Select
                placeholder="State"
                options={Object.entries(getStates()).map(([k, v]) => ({ value: k, label: v }))}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Latitude"
              name="lat"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'lat', '')}
            >
              <InputNumber style={{ width: '100%' }} placeholder="Latitude" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Longitude"
              name="lng"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'lng', '')}
            >
              <InputNumber style={{ width: '100%' }} placeholder="Longitude" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description"
            >
              <Editor
                name="description"
                editorState={store.description}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Country"
              name="country"
              rules={[
                { required: true, message: 'Required' },
              ]}
              initialValue={getCountry(store)}
            >
              <Select placeholder="Country" disabled>
                <Option value="AU">Australia</Option>
                <Option value="NZ">New Zealand</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(store, 'status') ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Title"
              name="metaTitle"
              initialValue={get(store, 'metaTitle', '')}
            >
              <TextArea rows={2} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Description"
              name="metaDescription"
              initialValue={get(store, 'metaDescription', '')}
            >
              <TextArea rows={3} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Store Content"
            >
              <Editor
                name="content"
                editorState={store.content}
              />
            </FormItem>
          </TabPane>

          <TabPane tab="Hours" key="hours" forceRender>

            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => this.renderHours(hours, day))}

          </TabPane>

          <TabPane tab="BDM Emails" key="bdmEmails" forceRender>
            <BdmEmails brands={brands} store={store} />
          </TabPane>

          {store.id && (
            <TabPane tab="History" key="history">
              <StoreHistory store={store} viewer={viewer} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(StoreForm, {
  viewer: graphql`
    fragment StoreForm_viewer on Admin {
      ...StoreHistory_viewer
      brands(first: 999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
