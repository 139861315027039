import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const routes = (
  <Route
    path="/config"
    getComponent={() => (
      import(/* webpackChunkName: "ConfigForm" */'./ConfigForm').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_ConfigForm_Query {
        viewer {
          ...ConfigForm_viewer
        }
      }
    `}
  />
);

export default routes;
