import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import moment from 'moment-timezone';
import { Affix, Button, Divider, Form, Input, InputNumber, Select, Switch, Tabs, TimePicker } from 'antd';
import { DatePicker, FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';
import Status from '~/components/Status';

import { BustCacheMutation, UpdateConfigMutation } from './mutations';

const { Item: FormItem } = Form;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const stringToBool = (configs, f) => {
  const v = get(configs, f);

  if (v === 'true') {
    return true;
  } else if (v === 'false') {
    return false;
  }

  return v;
};

const hourFormat = 'HH:mm';

const SAME_DAY_METHODS = {
  AustraliaPostOnDemand: "Australia Post On-Demand",
  CenturyCouriers: "Century Couriers",
};

let PAYMENT_OPTIONS = [];

if (process.env.COUNTRY === 'AU') {
  PAYMENT_OPTIONS = [
    {name: "AfterPay", code: "afterpay"},
    {name: "Apple Pay", code: "till_applepay"},
    {name: "Google Pay", code: "till_googlepay"},
    {name: "Apple Pay Tyro", code: "tyro_applepay"},
    {name: "Google Pay Tyro", code: "tyro_googlepay"},
    {name: "Free", code: "free"},
    {name: "Humm", code: "humm"},
    {name: "Latitude Financial", code: "latitude_financial"},
    {name: "Latitude Pay", code: "latitude_pay"},
    {name: "Paypal", code: "paypal"},
    {name: "Till", code: "till"},
    {name: "Till 3DS", code: "till_3ds"},
    {name: "Tyro", code: "tyro"},
    {name: "Securepay", code: "securepay"},
    {name: "Stripe", code: "stripe"},
    {name: "Zip", code: "zip"},
    {name: "Direct Deposit", code: "direct_deposit"}
  ];
} else if (process.env.COUNTRY === 'NZ') {
  PAYMENT_OPTIONS = [
    {name: "AfterPay", code: "afterpay"},
    {name: "Free", code: "free"},
    {name: "Stripe", code: "stripe"},
    {name: "Zip", code: "zip"},
  ];
}

class ConfigForm extends FormBase {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (formValues) => {
    const fields = Object.keys(formValues);
    const touchedFields = fields.filter(key => this.formRef.current.isFieldTouched(key));

    const grt = get(formValues, "google_recaptcha_time");

    grt.start = (grt.start && grt.end) ? grt.start.format('HH:mm') : null
    grt.end = (grt.start && grt.end) ? grt.end.format('HH:mm') : null

    this.formRef.current.validateFields(touchedFields).then((touchedValues) => {
      const values = { ...touchedValues };

      if (values.google_recaptcha_time) {
        values.google_recaptcha_time = grt;
      }

      if (values.payment_methods) {
        values.payment_methods = values.payment_methods.join(",");
      }

      if (values.price_match_excluded_brands) {
        values.price_match_excluded_brands = values.price_match_excluded_brands.join(",");
      }

      if (values.same_day_carriers) {
        values.same_day_carriers = values.same_day_carriers.join(",");
      }

      const mutation = {
        environment: this.props.relay.environment,
        variables: { input: { configs: JSON.stringify(values) } },
        viewer: this.props.viewer,
        safeGuard: "update",
      };
      UpdateConfigMutation.commit(mutation);
    }).catch(() => {});
  }

  bustCache = (target) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { target } },
      viewer: this.props.viewer,
    };
    BustCacheMutation.commit(mutation);
  }

  renderHours = () => { // eslint-disable-line react/display-name
    const { viewer: { configs } } = this.props;

    let grt = get(configs, 'google_recaptcha_time', "{}");
    grt = JSON.parse(grt);
    const startTime = get(grt, "start");
    const endTime = get(grt, "end");

    return (
      <div>
        <FormItem
          {...formItemLayout}
          label="Start Time"
          name={["google_recaptcha_time", "start"]}
          initialValue={startTime ? moment(startTime, hourFormat) : null}
        >
          <TimePicker format={hourFormat} />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="End Time"
          name={["google_recaptcha_time", "end"]}
          initialValue={endTime ? moment(endTime, hourFormat) : null}
          rules={[
            ({ getFieldValue }) => ({ required: !!getFieldValue("google_recaptcha_time[start]"), message: 'required' }),
          ]}
        >
          <TimePicker format={hourFormat} />
        </FormItem>
      </div>
    )
  }

  getNumber = (object, path, defaultValue) => {
    const valueString = get(object, path, defaultValue);
    const valueNumber = Number(valueString);

    if (valueString && typeof valueNumber === 'number') {
      return valueNumber;
    }

    return valueString;
  }

  render() {
    const { match, viewer: { configs } } = this.props;
    const brands = get(this.props.viewer, 'brands.edges', []);
    const excludedBrands =  get(configs, 'price_match_excluded_brands', "");
    const sameDayCarriers =  get(configs, 'same_day_carriers', "");
    const paymentMethods = get(configs, 'payment_methods', null);

    return (
      <div>
        <Form ref={this.formRef} onFinish={this.handleSubmit}>
          <Affix>
            <div>
              <Presence match={match} disableButton={this.handleDisableBtn} />
              <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
            </div>
          </Affix>

          <Tabs defaultActiveKey="general">
            <TabPane tab="General" key="general">
              <h2>Shipping</h2>

              <FormItem
                {...formItemLayout}
                label="Dangerous Goods Surcharge"
                name="dangerous_goods_surcharge"
                rules={[{ type: 'number', message: 'invalid amount' }]}
                initialValue={this.getNumber(configs, 'dangerous_goods_surcharge', null)}
              >
                <InputNumber />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Same Day Carriers"
                name="same_day_carriers"
                initialValue={sameDayCarriers ? sameDayCarriers.split(",") : undefined}
                extra="Leave it empty = disabling same day delivery service"
              >
                <Select
                  mode="multiple"
                  placeholder="Same Day Carriers"
                >
                  {Object.entries(SAME_DAY_METHODS).map( ([key, label]) => (
                    <Option key={key} value={key} >{label}</Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Free Shipping Amount"
                name="free_shipping_amount"
                rules={[{ type: 'number', message: 'invalid amount' }]}
                initialValue={this.getNumber(configs, 'free_shipping_amount', null)}
              >
                <InputNumber />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Free Shipping Start Date"
                name="free_shipping_start_date"
                initialValue={get(configs, 'free_shipping_start_date', null) != null ? moment(configs.free_shipping_start_date) : null}
              >
                <DatePicker placeholder="Free Shipping Start Date" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Free Shipping End Date"
                name="free_shipping_end_date"
                initialValue={get(configs, 'free_shipping_end_date', null) != null ? moment(configs.free_shipping_end_date) : null}
              >
                <DatePicker placeholder="Free Shipping End Date" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Free Shipping Maximum Length(cm)"
                name="free_shipping_max_len"
                initialValue={get(configs, 'free_shipping_max_len')}
                extra="The longest dimension of an item. 0 means unlimited"
              >
                <InputNumber min={0} step={1} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Free Shipping Maximum Weight(kg)"
                name="free_shipping_max_weight"
                initialValue={get(configs, 'free_shipping_max_weight')}
                extra="0 means unlimited"
              >
                <InputNumber min={0} step={1} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Free Shipping Terms"
                name="free_shipping_terms"
                initialValue={get(configs, 'free_shipping_terms')}
              >
                <TextArea />
              </FormItem>

              <Divider />

              <h2>Security Check</h2>
              <FormItem
                {...formItemLayout}
                label="Enable Security Check"
                name="enable_security_check"
                valuePropName="checked"
                initialValue={stringToBool(configs, 'enable_security_check')}
              >
                <Switch />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Order Verification Max Attempts"
                name="order_verification_max_attempts"
                initialValue={get(configs, 'orderVerificationMaxAttempts', '5')}
              >
                <Input />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Security Check Threshold"
                name="security_check_threshold"
                initialValue={get(configs, 'security_check_threshold', '50')}
                extra="Security check applies on orders over this amount for new customers"
              >
                <Input />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Always Security Check Threshold"
                name="always_security_check_threshold"
                initialValue={get(configs, 'always_security_check_threshold', '2000')}
                extra="Security check always apply on orders over this amount"
              >
                <Input />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Captcha"
                name="captcha"
                valuePropName="checked"
                initialValue={stringToBool(configs, 'captcha')}
              >
                <Switch />
              </FormItem>

              <Divider />

              <h2>Checkout reCAPTCHA</h2>

              <FormItem
                {...formItemLayout}
                label="Google reCAPTCHA"
                name="google_recaptcha"
                valuePropName="checked"
                initialValue={stringToBool(configs, 'google_recaptcha')}
                extra="Leave Start & End time empty, for whole day. (Australia/Sydney time zone)"
              >
                <Switch />
              </FormItem>

              {true && this.renderHours()}

            </TabPane>

            <TabPane tab="Sales" key="sales" forceRender>
              <FormItem
                {...formItemLayout}
                label="Status Change"
                name="unhide_link_only"
                initialValue={14}
                extra="Make `Link Only` products public when it is n days old"
              >
                <Input />
              </FormItem>

              <h2>Price Match</h2>
              <FormItem
                {...formItemLayout}
                label="Excluded Brands"
                name="price_match_excluded_brands"
                initialValue={excludedBrands ? excludedBrands.split(",") : undefined}
              >
                <Select
                  mode="multiple"
                  placeholder="Brands"
                  optionFilterProp="children"
                  showSearch
                  style={{width: '60%', marginBottom: '10px'}}
                  onChange={this.handleBrandChange}
                >
                  {
                    brands.map((edge) => {
                      const brand = edge.node;
                      return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                    })
                  }
                </Select>
              </FormItem>

              <h2>Payment Methods</h2>

              <FormItem
                {...formItemLayout}
                label="Payment Methods"
                name="payment_methods"
                initialValue={paymentMethods ? paymentMethods.split(",") : undefined}
              >
                <Select
                  mode="multiple"
                  placeholder="Payment Methods"
                  optionFilterProp="children"
                  showSearch
                  style={{width: '60%', marginBottom: '10px'}}
                >
                  {
                    PAYMENT_OPTIONS.map(p => <Option key={p.code} value={p.code}>{p.name}</Option>)
                  }
                </Select>
              </FormItem>

            </TabPane>

            <TabPane tab="Emails" key="emails" forceRender>
              <FormItem
                {...formItemLayout}
                label="Warranty Emails"
                name="warranty_emails"
                initialValue={get(configs, 'warranty_emails')}
              >
                <Input />
              </FormItem>
            </TabPane>

            <TabPane tab="SEO" key="seo" forceRender>
              <FormItem
                {...formItemLayout}
                label="Product Meta Description"
                name="product_meta_description"
                initialValue={get(configs, 'product_meta_description')}
              >
                <TextArea rows={2} />
              </FormItem>
            </TabPane>

            <TabPane tab="Cache" key="cache">
              <p>Busting means displaying a notification message to client and prompting users to refresh the browser.</p>
              <ul>
                <li><Button onClick={()=> { this.bustCache("frontend"); }}>Bust Frontend Client</Button></li>
                <li><Button onClick={()=> { this.bustCache("backend"); }}>Bust Backend Client</Button></li>
              </ul>
              <Status viewer={this.props.viewer} />
            </TabPane>

          </Tabs>
        </Form>
      </div>
    );
  }
}
export default createFragmentContainer(ConfigForm, {
  viewer: graphql`
    fragment ConfigForm_viewer on Admin {
      id
      ...Status_viewer
      configs
      brands(first: 1000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
