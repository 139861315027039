import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { RemoveCmsCarouselImageMutation, UpdateCmsCarouselImageMutation } from './mutations';
import CarouselImageForm from './CarouselImageForm';

class CarouselImageView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      cmsCarouselImages: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const imageFields = ['main1', 'main2', 'bg1', 'bg2'];

    const uploadables = {};
    imageFields.forEach((i) => {
      if (values[i] && values[i].file) {
        const { file } = values[i];
        values[i] = i;
        uploadables[i] = file;
      }
    });

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };

    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateCmsCarouselImageMutation.commit(mutation);
  }

  removeCarousel = (carousel) => {
    RemoveCmsCarouselImageMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: carousel.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        window.location.href = "/cms/carousel";
      },
    });
  }

  render() {
    const { match, viewer } = this.props;
    const carousel = get(viewer, 'cmsCarouselImages.edges[0].node', {});

    return (
      <div>
        <Helmet title={carousel.name} />
        <h1>{carousel.title}</h1>
        <CarouselImageForm
          key={carousel.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          removeCarousel={this.removeCarousel}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CarouselImageView, {
  viewer: graphql`
    fragment CarouselImageView_viewer on Admin {
      cmsCarouselImages(first: 1, ids: $ids) {
        edges {
          node {
            id
            title
            link
            main1
            main2
            bg1
            bg2
            startDate
            endDate
            position
            status
          }
        }
      }
    }
  `,
});
