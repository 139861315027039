import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateUrlRewriteMutation } from './mutations';
import UrlRewriteForm from './UrlRewriteForm';

class UrlRewriteView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      urlRewrites: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateUrlRewriteMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const urlRewrite = get(viewer, 'urlRewrites.edges[0].node', {});

    return (
      <div>
        <Helmet title={urlRewrite.from} />
        <h1>{urlRewrite.from}</h1>
        <UrlRewriteForm
          key={urlRewrite.id}
          match={match}
          viewer={viewer}
          router={this.props.router}
          entity={urlRewrite}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(UrlRewriteView, {
  viewer: graphql`
    fragment UrlRewriteView_viewer on Admin {
      ...UrlRewriteForm_viewer
      urlRewrites(first: 1, ids: $ids) {
        edges {
          node {
            id
            from
            to
            httpCode
            lastAccessed
            urlType
            status
          }
        }
      }
    }
  `,
});
