import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddSearchRedirectMutation($input: AddSearchRedirectInput!) {
    addSearchRedirect(input:$input) {
      searchRedirectEdge {
        cursor
        node {
          id
          query
          url
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
