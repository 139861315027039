import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../helper';
import { AddAttributeMutation } from './mutations';
import AttributeForm from './AttributeForm';

class AttributeNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      options: formValues.table.map(({ option }) => option),
    };

    delete values.table;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/attribute/${fromGlobalId(resp.addAttribute.attributeEdge.node.id).id}`;
      },
    };

    AddAttributeMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Attribute" />

        <h1>New Attribute</h1>
        <AttributeForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(AttributeNew, {
  viewer: graphql`
    fragment AttributeNew_viewer on Admin {
      id
      ...AttributeForm_viewer
    }
  `,
});
