/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddHistoryInput = {|
  history?: ?string,
  id?: ?string,
|};
export type AddHistoryMutationVariables = {|
  input: AddHistoryInput
|};
export type AddHistoryMutationResponse = {|
  +addHistory: ?{|
    +historyEdge: ?{|
      +__typename: string,
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +changes: ?any,
        +user: ?string,
        +insertedAt: ?string,
      |},
    |}
  |}
|};
export type AddHistoryMutation = {|
  variables: AddHistoryMutationVariables,
  response: AddHistoryMutationResponse,
|};
*/


/*
mutation AddHistoryMutation(
  $input: AddHistoryInput!
) {
  addHistory(input: $input) {
    historyEdge {
      __typename
      cursor
      node {
        id
        changes
        user
        insertedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddHistoryPayload",
    "kind": "LinkedField",
    "name": "addHistory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HistoryEdge",
        "kind": "LinkedField",
        "name": "historyEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "History",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "user",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddHistoryMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddHistoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0bfb713bf4f767235cdb244683efb2b0",
    "id": null,
    "metadata": {},
    "name": "AddHistoryMutation",
    "operationKind": "mutation",
    "text": "mutation AddHistoryMutation(\n  $input: AddHistoryInput!\n) {\n  addHistory(input: $input) {\n    historyEdge {\n      __typename\n      cursor\n      node {\n        id\n        changes\n        user\n        insertedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c9d6daa5e38ec23f1114f6ed5582b0d';

module.exports = node;
