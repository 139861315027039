/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCmsBannerInput = {|
  categoryBig?: ?any,
  categorySmall?: ?any,
  endDate: string,
  homeBig?: ?any,
  homeSmall?: ?any,
  link?: ?string,
  position?: ?number,
  startDate: string,
  status: boolean,
  title: string,
  urlPaths?: ?$ReadOnlyArray<?string>,
|};
export type AddCmsBannerMutationVariables = {|
  input: AddCmsBannerInput
|};
export type AddCmsBannerMutationResponse = {|
  +addCmsBanner: ?{|
    +cmsBannerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +title: ?string,
        +link: ?string,
        +homeBig: ?string,
        +homeSmall: ?string,
        +categoryBig: ?string,
        +categorySmall: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +urlPaths: ?$ReadOnlyArray<?string>,
        +position: ?number,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddCmsBannerMutation = {|
  variables: AddCmsBannerMutationVariables,
  response: AddCmsBannerMutationResponse,
|};
*/


/*
mutation AddCmsBannerMutation(
  $input: AddCmsBannerInput!
) {
  addCmsBanner(input: $input) {
    cmsBannerEdge {
      cursor
      node {
        id
        title
        link
        homeBig
        homeSmall
        categoryBig
        categorySmall
        startDate
        endDate
        urlPaths
        position
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCmsBannerPayload",
    "kind": "LinkedField",
    "name": "addCmsBanner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsBannerEdge",
        "kind": "LinkedField",
        "name": "cmsBannerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsBanner",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "homeBig",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "homeSmall",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categoryBig",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categorySmall",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlPaths",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCmsBannerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCmsBannerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3cc0bf6954a5fe41bf700a4997883917",
    "id": null,
    "metadata": {},
    "name": "AddCmsBannerMutation",
    "operationKind": "mutation",
    "text": "mutation AddCmsBannerMutation(\n  $input: AddCmsBannerInput!\n) {\n  addCmsBanner(input: $input) {\n    cmsBannerEdge {\n      cursor\n      node {\n        id\n        title\n        link\n        homeBig\n        homeSmall\n        categoryBig\n        categorySmall\n        startDate\n        endDate\n        urlPaths\n        position\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '472dcd73bf9fba275764ccbedc3d8298';

module.exports = node;
