import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddPurchaseLimitMutation($input: AddPurchaseLimitInput!) {
    addPurchaseLimit(input:$input) {
      purchaseLimitEdge {
        cursor
        node {
          id
          startDate
          endDate
          product {
            id
            name
            sku
            mainImage {
              id
              url
            }
            status
          }
          limit
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addPurchaseLimit', edgeName: 'purchaseLimitEdge', connectionKey: 'PurchaseLimitList_purchaseLimits' }),
});

export default { commit };
