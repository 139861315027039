/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCustomerEmailInput = {|
  email?: ?string,
  id?: ?string,
  magicPhrase?: ?string,
|};
export type UpdateCustomerEmailMutationVariables = {|
  input: UpdateCustomerEmailInput
|};
export type UpdateCustomerEmailMutationResponse = {|
  +updateCustomerEmail: ?{|
    +customer: ?{|
      +id: string,
      +email: ?string,
      +subscription: ?{|
        +status: ?number
      |},
    |}
  |}
|};
export type UpdateCustomerEmailMutation = {|
  variables: UpdateCustomerEmailMutationVariables,
  response: UpdateCustomerEmailMutationResponse,
|};
*/


/*
mutation UpdateCustomerEmailMutation(
  $input: UpdateCustomerEmailInput!
) {
  updateCustomerEmail(input: $input) {
    customer {
      id
      email
      subscription {
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCustomerEmailPayload",
    "kind": "LinkedField",
    "name": "updateCustomerEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCustomerEmailMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCustomerEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2573a1961d324d6688c25c84e1815c30",
    "id": null,
    "metadata": {},
    "name": "UpdateCustomerEmailMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCustomerEmailMutation(\n  $input: UpdateCustomerEmailInput!\n) {\n  updateCustomerEmail(input: $input) {\n    customer {\n      id\n      email\n      subscription {\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e820d46a2b39443bd06a9a22147b1dfe';

module.exports = node;
