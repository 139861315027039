import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation EmptyCategoryMutation($input: EmptyCategoryInput!) {
    emptyCategory(input:$input) {
      id
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
