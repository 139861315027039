/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SecurityView_viewer$ref = any;
export type routes_SecurityView_QueryVariables = {||};
export type routes_SecurityView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SecurityView_viewer$ref
  |}
|};
export type routes_SecurityView_Query = {|
  variables: routes_SecurityView_QueryVariables,
  response: routes_SecurityView_QueryResponse,
|};
*/


/*
query routes_SecurityView_Query {
  viewer {
    ...SecurityView_viewer
    id
  }
}

fragment SecurityView_viewer on Admin {
  id
  securities
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SecurityView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SecurityView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_SecurityView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "securities",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28f6d49f45b73552d3f173249b30ce04",
    "id": null,
    "metadata": {},
    "name": "routes_SecurityView_Query",
    "operationKind": "query",
    "text": "query routes_SecurityView_Query {\n  viewer {\n    ...SecurityView_viewer\n    id\n  }\n}\n\nfragment SecurityView_viewer on Admin {\n  id\n  securities\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'fe0a9297901469b237d02d05db93f9a4';

module.exports = node;
