import React from 'react';
import { Route } from 'found';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/purchase-limit"
    getComponent={() => (
      import(/* webpackChunkName: "PurchaseLimitList" */'./PurchaseLimitList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_PurchaseLimitList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...PurchaseLimitList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >
    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "PurchaseLimitNew" */'./PurchaseLimitNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_PurchaseLimitNew_Query {
          viewer {
            ...PurchaseLimitNew_viewer
          }
        }
      `}
    />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "PurchaseLimitView" */'./PurchaseLimitView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_PurchaseLimitView_Query ($ids: [ID]) {
          viewer {
            ...PurchaseLimitView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('PurchaseLimit', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
