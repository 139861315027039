/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCustomerAddressInput = {|
  city: string,
  company?: ?string,
  country: string,
  fax?: ?string,
  firstname: string,
  id: string,
  lastname: string,
  postcode: string,
  region: string,
  street: string,
  telephone: string,
|};
export type UpdateCustomerAddressMutationVariables = {|
  input: UpdateCustomerAddressInput
|};
export type UpdateCustomerAddressMutationResponse = {|
  +updateCustomerAddress: ?{|
    +customerAddress: ?{|
      +firstname: ?string,
      +lastname: ?string,
      +street: ?string,
      +city: ?string,
      +postcode: ?string,
      +region: ?string,
      +country: ?{|
        +alpha2: ?string,
        +name: ?string,
      |},
      +telephone: ?string,
      +company: ?string,
      +fax: ?string,
    |}
  |}
|};
export type UpdateCustomerAddressMutation = {|
  variables: UpdateCustomerAddressMutationVariables,
  response: UpdateCustomerAddressMutationResponse,
|};
*/


/*
mutation UpdateCustomerAddressMutation(
  $input: UpdateCustomerAddressInput!
) {
  updateCustomerAddress(input: $input) {
    customerAddress {
      firstname
      lastname
      street
      city
      postcode
      region
      country {
        alpha2
        name
      }
      telephone
      company
      fax
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "region",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alpha2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telephone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fax",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCustomerAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerAddressPayload",
        "kind": "LinkedField",
        "name": "updateCustomerAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAddress",
            "kind": "LinkedField",
            "name": "customerAddress",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCustomerAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerAddressPayload",
        "kind": "LinkedField",
        "name": "updateCustomerAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAddress",
            "kind": "LinkedField",
            "name": "customerAddress",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d6453435212e3d88fec3c9b3847cfbf",
    "id": null,
    "metadata": {},
    "name": "UpdateCustomerAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCustomerAddressMutation(\n  $input: UpdateCustomerAddressInput!\n) {\n  updateCustomerAddress(input: $input) {\n    customerAddress {\n      firstname\n      lastname\n      street\n      city\n      postcode\n      region\n      country {\n        alpha2\n        name\n      }\n      telephone\n      company\n      fax\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad7fbee327ba59e4bcd4ac1fc5dc1761';

module.exports = node;
