import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../mutation';

const mutation = graphql`
  mutation RemoveProductImageMutation($input: RemoveProductImageInput!) {
    removeProductImage(input:$input) {
      removedProductImageId
    }
  }
`;


// Remove main image field in form if the deleted image is a main image.
function updateImage({ viewer, parent }, store, data) {
  const node = { rootField: 'removeProductImage', edgeName: 'removedProductImageId', connectionKey: 'ProductView_images' };
  edgeDeleter(node, { viewer, parent }, store, data);

  const deletedID = store.getRootField(node.rootField).getValue(node.edgeName);

  if (parent.mainImage && parent.mainImage.id === deletedID) {
    store.delete(deletedID);
  }
}
const commit = buildMutation(mutation, {
  updater: updateImage.bind(this),
});
export default { commit };
