import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import { UpdateStoreMutation } from './mutations';

import StoreForm from './StoreForm';

class StoreView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    if (values.excludeBulkyGood == null) {
      values.excludeBulkyGood = false;
    }

    const keys = Object.keys(values.hours);

    for (let i = 0, l = keys.length; i < l; i += 1) {
      const day = keys[i];
      values.hours[`${day}_open`] = values.hours[day].open ? values.hours[day].open.format('HH:mm:ss') : null;
      values.hours[`${day}_close`] = values.hours[day].close ? values.hours[day].close.format('HH:mm:ss') : null;
      delete values.hours[day];
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateStoreMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const store = get(viewer, 'stores.edges[0].node', {});

    return (
      <div>
        <Helmet title={store.name} />
        <h1>{store.name}</h1>
        <StoreForm
          key={store.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          store={store}
        />
      </div>
    );
  }
}

export default createFragmentContainer(StoreView, {
  viewer: graphql`
    fragment StoreView_viewer on Admin {
      ...StoreForm_viewer
      stores(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            address
            city
            postcode
            region
            state
            country {
              alpha2
              name
            }
            description
            fax
            phone
            emails
            managerEmails
            bdmEmails {
              brandId
              emails
            }
            stateManager {
              emails
            }
            lat
            lng
            status
            regional
            canPickup
            excludeBulkyGood
            inCallPool
            distributionHub
            staffDelivery
            allocationLimit
            excludeAllocation {
              brands {
                id
                name
              }
              startDate
              endDate
            }
            metaTitle
            metaDescription
            branchCode
            content
            canHusqvarnaOpe
            encryptedId
            hours {
              monday {
                open
                close
              }
              tuesday {
                open
                close
              }
              wednesday {
                open
                close
              }
              thursday {
                open
                close
              }
              friday {
                open
                close
              }
              saturday {
                open
                close
              }
              sunday {
                open
                close
              }
            }
            ...StoreHistory_store
          }
        }
      }
    }
  `,
});
