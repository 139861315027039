/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StoreNew_viewer$ref = any;
export type routes_StoreNew_QueryVariables = {||};
export type routes_StoreNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: StoreNew_viewer$ref
  |}
|};
export type routes_StoreNew_Query = {|
  variables: routes_StoreNew_QueryVariables,
  response: routes_StoreNew_QueryResponse,
|};
*/


/*
query routes_StoreNew_Query {
  viewer {
    ...StoreNew_viewer
    id
  }
}

fragment StoreForm_viewer on Admin {
  ...StoreHistory_viewer
  brands(first: 999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
}

fragment StoreHistory_viewer on Admin {
  id
}

fragment StoreNew_viewer on Admin {
  id
  ...StoreForm_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_StoreNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StoreNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_StoreNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": {
                  "direction": "asc",
                  "field": "name"
                }
              }
            ],
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "brands(first:999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7152605640b6d6073093d0ba1d4f259",
    "id": null,
    "metadata": {},
    "name": "routes_StoreNew_Query",
    "operationKind": "query",
    "text": "query routes_StoreNew_Query {\n  viewer {\n    ...StoreNew_viewer\n    id\n  }\n}\n\nfragment StoreForm_viewer on Admin {\n  ...StoreHistory_viewer\n  brands(first: 999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment StoreHistory_viewer on Admin {\n  id\n}\n\nfragment StoreNew_viewer on Admin {\n  id\n  ...StoreForm_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2aca6b9b77b601ef985eb114a53206cf';

module.exports = node;
