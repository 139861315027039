import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation LogoutMutation {
    logout {
      viewer {
        id
        username
      }
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };

