/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SearchCustomer_viewer$ref = any;
export type SearchCustomerRefetchQueryVariables = {|
  query?: ?string
|};
export type SearchCustomerRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SearchCustomer_viewer$ref
  |}
|};
export type SearchCustomerRefetchQuery = {|
  variables: SearchCustomerRefetchQueryVariables,
  response: SearchCustomerRefetchQueryResponse,
|};
*/


/*
query SearchCustomerRefetchQuery(
  $query: String
) {
  viewer {
    ...SearchCustomer_viewer_1Qr5xf
    id
  }
}

fragment SearchCustomer_viewer_1Qr5xf on Admin {
  getCustomerSuggestion(query: $query)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchCustomerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "SearchCustomer_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchCustomerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "getCustomerSuggestion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92f181e1e6b75e54eaec71556948ae21",
    "id": null,
    "metadata": {},
    "name": "SearchCustomerRefetchQuery",
    "operationKind": "query",
    "text": "query SearchCustomerRefetchQuery(\n  $query: String\n) {\n  viewer {\n    ...SearchCustomer_viewer_1Qr5xf\n    id\n  }\n}\n\nfragment SearchCustomer_viewer_1Qr5xf on Admin {\n  getCustomerSuggestion(query: $query)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21ddc4fecbf6f5bb39b3098a05ab4fac';

module.exports = node;
