/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type QuoteView_viewer$ref = any;
export type routes_QuoteView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_QuoteView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: QuoteView_viewer$ref
  |}
|};
export type routes_QuoteView_Query = {|
  variables: routes_QuoteView_QueryVariables,
  response: routes_QuoteView_QueryResponse,
|};
*/


/*
query routes_QuoteView_Query(
  $ids: [ID]
) {
  viewer {
    ...QuoteView_viewer
    id
  }
}

fragment QuoteView_viewer on Admin {
  ...SalesQuoteHistory_viewer
  salesQuotes(first: 1, ids: $ids, reload: true) {
    edges {
      node {
        ...SalesQuoteHistory_salesQuote
        id
        subtotal
        grandTotal
        insertedAt
        updatedAt
        deletedAt
        recoveryStatus
        serialisation
        customer {
          id
          firstname
          lastname
        }
        lines(first: 99) {
          edges {
            node {
              id
              name
              quantity
              unitPrice
              unitDiscount
              unitSurcharge
              rowTotal
              product {
                id
                fullUrl
              }
              children(first: 99) {
                edges {
                  node {
                    id
                    name
                    quantity
                    unitPrice
                    unitDiscount
                    unitSurcharge
                    rowTotal
                    product {
                      id
                      fullUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment SalesQuoteHistory_salesQuote on SalesQuote {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment SalesQuoteHistory_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitDiscount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSurcharge",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowTotal",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_QuoteView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuoteView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_QuoteView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              },
              {
                "kind": "Literal",
                "name": "reload",
                "value": true
              }
            ],
            "concreteType": "SalesQuoteConnection",
            "kind": "LinkedField",
            "name": "salesQuotes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesQuoteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesQuote",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "concreteType": "HistoryConnection",
                        "kind": "LinkedField",
                        "name": "histories",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HistoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "History",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "changes",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "user",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "histories(first:10)"
                      },
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "SalesQuoteHistory_histories",
                        "kind": "LinkedHandle",
                        "name": "histories"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subtotal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grandTotal",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recoveryStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serialisation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstname",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastname",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v4/*: any*/),
                        "concreteType": "SalesQuoteLineConnection",
                        "kind": "LinkedField",
                        "name": "lines",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesQuoteLineEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesQuoteLine",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v4/*: any*/),
                                    "concreteType": "SalesQuoteLineConnection",
                                    "kind": "LinkedField",
                                    "name": "children",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SalesQuoteLineEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SalesQuoteLine",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v1/*: any*/),
                                              (v5/*: any*/),
                                              (v6/*: any*/),
                                              (v7/*: any*/),
                                              (v8/*: any*/),
                                              (v9/*: any*/),
                                              (v10/*: any*/),
                                              (v11/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "children(first:99)"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "lines(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "506eb95987367de98d511a323f4803b6",
    "id": null,
    "metadata": {},
    "name": "routes_QuoteView_Query",
    "operationKind": "query",
    "text": "query routes_QuoteView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...QuoteView_viewer\n    id\n  }\n}\n\nfragment QuoteView_viewer on Admin {\n  ...SalesQuoteHistory_viewer\n  salesQuotes(first: 1, ids: $ids, reload: true) {\n    edges {\n      node {\n        ...SalesQuoteHistory_salesQuote\n        id\n        subtotal\n        grandTotal\n        insertedAt\n        updatedAt\n        deletedAt\n        recoveryStatus\n        serialisation\n        customer {\n          id\n          firstname\n          lastname\n        }\n        lines(first: 99) {\n          edges {\n            node {\n              id\n              name\n              quantity\n              unitPrice\n              unitDiscount\n              unitSurcharge\n              rowTotal\n              product {\n                id\n                fullUrl\n              }\n              children(first: 99) {\n                edges {\n                  node {\n                    id\n                    name\n                    quantity\n                    unitPrice\n                    unitDiscount\n                    unitSurcharge\n                    rowTotal\n                    product {\n                      id\n                      fullUrl\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SalesQuoteHistory_salesQuote on SalesQuote {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment SalesQuoteHistory_viewer on Admin {\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4dce113cee0293fd8d7be0324f43ebd9';

module.exports = node;
