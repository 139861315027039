import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateCustomerAddressMutation($input: UpdateCustomerAddressInput!) {
    updateCustomerAddress(input:$input) {
      customerAddress {
        firstname
        lastname
        street
        city
        postcode
        region
        country {
          alpha2
          name
        }
        telephone
        company
        fax
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
