import { graphql } from 'react-relay';

const mutation = graphql`
  mutation AddHistoryMutation($input: AddHistoryInput!) {
    addHistory(input:$input) {
      historyEdge {
        __typename
        cursor
        node {
          id
          changes
          user
          insertedAt
        }
      }
    }
  }
`;

module.exports = {
  mutation
}
