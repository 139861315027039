/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Related_viewer$ref: FragmentReference;
declare export opaque type Related_viewer$fragmentType: Related_viewer$ref;
export type Related_viewer = {|
  +$fragmentRefs: SelectProduct_viewer$ref,
  +$refType: Related_viewer$ref,
|};
export type Related_viewer$data = Related_viewer;
export type Related_viewer$key = {
  +$data?: Related_viewer$data,
  +$fragmentRefs: Related_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Related_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ab27699e9e6372d638f261d2da093a6b';

module.exports = node;
