import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddAlertMutation } from './mutations';
import AlertForm from './AlertForm';

class AlertNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/promotion/alert/${fromGlobalId(resp.addAlert.alertEdge.node.id).id}`;
      },
    };

    AddAlertMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Alert" />

        <h1>New Alert</h1>
        <AlertForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(AlertNew, {
  viewer: graphql`
    fragment AlertNew_viewer on Admin {
      id
      ...AlertForm_viewer
    }
  `,
});
