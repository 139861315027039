/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StoreForm_viewer$ref = any;
type StoreHistory_store$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StoreView_viewer$ref: FragmentReference;
declare export opaque type StoreView_viewer$fragmentType: StoreView_viewer$ref;
export type StoreView_viewer = {|
  +stores: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +address: ?string,
        +city: ?string,
        +postcode: ?string,
        +region: ?string,
        +state: ?string,
        +country: ?{|
          +alpha2: ?string,
          +name: ?string,
        |},
        +description: ?string,
        +fax: ?string,
        +phone: ?string,
        +emails: ?$ReadOnlyArray<?string>,
        +managerEmails: ?$ReadOnlyArray<?string>,
        +bdmEmails: ?$ReadOnlyArray<?{|
          +brandId: ?string,
          +emails: ?$ReadOnlyArray<?string>,
        |}>,
        +stateManager: ?{|
          +emails: ?$ReadOnlyArray<?string>
        |},
        +lat: ?number,
        +lng: ?number,
        +status: ?boolean,
        +regional: ?boolean,
        +canPickup: ?boolean,
        +excludeBulkyGood: ?boolean,
        +inCallPool: ?boolean,
        +distributionHub: ?boolean,
        +staffDelivery: ?boolean,
        +allocationLimit: ?number,
        +excludeAllocation: ?{|
          +brands: ?$ReadOnlyArray<?{|
            +id: string,
            +name: ?string,
          |}>,
          +startDate: ?string,
          +endDate: ?string,
        |},
        +metaTitle: ?string,
        +metaDescription: ?string,
        +branchCode: ?string,
        +content: ?string,
        +canHusqvarnaOpe: ?boolean,
        +encryptedId: ?string,
        +hours: ?{|
          +monday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +tuesday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +wednesday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +thursday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +friday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +saturday: ?{|
            +open: ?string,
            +close: ?string,
          |},
          +sunday: ?{|
            +open: ?string,
            +close: ?string,
          |},
        |},
        +$fragmentRefs: StoreHistory_store$ref,
      |}
    |}>
  |},
  +$fragmentRefs: StoreForm_viewer$ref,
  +$refType: StoreView_viewer$ref,
|};
export type StoreView_viewer$data = StoreView_viewer;
export type StoreView_viewer$key = {
  +$data?: StoreView_viewer$data,
  +$fragmentRefs: StoreView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "open",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "close",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "StoreConnection",
      "kind": "LinkedField",
      "name": "stores",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Store",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "region",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "country",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "alpha2",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "managerEmails",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BdmEmails",
                  "kind": "LinkedField",
                  "name": "bdmEmails",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "brandId",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StateManager",
                  "kind": "LinkedField",
                  "name": "stateManager",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lat",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lng",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "regional",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canPickup",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "excludeBulkyGood",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inCallPool",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "distributionHub",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "staffDelivery",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allocationLimit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExcludeAllocation",
                  "kind": "LinkedField",
                  "name": "excludeAllocation",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Brand",
                      "kind": "LinkedField",
                      "name": "brands",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "metaTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "metaDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "branchCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "content",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canHusqvarnaOpe",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "encryptedId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreHour",
                  "kind": "LinkedField",
                  "name": "hours",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "monday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "tuesday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "wednesday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "thursday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "friday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "saturday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BusinessHour",
                      "kind": "LinkedField",
                      "name": "sunday",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StoreHistory_store"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StoreForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7697d64162d7616f021f48bbdfb8e4b';

module.exports = node;
