/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UpdatePricesSchedule_viewer$ref = any;
export type UpdatePricesScheduleRefetchQueryVariables = {|
  id: string
|};
export type UpdatePricesScheduleRefetchQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: UpdatePricesSchedule_viewer$ref
  |}
|};
export type UpdatePricesScheduleRefetchQuery = {|
  variables: UpdatePricesScheduleRefetchQueryVariables,
  response: UpdatePricesScheduleRefetchQueryResponse,
|};
*/


/*
query UpdatePricesScheduleRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...UpdatePricesSchedule_viewer
    id
  }
}

fragment UpdatePricesSchedule_viewer on Admin {
  id
  updatePriceSchedules {
    id
    product {
      id
      name
      brandId
      urlSlug
    }
    newPrice
    effectiveDate
    user
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePricesScheduleRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdatePricesSchedule_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePricesScheduleRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UpdatePriceSchedule",
                "kind": "LinkedField",
                "name": "updatePriceSchedules",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "brandId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urlSlug",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Admin",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94e8ff15b1909c83d4559054ac55761e",
    "id": null,
    "metadata": {},
    "name": "UpdatePricesScheduleRefetchQuery",
    "operationKind": "query",
    "text": "query UpdatePricesScheduleRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...UpdatePricesSchedule_viewer\n    id\n  }\n}\n\nfragment UpdatePricesSchedule_viewer on Admin {\n  id\n  updatePriceSchedules {\n    id\n    product {\n      id\n      name\n      brandId\n      urlSlug\n    }\n    newPrice\n    effectiveDate\n    user\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96185a99f3d0cdbd0bac48cad008bbb1';

module.exports = node;
