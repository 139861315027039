/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommonPromoHistory_viewer$ref: FragmentReference;
declare export opaque type CommonPromoHistory_viewer$fragmentType: CommonPromoHistory_viewer$ref;
export type CommonPromoHistory_viewer = {|
  +id: string,
  +$refType: CommonPromoHistory_viewer$ref,
|};
export type CommonPromoHistory_viewer$data = CommonPromoHistory_viewer;
export type CommonPromoHistory_viewer$key = {
  +$data?: CommonPromoHistory_viewer$data,
  +$fragmentRefs: CommonPromoHistory_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommonPromoHistory_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7ece6d28d949f1a7a260ad384704df48';

module.exports = node;
