import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import GiftCardForm from './GiftCardForm';
import { UpdateGiftCardMutation } from './mutations';

class GiftCardView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      giftCards: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.products = values.products.map(p => p.key);

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
    };
    UpdateGiftCardMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const giftCard = get(viewer, 'giftCards.edges[0].node', {});

    return (
      <div>
        <Helmet title={giftCard.name} />
        <h1>{giftCard.name}</h1>
        <GiftCardForm
          key={giftCard.id}
          match={match}
          viewer={viewer}
          giftCard={giftCard}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(GiftCardView, {
  viewer: graphql`
    fragment GiftCardView_viewer on Admin {
      ...GiftCardForm_viewer
      giftCards(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            amount
            expiryMonths
            products(first: 99) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            ...GiftCardHistory_giftCard
          }
        }
      }
    }
  `,
});
