/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSecurityInput = {|
  securities?: ?string
|};
export type UpdateSecurityMutationVariables = {|
  input: UpdateSecurityInput
|};
export type UpdateSecurityMutationResponse = {|
  +updateSecurity: ?{|
    +securities: ?any
  |}
|};
export type UpdateSecurityMutation = {|
  variables: UpdateSecurityMutationVariables,
  response: UpdateSecurityMutationResponse,
|};
*/


/*
mutation UpdateSecurityMutation(
  $input: UpdateSecurityInput!
) {
  updateSecurity(input: $input) {
    securities
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSecurityPayload",
    "kind": "LinkedField",
    "name": "updateSecurity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "securities",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSecurityMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSecurityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0f97e61a7a0b3eeb16628919d6be9818",
    "id": null,
    "metadata": {},
    "name": "UpdateSecurityMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSecurityMutation(\n  $input: UpdateSecurityInput!\n) {\n  updateSecurity(input: $input) {\n    securities\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba7ba21a72289ef07c160d1478dd22c8';

module.exports = node;
