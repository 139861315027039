import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateCmsPageMutation($input: UpdateCmsPageInput!) {
    updateCmsPage(input:$input) {
      cmsPage {
        title
        content
        urlSlug
        excludeFromSitemap
        status
        metaDescription
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
