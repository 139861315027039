import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Affix, Button, Form, Input, Select, Switch } from 'antd';
import { Editor, FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

class CmsPageForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const cmsPage = get(viewer, 'cmsPages.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={cmsPage.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Title"
          name='title'
          rules={[{ required: true, message: 'required' }]}
          initialValue={cmsPage.title}
        >
          <Input
            placeholder="Title"
            onChange={(e) => {
              if (!cmsPage.id) {
                const urlSlug = e.target.value;
                this.formRef.current.setFieldsValue({ urlSlug });
              }
            }}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="URL"
          name='urlSlug'
          rules={[{ required: true, message: 'required' }]}
          initialValue={cmsPage.urlSlug}
        >
          <Input readOnly placeholder="URL" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Exclude From Sitemap"
          name='excludeFromSitemap'
          valuePropName='checked'
          initialValue={cmsPage.excludeFromSitemap}
        >
          <Switch placeholder="Exclude From Sitemap" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Meta Description"
          name='metaDescription'
          initialValue={cmsPage.metaDescription}
        >
          <TextArea rows={2} />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name='status'
          rules={[{ required: true, message: 'required' }]}
          initialValue={cmsPage.status ? 1 : 0}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

        <Editor
          name="content"
          editorState={cmsPage.content}
        />

      </Form>
    );
  }
}
export default CmsPageForm;
