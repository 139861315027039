import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddBonusMutation($input: AddBonusInput!) {
    addBonus(input:$input) {
      bonusEdge {
        cursor
        node {
          id
          name
          startDate
          endDate
          status
          stealthMode
          quantityRequired
          onProducts(first: 999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
          products(first: 999) {
            edges {
              node {
                product {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
                price
                quantity
                optional
              }
            }
          }
          images (first: 999) {
            edges {
              node {
                id
                imageUrl
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
