/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SearchProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ByMinMaxReport_selectFragment_viewer$ref: FragmentReference;
declare export opaque type ByMinMaxReport_selectFragment_viewer$fragmentType: ByMinMaxReport_selectFragment_viewer$ref;
export type ByMinMaxReport_selectFragment_viewer = {|
  +brands: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +stores: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +state: ?string,
        +branchCode: ?string,
      |}
    |}>
  |},
  +suppliers: ?any,
  +$fragmentRefs: SearchProduct_viewer$ref,
  +$refType: ByMinMaxReport_selectFragment_viewer$ref,
|};
export type ByMinMaxReport_selectFragment_viewer$data = ByMinMaxReport_selectFragment_viewer;
export type ByMinMaxReport_selectFragment_viewer$key = {
  +$data?: ByMinMaxReport_selectFragment_viewer$data,
  +$fragmentRefs: ByMinMaxReport_selectFragment_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "asc",
      "field": "name"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ByMinMaxReport_selectFragment_viewer",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "BrandConnection",
      "kind": "LinkedField",
      "name": "brands",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Brand",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "brands(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "StoreConnection",
      "kind": "LinkedField",
      "name": "stores",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Store",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "branchCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "stores(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suppliers",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchProduct_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '4c13c50545836f7512abf0b42ff6c4d3';

module.exports = node;
