/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CouponList_viewer$ref = any;
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_CouponList_QueryVariables = {|
  count: number,
  cursor?: ?string,
  orderBy?: ?OrderBy,
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
|};
export type routes_CouponList_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CouponList_viewer$ref
  |}
|};
export type routes_CouponList_Query = {|
  variables: routes_CouponList_QueryVariables,
  response: routes_CouponList_QueryResponse,
|};
*/


/*
query routes_CouponList_Query(
  $count: Int!
  $cursor: String
  $orderBy: OrderBy
  $filterBy: [FilterBy]
) {
  viewer {
    ...CouponList_viewer
    id
  }
}

fragment CouponList_viewer on Admin {
  coupons(first: $count, after: $cursor, orderBy: $orderBy, filterBy: $filterBy) {
    totalCount(filterBy: $filterBy)
    edges {
      node {
        id
        name
        codes
        startDate
        endDate
        status
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CouponList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CouponList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_CouponList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CouponConnection",
            "kind": "LinkedField",
            "name": "coupons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v4/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CouponEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Coupon",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "codes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [
              "orderBy",
              "filterBy"
            ],
            "handle": "connection",
            "key": "CouponList_coupons",
            "kind": "LinkedHandle",
            "name": "coupons"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dbb12c5524de276296e9b0604f94e922",
    "id": null,
    "metadata": {},
    "name": "routes_CouponList_Query",
    "operationKind": "query",
    "text": "query routes_CouponList_Query(\n  $count: Int!\n  $cursor: String\n  $orderBy: OrderBy\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...CouponList_viewer\n    id\n  }\n}\n\nfragment CouponList_viewer on Admin {\n  coupons(first: $count, after: $cursor, orderBy: $orderBy, filterBy: $filterBy) {\n    totalCount(filterBy: $filterBy)\n    edges {\n      node {\n        id\n        name\n        codes\n        startDate\n        endDate\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c597a8bdc03f3b9ede4441a14d2c0d40';

module.exports = node;
