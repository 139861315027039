import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Avatar, Tooltip } from 'antd';
import ReactTimeAgo from 'react-time-ago';

import socket from '~/socket';

/*
 * By adding the key: `safeGuard` to mutation Object,
 * Providing the value are: `update` or `delete`,
 * It will notify other user on same page when a update/delete are made,
 * Subsequently, disabling the save/delete button.
 */

export default class Presence extends React.Component {
  static delete = function pageDelete() {
    socket.userPresenceUpdate("page_deleted");
  }

  static update = function pageUpdate() {
    socket.userPresenceUpdate();
  }

  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      })
    }).isRequired,
    disableButton: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      users: null,
    }
  }

  componentDidMount() {
    const { match } = this.props;

    if (!isEmpty(match)) {
      socket.userPresence(this.props, this.callback);
    }
  }

  componentDidUpdate(prevProps) {
    const { disableButton, match } = this.props;

    if (!isEmpty(match) && (prevProps.match.location.pathname !== this.props.match.location.pathname)) {
      disableButton(false);
      socket.userPresenceLeaves(prevProps, this.props, this.callback);
    }
  }

  componentWillUnmount() {
    const { match } = this.props;

    if (!isEmpty(match)) {
      socket.userPresenceLeaves(this.props);

      this.setState = () => ({
        users: null,
      });
    }
  }

  getContent = (metas) => {
    const firstname = get(metas, '[0].user.firstname', "");
    const lastname = get(metas, '[0].user.lastname', "");
    const onlineAt = get(metas, '[0].online_at');
    const timeSince = new Date(onlineAt);

    return (
      <div>
        <span>{firstname} {lastname}</span>
        <br />
        {onlineAt && (
          <span>Active: <ReactTimeAgo date={timeSince} locale="en-AU" /></span>
        )}
      </div>
    );
  }

  callback = (users) => {
    this.setState({
      users,
    })
  }

  render() {
    const { match } = this.props;
    const { users } = this.state;

    if (isEmpty(match)) {
      return null;
    }

    return (
      <div style={{display: 'block'}}>
        {users && users.map(({metas}) => {
          const username = get(metas, '[0].user.username', "");

          return (
            <div key={username} style={{display: 'inline-block', margin: '0px 5px 5px 0px'}}>
              <Tooltip title={this.getContent(metas)}>
                <Avatar size="large">
                  {username}
                </Avatar>
              </Tooltip>
            </div>
          )
        })}
      </div>
    )
  }
}
