import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateComboFreebieMutation($input: UpdateComboFreebieInput!) {
    updateComboFreebie(input:$input) {
      comboFreebie {
        name
        startDate
        endDate
        status
        bases(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        skins(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        freebies(first: 999) {
          edges {
            node {
              quantity
              product {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
