import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddComboFreebieMutation } from './mutations';
import ComboFreebieForm from './ComboFreebieForm';

class ComboFreebieNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      bases: formValues.bases.map(p => p.key),
      skins: formValues.skins.map(p => p.key),
      freebies: formValues.freebies.map(t => ({ ...t, productId: t.productId.key })),
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/promotion/combo-freebie/${fromGlobalId(resp.addComboFreebie.comboFreebieEdge.node.id).id}`;
      },
    };

    AddComboFreebieMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New ComboFreebie" />

        <h1>New ComboFreebie</h1>
        <ComboFreebieForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(ComboFreebieNew, {
  viewer: graphql`
    fragment ComboFreebieNew_viewer on Admin {
      ...ComboFreebieForm_viewer
    }
  `,
});
