import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { Button, List, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons'

import { PRODUCT_STATUSES } from '~/components/product/ProductList';

import { BooleanDropdownFilter, Grid, currencyFormatter, iDGetter, ImageCellRenderer, valueGetter } from '../../grid';
import bulkActions from './bulkActions';
import { UploadPromoCardImageMutation } from './mutations';

const entityName = 'PromoCard';

class PromoCardList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      promoCard: PropTypes.shape({
        files: PropTypes.arrayOf(PropTypes.string),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    const BRANDS = {};
    props.viewer.brands.edges.forEach(edge => {
      BRANDS[edge.node.id] = edge.node.name;
    });

    const SUBCATEGORIES = {};
    props.viewer.subcategories.edges.forEach(edge => {
      SUBCATEGORIES[edge.node.id] = edge.node.name;
    });

    const CATEGORIES = {};
    props.viewer.categories.edges.forEach(edge => {
      CATEGORIES[edge.node.id] = edge.node.name;
    });

    this.columnDefs = [
      {
        width: 30,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        checkboxSelection: true,
      },
      {
        headerName: 'ID',
        valueGetter: iDGetter.bind(this, 'id'),
        suppressMenu: true,
        sortable: false,
        filter: false,
        cellRendererFramework: (params) => {
          if (params.value) {
            return <a href={`/product/${params.value}`} target="_blank" rel="noopener noreferrer">{params.value}</a>;
          }

          return null;
        },
      },
      {
        headerName: 'Image', valueGetter: valueGetter.bind(this, 'mainImage.thumbnail'), suppressMenu: true, sortable: false, filter: false, cellRendererFramework: ImageCellRenderer,
      },
      {
        colId: 'promo_card',
        headerName: 'Promo Card',
        valueGetter: valueGetter.bind(this, 'promoCard'),
        width: 150,
        ...BooleanDropdownFilter
      },
      {
        colId: 'brandId',
        headerName: 'Brand',
        valueGetter: valueGetter.bind(this, 'brandId'),
        filter: 'agNumberColumnFilter',
        valueFormatter: params => BRANDS[params.value],
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: BRANDS, globalId: true, suppressFilterButton: true },
        width: 130
      },
      {
        colId: 'subcategories.id',
        field: 'subcategoryIds',
        headerName: 'Subcategory',
        valueGetter: valueGetter.bind(this, 'subcategoryIds'),
        filter: 'agNumberColumnFilter',
        cellRenderer: params => get(params, 'value', []).map(id => SUBCATEGORIES[id]).join("<br/>"),
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: SUBCATEGORIES, globalId: true, suppressFilterButton: true },
        cellStyle: { 'overflow': 'auto' },
        width: 215
      },
      {
        colId: 'categories.id',
        field: 'categoryIds',
        headerName: 'Category',
        valueGetter: valueGetter.bind(this, 'categoryIds'),
        filter: 'agNumberColumnFilter',
        cellRenderer: params => get(params, 'value', []).map(id => CATEGORIES[id]).join("<br/>"),
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: CATEGORIES, globalId: true, suppressFilterButton: true },
        cellStyle: { 'overflow': 'auto' },
        width: 215
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 300,
      },
      {
        colId: 'finalPrice', headerName: 'Final Price', valueGetter: valueGetter.bind(this, 'finalPrice'), valueFormatter: currencyFormatter, suppressMenu: true, sortable: false, filter: false,
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: valueGetter.bind(this, 'status'),
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: PRODUCT_STATUSES, suppressFilterButton: true },
      },
    ];

    const defaultFilters = [
      {
        field: 'visibility', filter: '4', filterType: 'number', type: 'equals',
      },
    ];
    this.datasource = Grid.getDataSource.call(this, 'products', defaultFilters);
  }

  render() {
    const { viewer, children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <h1>{entityName}</h1>
        <List
          header={<div>PDF Files</div>}
          dataSource={viewer.promoCard.files}
          renderItem={file => (<List.Item><a target="_blank" href={file} rel="noopener noreferrer">{file}</a></List.Item>)}
        />

        Current A4 and A6 Headers
        {
          get(viewer.promoCard, 'images').map((i) => <img key={i} style={{display: 'block', marginBottom: '5px'}} alt="" src={i} width="120" />)
        }

        <Upload
          accept="image/gif,image/png,image/jpeg"
          beforeUpload={() => false}
          customRequest={({file}) => {
            const values = {
              image: 'image',
            };
            const uploadables = {
              image: file,
            };

            const mutation = {
              environment: this.props.relay.environment,
              variables: { input: values },
              viewer: this.props.viewer,
              onCompleted: () => {
                message.success('Saved');
              },
            };
            if (Object.keys(uploadables).length) {
              mutation.uploadables = uploadables;
            }
            UploadPromoCardImageMutation.commit(mutation);
          }}
          listType="picture"
          multiple

        >
          <Button>
            <UploadOutlined /> Upload
          </Button>
        </Upload>

        <Grid
          viewer={this.props.viewer}
          columnDefs={this.columnDefs}
          datasource={this.datasource}
          bulkActions={bulkActions}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  PromoCardList, {
    viewer: graphql`
    fragment PromoCardList_viewer on Admin {
      ...GenPromoCardPdf_viewer
      brands(first: 2000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      promoCard {
        files
        images
      }
      products(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "PromoCardList_products") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            brandId
            name
            adenCode
            sku
            finalPrice
            status
            categoryIds
            subcategoryIds
            promoCard
            mainImage {
              id
              thumbnail
            }
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.products;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query PromoCardListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...PromoCardList_viewer
      }
    }
  `,
  },
);
