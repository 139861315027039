import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation GenPromoCardPdfMutation($input: GenPromoCardPdfInput!) {
    genPromoCardPdf(input:$input) {
      promoCard {
        files
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: (_, store, data) => {
    const rootProxy = store.getRoot();
    const viewerProxy = rootProxy.getLinkedRecord('viewer');

    const payload = data.genPromoCardPdf.promoCard.files;
    if (!payload) {
      return;
    }

    const promoCard = viewerProxy.getLinkedRecord('promoCard');
    promoCard.setValue(payload, 'files');
  },
});

export default { commit };

