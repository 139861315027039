import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '~/mutation';

const mutation = graphql`
  mutation RemoveSearchTermsMutation($input: RemoveSearchTermsInput!) {
    removeSearchTerms(input:$input) {
      removedSearchTermIds
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeSearchTerms', edgeName: 'removedSearchTermIds', connectionKey: 'SearchTermsReportList_searchTermsReports' }),
});

export default { commit };
