import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useFragment, useRefetchableFragment, useRelayEnvironment } from 'react-relay';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Table } from 'antd';
import { get, uniq } from 'lodash';
import moment from 'moment-timezone';
import { fromGlobalId } from '~/helper';
import { RemoveUpdatePricesScheduleJobs } from './mutations';

import './style.css';

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, confirm }) => (
    <div
      style={{ padding: 8 }}
      tabIndex="0"
      role="searchbox"
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        onChange={(e) => {
          const { value } = e.target;

          setSelectedKeys(value ? [value] : []);
          confirm({ closeDropdown: false });
        }}
        placeholder="Search"
        allowClear
      />
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => get(record, dataIndex, "").toLowerCase().includes(value.toLowerCase()),
});

const getColumns = (brandFilters = []) => {
  return [
    {
      key: 'id',
      title: 'ID',
      render: ({ productId }) => {
        return (
          <a href={`/product/${productId}`} target="_blank" rel="noopener noreferrer">
            {productId}
          </a>
        )
      },
      sorter: (a, b) => a.productId - b.productId,
    },
    {
      key: 'brand',
      title: 'Brand',
      dataIndex: 'brand',
      filters: brandFilters,
      onFilter: (value, record) => value === record.brand,
      sorter: ({ brand: a }, { brand: b }) => {
        const brandA = a.toLowerCase();
        const brandB = b.toLowerCase();

        if (brandA < brandB) {
          return -1;
        } else if (brandA > brandB) {
          return 1;
        }

        return 0;
      },
    },
    {
      key: 'name',
      title: 'Name',
      render: (data) => get(data, "product.name"),
      ...getColumnSearchProps("product.name"),
    },
    {
      key: 'newPrice',
      title: 'New Price',
      dataIndex: 'newPrice',
    },
    {
      key: 'effectiveDate',
      title: 'Effective Date',
      render: (data) => {
        const effectiveDate = get(data, "effectiveDate");

        return moment(effectiveDate).format('YYYY-MM-DD');
      },
      sorter: (a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate),
    },
    {
      key: 'user',
      title: 'User',
      dataIndex: 'user',
      ...getColumnSearchProps("user"),
    },
  ]
};

const Loading = () => {
  const columns = useMemo(() => getColumns(), []);

  return (
    <Table
      className="price-schedule-table"
      columns={columns}
      dataSource={[]}
      rowSelection
      loading
    />
  )
};

/*
 * For displaying up coming price update schedules in ProductForm.
 */
const SmallTable = (props) => {
  const environment = useRelayEnvironment();

  const [schedules, setSchedules] = useState(() => {
    return get(props, 'updatePriceSchedules') || []
  });

  if (schedules.length <= 0) {
    return null;
  }

  const getDate = (date) => moment(date).format("YYYY-MM-DD");

  const handleDelete = (jobId) => {
    RemoveUpdatePricesScheduleJobs.commit({
      environment,
      variables: { input: { jobIds: [jobId] } },
      onCompleted: () => {
        const newSchedules = schedules.filter((s) => s.id !== jobId);
        setSchedules(newSchedules);
      },
    });
  };

  return (
    <table style={{ color: '#cb0000' }}>
      <tr>
        <th style={{ paddingRight: '10px' }}>New Price</th>
        <th>Effective Date</th>
        <th />
      </tr>

      {schedules.map((s) => (
        <tr key={s.id}>
          <td>$ {s.newPrice}</td>
          <td>{getDate(s.effectiveDate)}</td>
          <td>
            <Button type="text" icon={<DeleteOutlined />} size="small" onClick={() => handleDelete(s.id)} />
          </td>
        </tr>
      ))}
    </table>
  )
};

export const ScheduleTable = (props) => {
  const { tableRef, setJobIds } = props;

  const brandFragment = useFragment(
    graphql`
      fragment UpdatePricesSchedule_brand_viewer on Admin {
        id
        brands(first: 2000, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    props.viewer,
  );

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment UpdatePricesSchedule_viewer on Admin
      @refetchable(queryName: "UpdatePricesScheduleRefetchQuery") {
        id
        updatePriceSchedules {
          id
          product {
            id
            name
            brandId
            urlSlug
          }
          newPrice
          effectiveDate
          user
        }
      }
    `,
    props.viewer
  );

  const updatePriceSchedules = get(data, 'updatePriceSchedules', []);

  const brands = get(brandFragment, 'brands.edges', []).reduce((acc, { node }) => {
    acc[node.id] = node.name
    return acc;
  }, {});

  const columns = useMemo(() => {
    const brandIds = updatePriceSchedules.map(({ product }) => product.brandId);

    const brandFilters = uniq(brandIds).map((brandId) => {
      const name = brands[brandId];
      return { text: name, value: name }
    });

    return getColumns(brandFilters);
  }, [updatePriceSchedules]);

  useEffect(() => {
    refetch({}, { fetchPolicy: 'store-and-network' });
    tableRef.current = { refetch };
  }, []);

  const dataSource = updatePriceSchedules.map((d) => {
    const id = get(d, 'product.id');
    const brandId = get(d, 'product.brandId');
    const { id: productId } = fromGlobalId(id) || {};

    return { ...d, key: d.id, productId, brand: brands[brandId] }
  });

  return (
    <Table
      className="price-schedule-table"
      columns={columns}
      dataSource={dataSource}
      rowSelection={{
        onChange: (selectedRowKeys) => {
          setJobIds(selectedRowKeys);
        },
      }}
    />
  )
};

ScheduleTable.propTypes = {
  tableRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
  viewer: PropTypes.shape({}).isRequired,
  setJobIds: PropTypes.func.isRequired,
};

ScheduleTable.Small = SmallTable;

const UpdatePricesSchedule = (props) => {
  const [open, setOpen] = useState(false);
  const tableRef = useRef(null);
  const [jobIds, setJobIds] = useState([]);
  const environment = useRelayEnvironment();

  const onClick = () => {
    setOpen(true);
  };

  const handleDelete = (args = {}) => {
    const { refetch } = tableRef.current;
    const { deleteAll } = args;

    let input = {};

    if (deleteAll) {
      input = { ...input, deleteAll };
    } else {
      input = { jobIds }
    }

    RemoveUpdatePricesScheduleJobs.commit({
      environment,
      variables: { input },
      onCompleted: () => {
        refetch({}, { fetchPolicy: 'store-and-network' });
      },
    });
  };

  const handleRefresh = () => {
    const { refetch } = tableRef.current;

    if (refetch) {
      refetch({}, { fetchPolicy: 'store-and-network' });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={onClick}>
        Schedule
      </Button>

      <Modal
        title="Update Prices Schedule"
        footer={null}
        visible={open}
        onCancel={handleCancel}
        width={900}
        destroyOnClose
      >
        <Button onClick={handleRefresh} style={{ marginRight: '10px' }}>
          Refresh
        </Button>
        <Button danger onClick={() => handleDelete()} disabled={jobIds.length === 0}>
          Delete
        </Button>
        <Popconfirm
          title="Are you sure to delete all schedule task?"
          onConfirm={() => handleDelete({ deleteAll: true })}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger>
            Delete All
          </Button>
        </Popconfirm>

        <React.Suspense fallback={<Loading />}>
          <ScheduleTable tableRef={tableRef} viewer={props.viewer} setJobIds={setJobIds} />
        </React.Suspense>
      </Modal>
    </>
  )
};

UpdatePricesSchedule.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
};

export default UpdatePricesSchedule;
