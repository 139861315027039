/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddSearchRedirectInput = {|
  query: string,
  status: boolean,
  url: string,
|};
export type AddSearchRedirectMutationVariables = {|
  input: AddSearchRedirectInput
|};
export type AddSearchRedirectMutationResponse = {|
  +addSearchRedirect: ?{|
    +searchRedirectEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +query: ?string,
        +url: ?string,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddSearchRedirectMutation = {|
  variables: AddSearchRedirectMutationVariables,
  response: AddSearchRedirectMutationResponse,
|};
*/


/*
mutation AddSearchRedirectMutation(
  $input: AddSearchRedirectInput!
) {
  addSearchRedirect(input: $input) {
    searchRedirectEdge {
      cursor
      node {
        id
        query
        url
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddSearchRedirectPayload",
    "kind": "LinkedField",
    "name": "addSearchRedirect",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SearchRedirectEdge",
        "kind": "LinkedField",
        "name": "searchRedirectEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchRedirect",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "query",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSearchRedirectMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSearchRedirectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "02d6f42fc06e518c7937743a680dfb9c",
    "id": null,
    "metadata": {},
    "name": "AddSearchRedirectMutation",
    "operationKind": "mutation",
    "text": "mutation AddSearchRedirectMutation(\n  $input: AddSearchRedirectInput!\n) {\n  addSearchRedirect(input: $input) {\n    searchRedirectEdge {\n      cursor\n      node {\n        id\n        query\n        url\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b76c598bebdfc7761948fac5732fa4de';

module.exports = node;
