/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type GenPromoCardPdf_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PromoCardList_viewer$ref: FragmentReference;
declare export opaque type PromoCardList_viewer$fragmentType: PromoCardList_viewer$ref;
export type PromoCardList_viewer = {|
  +brands: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +categories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +subcategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +promoCard: ?{|
    +files: ?$ReadOnlyArray<?string>,
    +images: ?$ReadOnlyArray<?string>,
  |},
  +products: ?{|
    +totalCount: ?number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +brandId: ?string,
        +name: ?string,
        +adenCode: ?string,
        +sku: ?string,
        +finalPrice: ?number,
        +status: ?string,
        +categoryIds: ?$ReadOnlyArray<?string>,
        +subcategoryIds: ?$ReadOnlyArray<?string>,
        +promoCard: ?boolean,
        +mainImage: ?{|
          +id: string,
          +thumbnail: ?string,
        |},
      |}
    |}>,
  |},
  +$fragmentRefs: GenPromoCardPdf_viewer$ref,
  +$refType: PromoCardList_viewer$ref,
|};
export type PromoCardList_viewer$data = PromoCardList_viewer;
export type PromoCardList_viewer$key = {
  +$data?: PromoCardList_viewer$data,
  +$fragmentRefs: PromoCardList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "direction": "asc",
    "field": "name"
  }
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  (v0/*: any*/)
],
v5 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "filterBy"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "products"
        ]
      }
    ]
  },
  "name": "PromoCardList_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2000
        },
        (v0/*: any*/)
      ],
      "concreteType": "BrandConnection",
      "kind": "LinkedField",
      "name": "brands",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Brand",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "brands(first:2000,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "categories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "SubcategoryConnection",
      "kind": "LinkedField",
      "name": "subcategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubcategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Subcategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "subcategories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PromoCard",
      "kind": "LinkedField",
      "name": "promoCard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "files",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "images",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "products",
      "args": [
        (v5/*: any*/),
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "ProductConnection",
      "kind": "LinkedField",
      "name": "__PromoCardList_products_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            (v5/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brandId",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "adenCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sku",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "finalPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subcategoryIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promoCard",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductImage",
                  "kind": "LinkedField",
                  "name": "mainImage",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnail",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GenPromoCardPdf_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad2c273dd3d255a128bbc6a2920afcd4';

module.exports = node;
