/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SalesQuoteHistory_salesQuote$ref = any;
type SalesQuoteHistory_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuoteView_viewer$ref: FragmentReference;
declare export opaque type QuoteView_viewer$fragmentType: QuoteView_viewer$ref;
export type QuoteView_viewer = {|
  +salesQuotes: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +subtotal: ?number,
        +grandTotal: ?number,
        +insertedAt: ?string,
        +updatedAt: ?string,
        +deletedAt: ?string,
        +recoveryStatus: ?number,
        +serialisation: ?any,
        +customer: ?{|
          +id: string,
          +firstname: ?string,
          +lastname: ?string,
        |},
        +lines: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +quantity: ?number,
              +unitPrice: ?number,
              +unitDiscount: ?number,
              +unitSurcharge: ?number,
              +rowTotal: ?number,
              +product: ?{|
                +id: string,
                +fullUrl: ?string,
              |},
              +children: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                    +quantity: ?number,
                    +unitPrice: ?number,
                    +unitDiscount: ?number,
                    +unitSurcharge: ?number,
                    +rowTotal: ?number,
                    +product: ?{|
                      +id: string,
                      +fullUrl: ?string,
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +$fragmentRefs: SalesQuoteHistory_salesQuote$ref,
      |}
    |}>
  |},
  +$fragmentRefs: SalesQuoteHistory_viewer$ref,
  +$refType: QuoteView_viewer$ref,
|};
export type QuoteView_viewer$data = QuoteView_viewer;
export type QuoteView_viewer$key = {
  +$data?: QuoteView_viewer$data,
  +$fragmentRefs: QuoteView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitDiscount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSurcharge",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowTotal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        },
        {
          "kind": "Literal",
          "name": "reload",
          "value": true
        }
      ],
      "concreteType": "SalesQuoteConnection",
      "kind": "LinkedField",
      "name": "salesQuotes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesQuoteEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesQuote",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subtotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grandTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "insertedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deletedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recoveryStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serialisation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Customer",
                  "kind": "LinkedField",
                  "name": "customer",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstname",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastname",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v1/*: any*/),
                  "concreteType": "SalesQuoteLineConnection",
                  "kind": "LinkedField",
                  "name": "lines",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesQuoteLineEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesQuoteLine",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/),
                            {
                              "alias": null,
                              "args": (v1/*: any*/),
                              "concreteType": "SalesQuoteLineConnection",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesQuoteLineEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesQuoteLine",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v2/*: any*/),
                                        (v3/*: any*/),
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/),
                                        (v7/*: any*/),
                                        (v8/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "children(first:99)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "lines(first:99)"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SalesQuoteHistory_salesQuote"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SalesQuoteHistory_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b362f615c2e5fbd469f5487256af7d55';

module.exports = node;
