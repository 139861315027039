/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type home_viewer$ref = any;
export type routes_Home_QueryVariables = {||};
export type routes_Home_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: home_viewer$ref
  |}
|};
export type routes_Home_Query = {|
  variables: routes_Home_QueryVariables,
  response: routes_Home_QueryResponse,
|};
*/


/*
query routes_Home_Query {
  viewer {
    ...home_viewer
    id
  }
}

fragment Login_viewer on Admin {
  username
}

fragment home_viewer on Admin {
  id
  username
  ...Login_viewer
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Home_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "home_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Home_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "831455ce0fa9d92fba37b02d326a4814",
    "id": null,
    "metadata": {},
    "name": "routes_Home_Query",
    "operationKind": "query",
    "text": "query routes_Home_Query {\n  viewer {\n    ...home_viewer\n    id\n  }\n}\n\nfragment Login_viewer on Admin {\n  username\n}\n\nfragment home_viewer on Admin {\n  id\n  username\n  ...Login_viewer\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '2a242a85eee541fb06348b307adcdaf0';

module.exports = node;
