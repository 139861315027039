import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../../mutation';

const mutation = graphql`
  mutation RemoveBonusMutation($input: RemoveBonusInput!) {
    removeBonus(input:$input) {
      removedBonusId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeBonus', edgeName: 'removedBonusId', connectionKey: 'BonusList_bonuses' }),
});
export default { commit };

