import AddProductMutation from './AddProductMutation';
import CopyToWebsiteMutation from './CopyToWebsiteMutation';
import LinkProductMutation from './LinkProductMutation';
import RecreateIndexMutation from './RecreateIndexMutation';
import ReindexProductsMutation from './ReindexProductsMutation';
import RemoveProductMutation from './RemoveProductMutation';
import RemoveProductFileMutation from './RemoveProductFileMutation';
import RemoveProductImageMutation from './RemoveProductImageMutation';
import UnlinkProductMutation from './UnlinkProductMutation';
import UpdateProductMutation from './UpdateProductMutation';

module.exports = {
  AddProductMutation,
  CopyToWebsiteMutation,
  LinkProductMutation,
  RecreateIndexMutation,
  ReindexProductsMutation,
  RemoveProductMutation,
  RemoveProductFileMutation,
  RemoveProductImageMutation,
  UnlinkProductMutation,
  UpdateProductMutation,
};
