import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation CancelOrderMutation($input: CancelOrderInput!) {
    cancelOrder(input:$input) {
      salesOrder {
        status
        shipping
        verification {
          cancelledBy
        }
        ...SalesOrderHistory_salesOrder
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
