import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateCustomerMutation($input: UpdateCustomerInput!) {
    updateCustomer(input:$input) {
      customer {
        firstname
        lastname
        email
        reviewReminder
        checkedOrders
        securityCheck
        lastLoginAt
        subscription {
          status
        }
        status
        ...CustomerHistory_customer
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
