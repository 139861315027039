import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button, Col, Divider, Form, Row, Select, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { fileValidator, imageValidator } from '~/components/form';

const { Item: FormItem } = Form;
const { Option } = Select;

class FeaturedBrand extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    category: PropTypes.shape({
    }).isRequired,
  }

  render() {
    const { category, viewer } = this.props;

    if (Object.keys(category).length === 0) {
      return null;
    }

    const allBrands = get(viewer, 'brands.edges', []);
    const allSubcategories = get(viewer, 'subcategories.edges', []);
    const brandSubcategory = get(category, 'featuredBrands.edges', []);

    const initialValue = brandSubcategory.length ? brandSubcategory.slice(0).map(({ node }) => {
      const brandId = get(node, "brand.id", null);
      const brandName = get(node, "brand.name", null);
      const subcategoryId = get(node, "subcategory.id", null);
      const subcategoryName = get(node, "subcategory.name", null);

      return (
        {
          ...node.brand,
          id: {key: brandId, label: brandName },
          subcategory: {key: subcategoryId, label: subcategoryName}
        }
      )
    }) : [];

    return (
      <Form.List name="brands" initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key}>
                <Row>
                  <Col xs={24} sm={12}>
                    <FormItem
                      label={
                        <div style={{ display: "inline-block" }}>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              style={{ cursor: 'pointer', margin: "0 10px" }}
                              disabled={fields.length === 1}
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </div>
                      }
                    >
                      <FormItem
                        name={[name, "id"]}
                        rules={[{ required: true, message: 'required' }]}
                      >
                        <Select
                          placeholder="Brand"
                          optionFilterProp="children"
                          optionLabelProp="children"
                          labelInValue
                          showSearch
                        >
                          {
                            allBrands.map((catEdge) => {
                              const o = catEdge.node;
                              return <Option key={o.id} value={o.id}>{o.name}</Option>;
                            })
                          }
                        </Select>
                      </FormItem>
                    </FormItem>
                    <FormItem
                      extra="When specified, the logo links to the specified subcategory. If not specified, it will peform a filter on the current category."
                    >
                      <FormItem
                        name={[name, "subcategory"]}
                      >
                        <Select
                          placeholder="Subcategory"
                          optionFilterProp="children"
                          optionLabelProp="children"
                          labelInValue
                          showSearch
                          allowClear
                        >
                          {
                            allSubcategories.map((subcatEdge) => {
                              const o = subcatEdge.node;
                              return <Option key={o.id} value={o.id}>{o.name}</Option>;
                            })
                          }
                        </Select>
                      </FormItem>
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={12}>
                    <FormItem
                      label="Logo"
                    >
                      <FormItem
                        name={[name, "image"]}
                        valuePropName="fileList"
                        extra="Optional, if not specified, it will use the brand's logo."
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e && e.fileList;
                        }}
                        rules={[
                          { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) },
                          { required: true, message: 'Image size should be either 240x240 or 340x170', validator: imageValidator.bind(this, [[240, 240], [340, 170]]) },
                        ]}
                      >
                        <Upload
                          accept="image/gif,image/png,image/jpeg"
                          beforeUpload={() => false}
                          listType="picture"
                        >
                          <Button>
                            <UploadOutlined /> Upload
                          </Button>
                        </Upload>
                      </FormItem>

                      <FormItem noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          const imageUrl = getFieldValue(["brands", name, "imageUrl"]);
                          return imageUrl ? (
                            <img alt="" src={imageUrl} width="120" />
                          ) : null
                        }}
                      </FormItem>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Divider />
                </Row>
              </div>
            ))}
            <Button onClick={() => add({ })}>
              <PlusOutlined />
            </Button>
          </>
        )}
      </Form.List>
    );
  }
}

export default createFragmentContainer(FeaturedBrand, {
  viewer: graphql`
    fragment FeaturedBrand_viewer on Admin {
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
