import React from 'react'
import PropTypes from 'prop-types';
import {
  useFragment,
  useRelayEnvironment,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateFooterMutation } from './mutations';
import FooterForm from './FooterForm';

const FooterView = (props) => {
  const environment = useRelayEnvironment();

  const viewer = useFragment(
    graphql`
      fragment FooterView_viewer on Admin {
        footers(first: 1, ids: $ids) {
          edges {
            node {
              id
              name
              position
              urls {
                name
                type
                url
                properties
                position
              }
              status
            }
          }
        }
      }
    `,
    props.viewer
  );

  const { match } = props;
  const footer = get(viewer, 'footers.edges[0].node', {});

  const handleSubmit = (values) => {
    values.status = !!values.status;

    UpdateFooterMutation.commit({
      environment,
      variables: { input: values },
      viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
      }
    });
  }

  return (
    <div>
      <Helmet title={footer.name} />
      <h1>{footer.name}</h1>
      <FooterForm
        key={footer.id}
        match={match}
        viewer={viewer}
        onSubmit={handleSubmit}
        footer={footer}
      />
    </div>
  )
}

FooterView.propTypes = {
  match: PropTypes.shape({}).isRequired,
  viewer: PropTypes.shape({}).isRequired,
}

export default FooterView;
