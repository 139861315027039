/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesReport_viewer$ref = any;
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_SalesReport_QueryVariables = {|
  orderBy?: ?OrderBy,
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
|};
export type routes_SalesReport_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SalesReport_viewer$ref
  |}
|};
export type routes_SalesReport_Query = {|
  variables: routes_SalesReport_QueryVariables,
  response: routes_SalesReport_QueryResponse,
|};
*/


/*
query routes_SalesReport_Query(
  $orderBy: OrderBy
  $filterBy: [FilterBy]
) {
  viewer {
    ...SalesReport_viewer
    id
  }
}

fragment SalesReport_viewer on Admin {
  roles(first: 99) {
    edges {
      node {
        name
        id
      }
    }
  }
  salesReport(orderBy: $orderBy, filterBy: $filterBy)
  stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
        canPickup
        state
        status
        branchCode
        distributionHub
        emails
        managerEmails
        stateManager {
          emails
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SalesReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SalesReport_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_SalesReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 99
              }
            ],
            "concreteType": "AdminRoleConnection",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "roles(first:99)"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              }
            ],
            "kind": "ScalarField",
            "name": "salesReport",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 9999
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": {
                  "direction": "asc",
                  "field": "name"
                }
              }
            ],
            "concreteType": "StoreConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canPickup",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "branchCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "distributionHub",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "managerEmails",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StateManager",
                        "kind": "LinkedField",
                        "name": "stateManager",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stores(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c2de9fcc3ec5fc487075bfe7946e88b",
    "id": null,
    "metadata": {},
    "name": "routes_SalesReport_Query",
    "operationKind": "query",
    "text": "query routes_SalesReport_Query(\n  $orderBy: OrderBy\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...SalesReport_viewer\n    id\n  }\n}\n\nfragment SalesReport_viewer on Admin {\n  roles(first: 99) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  salesReport(orderBy: $orderBy, filterBy: $filterBy)\n  stores(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n        canPickup\n        state\n        status\n        branchCode\n        distributionHub\n        emails\n        managerEmails\n        stateManager {\n          emails\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '040a65c38d7b415724f6b68c3ab2731a';

module.exports = node;
