import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation UpdateSearchCanonicalMutation($input: UpdateSearchCanonicalInput!) {
    updateSearchCanonical(input:$input) {
      searchCanonical {
        queries
        url
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
