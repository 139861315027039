import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddCreditMutation } from './mutations';
import CreditForm from './CreditForm';

class CreditNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues, { setLoading }) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      stealthMode: !!formValues.stealthMode,
      promoItems: formValues.promoItems.map(p => p.key),
      excludeProducts: formValues.excludeProducts.map(p => p.key),
      excludeProductsSpend: formValues.excludeProductsSpend.map(p => p.key),
      includeProducts: formValues.includeProducts.map(p => p.key),
    };

    if (formValues.fromBrandIds.includes('*')) {
      values.fromBrandIds = null;
    }

    if (formValues.creditFromProducts) {
      values.creditFromProducts = formValues.creditFromProducts.map((p) => ({
        ...p, productId: p.productId.key,
      }));
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: { insert: setLoading },
      onCompleted: (resp) => {
        window.location.href = `/promotion/credit/${fromGlobalId(resp.addCredit.creditEdge.node.id).id}`;
      },
    };

    AddCreditMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Credit" />

        <h1>New Credit</h1>
        <CreditForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(CreditNew, {
  viewer: graphql`
    fragment CreditNew_viewer on Admin {
      id
      ...CreditForm_viewer
    }
  `,
});
