/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddBrandInput = {|
  description?: ?string,
  description2?: ?string,
  expressShipping?: ?boolean,
  image: any,
  metaDescription?: ?string,
  metaTitle?: ?string,
  name: string,
  privateLabel?: ?boolean,
  searchScoreMultiplier?: ?number,
  status: boolean,
|};
export type AddBrandMutationVariables = {|
  input: AddBrandInput
|};
export type AddBrandMutationResponse = {|
  +addBrand: ?{|
    +brandEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +imageUrl: ?string,
        +urlSlug: ?string,
        +status: ?boolean,
        +metaTitle: ?string,
        +metaDescription: ?string,
        +description: ?string,
        +description2: ?string,
        +expressShipping: ?boolean,
        +privateLabel: ?boolean,
        +searchScoreMultiplier: ?number,
      |},
    |}
  |}
|};
export type AddBrandMutation = {|
  variables: AddBrandMutationVariables,
  response: AddBrandMutationResponse,
|};
*/


/*
mutation AddBrandMutation(
  $input: AddBrandInput!
) {
  addBrand(input: $input) {
    brandEdge {
      cursor
      node {
        id
        name
        imageUrl
        urlSlug
        status
        metaTitle
        metaDescription
        description
        description2
        expressShipping
        privateLabel
        searchScoreMultiplier
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddBrandPayload",
    "kind": "LinkedField",
    "name": "addBrand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandEdge",
        "kind": "LinkedField",
        "name": "brandEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Brand",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expressShipping",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privateLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "searchScoreMultiplier",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddBrandMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddBrandMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "233cd8d92ce5ffe5b96f6ffff5bcb8e4",
    "id": null,
    "metadata": {},
    "name": "AddBrandMutation",
    "operationKind": "mutation",
    "text": "mutation AddBrandMutation(\n  $input: AddBrandInput!\n) {\n  addBrand(input: $input) {\n    brandEdge {\n      cursor\n      node {\n        id\n        name\n        imageUrl\n        urlSlug\n        status\n        metaTitle\n        metaDescription\n        description\n        description2\n        expressShipping\n        privateLabel\n        searchScoreMultiplier\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b02c8a74df36c27399cc562167fb0246';

module.exports = node;
