import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Button, Table, Tooltip } from 'antd';
import { getStates } from '~/components/form';

import { fromGlobalId } from '../../helper';
import { groupOrders } from './SalesReport';

const renderStatus = (val, record, index, extra = {}) => {
  const { dataIndex, cb } = extra;

  if (val && val !== 0) {
    const total = record["Total Orders"];

    if (total > 0) {
      return (
        <Button
          type="link"
          style={{ margin: '0px', padding: '0px', width: '100%', height: '100%' }}
          onClick={() => {
            if (cb && typeof cb === 'function') {
              cb(record.key, dataIndex);
            }
          }}
        >
          {val}
          <div>
            <span style={{fontSize: '9px'}}>({(val / total * 100.0).toFixed(2)}%)</span>
          </div>
        </Button>
      );
    }

    return val;
  }
  return null;
};

const renderDays = (val, record) => {
  if (val && val !== 0) {
    const totalShipped = record["Total Shipped"];

    if (totalShipped > 0) {
      return (
        <div>
          {val}
          <div>
            <span style={{fontSize: '9px'}}>({(val / totalShipped * 100.0).toFixed(2)}%)</span>
          </div>
        </div>
      );
    }

    return val;
  }
  return null;
};

const getColumns = (viewOrders) => {
  return [
  {
    title: 'State',
    dataIndex: 'state',
    fixed: 'left',
    filters: Object.keys(getStates()).map(key => ({ text: key, value: key })),
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (value, record) => record.state.includes(value),
    width: 100,
    sorter: (a, b) => a.state.localeCompare(b.state),
  },
  {
    title: 'Branch',
    dataIndex: 'name',
    fixed: 'left',
    width: 125,
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (val, record) => {
      const oneDay = record['0-1 Days'];
      const totalShipped = record["Total Shipped"];

      const style = {};

      if (oneDay && totalShipped) {
        const pct = oneDay / totalShipped * 100.0;

        style.fontWeight = (pct < 50.0) ? 'bold' : 'normal';
      }

      if (record.Disabled && record.key !== '05') {
        return (
          <Tooltip title="Allocation Disabled">
            <span style={{color: 'red', ...style}}>{val}</span>
          </Tooltip>
        );
      }
        return <span style={style}>{val}</span>

    },
  },
  {
    align: 'center',
    title: '0-1 Days',
    dataIndex: '0-1 Days',
    className: 'ant-alert-success',
    render: renderDays,
  },
  {
    align: 'center',
    title: '2-3 Days',
    dataIndex: '2-3 Days',
    className: 'ant-alert-success',
    render: renderDays,
  },
  {
    align: 'center',
    title: '4-5 Days',
    dataIndex: '4-5 Days',
    className: 'ant-alert-warning',
    render: renderDays,
  },
  {
    align: 'center',
    title: '6-10 Days',
    dataIndex: '6-10 Days',
    className: 'ant-alert-error',
    render: renderDays,
  },
  {
    align: 'center',
    title: '11-19 Days',
    dataIndex: '11-19 Days',
    className: 'ant-alert-error',
    render: renderDays,
  },
  {
    align: 'center',
    title: '20+ Days',
    dataIndex: '20+ Days',
    className: 'ant-alert-error',
    render: renderDays,
  },
  {
    align: 'center',
    title: 'Processing',
    dataIndex: 'Processing',
    className: 'ant-alert-error',
    width: 105,
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Allocating Stock',
    dataIndex: 'Allocating Stock',
    className: 'ant-alert-error',
    width: 100,
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Being Picked',
    dataIndex: 'Being Picked',
    className: 'ant-alert-error',
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Partially Picked',
    dataIndex: 'Partially Picked',
    width: 90,
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Back Order',
    dataIndex: 'Back Order',
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Partially Shipped',
    dataIndex: 'Partially Shipped',
    className: 'ant-alert-success',
    width: 90,
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Shipped',
    dataIndex: 'Shipped',
    className: 'ant-alert-success',
    width: 85,
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Click & Collect',
    dataIndex: 'Click & Collect',
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Cancelled',
    dataIndex: 'Cancelled',
    width: 95,
    render: renderStatus,
  },
  {
    align: 'center',
    title: 'Total Orders',
    dataIndex: 'Total Orders',
    width: 95
  },
]
.map(col => {

  const { dataIndex, render } = col;

  if (render && render === renderStatus) {
    col.render = (val, record, index) => renderStatus(val, record, index, { dataIndex, cb: viewOrders });
  }

  if (!col.sorter) {
    col.sorter = (a, b) => {
      return get(a, dataIndex, 0) - get(b, dataIndex, 0)
    };
  }
  return col;
})};


const BreakDown = (props) => {
  const { orders, excludeNonStockPreorder, loading, viewOrders } = props;
  const ordersByBranch = groupOrders(orders, { groupByBranch: true, excludeNonStockPreorder });

  // ignore Repair(id = 18) branch
  const stores = props.stores.edges.filter(({ node }) => fromGlobalId(node.id).id !== 18);

  const data = stores.map(({node}) => {
    const branch = ordersByBranch[node.branchCode];

    if (branch) {
      return {
        key: node.branchCode,
        name: node.name,
        state: node.state,
        Disabled: !node.distributionHub,
        '0-1 Days': branch['0-1 Days'],
        '2-3 Days': branch['2-3 Days'],
        '4-5 Days': branch['4-5 Days'],
        '6-10 Days': branch['6-10 Days'],
        '11-19 Days': branch['11-19 Days'],
        '20+ Days': branch['20+ Days'],
        'Total Shipped': branch['Total Shipped'],
        'Processing': branch.Processing,
        'Allocating Stock': branch['Allocating Stock'],
        'Being Picked': branch['Being Picked'],
        'Partially Picked': branch['Partially Picked'],
        'Back Order': branch['Back Order'],
        'Partially Shipped': branch['Partially Shipped'],
        'Shipped': branch.Shipped,
        'Click & Collect': branch['Click & Collect'],
        'Cancelled': branch.Cancelled,
        'Total Orders': branch['Total Orders'],
      }
    }
    return null;
  }).filter(s => s);

  const columns = getColumns(viewOrders);

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={columns}
      pagination={{ position: "none", pageSize: 9999 }}
      scroll={{
        x: '100%'
      }}
      summary={(pageData) => {
        return (
          <Table.Summary fixed>
            <Table.Summary.Row style={{textAlign: 'center'}}>
              {columns.map(({ dataIndex }) => {
                let val = null;

                if (!["name"].includes(dataIndex)) {
                  val = pageData.reduce((acc, row) => {
                    return acc + (row[dataIndex] || 0);
                  }, 0);
                }

                if (dataIndex === 'state') {
                  val = '';
                }

                if (Number.isNaN(val) || val <= 0) {
                  val = '';
                } else {
                  val = (
                    <span>
                      {val}<br />
                      <span style={{fontSize: '9px'}}>({(val / orders.length * 100.0).toFixed(2)}%)</span>
                    </span>
                  );
                }

                return <Table.Summary.Cell key={dataIndex} index={dataIndex}>{val}</Table.Summary.Cell>;
              })}
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );

};

BreakDown.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape).isRequired,
  excludeNonStockPreorder: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  stores: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  viewOrders: PropTypes.func.isRequired,
};

export default BreakDown;
