/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CarouselImageView_viewer$ref = any;
export type routes_CarouselImageView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_CarouselImageView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CarouselImageView_viewer$ref
  |}
|};
export type routes_CarouselImageView_Query = {|
  variables: routes_CarouselImageView_QueryVariables,
  response: routes_CarouselImageView_QueryResponse,
|};
*/


/*
query routes_CarouselImageView_Query(
  $ids: [ID]
) {
  viewer {
    ...CarouselImageView_viewer
    id
  }
}

fragment CarouselImageView_viewer on Admin {
  cmsCarouselImages(first: 1, ids: $ids) {
    edges {
      node {
        id
        title
        link
        main1
        main2
        bg1
        bg2
        startDate
        endDate
        position
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CarouselImageView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CarouselImageView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_CarouselImageView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "CmsCarouselImageConnection",
            "kind": "LinkedField",
            "name": "cmsCarouselImages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CmsCarouselImageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CmsCarouselImage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "main1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "main2",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bg1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bg2",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38d7c9c19b535a402fdde68ee01d2110",
    "id": null,
    "metadata": {},
    "name": "routes_CarouselImageView_Query",
    "operationKind": "query",
    "text": "query routes_CarouselImageView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...CarouselImageView_viewer\n    id\n  }\n}\n\nfragment CarouselImageView_viewer on Admin {\n  cmsCarouselImages(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        title\n        link\n        main1\n        main2\n        bg1\n        bg2\n        startDate\n        endDate\n        position\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4a1a2826520b905fd2343b5fdb56874';

module.exports = node;
