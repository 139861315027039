/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenPromoCardPdfInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  pageSize: string,
|};
export type GenPromoCardPdfMutationVariables = {|
  input: GenPromoCardPdfInput
|};
export type GenPromoCardPdfMutationResponse = {|
  +genPromoCardPdf: ?{|
    +promoCard: ?{|
      +files: ?$ReadOnlyArray<?string>
    |}
  |}
|};
export type GenPromoCardPdfMutation = {|
  variables: GenPromoCardPdfMutationVariables,
  response: GenPromoCardPdfMutationResponse,
|};
*/


/*
mutation GenPromoCardPdfMutation(
  $input: GenPromoCardPdfInput!
) {
  genPromoCardPdf(input: $input) {
    promoCard {
      files
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenPromoCardPdfPayload",
    "kind": "LinkedField",
    "name": "genPromoCardPdf",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PromoCard",
        "kind": "LinkedField",
        "name": "promoCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "files",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenPromoCardPdfMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenPromoCardPdfMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "688e1785b378a97f8b54712a8cbee97f",
    "id": null,
    "metadata": {},
    "name": "GenPromoCardPdfMutation",
    "operationKind": "mutation",
    "text": "mutation GenPromoCardPdfMutation(\n  $input: GenPromoCardPdfInput!\n) {\n  genPromoCardPdf(input: $input) {\n    promoCard {\n      files\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf7b55c0ea0c475d5ef1357b78c0d2f5';

module.exports = node;
