import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { toGlobalId } from '~/helper';
import { getPageSize } from '~/components/grid';

import UsageRoutes from './usage/routes';

const routes = (
  <Route
    path="/gift-card"
  >
    <Route
      path="/setup"
      getComponent={() => (
        import(/* webpackChunkName: "GiftCardList" */'./GiftCardList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_GiftCardList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...GiftCardList_viewer
          }
        }
      `}
      prepareVariables={() => ({
        count: getPageSize(),
      })}
    >
      <Route />

      <Route
        path="/new"
        getComponent={() => (
          import(/* webpackChunkName: "GiftCardNew" */'./GiftCardNew').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_GiftCardNew_Query {
            viewer {
              ...GiftCardNew_viewer
            }
          }
        `}
      />

      <Route
        path="/:id"
        getComponent={() => (
          import(/* webpackChunkName: "GiftCardView" */'./GiftCardView').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_GiftCardView_Query ($ids: [ID]) {
            viewer {
              ...GiftCardView_viewer
            }
          }
        `}
        prepareVariables={(params) => {
          const globalId = toGlobalId('GiftCard', params.id);
          return { ids: [globalId] };
        }}
      />
    </Route>
    {UsageRoutes}
  </Route>
);

export default routes;
