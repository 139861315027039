import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { fromGlobalId } from '../../helper';
import { BooleanDropdownFilter, Grid, iDGetter, valueGetter } from '../grid';

const entityName = 'Attribute';

class AttributeList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      attributes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/attribute/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'id', headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: true, filter: false,
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 200,
      },
      {
        colId: 'code', headerName: 'Code', valueGetter: valueGetter.bind(this, 'code'), width: 200,
      },
      {
        colId: 'multi', headerName: 'Multi Value', valueGetter: valueGetter.bind(this, 'multi'), width: 110, ...BooleanDropdownFilter
      },
      {
        colId: 'visibleToFrontend', headerName: 'Frontend Visible', valueGetter: valueGetter.bind(this, 'visibleToFrontend'), width: 150, ...BooleanDropdownFilter
      },
      {
        colId: 'includeInSearch', headerName: 'In Search', valueGetter: valueGetter.bind(this, 'includeInSearch'), ...BooleanDropdownFilter
      },
      {
        colId: 'position', headerName: 'Position', valueGetter: valueGetter.bind(this, 'position'), filter: 'agNumberColumnFilter', width: 110
      },
    ];

    this.datasource = Grid.getDataSource.call(this, 'attributes');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/attribute/new"><Button>Add {entityName}</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  AttributeList, {
    viewer: graphql`
    fragment AttributeList_viewer on Admin {
      attributes(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "AttributeList_attributes") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            code
            multi
            visibleToFrontend
            includeInSearch
            position
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.attributes;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query AttributeListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...AttributeList_viewer
      }
    }
  `,
  },
);
