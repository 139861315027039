import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateBonusMutation($input: UpdateBonusInput!) {
    updateBonus(input:$input) {
      bonus {
        name
        startDate
        endDate
        status
        stealthMode
        quantityRequired
        onProducts(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        products(first: 999) {
          edges {
            node {
              product {
                id
                name
                mainImage {
                  id
                  url
                }
              }
              price
              quantity
              optional
            }
          }
        }
        images (first: 999) {
          edges {
            node {
              id
              imageUrl
            }
          }
        }
        ...BonusHistory_bonus
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
