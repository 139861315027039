const removeItem = (indexToRemove, form, fieldName) => {
  const fieldArrays = form.getFieldValue(fieldName);
  if (fieldArrays.length <= 0) {
    return;
  }

  const updatedArrays = fieldArrays.filter((_, index) => index !== indexToRemove);

  form.setFieldsValue({
    [fieldName]: updatedArrays,
  });
}

module.exports = {
  removeItem,
};