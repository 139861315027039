import React from 'react';
import { Route } from 'found';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/review"
    getComponent={() => (
      import(/* webpackChunkName: "ReviewList" */'./ReviewList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_ReviewList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...ReviewList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
      orderBy: { field: 'id', direction: 'desc' },
    })}
  >
    <Route />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "ReviewView" */'./ReviewView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_ReviewView_Query ($ids: [ID]) {
          viewer {
            ...ReviewView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('ProductReview', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
