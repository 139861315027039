import React from 'react'
import PropTypes from 'prop-types';
import {
  useFragment,
  useRelayEnvironment,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { fromGlobalId } from '../../../helper';
import { AddFooterMutation } from './mutations';
import FooterForm from './FooterForm';

const FooterNew = (props) => {
  const environment = useRelayEnvironment();

  const viewer = useFragment(
    graphql`
      fragment FooterNew_viewer on Admin {
        id
      }
    `,
    props.viewer,
  );

  const handleSubmit = (values, { setLoading }) => {
    values.status = !!values.status;

    AddFooterMutation.commit({
      environment,
      variables: { input: values },
      viewer,
      safeGuard: { insert: setLoading },
      onCompleted: (resp) => {
        window.location.href = `/cms/footer/${fromGlobalId(resp.addFooter.footerEdge.node.id).id}`;
      }
    });
  }

  return (
    <div>
      <Helmet title="New Footer" />

      <h1>New Faq</h1>
      <FooterForm viewer={viewer} onSubmit={handleSubmit} />
    </div>
  )
}

FooterNew.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

export default FooterNew;
