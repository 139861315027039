import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateAdjacentBannerMutation($input: UpdateAdjacentBannerInput!) {
    updateAdjacentBanner(input:$input) {
      adjacentBanner {
        name
        product {
          id
          name
          mainImage {
            id
            url
          }
        }
        link
        imageUrl
        placement
        position
        startDate
        endDate
        status
        categories(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories(first: 9999) {
          edges {
            node {
              id
              name
            }
          }
        }
        ...AdjacentBannerHistory_adjacentBanner
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
