/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommonPromoHistory_commonPromo$ref = any;
export type UpdateCommonPromoInput = {|
  brandIds?: ?$ReadOnlyArray<?string>,
  endDate?: ?string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  freebieId?: ?string,
  id: string,
  images?: ?$ReadOnlyArray<?InputCommonImage>,
  name?: ?string,
  onProducts?: ?$ReadOnlyArray<?string>,
  startDate?: ?string,
  status?: ?boolean,
  type?: ?number,
|};
export type InputCommonImage = {|
  file?: ?any,
  id?: ?string,
|};
export type UpdateCommonPromoMutationVariables = {|
  input: UpdateCommonPromoInput
|};
export type UpdateCommonPromoMutationResponse = {|
  +updateCommonPromo: ?{|
    +commonPromo: ?{|
      +name: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +onProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +type: ?number,
      +brandIds: ?$ReadOnlyArray<?string>,
      +freebieId: ?string,
      +excludeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +status: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +images: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +imageUrl: ?string,
          |}
        |}>
      |},
      +status: ?boolean,
      +$fragmentRefs: CommonPromoHistory_commonPromo$ref,
    |}
  |}
|};
export type UpdateCommonPromoMutation = {|
  variables: UpdateCommonPromoMutationVariables,
  response: UpdateCommonPromoMutationResponse,
|};
*/


/*
mutation UpdateCommonPromoMutation(
  $input: UpdateCommonPromoInput!
) {
  updateCommonPromo(input: $input) {
    commonPromo {
      name
      startDate
      endDate
      onProducts(first: 99999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      type
      brandIds
      freebieId
      excludeProducts(first: 99999) {
        edges {
          node {
            id
            name
            status
            mainImage {
              id
              url
            }
          }
        }
      }
      images(first: 999) {
        edges {
          node {
            id
            imageUrl
          }
        }
      }
      status
      ...CommonPromoHistory_commonPromo
      id
    }
  }
}

fragment CommonPromoHistory_commonPromo on CommonPromo {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "onProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v2/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "onProducts(first:99999)"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandIds",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freebieId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "excludeProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v2/*: any*/),
            (v12/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "excludeProducts(first:99999)"
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 999
    }
  ],
  "concreteType": "CommonImageConnection",
  "kind": "LinkedField",
  "name": "images",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CommonImageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommonImage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "images(first:999)"
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCommonPromoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCommonPromoPayload",
        "kind": "LinkedField",
        "name": "updateCommonPromo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CommonPromo",
            "kind": "LinkedField",
            "name": "commonPromo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommonPromoHistory_commonPromo"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCommonPromoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCommonPromoPayload",
        "kind": "LinkedField",
        "name": "updateCommonPromo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CommonPromo",
            "kind": "LinkedField",
            "name": "commonPromo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v12/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CommonPromoHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9863f26437f73fb9123a4b5955ccc845",
    "id": null,
    "metadata": {},
    "name": "UpdateCommonPromoMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCommonPromoMutation(\n  $input: UpdateCommonPromoInput!\n) {\n  updateCommonPromo(input: $input) {\n    commonPromo {\n      name\n      startDate\n      endDate\n      onProducts(first: 99999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      type\n      brandIds\n      freebieId\n      excludeProducts(first: 99999) {\n        edges {\n          node {\n            id\n            name\n            status\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      images(first: 999) {\n        edges {\n          node {\n            id\n            imageUrl\n          }\n        }\n      }\n      status\n      ...CommonPromoHistory_commonPromo\n      id\n    }\n  }\n}\n\nfragment CommonPromoHistory_commonPromo on CommonPromo {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a8e845bb1a3051bbee37461373602b4';

module.exports = node;
