import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, Modal, Table } from 'antd';

const { COUNTRY } = process.env;

const CHECKER_URL = COUNTRY === 'AU' ? "https://price.sydneytools.com.au/products" : "https://price.sydneytools.co.nz/products";

export default class PriceCheck extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      model: PropTypes.string,
    }).isRequired,
  }

  state = {
    visible: false,
    prices: [],
  }

  columns = [{
    title: 'Image',
    dataIndex: 'img_src',
    render: src => (
      <img alt="" src={src} width="70" height="70" />
    ),
    width: '15%',
  }, {
    title: 'Retailer',
    dataIndex: 'retailer',
    render: (retailer, row) => (
      <a href={row.link} target="_blank" rel="noopener noreferrer">{retailer}</a>
    ),
    width: '45%',
  }, {
    title: 'Price',
    dataIndex: 'price',
    render: (price) => (
      <span>${price}</span>
    ),
    width: '15%',
  }, {
    title: 'Timestamp',
    dataIndex: 'created_at',
    render: ts => moment(ts).format('YYYY-MM-DD'),
    width: '25%',

  }]



  showModal = () => {
    const { model } = this.props.product;
    if (model) {
      fetch(`${CHECKER_URL}?model=${model}`)
      .then(response => {
        response.json().then((prices) => {
          if (prices) {
            this.setState({prices});
          }
        });
      })
      .catch((e) => {
        console.error(e);
      });
    }

    this.setState({
      visible: true,
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    const { prices } = this.state;

    return (
      <div style={{display: 'inline-block'}}>
        <Button onClick={this.showModal}>Price Check</Button>
        <Modal
          title="Price Check"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Table rowKey={r => r.id} columns={this.columns} dataSource={prices} />
        </Modal>
      </div>
    );
  }
}
