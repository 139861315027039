/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchProduct_viewer$ref: FragmentReference;
declare export opaque type SearchProduct_viewer$fragmentType: SearchProduct_viewer$ref;
export type SearchProduct_viewer = {|
  +getProductSuggestion: ?any,
  +$refType: SearchProduct_viewer$ref,
|};
export type SearchProduct_viewer$data = SearchProduct_viewer;
export type SearchProduct_viewer$key = {
  +$data?: SearchProduct_viewer$data,
  +$fragmentRefs: SearchProduct_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchProduct_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "kind": "ScalarField",
      "name": "getProductSuggestion",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'dda7a5f715fd2d8acfc4ecdb0c3bf0e8';

module.exports = node;
