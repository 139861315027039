/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExportProductsInput = {|
  filters?: ?$ReadOnlyArray<?FilterBy>,
  options?: ?InputExportOptions,
  query?: ?$ReadOnlyArray<?string>,
|};
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type InputExportOptions = {|
  priceDropDate?: ?string
|};
export type ExportProductsMutationVariables = {|
  input: ExportProductsInput
|};
export type ExportProductsMutationResponse = {|
  +exportProducts: ?{|
    +result: ?any
  |}
|};
export type ExportProductsMutation = {|
  variables: ExportProductsMutationVariables,
  response: ExportProductsMutationResponse,
|};
*/


/*
mutation ExportProductsMutation(
  $input: ExportProductsInput!
) {
  exportProducts(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ExportProductsPayload",
    "kind": "LinkedField",
    "name": "exportProducts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportProductsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportProductsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7d5b3b28e08c45fe052bf2936b1e9f84",
    "id": null,
    "metadata": {},
    "name": "ExportProductsMutation",
    "operationKind": "mutation",
    "text": "mutation ExportProductsMutation(\n  $input: ExportProductsInput!\n) {\n  exportProducts(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81dc5a6438e3ee99b5d90e0b21ed50fa';

module.exports = node;
