/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectOrderRefetchQueryVariables = {|
  query?: ?string
|};
export type SelectOrderRefetchQueryResponse = {|
  +viewer: ?{|
    +searchOrders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |}
  |}
|};
export type SelectOrderRefetchQuery = {|
  variables: SelectOrderRefetchQueryVariables,
  response: SelectOrderRefetchQueryResponse,
|};
*/


/*
query SelectOrderRefetchQuery(
  $query: String
) {
  viewer {
    searchOrders: salesOrders(first: 20, query: $query) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "searchOrders",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 20
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "SalesOrderConnection",
  "kind": "LinkedField",
  "name": "salesOrders",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectOrderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectOrderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72031220dbfecd2e2146493375a30eb3",
    "id": null,
    "metadata": {},
    "name": "SelectOrderRefetchQuery",
    "operationKind": "query",
    "text": "query SelectOrderRefetchQuery(\n  $query: String\n) {\n  viewer {\n    searchOrders: salesOrders(first: 20, query: $query) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b697c1720f643851bba76c29db690470';

module.exports = node;
