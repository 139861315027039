import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddCustomerMutation($input: AddCustomerInput!) {
    addCustomer(input:$input) {
      customerEdge {
        cursor
        node {
          id
          firstname
          lastname
          email
          reviewReminder
          checkedOrders
          securityCheck
          lastLoginAt
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
