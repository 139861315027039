import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddGiftCardUsageMutation($input: AddGiftCardUsageInput!) {
    addGiftCardUsage(input:$input) {
      giftCardUsageEdge {
        cursor
        node {
          id
          code
          amount
          remainingAmount
          expiry
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

