import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { toGlobalId } from '~/helper';
import { getPageSize } from '~/components/grid';

import BannerRoutes from './banner/routes';
import CarouselRoutes from './carousel/routes';
import FaqRoutes from './faq/routes';
import FooterRoutes from './footer/routes';

const routes = (
  <Route
    path="/cms"
  >
    {BannerRoutes}
    {CarouselRoutes}
    {FaqRoutes}
    {FooterRoutes}

    <Route
      path="/page"
      getComponent={() => (
        import(/* webpackChunkName: "CmsPageList" */'./CmsPageList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_CmsPageList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...CmsPageList_viewer
          }
        }
      `}
      prepareVariables={() => ({
        count: getPageSize(),
      })}
    >
      <Route />

      <Route
        path="/new"
        getComponent={() => (
          import(/* webpackChunkName: "CmsPageNew" */'./CmsPageNew').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_CmsPageNew_Query {
            viewer {
              ...CmsPageNew_viewer
            }
          }
        `}
      />

      <Route
        path="/:id"
        getComponent={() => (
          import(/* webpackChunkName: "CmsPageView" */'./CmsPageView').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_CmsPageView_Query ($ids: [ID]) {
            viewer {
              ...CmsPageView_viewer
            }
          }
        `}
        prepareVariables={(params) => {
          const globalId = toGlobalId('CmsPage', params.id);
          return { ids: [globalId] };
        }}
      />
    </Route>
  </Route>
);

export default routes;
