import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Affix, Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormBase, formItemLayout, formItemLayoutWithOutLabel, getStates } from '~/components/form';
import Presence from '~/components/Presence';
import { validateEmail, checkEmails } from '~/helper';

const { Item: FormItem } = Form;

class StateManagerForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      stateManagers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  shouldDisableField = (roles) => {
    return roles.some(({ node }) => node.name === 'State Manager');
  }

  render() {
    const { match, viewer } = this.props;
    const stores = get(viewer, 'stores.edges', []);
    const roles = get(viewer, 'roles.edges', []);
    const stateManager = get(viewer, 'stateManagers.edges[0].node', {});
    const areaManagers = get(stateManager, 'areaManagers', []).map((areaManager) => {
      const { stores: _stores, ...rest } = areaManager;
      return { ...rest, stores: _stores.map(({ id }) => id) };
    });
    const dispatchers = get(stateManager, 'stateManagerAssistants', []).map((dispatcher) => {
      return dispatcher?.roles.includes("Dispatch Assistant") ? dispatcher?.email : undefined
    }).filter(email => email !== undefined);

    const disableField = this.shouldDisableField(roles);
    const initialValue = { areaManagers };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={stateManager.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Emails"
          name="emails"
          rules={[
            { required: true, message: 'required' },
            { required: true, validator: checkEmails }
          ]}
          initialValue={get(stateManager, 'emails', undefined) || undefined}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="State Managers"
            disabled={disableField}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="State"
          name="state"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(stateManager, 'state', null)}
        >
          <Select
            placeholder="State"
            options={Object.entries(getStates()).map(([k, v]) => ({ value: k, label: v }))}
            disabled={disableField}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Dispatch Assistants"
          name="dispatchAssistants"
          rules={[
            { required: false, validator: checkEmails }
          ]}
          initialValue={dispatchers}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Dispatch Assistants"
            disabled={disableField}
          />
        </FormItem>

        <Divider />

        <Form.List
          name="areaManagers"
          initialValue={initialValue.areaManagers}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }, index) => {
                const formStyle = {
                  emailCol: { xs: 24, sm: 10 },
                  storesCol: { xs: 24, sm: 12 },
                };

                return (
                  <React.Fragment key={key}>
                    <FormItem
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Area Managers' : ''}
                      required={false}
                    >
                      <Row>
                        <Col {...formStyle.emailCol}>
                          <FormItem
                            name={[name, "id"]}
                            hidden
                          >
                            <Input />
                          </FormItem>

                          <FormItem
                            name={[name, "email"]}
                            rules={[
                              { required: true, message: 'required' },
                              {
                                required: true, validator: (rule, value) => {
                                  if (value && !validateEmail(value)) {
                                    return Promise.reject(new Error('invalid email'));
                                  }
                                  return Promise.resolve();
                                }
                              },
                            ]}
                          >
                            <Input type="email" placeholder="Email" allowClear />
                          </FormItem>
                        </Col>

                        <Col {...formStyle.storesCol}>
                          <FormItem
                            name={[name, "stores"]}
                            rules={[
                              { required: true, message: 'required' },
                              { validator: (rule, value) => {
                                if (Array.isArray(value) && value.length > 10) {
                                  return Promise.reject(new Error('Cannot have more than 10 stores'));
                                }
                                return Promise.resolve();
                              }},
                            ]}
                            style={{ display: 'inline-block', width: 'calc(100% - 24px)' }}
                          >
                            <Select
                              allowClear
                              showSearch
                              optionFilterProp="label"
                              mode="multiple"
                              placeholder="Stores"
                              style={{ width: '100%' }}
                              options={stores.map(({ node: store }) => ({ value: store.id, label: store.name }))}
                            />
                          </FormItem>

                          <MinusCircleOutlined
                            style={{ marginLeft: '10px' }}
                            onClick={() => remove(name)}
                          />
                        </Col>
                      </Row>
                    </FormItem>
                  </React.Fragment>
                )
              })}

              <FormItem {...formItemLayoutWithOutLabel}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add Area Managers
                </Button>
              </FormItem>
            </>
          )}
        </Form.List>
      </Form>
    )
  }
}
export default StateManagerForm;
