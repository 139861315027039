/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BannerNew_viewer$ref = any;
export type routes_BannerNew_QueryVariables = {||};
export type routes_BannerNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BannerNew_viewer$ref
  |}
|};
export type routes_BannerNew_Query = {|
  variables: routes_BannerNew_QueryVariables,
  response: routes_BannerNew_QueryResponse,
|};
*/


/*
query routes_BannerNew_Query {
  viewer {
    ...BannerNew_viewer
    id
  }
}

fragment BannerNew_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_BannerNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BannerNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_BannerNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bec8ce782a4d9126d3f53530b7aa32e",
    "id": null,
    "metadata": {},
    "name": "routes_BannerNew_Query",
    "operationKind": "query",
    "text": "query routes_BannerNew_Query {\n  viewer {\n    ...BannerNew_viewer\n    id\n  }\n}\n\nfragment BannerNew_viewer on Admin {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '612106fb0e74d1d727527bfec28487ff';

module.exports = node;
