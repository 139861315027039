import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '~/mutation';

const mutation = graphql`
  mutation RemoveSearchRedirectMutation($input: RemoveSearchRedirectInput!) {
    removeSearchRedirect(input:$input) {
      removedSearchRedirectId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeSearchRedirect', edgeName: 'removedSearchRedirectId', connectionKey: 'SearchRedirectList_searchRedirects' }),
});
export default { commit };
