import React from 'react';
import ReactDOM from 'react-dom';

import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';


// Routing
import { BrowserProtocol, queryMiddleware } from 'farce';
import { createRender, createFarceRouter, ElementsRenderer } from 'found';
import { Resolver } from 'found-relay';
import { ScrollManager } from 'found-scroll';
import { get, omit } from 'lodash';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import StaticContainer from 'react-static-container';

import Cookies from 'universal-cookie';

import moment from 'moment-timezone';
import TimeAgo from 'javascript-time-ago';
import au from 'javascript-time-ago/locale/en-AU.json';

import routes from './routes';
import NotFound from './components/NotFound';

import socket from './socket';

// `VERSION` is defined in webpack.prod.babel.js
/* global VERSION */
socket.init(VERSION);

const cookies = new Cookies();
const token = cookies.get('auth_token');
const mountNode = document.getElementById('root');

if (window.location.host.includes('co.nz')) {
  moment.tz.setDefault("NZ");
}

TimeAgo.addDefaultLocale(au);

function fetchQuery(
  operation,
  formVariables,
  cacheConfig,
  uploadables,
) {
  let variables = { ...formVariables };

  const headers = {};
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers,
  };

  const captchaToken = get(variables, 'input.captchaToken');

  if (captchaToken) {
    request.headers['captcha-token'] = captchaToken;
    variables = omit(variables, ['input.captchaToken']);
  }

  if (uploadables) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    formData.append('query', operation.text);
    formData.append('variables', JSON.stringify(variables));

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    request.body = formData;
  } else {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }

  return fetch('/admin/graphql', request)
    .then(response => response.json())
    .then(json => {
      /*
       * Rejects error only if there are errors and the viewer is not null.
       * New viewer on first visit will get an not authenticated error and also
       * viewer = null and thus it will never present login form at all.
       */
      if (json.errors && json.data.viewer !== null) {
        return Promise.reject(json.errors);
      }
      return json;
    });
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});


const render = createRender({
  renderPending: () => {
    NProgress.start();
  },
  renderReady: (args) => { // eslint-disable-line react/display-name
    const { elements } = args;
    NProgress.done();
    return (
      <StaticContainer shouldUpdate>
        <ElementsRenderer elements={elements} />
      </StaticContainer>
    );
  },
  renderError: () => { // eslint-disable-line react/display-name
    NProgress.done();
    return <NotFound />;
  },
});

NProgress.configure({ showSpinner: false });
const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,

  render: renderArgs => ( // eslint-disable-line react/display-name
    <ScrollManager renderArgs={renderArgs}>
      {render(renderArgs)}
    </ScrollManager>
  ),

});
ReactDOM.render(
  <ConfigProvider locale={enUS}>
    <Router resolver={new Resolver(environment)} />
  </ConfigProvider>
  ,
  mountNode,
);
