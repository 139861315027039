import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { fromGlobalId } from '../../helper';
import { BooleanDropdownFilter, Grid, iDGetter, valueGetter } from '../grid';

const entityName = 'Url Rewrite Page';

const urlTypes = {
  0: "Unspecified",
  1: "Category",
  2: "Subcategory",
  3: "Brand",
  4: "Product",
};

class UrlRewriteList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      urlRewrites: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/url/rewrite/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'id', headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: true, filter: false,
      },
      {
        colId: 'from', headerName: 'From', valueGetter: valueGetter.bind(this, 'from'), width: 200,
      },
      {
        colId: 'to', headerName: 'To', valueGetter: valueGetter.bind(this, 'to'), width: 200,
      },
      {
        colId: 'urlType',
        headerName: 'Url Type',
        valueGetter: valueGetter.bind(this, 'urlType'),
        filter: 'agNumberColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: urlTypes, suppressFilterButton: true },
        width: 120,
        cellRendererFramework: ({value}) => {
          if (typeof value !== 'undefined') {
            return <span>{urlTypes[value]}</span>;
          }

          return null;
        },
      },
      {
        colId: 'httpCode',
        headerName: 'HTTP Code',
        valueGetter: valueGetter.bind(this, 'httpCode'),
        filter: 'agNumberColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: {301: 301, 302: 302}, suppressFilterButton: true },
        width: 120,
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: valueGetter.bind(this, 'status'),
        filter: 'agTextColumnFilter',
        ...BooleanDropdownFilter
      },
      {
        colId: 'lastAccessed', headerName: 'Last Accessed', valueGetter: valueGetter.bind(this, 'lastAccessed'), width: 200, filter: false,
      },
    ];

    this.datasource = Grid.getDataSource.call(this, 'urlRewrites');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/url/rewrite/new"><Button>Add Url Rewrite Page</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  UrlRewriteList, {
    viewer: graphql`
    fragment UrlRewriteList_viewer on Admin {
      urlRewrites(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "UrlRewriteList_urlRewrites") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            from
            to
            httpCode
            lastAccessed
            urlType
            status
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.urlRewrites;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query UrlRewriteListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...UrlRewriteList_viewer
      }
    }
  `,
  },
);
