/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesOrderVerification_order$ref: FragmentReference;
declare export opaque type SalesOrderVerification_order$fragmentType: SalesOrderVerification_order$ref;
export type SalesOrderVerification_order = {|
  +id: string,
  +verified: ?number,
  +$refType: SalesOrderVerification_order$ref,
|};
export type SalesOrderVerification_order$data = SalesOrderVerification_order;
export type SalesOrderVerification_order$key = {
  +$data?: SalesOrderVerification_order$data,
  +$fragmentRefs: SalesOrderVerification_order$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrderVerification_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verified",
      "storageKey": null
    }
  ],
  "type": "SalesOrder",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9474e940120682f744c3bde627be425a';

module.exports = node;
