import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { capitalize, get } from 'lodash';
import moment from 'moment-timezone';
import ReactTimeAgo from 'react-time-ago';

import { Affix, Alert, Button, Form, Input, InputNumber, Popconfirm, Select, Switch, Tabs, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import { DatePicker, FormBase, fileValidator, formItemLayout, QuickLink, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { fromGlobalId } from '~/helper';

import LabelHistory from './LabelHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

export const TYPE = {
  'None': null,
  'Bonus': 0,
  'Redemption': 1,
  'Credit': 2,
  'Freebie': 3,
  'Category': 4,
};

const SelectModel = (props) => {
  const form = Form.useFormInstance();
  const { label, name, data, onSelect } = props;

  const labelName = capitalize(name);
  const fieldName = `${name}Id`;

  return (
    <FormItem
      {...formItemLayout}
      label={labelName}
      extra={<span>To update products, simply click the <b>Save</b> button.</span>}
    >
      <FormItem
        name={fieldName}
        rules={[{ required: true, message: 'required' }]}
        initialValue={label[fieldName]}
      >
        <Select placeholder={labelName} showSearch optionFilterProp="children" onSelect={onSelect}>
          {
            data.map(({node}) => <Option key={node.id} value={node.id} node={node}>{node.name}</Option>)
          }
        </Select>
      </FormItem>
      <QuickLink form={form} fieldName={fieldName} />
    </FormItem>
  )
};

SelectModel.propTypes = {
  label: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
}

SelectModel.defaultProps = {
  data: [],
  onSelect: () => {},
}

class LabelForm extends FormBase {
  static propTypes = {
    label: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      brandId: PropTypes.string,
      imageUrl: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
      excludeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    label: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  sync = (value, option) => (
    this.formRef.current.setFieldsValue({
      startDate: moment(option.node.startDate),
      endDate: moment(option.node.endDate),
      status: option.node.status ? 1 : 0,
    })
  )

  showJobs = (jobs) => jobs.map(job => (
    <div key={job.id}>
      There is a/an {job.state} background job.
      <small>
        <ReactTimeAgo date={new Date(job.insertedAt)} locale="en-US" />
      </small>
    </div>
  ))

  render() {
    const { match, viewer } = this.props;
    const bonuses = get(viewer, 'bonuses.edges', []);
    const brands = get(viewer, 'brands.edges', []);
    const credits = get(viewer, 'credits.edges', []);
    const freebies = get(viewer, 'freebies.edges', []);
    const label = get(this.props, 'label', {});
    const products = get(label.products, 'edges', []);
    const redemptions = get(viewer, 'redemptions.edges', []);
    const categories = get(viewer, 'categories.edges', []);

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values, { setLoading: this.setLoading }); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            {this.renderSaveButton()}
            {label.id && (
            <Popconfirm
              title="Are you sure delete this label?"
              onConfirm={() => { this.props.remove(label); }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            {label.id && label.fuseImageJobs.length > 0 && (
              <Alert
                type="warning"
                message={this.showJobs(label.fuseImageJobs)}
              />
            )}

            <FormItem
              name="id"
              initialValue={label.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={label.name}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Image"
              required
            >
              {label.imageUrl && <img alt="" src={label.imageUrl} width="120" />}

              <FormItem
                name="image"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
                rules={[
                  { required: !label.imageUrl, message: 'required' },
                  { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
                ]}
              >
                <Upload
                  accept="image/gif,image/png,image/jpeg"
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </FormItem>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Product Page Image"
              extra="Optional"
            >
              {label.productPageImageUrl && <img alt="" src={label.productPageImageUrl} width="120" />}
              <FormItem
                name="productPageImage"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
                rules={[
                  { required: false, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
                ]}
              >
                <Upload
                  accept="image/gif,image/png,image/jpeg"
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </FormItem>
            </FormItem>

            {label.id && (
            <FormItem
              {...formItemLayout}
              label="Fuse Image"
              name="fuseImage"
              valuePropName="checked"
              initialValue={get(label, 'fuseImage')}
              rules={[
                ({ getFieldValue }) => ({
                  required: true,
                  validator: (rule, value) => {
                    if (value) {
                      const status = getFieldValue('status');
                      if (status === 0) {
                        return Promise.reject(new Error("Status has to be Enabled"));
                      }

                      const type = getFieldValue('type');

                      if (type === 2) {
                        return Promise.reject(new Error("It doesn't work with credits."));
                      }

                      if (type === 4) {
                        return Promise.reject(new Error("It doesn't work with categories."));
                      }

                      const brandId = getFieldValue('brandId');
                      if ((type === null || type === undefined) && brandId) {
                        return Promise.reject(new Error("It doesn't work with brands."));
                      }
                    }
                    return Promise.resolve();
                  }
                }),
              ]}
              extra="Label will be fused into the main product images. It is a background job, i.e. it could fail after you clicking the Save button, in such cases, an email will be sent out to the designer group and this function will be switch off."
            >
              <Switch disabled={label.fuseImageJobs.filter(job => ["available", "scheduled", "executing", "retryable"].includes(job.state) ).length > 0} />
            </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Type"
              name="type"
              initialValue={get(label, 'type', null)}
              extra="Depends on the type, some fields will be automated"
            >
              <Select placeholder="Type">
                {Object.entries(TYPE).map(([key, value]) => {
                  return (
                    <Option key={key} value={value}>{key}</Option>
                  )
                })}
              </Select>
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const type = getFieldValue('type');

                return (
                  <>
                    {type === TYPE.Bonus && (
                      <SelectModel label={label} name="bonus" data={bonuses} onSelect={this.sync} />
                    )}

                    {type === TYPE.Redemption && (
                      <SelectModel label={label} name="redemption" data={redemptions} onSelect={this.sync} />
                    )}

                    {type === TYPE.Credit && (
                      <SelectModel label={label} name="credit" data={credits} onSelect={this.sync} />
                    )}

                    {type === TYPE.Freebie && (
                      <SelectModel label={label} name="freebie" data={freebies} onSelect={this.sync} />
                    )}

                    {type === TYPE.Category && (
                      <SelectModel label={label} name="category" data={categories} />
                    )}
                  </>
                )
              }}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Placement"
              name="placement"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(label, 'placement', 'BL')}
              extra="Freeshipping is at Top-Right, PreOrder is at Top-Left, Clearance is at 100% bottom"
            >
              <Select placeholder="Placement">
                <Option value='TL'>Top Left</Option>
                <Option value='TR'>Top Right</Option>
                <Option value='BR'>Bottom Right</Option>
                <Option value='BL'>Bottom Left</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name="position"
              initialValue={get(label, 'position', 0)}
              extra="Higher the number, higher the position"
            >
              <InputNumber />
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  <FormItem
                    {...formItemLayout}
                    label="Start Date"
                    name="startDate"
                    rules={[{ required: true, message: 'required' }]}
                    initialValue={label.startDate ? moment(label.startDate) : null}
                  >
                    <DatePicker
                      disabled={![TYPE.None, TYPE.Category].includes(getFieldValue('type'))}
                      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      placeholder="Start Date"
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="End Date"
                    name="endDate"
                    rules={[{ required: true, message: 'required' }]}
                    initialValue={label.endDate ? moment(label.endDate) : null}
                    help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
                  >
                    <DatePicker
                      disabled={![TYPE.None, TYPE.Category].includes(getFieldValue('type'))}
                      showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                      placeholder="End Date"
                    />
                  </FormItem>
                </>
              )}
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {(form) => form.getFieldValue('type') === TYPE.None ? (
                <>
                  <FormItem
                    {...formItemLayout}
                    label="Brand"
                    name="brandId"
                    initialValue={label.brandId}
                  >
                    <Select placeholder="Select Brand" showSearch optionFilterProp="children" allowClear>
                      {
                        brands.map((edge) => {
                          const brand = edge.node;
                          return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                        })
                      }
                    </Select>
                  </FormItem>

                  {form.getFieldValue('brandId') && (
                    <FormItem
                      {...formItemLayout}
                      label="Exclude Only Products"
                      extra="These products will be excluded from selected brand"
                      name="excludeProducts"
                      initialValue={get(label, 'excludeProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                    >
                      <SelectProduct
                        placeholder="Exclude Products"
                        mode="multiple"
                        labelInValue
                        filterOption={false}
                        viewer={viewer}
                        importExport={{
                          onImport: (value) => { SelectProduct.updateSelect(form, value, 'excludeProducts'); },
                          listToExport: get(label, 'excludeProducts.edges', [])
                        }}
                        filters={{
                          brand: form.getFieldValue('brandId')
                        }}
                      />
                    </FormItem>
                  )}

                  {!form.getFieldValue('brandId') && (
                    <FormItem
                      {...formItemLayout}
                      label="On Products"
                      name="products"
                      rules={[{required: true, message: 'required' }]}
                      initialValue={get(label, 'products.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                    >
                      <SelectProduct
                        placeholder="On Products"
                        mode="multiple"
                        labelInValue
                        filterOption={false}
                        viewer={viewer}
                        importExport={{
                          onImport: (value) => { SelectProduct.updateSelect(form, value, 'products'); },
                          listToExport: get(label, 'products.edges', [])
                        }}
                      />
                    </FormItem>
                  )}
                </>
              ) : null}
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <FormItem
                  {...formItemLayout}
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'required' }]}
                  initialValue={get(label, 'status', 0) ? 1 : 0}
                >
                  <Select placeholder="Status" disabled={![TYPE.None, TYPE.Category].includes(getFieldValue('type'))} >
                    <Option value={1}>Enabled</Option>
                    <Option value={0}>Disabled</Option>
                  </Select>
                </FormItem>
              )}
            </FormItem>
          </TabPane>

          <TabPane tab="Products" key="products">
            {products.length} products

            {products.map((edge) => {
              const p = edge.node;
              return (
                <div key={p.id}>
                  <img width="50" height="50" src={get(p, 'mainImage.url', '')} alt="" />
                  <a href={`/product/${fromGlobalId(p.id).id}`} target="_blank" rel="noopener noreferrer" >{p.name}</a>
                </div>
              );
            })}
          </TabPane>

          {label.id && (
            <TabPane tab="History" key="history">
              <LabelHistory label={label} viewer={viewer} />
            </TabPane>
          )}

        </Tabs>

      </Form>
    );
  }
}
export default createFragmentContainer(LabelForm, {
  viewer: graphql`
    fragment LabelForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      id
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      bonuses(first: 9999, orderBy: {field: "id", direction: "desc"}) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
          }
        }
      }
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      credits(first: 9999, orderBy: {field: "id", direction: "desc"}) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
          }
        }
      }
      freebies(first: 9999, orderBy: {field: "id", direction: "desc"}) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
          }
        }
      }
      redemptions(first: 9999, orderBy: {field: "id", direction: "desc"}) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
          }
        }
      }
      ...LabelHistory_viewer
      ...SelectProduct_viewer
    }
  `,
});
