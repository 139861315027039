import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { message } from 'antd';
import { get } from 'lodash';

import { RemoveProductImageMutation, UpdateProductMutation } from './mutations';
import ProductForm from './ProductForm';

class ProductView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, onSubmitCompleted, attributeEdges, formValues) => {
    const { enableStartDate, enableEndDate, ...rest } = formValues;

    const values = {
      ...rest,
      partialName: formValues.partialName?.trim(),
      name: formValues.name?.trim(),
      model: formValues.model?.trim(),
      secondModel: formValues.secondModel?.trim(),
      adenCode: formValues.adenCode?.trim(),
      odooCode: formValues.odooCode?.trim(),
      barcode: formValues.barcode?.trim(),
      supersededBy: formValues.supersededBy.map(p => p.key)
    };

    if (formValues.type === 'bundle' && formValues.bundles) {
      values.bundles = formValues.bundles.map(({ id, productId, position, quantity }) => ({
        ...(id ? { id } : {}),
        productId: productId.key,
        position,
        quantity,
      }));
    }

    if (formValues.type === 'configurable' && formValues.configurables) {
      values.configurableAttributes = formValues.configurableAttributes.map(value => value.key);

      values.configurables = formValues.configurables.map(({ productId, position }) => ({
        productId: productId.key,
        position,
      }));
    }

    if (formValues.stockAvailable == null || typeof formValues.convertTo === 'undefined') {
      values.convertTo = null;
    }

    if (formValues.adenCode) {
      values.adenCode = formValues.adenCode.trim();
    }

    if (enableStartDate || enableEndDate) {
      values.enableFromTo = [enableStartDate, enableEndDate];
    }

    const uploadables = {};

    const images = formValues.newImages;
    if (images) {
      const fileList = images.fileList.map(f => f.originFileObj).filter(f => f.size / 1024 / 1024 < 2);

      values.newImages = fileList.map((f, i) => {
        const name = `newImage${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    // make images ready for submission
    if (formValues.images) {
      values.images = Object.keys(values.images).map(key => values.images[key]);
    }

    const files = formValues.newFiles;
    if (files) {
      const fileList = files.fileList.map(f => f.originFileObj).filter(f => f.size / 1024 / 1024 < 15);

      values.newFiles = fileList.map((f, i) => {
        const name = `newFiles${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    // make attributes ready for submission
    const codes = Object.keys(values.attributes).filter(code => values.attributes[code]);

    const attributes = {};
    codes.filter(code => attributeEdges.find((edge) => {
      const attr = edge.node;
      return attr.code === code;
    })).forEach((code) => {
      attributes[code] = values.attributes[code];
    });

    values.attributes = JSON.stringify(attributes);

    // make `undefined` stockAvailable to be null
    if (typeof formValues.stockAvailable === 'undefined') {
      values.stockAvailable = null;
    }

    if (formValues.related) {
      values.related = formValues.related.map(({ key }) => key);
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
        onSubmitCompleted();
      },
    };

    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }

    UpdateProductMutation.commit(mutation);
  }

  deleteImage = (image, form) => {
    const product = get(this.props.viewer, 'products.edges[0].node', {});
    RemoveProductImageMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: image.id } },
      parent: product,
      viewer: this.props.viewer,
      onCompleted: () => {
        if (image.id === form.getFieldValue("mainImageId")) {
          form.setFieldsValue({
            mainImageId: null,
          });
        }
        if (image.id === form.getFieldValue("feedImageId")) {
          form.setFieldsValue({
            feedImageId: null,
          });
        }
      }
    });
  }

  render() {
    const { match, router, viewer } = this.props;
    const product = get(viewer, 'products.edges[0].node', {});

    return (
      <div>
        <Helmet title={product.name} />
        <h1>{product.name}</h1>
        <ProductForm
          key={product.id}
          match={match}
          viewer={viewer}
          product={product}
          deleteImage={this.deleteImage}
          onSubmit={this.handleSubmit}
          router={router}
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  ProductView,
  {
    viewer: graphql`
    fragment ProductView_viewer on Admin {
      ...ProductForm_viewer
      products(first: 1, ids: $ids) {
        edges {
          node {
            ...ProductFragment_product @relay(mask: false)
          }
        }
      }
    }
  `,
  },
);
