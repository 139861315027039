/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExportBrandsInput = {|
  filters?: ?$ReadOnlyArray<?FilterBy>
|};
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type ExportBrandsMutationVariables = {|
  input: ExportBrandsInput
|};
export type ExportBrandsMutationResponse = {|
  +exportBrands: ?{|
    +result: ?any
  |}
|};
export type ExportBrandsMutation = {|
  variables: ExportBrandsMutationVariables,
  response: ExportBrandsMutationResponse,
|};
*/


/*
mutation ExportBrandsMutation(
  $input: ExportBrandsInput!
) {
  exportBrands(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ExportBrandsPayload",
    "kind": "LinkedField",
    "name": "exportBrands",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportBrandsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportBrandsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "521ff0b3ba4ba58faa1ebba342e0878d",
    "id": null,
    "metadata": {},
    "name": "ExportBrandsMutation",
    "operationKind": "mutation",
    "text": "mutation ExportBrandsMutation(\n  $input: ExportBrandsInput!\n) {\n  exportBrands(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '51dce1ddd15da1fa1b5ab5f48c99ab28';

module.exports = node;
