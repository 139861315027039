import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdatePurchaseLimitMutation($input: UpdatePurchaseLimitInput!) {
    updatePurchaseLimit(input:$input) {
      purchaseLimit {
        startDate
        endDate
        product {
          id
          name
          sku
          mainImage {
            id
            url
          }
          status
        }
        limit
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
