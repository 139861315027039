import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';
import { message } from 'antd';

import { RemoveRedemptionMutation, UpdateRedemptionMutation } from './mutations';
import RedemptionForm from './RedemptionForm';

class RedemptionView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      redemptions: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      images: formValues.images.map((i) => i.id ? { id: i.id, file: i.file } : { file: i.file }),
      redeemables: formValues.redeemables.map(p => p.key),
    };

    const uploadables = {};
    get(values, 'images', []).forEach((image, i) => {
      const { file } = image;
      if (file) {
        const name = `image${i}`;

        values.images[i].file = name;
        uploadables[name] = file[0].originFileObj;
      }
    });

    if (!isEmpty(formValues.brandIds)) {
      values.excludeProducts = formValues.excludeProducts.map(p => p.key);
    } else {
      values.onProducts = formValues.onProducts.map(p => p.key);
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateRedemptionMutation.commit(mutation);
  }

  remove = (redemption) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: redemption.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/promotion/redemption');
      },
    };
    RemoveRedemptionMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const redemption = get(viewer, 'redemptions.edges[0].node', {});

    return (
      <div>
        <Helmet title={redemption.name} />
        <h1>{redemption.name}</h1>
        <RedemptionForm
          key={redemption.id}
          match={match}
          viewer={viewer}
          redemption={redemption}
          remove={this.remove}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(RedemptionView, {
  viewer: graphql`
    fragment RedemptionView_viewer on Admin {
      id
      redemptions(first: 1, ids: $ids) {
        edges {
          node {
            id
            brandIds
            name
            startDate
            endDate
            status
            onProducts(first: 999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            redeemables(first: 999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            excludeProducts(first: 999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            images (first: 999) {
              edges {
                node {
                  id
                  imageUrl
                }
              }
            }
            products(first: 999) {
              edges {
                node {
                  id
                  name
                  sku
                  status
                }
              }
            }
            labels (first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...RedemptionHistory_redemption
          }
        }
      }
      ...RedemptionForm_viewer
    }
  `,
});
