import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddRedemptionMutation($input: AddRedemptionInput!) {
    addRedemption(input:$input) {
      redemptionEdge {
        cursor
        node {
          id
          name
          startDate
          endDate
          images (first: 999) {
            edges {
              node {
                id
                imageUrl
              }
            }
          }
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addRedemption', edgeName: 'redemptionEdge', connectionKey: 'RedemptionList_redemptions' }),
});

export default { commit };
