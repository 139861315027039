import React, { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { useRelayEnvironment } from 'react-relay';
import PropTypes from 'prop-types';
import { get, groupBy, isEmpty, orderBy, uniq } from 'lodash';
import { Button, Form, Input, message, Modal, Select, Tag } from 'antd';
import { Editor } from '~/components/form';
import { checkEmails } from '~/helper';
import { getBranches } from './SalesReport';
import Status from './Status';

import { SendSalesReportEmailMutation } from './mutations';

const getTemplate = (stores, selectedRows) => {
  let tableRows = `
    <tr style="height: 22.3958px;">
      <td style="width: 10%; height: 22.3958px;">&nbsp;</td>
      <td style="width: 20%; height: 22.3958px;">&nbsp;</td>
      <td style="width: 20%; height: 22.3958px;">&nbsp;</td>
      <td style="width: 50%; height: 22.3958px;">&nbsp;</td>
    </tr>
  `;

  if (!isEmpty(selectedRows)) {
    tableRows = selectedRows.map(({ node }) => {
      const link = `${window.location.origin}/sales/order/${node.id}`;
      const status = renderToString(<Status node={node} />);
      const branches = getBranches(stores, node.shipping);

      return (
        `<tr style="height: 22.3958px;">
          <td style="width: 10%; height: 22.3958px;">
            <a title="${node.name}" href="${link}" target="_blank" rel="noopener noreferrer">
              ${node.name}
            </a>
          </td>
          <td style="width: 20%; height: 22.3958px;">
            ${status}
          </td>
          <td style="width: 20%; height: 22.3958px;">
            ${branches}
          </td>
          <td style="width: 50%; height: 22.3958px;">&nbsp;</td>
        </tr>`
      )
    }).join("\n");
  }

  return (
    `<div>
      <p>Please give a brief summary of why the following orders have a long processing time:</p>
      <table style="border-collapse: collapse; width: 99.9969%; height: 44.7916px;" border="1">
        <tbody>
          <tr style="height: 22.3958px;">
            <td style="width: 10%; height: 22.3958px;">Order Name</td>
            <td style="width: 20%; height: 22.3958px;">Status</td>
            <td style="width: 20%; height: 22.3958px;">Branches</td>
            <td style="width: 50%; height: 22.3958px;">Explanation</td>
          </tr>
          ${tableRows}
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>Best Regards</p>
      <p>Jason Bey<br />Director</p>
    </div>`
  )
}

const getEmailDetails = (stores, selectedRows) => {
  const locations = selectedRows.flatMap(({ node }) => {
    return get(node, 'shipping.locations') || ['05'];
  });

  let list = uniq(locations).flatMap((l) => {
    const { node: store } = stores.find(({node: s}) => s.branchCode === l);

    const sm = get(store, 'stateManager.emails', []).map((email) => (
      { email, state: [store.state] }
    ));

    const m = get(store, 'managerEmails', []).map((email) => (
      { email, location: [store.name] }
    ));

    const staff = get(store, 'emails', []).map((email) => (
      { email, location: [store.name] }
    ));

    return sm.concat(m).concat(staff);
  });

  list = groupBy(list, ({email}) => email);

  const emailDetails = Object.keys(list).map((email) => {
    let state = list[email].flatMap((l) => l.state).filter(i => i);
    let location = list[email].flatMap((l) => l.location).filter(i => i);
    state = uniq(state);
    location = uniq(location);

    return { email, state, location };
  });

  return orderBy(emailDetails, [
    (o) => o.state[0],
    (o) => o.location[0],
  ]);
}

const SendEmail = (props) => {
  const { locations, stores, selectedRows } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const environment = useRelayEnvironment();

  const emailDetails = getEmailDetails(stores, selectedRows);
  const emails = emailDetails.map(({ email }) => email);

  const disabled = locations.length >= 2;

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({ to: emails });
    }
  }, [isModalVisible]);

  const handleOk = () => {
    form.submit();
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const onFinish = (input) => {
    const mutation = {
      environment,
      variables: { input },
      onCompleted: () => {
        message.success('Sent');
      }
    };

    SendSalesReportEmailMutation.commit(mutation);
  };

  return (
    <>
      <Button
        type="primary"
        style={{ display: 'block' }}
        onClick={() => { setIsModalVisible(true); }}
        disabled={disabled}
      >
        Send Email
      </Button>
      {disabled && (
        <div className="ant-form-item-extra">
          Unable to send emails to multiple stores
        </div>
      )}
      <Modal
        title="New Email"
        visible={isModalVisible}
        okText="Send"
        onOk={handleOk}
        onCancel={handleCancel}
        width={760}
        destroyOnClose
      >
        <Form id="salesReportEmailForm" form={form} onFinish={onFinish}>
          <Form.Item
            label="To"
            name="to"
            rules={[
              { required: true, message: 'required' },
              { validator: checkEmails },
            ]}
          >
            <Select
              placeholder="Recipients"
              mode="tags"
              allowClear
              getPopupContainer={() => document.getElementById("salesReportEmailForm")}
              tagRender={({ label, value, closable, onClose }) => {
                const detail = emailDetails.find((i) => i.email === value);
                const extra = detail ? `(${detail.state.concat(detail.location).join(", ")})` : null;

                return (
                  <Tag closable={closable} onClose={onClose}>
                    {label} {extra}
                  </Tag>
                )
              }}
            />
          </Form.Item>
          <Form.Item
            label="Subject"
            name="subject"
            initialValue="Orders with long processing time"
            rules={[
              { required: false, message: 'required' },
            ]}
          >
            <Input placeholder="Subject" />
          </Form.Item>

          <Editor
            name="body"
            editorState={getTemplate(stores, selectedRows)}
          />
        </Form>
      </Modal>
    </>
  );
}

SendEmail.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
};

SendEmail.defaultProps = {
  selectedRows: [],
}

export default SendEmail;
