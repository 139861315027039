import React from 'react';
import GenPromoCardPdf from './GenPromoCardPdf';

const bulkActions = [
  {
    title: 'Gen A6 Promo Card',
    node: <GenPromoCardPdf pageSize="a6" />
  },
  {
    title: 'Gen A4 Promo Card',
    node: <GenPromoCardPdf pageSize="a4" />
  },
  {
    title: 'Gen A7 Promo Card',
    node: <GenPromoCardPdf pageSize="a7" />
  },
  {
    title: 'Gen Price Tag',
    node: <GenPromoCardPdf pageSize="price_tag" />
  },
  {
    title: 'Gen Price Down Card',
    node: <GenPromoCardPdf pageSize="price_down" />
  },
  {
    title: 'Gen A4 Hot Price',
    node: <GenPromoCardPdf pageSize="a4_hot_price" />
  },
  {
    title: 'Gen A7 Hot Price',
    node: <GenPromoCardPdf pageSize="a7_hot_price" />
  },
  {
    title: 'Gen A4 Grand Open',
    node: <GenPromoCardPdf pageSize="a4_grandopen" />
  },
  {
    title: 'Gen A7 Grand Open',
    node: <GenPromoCardPdf pageSize="a7_grandopen" />
  }
];

export default bulkActions;
