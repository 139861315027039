import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Papa from "papaparse";
import { Button, message, Upload, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';

const { Option } = Select;

const headers = {
  tag_no: '187440',
};

const csvContent = `data:text/csv;charset=utf-8,${ Object.keys(headers).join(",") }\n${ Object.values(headers).join(",") }`;

const encodedUri = encodeURI(csvContent);

class SearchProduct extends React.Component {
  static propTypes = {
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      getProductSuggestion: PropTypes.arrayOf(PropTypes.shape({
        product_name: PropTypes.string.isRequired,
        tag_no: PropTypes.string.isRequired,
      })),
    }).isRequired,
    tagNos: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSearchText: PropTypes.func.isRequired,
  }

  debouncedHandleSearch = debounce((query) => {
    if (query.length >= 3) {
      this.props.relay.refetch({ query });
    }
  }, 100);

  handleClear = () => {
    this.props.setSearchText([]);
  }

  handleFileUpload = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const tags = result.data.map((row) => row.tag_no).filter(Boolean);

        this.props.setSearchText(tags);

        message.success("Products imported successfully");
      },
      error: (error) => {
        message.error(error);
      },
    });

    return false;
  };

  render() {
    const products = get(this.props.viewer, "getProductSuggestion", []);

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Select
          mode="multiple"
          allowClear
          onClear={this.handleClear}
          style={{ width: '50%', display: 'block', marginTop: '10px' }}
          placeholder="Search Products by Name or Tag Number..."
          filterOption={false}
          value={this.props.tagNos}
          onChange={(selected) => {
            this.props.setSearchText(selected);
          }}
          onSearch={(query) => {
            if (query.length >= 3) {
              this.debouncedHandleSearch(query);
            }
          }}
        >
          {products.map((product) => (
            <Option key={product.tag_no + product.product_name} value={product.tag_no}>
              {product.tag_no ? `${product.product_name} (${product.tag_no})` : product.product_name}
            </Option>
          ))}
        </Select>

        <div style={{display: 'inline-block'}}>
          <a href={encodedUri} download="template.csv" style={{margin: '0 10px'}} >Template</a>

          <Upload
            className="importProduct"
            accept=".csv"
            beforeUpload={(file) => this.handleFileUpload(file)}
            listType="picture"
            maxCount={1}
          >
            <Button>
              <UploadOutlined /> Import
            </Button>
          </Upload>
        </div>
      </div>
    );
  }
}

export default createRefetchContainer(SearchProduct, {
    viewer: graphql`
      fragment SearchProduct_viewer on Admin @argumentDefinitions(
        query: {type: "String", defaultValue: ""}
      ) {
        getProductSuggestion(query: $query)
      }
    `
  },
    graphql`
    query SearchProductRefetchQuery($query: String) {
      viewer {
        ...SearchProduct_viewer @arguments(query: $query)
      }
    }
  `
  );
