import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Divider, Form, Input, Popconfirm, Select, Space, Tabs, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { ShowLabel } from '~/components/promotion/label';
import { fromGlobalId } from '~/helper';
import RedemptionHistory from './RedemptionHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class RedemptionForm extends FormBase {
  static propTypes = {
    redemption: PropTypes.shape({
      id: PropTypes.string,
      brandIds: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
      excludeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    redemption: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  isBrandIdsEmpty = ({ getFieldValue }) => {
    const brandIds = getFieldValue('brandIds') || [];

    return isEmpty(brandIds);
  }

  render() {
    const { match, viewer } = this.props;
    const redemption = get(this.props, 'redemption', {});
    const products = get(redemption.products, 'edges', []);
    const onProducts = get(redemption.onProducts, 'edges', []);
    const images = get(redemption.images, 'edges', []);
    const brands = get(viewer, 'brands.edges', []);

    const initialValue = {
      images: images.length ? images.slice(0).map(({ node }) => node) : [{}],
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values, { setLoading: this.setLoading }); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            {this.renderSaveButton()}
            {redemption.id && (
            <React.Fragment>
              <Popconfirm
                title="Are you sure delete this redemption? Related product images will be deleted as well!"
                onConfirm={() => { this.props.remove(redemption); }}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={this.shouldDisableBtn()}>Delete</Button>
              </Popconfirm>
              <a href={`/api/promotion/download?id=${redemption.id}`} target="_blank" rel="noopener noreferrer">Download</a>
            </React.Fragment>
            )}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={redemption.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
            >
              <FormItem
                name="name"
                rules={[{ required: true, message: 'required' }]}
                initialValue={redemption.name}
              >
                <Input placeholder="Name" />
              </FormItem>

              <ShowLabel labels={redemption.labels} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={redemption.startDate ? moment(redemption.startDate) : null}
            >
              <DatePicker placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={redemption.endDate ? moment(redemption.endDate) : null}
            >
              <DatePicker placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Brands"
              name="brandIds"
              initialValue={redemption.brandIds}
            >
              <Select
                placeholder="Select Brand"
                showSearch
                optionFilterProp="children"
                mode="multiple"
                allowClear
              >
                {
                  brands.map((edge) => {
                    const brand = edge.node;
                    return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                  })
                }
              </Select>
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {(form) => !this.isBrandIdsEmpty(form) ? (
                <FormItem
                  {...formItemLayout}
                  label="Exclude Only Products"
                  extra="These products will be excluded from selected brands"
                  name="excludeProducts"
                  initialValue={get(redemption, 'excludeProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                >
                  <SelectProduct
                    placeholder="Exclude Products"
                    mode="multiple"
                    labelInValue
                    filterOption={false}
                    viewer={viewer}
                    importExport={{
                      onImport: (value) => { SelectProduct.updateSelect(form, value, 'excludeProducts'); },
                      listToExport: get(redemption, 'excludeProducts.edges', [])
                    }}
                    filters={{
                      brands: form.getFieldValue('brandIds')
                    }}
                  />
                </FormItem>
              ) : null}
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {(form) => this.isBrandIdsEmpty(form) ? (
                <FormItem
                  {...formItemLayout}
                  label="Common Images On Products"
                  name="onProducts"
                  initialValue={get(redemption, 'onProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
                >
                  <SelectProduct
                    placeholder="On Products"
                    mode="multiple"
                    labelInValue
                    filterOption={false}
                    viewer={viewer}
                    importExport={{
                      onImport: (value) => { SelectProduct.updateSelect(form, value, 'onProducts'); },
                      listToExport: get(redemption, 'onProducts.edges', [])
                    }}
                  />
                </FormItem>
              ) : null}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Redeemables"
              name="redeemables"
              initialValue={get(redemption, 'redeemables.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
              extra="Redeemable Products"
            >
              <SelectProduct
                placeholder="Redeemables"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
              />
            </FormItem>

            {redemption.id && (
            <FormItem
              {...formItemLayout}
              label="Images"
              extra="Images show across all related products"
            >
              <Form.List name="images" initialValue={initialValue.images}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, i) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["images", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <FormItem
                          label={`Image ${i}`}
                        >
                          <Space align="baseline">
                            <FormItem noStyle shouldUpdate>
                              {({ getFieldValue }) => {
                                const imageUrl = getFieldValue(["images", name, "imageUrl"]);
                                return imageUrl ? (
                                  <img alt="" src={imageUrl} width="120" height="120" />
                                ) : null
                              }}
                            </FormItem>

                            <MinusCircleOutlined
                              style={{ cursor: 'pointer' }}
                              disabled={fields.length === 1}
                              onClick={() => remove(name)}
                            />

                            <FormItem
                              name={[name, "file"]}
                              valuePropName="fileList"
                              getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                  return e;
                                }
                                return e && e.fileList;
                              }}
                            >
                              <Upload
                                accept="image/gif,image/png,image/jpeg"
                                beforeUpload={() => false}
                                listType="picture"
                              >
                                <Button>
                                  <UploadOutlined /> Upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Space>
                        </FormItem>

                        <Divider />
                      </div>
                    ))}
                    <Button onClick={() => add()}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(redemption, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          <TabPane tab="Products" key="products">
            {products.length} products

            {products.map((edge) => {
              const p = edge.node;
              return (
                <div key={p.id}>
                  <a href={`/product/${fromGlobalId(p.id).id}`} target="_blank" rel="noopener noreferrer" >{p.name}</a>
                </div>
              );
            })}

            <Divider />
            Common images on {onProducts.length} products
            {onProducts.map((edge) => {
              const p = edge.node;
              return (
                <div key={p.id}>
                  <a href={`/product/${fromGlobalId(p.id).id}`} target="_blank" rel="noopener noreferrer" >{p.name}</a>
                </div>
              );
            })}
          </TabPane>

          {redemption.id && (
            <TabPane tab="History" key="history">
              <RedemptionHistory redemption={redemption} viewer={viewer} />
            </TabPane>
          )}
        </Tabs>

      </Form>
    );
  }
}
export default createFragmentContainer(RedemptionForm, {
  viewer: graphql`
    fragment RedemptionForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      ...SelectProduct_viewer
      ...RedemptionHistory_viewer
    }
  `,
});
