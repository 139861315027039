import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddCouponMutation($input: AddCouponInput!) {
    addCoupon(input:$input) {
      couponEdge {
        cursor
        node {
          id
          name
          codes
          content {
            discountType
            discountAmount
            conditions
            actions
            removalDate
            removalProducts
            additionDate
            additionProducts
          }
          usageLimit
          usagePerCustomer
          timesUsed
          startDate
          endDate
          status
          showLabel
          autoApply
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addCoupon', edgeName: 'couponEdge', connectionKey: 'CouponList_coupons' }),
});

export default { commit };
