import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation RemoveSubcategoryMutation($input: RemoveSubcategoryInput!) {
    removeSubcategory(input:$input) {
      removedSubcategoryId
    }
  }
`;

const commit = buildMutation(mutation, {
});
export default { commit };
