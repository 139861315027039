/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ByMinMaxReport_reportFragment_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type ByMinMaxReportRefetchQueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
  orderBy?: ?OrderBy,
  id: string,
|};
export type ByMinMaxReportRefetchQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: ByMinMaxReport_reportFragment_viewer$ref
  |}
|};
export type ByMinMaxReportRefetchQuery = {|
  variables: ByMinMaxReportRefetchQueryVariables,
  response: ByMinMaxReportRefetchQueryResponse,
|};
*/


/*
query ByMinMaxReportRefetchQuery(
  $filterBy: [FilterBy]
  $orderBy: OrderBy
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...ByMinMaxReport_reportFragment_viewer
    id
  }
}

fragment ByMinMaxReport_reportFragment_viewer on Admin {
  minMaxReport(count: 5000, filterBy: $filterBy, orderBy: $orderBy)
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ByMinMaxReportRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ByMinMaxReport_reportFragment_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ByMinMaxReportRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "count",
                    "value": 5000
                  },
                  {
                    "kind": "Variable",
                    "name": "filterBy",
                    "variableName": "filterBy"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "orderBy"
                  }
                ],
                "kind": "ScalarField",
                "name": "minMaxReport",
                "storageKey": null
              }
            ],
            "type": "Admin",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3fd5068493125b4a24299e87bd1d1f1",
    "id": null,
    "metadata": {},
    "name": "ByMinMaxReportRefetchQuery",
    "operationKind": "query",
    "text": "query ByMinMaxReportRefetchQuery(\n  $filterBy: [FilterBy]\n  $orderBy: OrderBy\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ByMinMaxReport_reportFragment_viewer\n    id\n  }\n}\n\nfragment ByMinMaxReport_reportFragment_viewer on Admin {\n  minMaxReport(count: 5000, filterBy: $filterBy, orderBy: $orderBy)\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43433b6b7d6cb3ba45eafaf0db572bed';

module.exports = node;
