/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdateStoresBdmEmailsQueryVariables = {||};
export type BulkUpdateStoresBdmEmailsQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +brands: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +stores: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +state: ?string,
        |}
      |}>
    |},
  |}
|};
export type BulkUpdateStoresBdmEmailsQuery = {|
  variables: BulkUpdateStoresBdmEmailsQueryVariables,
  response: BulkUpdateStoresBdmEmailsQueryResponse,
|};
*/


/*
query BulkUpdateStoresBdmEmailsQuery {
  viewer {
    id
    brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
      edges {
        node {
          id
          name
        }
      }
    }
    stores(first: 999, orderBy: {field: "name", direction: "asc"}) {
      edges {
        node {
          id
          name
          state
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "direction": "asc",
    "field": "name"
  }
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Admin",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 9999
          },
          (v1/*: any*/)
        ],
        "concreteType": "BrandConnection",
        "kind": "LinkedField",
        "name": "brands",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Brand",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "brands(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 999
          },
          (v1/*: any*/)
        ],
        "concreteType": "StoreConnection",
        "kind": "LinkedField",
        "name": "stores",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "stores(first:999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkUpdateStoresBdmEmailsQuery",
    "selections": (v3/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BulkUpdateStoresBdmEmailsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ffe79a14a13335ad3e7432e440e4f68e",
    "id": null,
    "metadata": {},
    "name": "BulkUpdateStoresBdmEmailsQuery",
    "operationKind": "query",
    "text": "query BulkUpdateStoresBdmEmailsQuery {\n  viewer {\n    id\n    brands(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    stores(first: 999, orderBy: {field: \"name\", direction: \"asc\"}) {\n      edges {\n        node {\n          id\n          name\n          state\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3f19f86cca0c922ffe4a29c5a1296b01';

module.exports = node;
