/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CarouselImageNew_viewer$ref = any;
export type routes_CarouselImageNew_QueryVariables = {||};
export type routes_CarouselImageNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CarouselImageNew_viewer$ref
  |}
|};
export type routes_CarouselImageNew_Query = {|
  variables: routes_CarouselImageNew_QueryVariables,
  response: routes_CarouselImageNew_QueryResponse,
|};
*/


/*
query routes_CarouselImageNew_Query {
  viewer {
    ...CarouselImageNew_viewer
    id
  }
}

fragment CarouselImageNew_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CarouselImageNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CarouselImageNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_CarouselImageNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60b8153da17459d02280d1ccca71de5b",
    "id": null,
    "metadata": {},
    "name": "routes_CarouselImageNew_Query",
    "operationKind": "query",
    "text": "query routes_CarouselImageNew_Query {\n  viewer {\n    ...CarouselImageNew_viewer\n    id\n  }\n}\n\nfragment CarouselImageNew_viewer on Admin {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'e453796b51c7e10e2c1ee6b68d702f20';

module.exports = node;
