import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, message } from 'antd';

export default class ExportSalesOrder extends React.Component {
  static propTypes = {
    gridApi: PropTypes.shape({
      filterManager: PropTypes.shape({
        getFilterModel: PropTypes.func,
      }),
      getDataAsCsv: PropTypes.func,
    }),
  }

  static defaultProps = {
    gridApi: null,
  }

  genCsv = () => {
    const { gridApi } = this.props;

    if (!gridApi) {
      message.error('grid is not ready');
      return;
    }

    const filterModel = gridApi.filterManager.getFilterModel();

    if (Object.keys(filterModel).length === 0) {
      message.error('Apply a filter first');
      return;
    }

    const data = gridApi.getDataAsCsv();
    const csv = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;

    const link = document.createElement("a");
    link.setAttribute("href", csv);

    const timeNow = moment().format('YYYY-MM-DD-HH:mm:ss');

    link.setAttribute("download", `Export-Orders-${timeNow}.csv`);

    document.body.appendChild(link);

    link.click();
  }


  render() {
    return (
      <Button
        onClick={this.genCsv}
      >
        Download
      </Button>
    )
  }
}
