import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddCmsBannerMutation($input: AddCmsBannerInput!) {
    addCmsBanner(input:$input) {
      cmsBannerEdge {
        cursor
        node {
          id
          title
          link
          homeBig
          homeSmall
          categoryBig
          categorySmall
          startDate
          endDate
          urlPaths
          position
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
