/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdjacentBannerHistory_adjacentBanner$ref = any;
export type UpdateAdjacentBannerInput = {|
  categoryIds?: ?$ReadOnlyArray<?string>,
  endDate?: ?string,
  id: string,
  image?: ?any,
  link?: ?string,
  name?: ?string,
  placement?: ?string,
  position?: ?number,
  productId?: ?string,
  startDate?: ?string,
  status?: ?boolean,
  subcategoryIds?: ?$ReadOnlyArray<?string>,
|};
export type UpdateAdjacentBannerMutationVariables = {|
  input: UpdateAdjacentBannerInput
|};
export type UpdateAdjacentBannerMutationResponse = {|
  +updateAdjacentBanner: ?{|
    +adjacentBanner: ?{|
      +name: ?string,
      +product: ?{|
        +id: string,
        +name: ?string,
        +mainImage: ?{|
          +id: string,
          +url: ?string,
        |},
      |},
      +link: ?string,
      +imageUrl: ?string,
      +placement: ?string,
      +position: ?number,
      +startDate: ?string,
      +endDate: ?string,
      +status: ?boolean,
      +categories: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
      +subcategories: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
      +$fragmentRefs: AdjacentBannerHistory_adjacentBanner$ref,
    |}
  |}
|};
export type UpdateAdjacentBannerMutation = {|
  variables: UpdateAdjacentBannerMutationVariables,
  response: UpdateAdjacentBannerMutationResponse,
|};
*/


/*
mutation UpdateAdjacentBannerMutation(
  $input: UpdateAdjacentBannerInput!
) {
  updateAdjacentBanner(input: $input) {
    adjacentBanner {
      name
      product {
        id
        name
        mainImage {
          id
          url
        }
      }
      link
      imageUrl
      placement
      position
      startDate
      endDate
      status
      categories(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999) {
        edges {
          node {
            id
            name
          }
        }
      }
      ...AdjacentBannerHistory_adjacentBanner
      id
    }
  }
}

fragment AdjacentBannerHistory_adjacentBanner on AdjacentBanner {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "mainImage",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placement",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 999
    }
  ],
  "concreteType": "CategoryConnection",
  "kind": "LinkedField",
  "name": "categories",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CategoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "categories(first:999)"
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 9999
    }
  ],
  "concreteType": "SubcategoryConnection",
  "kind": "LinkedField",
  "name": "subcategories",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubcategoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Subcategory",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "subcategories(first:9999)"
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdjacentBannerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdjacentBannerPayload",
        "kind": "LinkedField",
        "name": "updateAdjacentBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdjacentBanner",
            "kind": "LinkedField",
            "name": "adjacentBanner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdjacentBannerHistory_adjacentBanner"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdjacentBannerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdjacentBannerPayload",
        "kind": "LinkedField",
        "name": "updateAdjacentBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdjacentBanner",
            "kind": "LinkedField",
            "name": "adjacentBanner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "AdjacentBannerHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9b2f37f36803b24f8046330d8a4d01b",
    "id": null,
    "metadata": {},
    "name": "UpdateAdjacentBannerMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdjacentBannerMutation(\n  $input: UpdateAdjacentBannerInput!\n) {\n  updateAdjacentBanner(input: $input) {\n    adjacentBanner {\n      name\n      product {\n        id\n        name\n        mainImage {\n          id\n          url\n        }\n      }\n      link\n      imageUrl\n      placement\n      position\n      startDate\n      endDate\n      status\n      categories(first: 999) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      subcategories(first: 9999) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      ...AdjacentBannerHistory_adjacentBanner\n      id\n    }\n  }\n}\n\nfragment AdjacentBannerHistory_adjacentBanner on AdjacentBanner {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '809261c3797aa53722d8b1dbf3f35f07';

module.exports = node;
