/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GiftCardUsageView_viewer$ref = any;
export type routes_GiftCardUsageView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_GiftCardUsageView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: GiftCardUsageView_viewer$ref
  |}
|};
export type routes_GiftCardUsageView_Query = {|
  variables: routes_GiftCardUsageView_QueryVariables,
  response: routes_GiftCardUsageView_QueryResponse,
|};
*/


/*
query routes_GiftCardUsageView_Query(
  $ids: [ID]
) {
  viewer {
    ...GiftCardUsageView_viewer
    id
  }
}

fragment GiftCardUsageForm_viewer on Admin {
  giftCards(first: 999) {
    edges {
      node {
        id
        name
        amount
        expiryMonths
      }
    }
  }
  ...GiftCardUsageHistory_viewer
  ...SelectOrder_viewer
}

fragment GiftCardUsageHistory_giftCardUsage on GiftCardUsage {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment GiftCardUsageHistory_viewer on Admin {
  id
}

fragment GiftCardUsageView_viewer on Admin {
  ...GiftCardUsageForm_viewer
  giftCardUsages(first: 1, ids: $ids) {
    edges {
      node {
        id
        code
        amount
        remainingAmount
        insertedAt
        expiry
        giftCard {
          id
          name
        }
        fromOrder {
          id
          name
        }
        onOrders(first: 999) {
          edges {
            node {
              id
              amount
              order {
                id
                name
              }
            }
          }
        }
        status
        ...GiftCardUsageHistory_giftCardUsage
      }
    }
  }
}

fragment SelectOrder_viewer on Admin {
  searchOrders: salesOrders(first: 20, query: "") {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_GiftCardUsageView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GiftCardUsageView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_GiftCardUsageView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GiftCardConnection",
            "kind": "LinkedField",
            "name": "giftCards",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GiftCardEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GiftCard",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expiryMonths",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "giftCards(first:999)"
          },
          (v2/*: any*/),
          {
            "alias": "searchOrders",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "concreteType": "SalesOrderConnection",
            "kind": "LinkedField",
            "name": "salesOrders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrderEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrder",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "salesOrders(first:20,query:\"\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "GiftCardUsageConnection",
            "kind": "LinkedField",
            "name": "giftCardUsages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GiftCardUsageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GiftCardUsage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remainingAmount",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expiry",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GiftCard",
                        "kind": "LinkedField",
                        "name": "giftCard",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesOrder",
                        "kind": "LinkedField",
                        "name": "fromOrder",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v1/*: any*/),
                        "concreteType": "SpentOnOrderConnection",
                        "kind": "LinkedField",
                        "name": "onOrders",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SpentOnOrderEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SpentOnOrder",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SalesOrder",
                                    "kind": "LinkedField",
                                    "name": "order",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "onOrders(first:999)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "HistoryConnection",
                        "kind": "LinkedField",
                        "name": "histories",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HistoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "History",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "changes",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "user",
                                    "storageKey": null
                                  },
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "histories(first:10)"
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "GiftCardUsageHistory_histories",
                        "kind": "LinkedHandle",
                        "name": "histories"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9d4f7f3ab7807ce7cc7d97b5e9378ad",
    "id": null,
    "metadata": {},
    "name": "routes_GiftCardUsageView_Query",
    "operationKind": "query",
    "text": "query routes_GiftCardUsageView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...GiftCardUsageView_viewer\n    id\n  }\n}\n\nfragment GiftCardUsageForm_viewer on Admin {\n  giftCards(first: 999) {\n    edges {\n      node {\n        id\n        name\n        amount\n        expiryMonths\n      }\n    }\n  }\n  ...GiftCardUsageHistory_viewer\n  ...SelectOrder_viewer\n}\n\nfragment GiftCardUsageHistory_giftCardUsage on GiftCardUsage {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment GiftCardUsageHistory_viewer on Admin {\n  id\n}\n\nfragment GiftCardUsageView_viewer on Admin {\n  ...GiftCardUsageForm_viewer\n  giftCardUsages(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        code\n        amount\n        remainingAmount\n        insertedAt\n        expiry\n        giftCard {\n          id\n          name\n        }\n        fromOrder {\n          id\n          name\n        }\n        onOrders(first: 999) {\n          edges {\n            node {\n              id\n              amount\n              order {\n                id\n                name\n              }\n            }\n          }\n        }\n        status\n        ...GiftCardUsageHistory_giftCardUsage\n      }\n    }\n  }\n}\n\nfragment SelectOrder_viewer on Admin {\n  searchOrders: salesOrders(first: 20, query: \"\") {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6c52613d968e6cdd62e7703697d89a0';

module.exports = node;
