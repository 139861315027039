/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadPromoCardImageInput = {|
  image: any
|};
export type UploadPromoCardImageMutationVariables = {|
  input: UploadPromoCardImageInput
|};
export type UploadPromoCardImageMutationResponse = {|
  +uploadPromoCardImage: ?{|
    +promoCard: ?{|
      +images: ?$ReadOnlyArray<?string>
    |}
  |}
|};
export type UploadPromoCardImageMutation = {|
  variables: UploadPromoCardImageMutationVariables,
  response: UploadPromoCardImageMutationResponse,
|};
*/


/*
mutation UploadPromoCardImageMutation(
  $input: UploadPromoCardImageInput!
) {
  uploadPromoCardImage(input: $input) {
    promoCard {
      images
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UploadPromoCardImagePayload",
    "kind": "LinkedField",
    "name": "uploadPromoCardImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PromoCard",
        "kind": "LinkedField",
        "name": "promoCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "images",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadPromoCardImageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadPromoCardImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6a4b643e2df50db2c5be472f2d45114",
    "id": null,
    "metadata": {},
    "name": "UploadPromoCardImageMutation",
    "operationKind": "mutation",
    "text": "mutation UploadPromoCardImageMutation(\n  $input: UploadPromoCardImageInput!\n) {\n  uploadPromoCardImage(input: $input) {\n    promoCard {\n      images\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa29949b2cf4511bb8bdb49490f1dc1a';

module.exports = node;
