/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StoreForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StoreNew_viewer$ref: FragmentReference;
declare export opaque type StoreNew_viewer$fragmentType: StoreNew_viewer$ref;
export type StoreNew_viewer = {|
  +id: string,
  +$fragmentRefs: StoreForm_viewer$ref,
  +$refType: StoreNew_viewer$ref,
|};
export type StoreNew_viewer$data = StoreNew_viewer;
export type StoreNew_viewer$key = {
  +$data?: StoreNew_viewer$data,
  +$fragmentRefs: StoreNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StoreForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c07a560d69df0c3045e03fce1619c47c';

module.exports = node;
