import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { Button, message, Popconfirm } from 'antd';

import { EmptyCategoryMutation, UpdateCategoryMutation, RemoveCategoryMutation } from './mutations';
import CategoryForm from './CategoryForm';

class CategoryView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      scheduleAssignments: formValues.scheduleAssignments.filter(s => s.products.length > 0).map(s => ({
        datetime: s.datetime,
        products: s.products.map(b => ({ productId: b.key })),
      })),
      scheduleRemovals: formValues.scheduleRemovals.filter(s => s.products.length > 0).map(s => ({
        datetime: s.datetime,
        products: s.products.map(b => ({ productId: b.key })),
      }))
    };

    const uploadables = {};

    if (formValues.brands) {
      values.brands = formValues.brands.map((b, i) => {
        const { image, subcategory } = b;
        const subcategoryId = get(subcategory, "key", null);
        const d = { id: b.id.key, subcategoryId };

        if (image) {
          const name = `image${i}`;

          d.image = name;
          uploadables[name] = image[0].originFileObj;
        }

        return d;
      });
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateCategoryMutation.commit(mutation);
  }

  emptyCategory = (id) => {
    if (id) {
      const mutation = {
        environment: this.props.relay.environment,
        variables: { input: { id } },
        viewer: this.props.viewer,
      };
      EmptyCategoryMutation.commit(mutation);
    }
  }

  remove = (category) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: category.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        window.location.href = "/category";
      },
    };
    RemoveCategoryMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const category = get(viewer, 'categories.edges[0].node', {});

    return (
      <div>
        <Helmet title={category.name} />
        <h1>{category.name}</h1>

        <Popconfirm title="Empty All Products?" onConfirm={() => { this.emptyCategory(category.id); }} okText="Yes" cancelText="No">
          <Button>Remove All Products</Button>
        </Popconfirm>

        <CategoryForm
          key={category.id}
          match={match}
          viewer={viewer}
          entity={category}
          onSubmit={this.handleSubmit}
          remove={this.remove}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CategoryView, {
  viewer: graphql`
    fragment CategoryView_viewer on Admin {
      ...CategoryForm_viewer
      categories(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            urlSlug
            description
            description2
            includeInMenu
            includeInSearch
            includeInSitemap
            includeInFilter
            internalPriceMatch
            featured
            position
            aliases
            displayMode
            brands(first: 9999) {
              edges {
                node {
                  id
                  name
                  imageUrl
                }
              }
            }
            featuredBrands(first:9999) {
              edges {
                node {
                  brand {
                    id
                    name
                    imageUrl
                  }
                  subcategory {
                    id
                    name
                  }
                }
              }
            }
            scheduleAssignments {
              id
              datetime
              products(first: 9999) {
                edges {
                  node {
                    id
                    name
                    mainImage {
                      id
                      url
                    }
                  }
                }
              }
            }
            scheduleRemovals {
              id
              datetime
              products(first: 9999) {
                edges {
                  node {
                    id
                    name
                    mainImage {
                      id
                      url
                    }
                  }
                }
              }
            }
            status
            enableStartDate
            enableEndDate
            previewDescription
            metaTitle
            metaDescription
            wideImage
            jsonLd
            labels (first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...CategoryHistory_category
            ...WebsiteRef_category
          }
        }
      }
    }
  `,
});
