import React from 'react';
import { Route } from 'found';
import { get } from 'lodash';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/product"
    getComponent={() => (
      import(/* webpackChunkName: "ProductList" */'./ProductList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_ProductList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...ProductList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >

    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "ProductNew" */'./ProductNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_ProductNew_Query {
          viewer {
            ...ProductNew_viewer
          }
        }
      `}
    />

    <Route
      path="/copy"
      getComponent={() => (
        import(/* webpackChunkName: "ProductCopy" */'./ProductCopy').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_ProductCopy_Query ($ids: [ID], $skip: Boolean = true) {
          viewer {
            ...ProductCopy_viewer
          }
        }
      `}
      prepareVariables={(params, router) => {
        let ids = [null];

        const id = get(router, 'location.query.id');
        if (id) {
          const globalId = toGlobalId('Product', id);
          ids = [globalId];
        }

        return { ids };
      }}
    />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "ProductView" */'./ProductView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_ProductView_Query ($ids: [ID], $skip: Boolean = false) {
          viewer {
            ...ProductView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('Product', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
