import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

import { STATUS_STYLES } from '~/components/sales/order/Status';
import { getShippingDay } from './SalesReport';

const Status = (props) => {
  const { node } = props;
  const shipDay = getShippingDay(node);

  return (
    <span>
      <b style={STATUS_STYLES[camelCase(node.shipping.status)]}>{node.shipping.status}</b>
      {shipDay && `(Took ${shipDay} Days)`}
    </span>
  )
}

Status.propTypes = {
  node: PropTypes.shape({
    shipping: PropTypes.shape({
      status: PropTypes.string.isRequired
    })
  }).isRequired,
}

export default Status;