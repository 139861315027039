import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation MarkShippedMutation($input: MarkShippedInput!) {
    markShipped(input:$input) {
      salesOrder {
        id
        shipping
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

