import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateAlertMutation($input: UpdateAlertInput!) {
    updateAlert(input:$input) {
      alert {
        id
        name
        content
        startDate
        endDate
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
