/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PurchaseLimitForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PurchaseLimitNew_viewer$ref: FragmentReference;
declare export opaque type PurchaseLimitNew_viewer$fragmentType: PurchaseLimitNew_viewer$ref;
export type PurchaseLimitNew_viewer = {|
  +id: string,
  +$fragmentRefs: PurchaseLimitForm_viewer$ref,
  +$refType: PurchaseLimitNew_viewer$ref,
|};
export type PurchaseLimitNew_viewer$data = PurchaseLimitNew_viewer;
export type PurchaseLimitNew_viewer$key = {
  +$data?: PurchaseLimitNew_viewer$data,
  +$fragmentRefs: PurchaseLimitNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseLimitNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PurchaseLimitForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '016559a512a0e12b9bb569453cd2668f';

module.exports = node;
