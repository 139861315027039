import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, Col, Divider, Form, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DatePicker, Editor } from '~/components/form';

const { Item: FormItem } = Form;
const { Option } = Select;

const Alert = (props) => {
  const form = Form.useFormInstance();

  const { product } = props;
  const initialValue = product.alerts.edges ? product.alerts.edges.slice(0).map(({ node: t }) => ({
    message: t.message,
    startDate: t.startDate ? moment(t.startDate) : null,
    endDate: t.endDate ? moment(t.endDate) : null,
    status: t.status,
  })) : [{}];

  return (
    <Form.List name="alerts" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => {
            const message = form.getFieldValue(["alerts", name, "message"]);

            return (
              <Row key={key}>
                <Col xs={24}>
                  <FormItem
                    label="Message"
                  >
                    <Editor
                      parentName="alerts"
                      name={[name, "message"]}
                      editorState={message}
                    />
                  </FormItem>
                </Col>

                <Col xs={6}>
                  <FormItem
                    label="Start Date"
                    name={[name, "startDate"]}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <DatePicker placeholder="Start Date" />
                  </FormItem>
                </Col>

                <Col xs={6}>
                  <FormItem
                    label="End Date"
                    name={[name, "endDate"]}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <DatePicker placeholder="End Date" />
                  </FormItem>
                </Col>

                <Col xs={4}>
                  <FormItem
                    label="Status"
                    name={[name, "status"]}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <Select
                      placeholder="Status"
                    >
                      <Option value>Enabled</Option>
                      <Option value={false}>Disabled</Option>
                    </Select>
                  </FormItem>
                </Col>

                <Col xs={2} style={{ textAlign: 'center', marginTop: '5px' }}>
                  <MinusCircleOutlined
                    style={{ cursor: 'pointer' }}
                    disabled={fields.length === 1}
                    onClick={() => remove(name)}
                  />
                </Col>

                <Col xs={24}>
                  <Divider />
                </Col>
              </Row>
            )
          })}
          <Button onClick={() => add()}>
            <PlusCircleOutlined />
          </Button>
        </>
      )}
    </Form.List>
  );
}

Alert.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    alerts: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default Alert;
