import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation RemoveCmsBannerMutation($input: RemoveCmsBannerInput!) {
    removeCmsBanner(input:$input) {
      cmsBannerId
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

