import React from 'react';
import { Route } from 'found';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/redemption"
    getComponent={() => (
      import(/* webpackChunkName: "RedemptionList" */'./RedemptionList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_RedemptionList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...RedemptionList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >
    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "RedemptionNew" */'./RedemptionNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_RedemptionNew_Query {
          viewer {
            ...RedemptionNew_viewer
          }
        }
      `}
    />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "RedemptionView" */'./RedemptionView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_RedemptionView_Query ($ids: [ID]) {
          viewer {
            ...RedemptionView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('Redemption', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
