import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import { Button, Dropdown, Input, Select } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, MinusOutlined } from '@ant-design/icons';

const TIMEZONE = 'Australia/Sydney';

const CurrencyFormatter = ({ amount, currency, minimumFractionDigits, locale }) => {
    if (!amount) {
        return <span>0</span>;
    }

    if (typeof amount !== 'number') {
        return <span>{amount}</span>;
    }

    const formattedAmount = amount.toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits,
    });

    return <span>{formattedAmount} </span>;
};

CurrencyFormatter.propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.string,
    minimumFractionDigits: PropTypes.number,
    locale: PropTypes.string,
};

CurrencyFormatter.defaultProps = {
    amount: undefined,
    currency: 'AUD',
    minimumFractionDigits: 2,
    locale: 'en-AU',
};

const PercentageFormatter = ({ numerator, denominator }) => {
    if (typeof numerator !== 'number' || typeof denominator !== 'number' || denominator === 0) {
        return null;
    }

    const percentage = (numerator / denominator * 100).toFixed(2);

    return <span>({percentage}%)</span>;
};

PercentageFormatter.propTypes = {
    numerator: PropTypes.number.isRequired,
    denominator: PropTypes.number.isRequired,
};

const debouncedHandleDateCustom = debounce((value, ind, setDateRange) => {
    const rangeValue = parseInt(value, 10);

    if (!Number.isNaN(rangeValue) && rangeValue >= 0) {
        const startDate = moment().subtract(rangeValue, ind).startOf(ind);
        const endDate = moment().subtract(1, ind).endOf(ind);

        setDateRange([startDate, endDate]);
    }
}, 500);

const RenderCustomDate = ({indicator, setDateRange, loading}) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (!loading) {
            setInputValue('');
        }
    }, [loading]);
    return (
      <span>
        Last{' '}
        <Input
          value={inputValue}
          style={{ width: '60px', textAlign: 'center' }}
          onChange={(e) => {
                    setInputValue(e.target.value)
                    debouncedHandleDateCustom(e.target.value, indicator, setDateRange)
                }}
        />{' '} {indicator}
      </span>
    )
}

RenderCustomDate.propTypes = {
    indicator: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setDateRange: PropTypes.func.isRequired,
};

const CurrentMonth = ({setDateRange}) => {
    const onClick = () => {
        const startDate = moment().startOf('months');
        const endDate = moment().endOf('days');

        setDateRange([startDate, endDate]);
    }

    return (
      <Button onClick={onClick} style={{width: '90%'}}>
        This Month
      </Button>
    )
}

CurrentMonth.propTypes = {
    setDateRange: PropTypes.func.isRequired,
};

const YearToDate = ({ setDateRange }) => {
    const onClick = () => {
        const startDate = moment().startOf('years');
        const endDate = moment().endOf('days');

        setDateRange([startDate, endDate]);
    }

    return (
      <Button onClick={onClick} style={{width: '90%'}}>
        Year to Date
      </Button>
    )
};

YearToDate.propTypes = {
    setDateRange: PropTypes.func.isRequired,
};

const STATES = () => {
    const country = process.env.COUNTRY;

    if ( country === "NZ" ) {
      return ['NORTH ISLAND', 'SOUTH ISLAND'];
    }

    return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
}



const DatePickerRanges = () => {
    const now = new Date();
    const today = moment(now).endOf('day');
    const last30 = moment(now).startOf('day').subtract(30, 'days');
    const last60 = moment(now).startOf('day').subtract(60, 'days');
    const last90 = moment(now).startOf('day').subtract(90, 'days');

    return {
        'Last 30 Days': [last30, today],
        'Last 60 Days': [last60, today],
        'Last 90 Days': [last90, today],
    };
}

const DateShortcuts = (props) => {
    const { menu } = props;

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const onDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible)
    };

    return (
      <Dropdown
        overlay={menu}
        visible={dropdownVisible}
        onVisibleChange={onDropdownVisibleChange}
        trigger={['click']}
      >
        <Button
          style={{ display: 'inline-block', marginLeft: '10px', textAlign: 'center' }}
          onClick={() => { setDropdownVisible(true) }}
        >
          Date Shortcuts
        </Button>
      </Dropdown>
    )
};

DateShortcuts.propTypes = {
    menu: PropTypes.element.isRequired,
};

const RenderSelect = ({optionList, selected, placeholder, handleChange}) => {
    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={values => {handleChange(values)}}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={selected}
        placeholder={placeholder}
        style={{marginLeft: '20px', minWidth: '200px'}}
      >
        {optionList.length > 0 && Array.isArray(optionList)
                ? optionList.map((option) => (
                  <Select.Option key={option} value={option}>{option}</Select.Option>
                ))
                : Object.entries(optionList).map(([key, value]) => (
                  <Select.Option key={key} value={key}>{`${value} (${key})`}</Select.Option>
                ))
            }
      </Select>
    )
}

RenderSelect.propTypes = {
    optionList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
      ]).isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
      ]).isRequired,
    placeholder: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const DifferenceFormatter = ( {diff} ) => {
  const colours = {
    "true": "#00cc44",
    "false": "#ff3333",
  }

  const difference = diff === 1000000 ? 'infinity' : Math.abs((diff * 100).toFixed(2))
  const increase = diff > 0;
  const stable = diff === 0.00;

  return (
    <div style={{ color: colours[increase] }}>
      {stable && <MinusOutlined />}
      {increase && <ArrowUpOutlined />}
      {!stable && !increase && <ArrowDownOutlined />}
      <p style={{display: 'inline'}}>{difference}%</p>
    </div>
  );
}

DifferenceFormatter.propTypes = {
  diff: PropTypes.number.isRequired,
};

module.exports = {
    CurrencyFormatter,
    DifferenceFormatter,
    PercentageFormatter,
    RenderCustomDate,
    CurrentMonth,
    YearToDate,
    RenderSelect,
    STATES,
    TIMEZONE,
    DatePickerRanges,
    DateShortcuts,
};
