import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateCouponMutation($input: UpdateCouponInput!) {
    updateCoupon(input:$input) {
      coupon {
        name
        codes
        content {
          discountType
          discountAmount
          conditions
          actions
          removalDate
          removalProducts
          additionDate
          additionProducts
        }
        usageLimit
        usagePerCustomer
        timesUsed
        startDate
        endDate
        status
        showLabel
        autoApply
        ...CouponHistory_coupon
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
