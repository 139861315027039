/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WebsiteRef_category$ref: FragmentReference;
declare export opaque type WebsiteRef_category$fragmentType: WebsiteRef_category$ref;
export type WebsiteRef_category = {|
  +websiteReference: ?{|
    +id: ?string,
    +country: ?string,
  |},
  +id: string,
  +$refType: WebsiteRef_category$ref,
|};
export type WebsiteRef_category$data = WebsiteRef_category;
export type WebsiteRef_category$key = {
  +$data?: WebsiteRef_category$data,
  +$fragmentRefs: WebsiteRef_category$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./WebsiteRefCategoryRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "WebsiteRef_category",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebsiteReference",
      "kind": "LinkedField",
      "name": "websiteReference",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Category",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6094f5165c9ab5771fc904211a29a05e';

module.exports = node;
