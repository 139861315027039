import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateUrlSlugMutation($input: UpdateUrlSlugInput!) {
    updateUrlSlug(input:$input) {
      type {
        ... on Brand {
          urlSlug
        }
        ... on Category {
          urlSlug
        }
        ... on Product {
          urlSlug
        }
        ... on Subcategory {
          urlSlug
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
