import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateVerificationMutation($input: UpdateVerificationInput!) {
    updateVerification(input:$input) {
      salesOrder {
        status
        shipping
        verified
        verification {
          firstAmount
          secondAmount
          verifiedAt
          verifiedBy
          voidedBy
          cancelledBy
          attempts
        }
        ...SalesOrderHistory_salesOrder
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

