import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const labels = {
  'AU': <>🇦🇺 AU</>,
  'NZ': <>🇳🇿 NZ</>
};

// It accepts URL and it is mainly for providing a button to go to a specific
// entity in the other country. For example, if there is a product A in AU, this
// button can provide a quick link for the same product A in NZ.
const WebsiteButton = (props) => {
  const { origin } = window.location;
  const { style, url } = props;

  let href = "";
  let linkText = null;

  if (origin.includes("co.nz")) {
    href = origin.replace("co.nz", "com.au");
    linkText = labels.AU;
  } else {
    href = origin.replace("com.au", "co.nz");
    linkText = labels.NZ;
  }

  return (
    <Button style={{...style}}>
      <a href={url || href} target="_blank" rel="noopener noreferrer">
        {linkText}
      </a>
    </Button>
  );
};

WebsiteButton.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  url: PropTypes.string,
};

WebsiteButton.defaultProps = {
  style: { },
  url: null,
};

export default WebsiteButton;
