import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../../mutation';

const mutation = graphql`
  mutation RemoveCommonPromoMutation($input: RemoveCommonPromoInput!) {
    removeCommonPromo(input:$input) {
      removedCommonPromoId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeCommonPromo', edgeName: 'removedCommonPromoId', connectionKey: 'CommonPromoList_commonPromos' }),
});
export default { commit };
