import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { get, lowerCase } from 'lodash';
import { Form, Select } from 'antd';

import QuickLink from './QuickLink';
import { formItemLayout } from './index';

const { Item: FormItem } = Form;
const { Option } = Select;

const typeOptions = {
  'None': null,
  'Bonus': 0,
  'Redemption': 1,
  'Credit': 2,
  'Freebie': 3,
  'Category': 4,
};

const CopyProduct = (props) => {
  const [type, setType] = useState("");
  const form = Form.useFormInstance();

  const { viewer, entityId, options, relay: { refetch } } = props;
  const list = get(viewer, `copy${type}.edges`, []);
  const fieldName = `${lowerCase(type)}Id`;

  const handleChange = (v) => {
    const key = Object.keys(typeOptions).find((t) => typeOptions[t] === v);

    const variables = {
      isBonus: key === 'Bonus',
      isRedemption: key === 'Redemption',
      isCredit: key === 'Credit',
      isFreebie: key === 'Freebie',
      isCategory: key === 'Category',
    }

    refetch(variables);
    setType(key === 'None' ? "" : key);
  }

  return (
    <>
      <FormItem
        {...formItemLayout}
        label="Copy Product From"
        name="type"
        initialValue={null}
      >
        <Select
          placeholder="Type"
          onChange={handleChange}
        >
          {Object.entries(typeOptions).filter(([k]) => options.includes(k)).map(([k, v]) => (
            <Option key={k} value={v}>{k}</Option>
          ))}
        </Select>
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => getFieldValue('type') !== null ? (
          <FormItem
            {...formItemLayout}
            label={type}
            extra={
              <>
                <span>To copy <b>{type}</b> products, simply click the <b>Save</b> button.</span>
                <br />
                <span>It will <b>append</b> those products into here.</span>
              </>
            }
          >
            <FormItem
              name={fieldName}
              rules={[{ required: true, message: 'required' }]}
              initialValue={null}
            >
              <Select
                placeholder={type}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                allowClear
              >
                {list.filter(({ node }) => node.id !== entityId).map(({ node }) => (
                  <Option key={node.id} value={node.id}>{node.name}</Option>
                ))}
              </Select>
            </FormItem>
            <QuickLink form={form} fieldName={fieldName} />
          </FormItem>
        ) : null}
      </FormItem>
    </>
  )
};

CopyProduct.propTypes = {
  relay: PropTypes.shape({
    refetch: PropTypes.func.isRequired,
  }).isRequired,
  viewer: PropTypes.shape({}).isRequired,
  entityId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

CopyProduct.defaultProps = {
  entityId: null,
  options: Object.keys(typeOptions),
};

export default createRefetchContainer(
  CopyProduct, {
    viewer: graphql`
    fragment CopyProduct_viewer on Admin @argumentDefinitions(
      isBonus: {type: "Boolean", defaultValue: false},
      isRedemption: {type: "Boolean", defaultValue: false},
      isCredit: {type: "Boolean", defaultValue: false},
      isFreebie: {type: "Boolean", defaultValue: false},
      isCategory: {type: "Boolean", defaultValue: false},
    ) {
      copyBonus: bonuses(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isBonus) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyRedemption: redemptions(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isRedemption) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyCredit: credits(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isCredit) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyFreebie: freebies(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isFreebie) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyCategory: categories(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isCategory) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  },
  graphql`
  query CopyProductRefetchQuery(
    $isBonus: Boolean,
    $isRedemption: Boolean,
    $isCredit: Boolean,
    $isFreebie: Boolean,
    $isCategory: Boolean,
  ) {
    viewer {
      copyBonus: bonuses(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isBonus) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyRedemption: redemptions(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isRedemption) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyCredit: credits(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isCredit) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyFreebie: freebies(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isFreebie) {
        edges {
          node {
            id
            name
          }
        }
      }
      copyCategory: categories(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isCategory) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
  `,
);
