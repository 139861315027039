import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateCreditMutation } from './mutations';
import CreditForm from './CreditForm';

class CreditView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      credits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      stealthMode: !!formValues.stealthMode,
      promoItems: formValues.promoItems.map(p => p.key),
      excludeProducts: formValues.excludeProducts.map(p => p.key),
      excludeProductsSpend: formValues.excludeProductsSpend.map(p => p.key),
      includeProducts: formValues.includeProducts.map(p => p.key),
    };

    const uploadables = {};
    if (values.tiers && values.tiers.length > 0) {
      values.tiers = values.tiers.map((tier, index) => {
        // remove `image` from tier. It maybe assigned again later.
        // `imageUrl` needs to be removed as it is not recognised by graphql
        const { imageUrl, image, ...rest } = tier;

        // HACK.
        // When the value of an image is null, backend will use this flag to delete the path stored in DB
        if (image === null) {
          rest.image = null;
        }

        if (Array.isArray(tier.image) && tier.image.length > 0) {
          const key = `tier${index}Image`;
          const file = tier.image[0];

          rest.image = key;
          uploadables[key] = file.originFileObj;
        }

        return rest;
      });
    }

    // replace * at fromBrandIds
    if (formValues.fromBrandIds.includes('*')) {
      values.fromBrandIds = null;
    }

    if (formValues.creditFromProducts) {
      values.creditFromProducts = formValues.creditFromProducts.map((p) => ({
        ...p, productId: p.productId.key,
      }));
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateCreditMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const credit = get(viewer, 'credits.edges[0].node', {});

    return (
      <div>
        <Helmet title={credit.name} />
        <h1>{credit.name}</h1>
        <CreditForm
          key={credit.id}
          match={match}
          viewer={viewer}
          credit={credit}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CreditView, {
  viewer: graphql`
    fragment CreditView_viewer on Admin {
      credits(first: 1, ids: $ids) {
        edges {
          node {
            id
            fromBrandIds
            name
            tiers(first: 999) {
              edges {
                node {
                  id
                  price
                  creditAmount
                  imageUrl
                }
              }
            }
            promoItems(first: 999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            creditFromProducts(first: 999) {
              edges {
                node {
                  id
                  product {
                    id
                    name
                    mainImage {
                      id
                      url
                    }
                  }
                  creditAmount
                }
              }
            }
            percentage
            minSpent
            applyBrandIds
            applyCreditMode
            excludeBrandIds
            excludeProducts(first: 9999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            excludeProductsSpend(first: 9999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            includeProducts(first: 9999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            startDate
            endDate
            expiry
            stealthMode
            excludeNonStock
            includeNonStockBrands {
              id
              name
            }
            excludePriceDrop
            includePriceDropBrands {
              id
              name
            }
            status
            customerStoreCredits(first: 999) @connection(key: "CreditView_customerStoreCredits") {
              edges {
                node {
                  id
                  creditAmount
                  creditRemaining
                  expiry
                  orderId
                  orderName
                }
              }
            }
            labels (first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...CreditForm_viewer
    }
  `,
});
