/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SubmitOrderInput = {|
  id?: ?string
|};
export type SubmitOrderMutationVariables = {|
  input: SubmitOrderInput
|};
export type SubmitOrderMutationResponse = {|
  +submitOrder: ?{|
    +salesOrder: ?{|
      +id: string
    |}
  |}
|};
export type SubmitOrderMutation = {|
  variables: SubmitOrderMutationVariables,
  response: SubmitOrderMutationResponse,
|};
*/


/*
mutation SubmitOrderMutation(
  $input: SubmitOrderInput!
) {
  submitOrder(input: $input) {
    salesOrder {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitOrderPayload",
    "kind": "LinkedField",
    "name": "submitOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SalesOrder",
        "kind": "LinkedField",
        "name": "salesOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitOrderMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitOrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "738b3c756c5908f4e3b369c7b81b4a43",
    "id": null,
    "metadata": {},
    "name": "SubmitOrderMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitOrderMutation(\n  $input: SubmitOrderInput!\n) {\n  submitOrder(input: $input) {\n    salesOrder {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3fa2f5bd719c2052b396e9b12f18c66d';

module.exports = node;
