import React from 'react';
import PropTypes from 'prop-types';

const ExtraMsg = (props) => {
  const { isBundle, isSyncField, children } = props;

  if (isBundle) {
    return (
      <>
        {children}
        <span style={{ display: 'block' }}>
          To Update this `Bundle` product field, apply changes on Child Product
        </span>
      </>
    )
  }

  if (isSyncField) {
    return (
      <>
        {children}
        <span style={{ display: 'block' }}>
          To Update this product field, apply changes on AU website.
        </span>
      </>
    )
  }

  return children;
}

ExtraMsg.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isBundle: PropTypes.bool,
  isSyncField: PropTypes.bool,
}

ExtraMsg.defaultProps = {
  isBundle: false,
  isSyncField: false,
  children: null,
}

module.exports = {
  ExtraMsg,
}
