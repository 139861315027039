import React from 'react';
import PropTypes from 'prop-types';

import { Button, message } from 'antd';

class Download extends React.Component {
  static propTypes = {
    gridApi: PropTypes.shape({
      getSelectedRows: PropTypes.func.isRequired,
    }),
  }

  static defaultProps = {
    gridApi: null
  }

  onClick = () => {
    const { gridApi } = this.props;
    if (!gridApi) {
      return null;
    }
    const ids = gridApi.getSelectedRows().map(({node}) => node.id);

    if (ids.length) {
      window.open(`/api/promotion/download?ids=${ids.join(",")}`);
    }
    else {
      message.error("Please select rows");
    }

    return null;
  }

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <Button onClick={this.onClick}>Download</Button>
      </div>
    );
  }
};

export default Download;
