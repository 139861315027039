/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreditForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreditNew_viewer$ref: FragmentReference;
declare export opaque type CreditNew_viewer$fragmentType: CreditNew_viewer$ref;
export type CreditNew_viewer = {|
  +id: string,
  +$fragmentRefs: CreditForm_viewer$ref,
  +$refType: CreditNew_viewer$ref,
|};
export type CreditNew_viewer$data = CreditNew_viewer;
export type CreditNew_viewer$key = {
  +$data?: CreditNew_viewer$data,
  +$fragmentRefs: CreditNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreditNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreditForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '32ebfdfd846697f66c6242ce1b1bf6ca';

module.exports = node;
