/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddPurchaseLimitInput = {|
  endDate: string,
  limit: number,
  productId: string,
  startDate: string,
  status: boolean,
|};
export type AddPurchaseLimitMutationVariables = {|
  input: AddPurchaseLimitInput
|};
export type AddPurchaseLimitMutationResponse = {|
  +addPurchaseLimit: ?{|
    +purchaseLimitEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +startDate: ?string,
        +endDate: ?string,
        +product: ?{|
          +id: string,
          +name: ?string,
          +sku: ?string,
          +mainImage: ?{|
            +id: string,
            +url: ?string,
          |},
          +status: ?string,
        |},
        +limit: ?number,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddPurchaseLimitMutation = {|
  variables: AddPurchaseLimitMutationVariables,
  response: AddPurchaseLimitMutationResponse,
|};
*/


/*
mutation AddPurchaseLimitMutation(
  $input: AddPurchaseLimitInput!
) {
  addPurchaseLimit(input: $input) {
    purchaseLimitEdge {
      cursor
      node {
        id
        startDate
        endDate
        product {
          id
          name
          sku
          mainImage {
            id
            url
          }
          status
        }
        limit
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddPurchaseLimitPayload",
    "kind": "LinkedField",
    "name": "addPurchaseLimit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PurchaseLimitEdge",
        "kind": "LinkedField",
        "name": "purchaseLimitEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseLimit",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sku",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductImage",
                    "kind": "LinkedField",
                    "name": "mainImage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "limit",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddPurchaseLimitMutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddPurchaseLimitMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d1d6aad6b2d8657778285ad11544b1ce",
    "id": null,
    "metadata": {},
    "name": "AddPurchaseLimitMutation",
    "operationKind": "mutation",
    "text": "mutation AddPurchaseLimitMutation(\n  $input: AddPurchaseLimitInput!\n) {\n  addPurchaseLimit(input: $input) {\n    purchaseLimitEdge {\n      cursor\n      node {\n        id\n        startDate\n        endDate\n        product {\n          id\n          name\n          sku\n          mainImage {\n            id\n            url\n          }\n          status\n        }\n        limit\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea5d281c7412e8bb80e203104df597a7';

module.exports = node;
