import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation GenerateGiftCardUsageMutation($input: GenerateGiftCardUsageInput!) {
    generateGiftCardUsage(input:$input) {
      salesOrder {
        id
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

