import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, startsWith } from 'lodash';

import { handleSubmit } from './ProductNew';
import ProductForm from './ProductForm';

class ProductCopy extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  getProduct = (viewer) => {
    const p = get(viewer, 'products.edges[0].node', {});

    const fields = [
      "brandId",
      "type",
      "bundles",
      "configurableAttributes",
      "configurables",
      "freeShipping",
      "categoryIds",
      "subcategoryIds",
      "attributes",
      "warranty",
    ];

    const product = {};
    Object.keys(p).forEach((k) => {
      if (startsWith(k, '__') || fields.includes(k)) {
        product[k] = p[k];
      } else {
        product[k] = null;
      }
    });

    delete product.id;
    product.visibility = 4;
    product.status = "disabled";

    return product;
  }

  handleSubmit = handleSubmit.bind(this);

  render() {
    const { viewer } = this.props;
    const product = this.getProduct(viewer);
    const title = `Copy Product: ${product.name}`;

    return (
      <div>
        <Helmet title={title} />
        <h1>{title}</h1>
        <ProductForm
          viewer={viewer}
          product={product}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  ProductCopy,
  {
    viewer: graphql`
    fragment ProductCopy_viewer on Admin {
      ...ProductForm_viewer
      products(first: 1, ids: $ids) {
        edges {
          node {
            ...ProductFragment_product @relay(mask: false)
          }
        }
      }
    }
  `,
  },
);
