import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
} from 'react-relay';
import { get, groupBy } from 'lodash';
import { Button, Form, Modal, TreeSelect } from 'antd';
import { Editor } from '~/components/form';
import { BulkUpdateStoresDescriptionMutation } from './mutations';

const { SHOW_CHILD } = TreeSelect;

const query = graphql`
  query BulkUpdateStoresDescriptionQuery {
    viewer {
      id
      stores(first: 999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            state
          }
        }
      }
    }
  }
`;

const BulkUpdateStoresDescription = () => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);
  const [form] = Form.useForm();

  const handleOk = () => {
    form.submit();
  }

  const handleChange = (value) => {
    form.setFieldsValue({
      stores: value,
    });
  }

  const onFinish = (values) => {
    BulkUpdateStoresDescriptionMutation.commit({
      environment: queryReference.environment,
      variables: { input: values },
    });
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        onClick={() => { loadQuery({fetchPolicy: 'store-and-network'}); }}
      >
        Edit
      </Button>
      <Modal
        title="Update Stores Description"
        visible={queryReference}
        okText="Submit"
        onOk={handleOk}
        onCancel={disposeQuery}
        width={760}
        destroyOnClose
      >
        <React.Suspense fallback="Loading...">
          {queryReference != null && (
            <Form form={form} preserve={false} onFinish={onFinish}>
              <Form.Item
                name="stores"
                rules={[{ required: true, message: 'required' }]}
              >
                <StoreTreeSelect queryReference={queryReference} handleChange={handleChange} />
              </Form.Item>
              <Editor
                name="description"
              />
            </Form>
          )}
        </React.Suspense>
      </Modal>
    </div>
  )
};

const StoreTreeSelect = ({ queryReference, handleChange }) => {
  const { viewer } = usePreloadedQuery(query, queryReference);
  const stores = get(viewer, 'stores.edges', []);
  const groupByState = groupBy(stores, ({node}) => node.state);
  const treeData = Object.entries(groupByState).map(([key, values]) => {
    return {
      title: key,
      value: key,
      key,
      children: values.map(({node}) => {
        return {
          title: node.name,
          value: node.id,
          key: node.id,
        }
      }),
    }
  });

  return (
    <TreeSelect
      treeData={treeData}
      onChange={handleChange}
      treeCheckable
      filterTreeNode={(input, treenode) => treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      showCheckedStrategy={SHOW_CHILD}
      placeholder="Please select stores"
      style={{ width: '100%' }}
      allowClear
    />
  );
};

BulkUpdateStoresDescription.propTypes = {};

StoreTreeSelect.propTypes = {
  queryReference: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default BulkUpdateStoresDescription;
