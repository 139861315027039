/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProductForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductNew_viewer$ref: FragmentReference;
declare export opaque type ProductNew_viewer$fragmentType: ProductNew_viewer$ref;
export type ProductNew_viewer = {|
  +$fragmentRefs: ProductForm_viewer$ref,
  +$refType: ProductNew_viewer$ref,
|};
export type ProductNew_viewer$data = ProductNew_viewer;
export type ProductNew_viewer$key = {
  +$data?: ProductNew_viewer$data,
  +$fragmentRefs: ProductNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductNew_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0aa24ab441b2df201c118cb16e3a1cda';

module.exports = node;
