/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAdminUserInput = {|
  email?: ?string,
  firstname?: ?string,
  id?: ?string,
  importProducts?: ?boolean,
  lastname?: ?string,
  password?: ?string,
  permissions?: ?$ReadOnlyArray<?InputPermission>,
  roles?: ?$ReadOnlyArray<?string>,
  status?: ?boolean,
  suppliers?: ?$ReadOnlyArray<?string>,
  username?: ?string,
|};
export type InputPermission = {|
  act?: ?string,
  id?: ?string,
  obj?: ?string,
|};
export type UpdateAdminUserMutationVariables = {|
  input: UpdateAdminUserInput
|};
export type UpdateAdminUserMutationResponse = {|
  +updateAdminUser: ?{|
    +adminUser: ?{|
      +username: ?string,
      +firstname: ?string,
      +lastname: ?string,
      +email: ?string,
      +status: ?boolean,
      +roles: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
      +permissions: ?$ReadOnlyArray<?{|
        +id: string,
        +obj: ?string,
        +act: ?string,
      |}>,
      +suppliers: ?{|
        +importProducts: ?boolean,
        +supplierCodes: ?$ReadOnlyArray<?string>,
      |},
    |}
  |}
|};
export type UpdateAdminUserMutation = {|
  variables: UpdateAdminUserMutationVariables,
  response: UpdateAdminUserMutationResponse,
|};
*/


/*
mutation UpdateAdminUserMutation(
  $input: UpdateAdminUserInput!
) {
  updateAdminUser(input: $input) {
    adminUser {
      username
      firstname
      lastname
      email
      status
      roles {
        id
        name
      }
      permissions {
        id
        obj
        act
      }
      suppliers {
        importProducts
        supplierCodes
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminRole",
  "kind": "LinkedField",
  "name": "roles",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Permission",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "obj",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "act",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SupplierPermission",
  "kind": "LinkedField",
  "name": "suppliers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "importProducts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierCodes",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdminUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminUserPayload",
        "kind": "LinkedField",
        "name": "updateAdminUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "adminUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdminUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminUserPayload",
        "kind": "LinkedField",
        "name": "updateAdminUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "adminUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb130a4d453ffeb8cb11775c10cc2cd3",
    "id": null,
    "metadata": {},
    "name": "UpdateAdminUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdminUserMutation(\n  $input: UpdateAdminUserInput!\n) {\n  updateAdminUser(input: $input) {\n    adminUser {\n      username\n      firstname\n      lastname\n      email\n      status\n      roles {\n        id\n        name\n      }\n      permissions {\n        id\n        obj\n        act\n      }\n      suppliers {\n        importProducts\n        supplierCodes\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d8e1a00a352d5f929d469e01022244d';

module.exports = node;
