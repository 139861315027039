import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Affix, Button, Form, Input, Popconfirm, Switch } from 'antd';
import { FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;

class SearchRedirectForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const redirect = get(viewer, 'searchRedirects.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values)=>{this.props.onSubmit(this.formRef.current, values);}}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>

            {redirect.id && (
            <Popconfirm
              title="Are you sure delete this redirect?"
              onConfirm={() => { this.props.remove(redirect); }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={redirect.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Query"
          name="query"
          rules={[{ required: true, message: 'required' }]}
          initialValue={redirect.query}
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="URL"
          name="url"
          rules={[{ required: true, message: 'required' }]}
          initialValue={redirect.url}
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          valuePropName="checked"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(redirect, 'status', false)}
        >
          <Switch />
        </FormItem>

      </Form>
    );
  }
}
export default SearchRedirectForm;
