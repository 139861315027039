import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveUrlRewriteMutation($input: RemoveUrlRewriteInput!) {
    removeUrlRewrite(input:$input) {
      urlRewrite {
        id
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
