/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAlertInput = {|
  content: string,
  endDate: string,
  id: string,
  name: string,
  startDate: string,
  status: boolean,
  type: number,
|};
export type UpdateAlertMutationVariables = {|
  input: UpdateAlertInput
|};
export type UpdateAlertMutationResponse = {|
  +updateAlert: ?{|
    +alert: ?{|
      +id: string,
      +name: ?string,
      +content: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateAlertMutation = {|
  variables: UpdateAlertMutationVariables,
  response: UpdateAlertMutationResponse,
|};
*/


/*
mutation UpdateAlertMutation(
  $input: UpdateAlertInput!
) {
  updateAlert(input: $input) {
    alert {
      id
      name
      content
      startDate
      endDate
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAlertPayload",
    "kind": "LinkedField",
    "name": "updateAlert",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "alert",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAlertMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAlertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a32d3df6c935fec8b7f7fd5fed5339c8",
    "id": null,
    "metadata": {},
    "name": "UpdateAlertMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAlertMutation(\n  $input: UpdateAlertInput!\n) {\n  updateAlert(input: $input) {\n    alert {\n      id\n      name\n      content\n      startDate\n      endDate\n      status\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1852959e7305e7387590e41b99c19fe';

module.exports = node;
