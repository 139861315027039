import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../helper';
import { AddSubcategoryMutation } from './mutations';

import SubcategoryForm from './SubcategoryForm';

class SubcategoryNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      attributes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const uploadables = {};

    const options = formValues.autoAssignCategory.treeNode.flatMap(({ children }) => children);

    const autoAssignCategory = [];
    formValues.autoAssignCategory.categoryIds.forEach((id) => {
      const data = options.find((o) => o.id === id);
      autoAssignCategory.push({
        categoryId: id,
        attributeId: data.attributeId,
        option: data.option,
      });
    });

    const values = {
      ...formValues,
      status: !!formValues.status,
      autoAssignCategory,
      attributes: formValues.attr.map((a) => ({
        id: a.id,
        options: a.options || null,
        required: a.required || false,
      })),
      images: formValues.images.map((image, index) => {
        const i = {
          subcategoryIds: image.subcategoryIds.map(p => p.key),
          categoryIds: image.categoryIds.map(p => p.key),
          brandIds: image.brandIds.map(p => p.key),
          mode: image.mode,
          link: image.link,
        };

        const { id, file } = image;

        if (id) {
          i.id = id;
        }

        if (file) {
          const name = `image${index}`;

          i.file = name;
          uploadables[name] = file[0].originFileObj;
        }

        return i;
      }),
      categoryIds: formValues.categoryIds.map(p => p.key),
      subcategoryIds: formValues.subcategoryIds.map(p => p.key),
      nests: formValues.nests.map(p => p.key),
      nestBrands: formValues.nestBrands.map(p => p.key),
      parents: formValues.parents.map(p => p.key),
    };

    delete values.attr;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/subcategory/${fromGlobalId(resp.addSubcategory.subcategoryEdge.node.id).id}`;
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }

    AddSubcategoryMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Subcategory" />

        <h1>New Subcategory</h1>
        <SubcategoryForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(SubcategoryNew, {
  viewer: graphql`
    fragment SubcategoryNew_viewer on Admin {
      ...SubcategoryForm_viewer
    }
  `,
});
