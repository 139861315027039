import AddGiftCardUsageMutation from './AddGiftCardUsageMutation';
import GenerateGiftCardUsageMutation from './GenerateGiftCardUsageMutation';
import SendGiftCardUsageEmailMutation from './SendGiftCardUsageEmailMutation';
import UpdateGiftCardUsageMutation from './UpdateGiftCardUsageMutation';

module.exports = {
  AddGiftCardUsageMutation,
  GenerateGiftCardUsageMutation,
  SendGiftCardUsageEmailMutation,
  UpdateGiftCardUsageMutation
};

