import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
} from 'react-relay';
import { get, groupBy } from 'lodash';
import { Button, Form, Modal, Select, TreeSelect } from 'antd';
import { checkEmails } from '~/helper';
import { BulkUpdateStoresBdmEmailsMutation } from './mutations';

const { SHOW_CHILD } = TreeSelect;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const query = graphql`
  query BulkUpdateStoresBdmEmailsQuery {
    viewer {
      id
      brands(first: 9999 orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      stores(first: 999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            state
          }
        }
      }
    }
  }
`;

const BulkUpdateStoresBdmEmails = () => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);
  const [form] = Form.useForm();

  const handleOk = () => {
    form.submit();
  };

  const brandChange = (brandId) => {
    form.setFieldsValue({ brandId });
  };

  const storesChange = (storeIds) => {
    form.setFieldsValue({ storeIds });
  };

  const onFinish = (values) => {
    BulkUpdateStoresBdmEmailsMutation.commit({
      environment: queryReference.environment,
      variables: { input: values },
    });
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        onClick={() => { loadQuery({fetchPolicy: 'store-and-network'}); }}
      >
        Edit
      </Button>
      <Modal
        title="Update Stores EDM Emails"
        visible={queryReference}
        okText="Submit"
        onOk={handleOk}
        onCancel={disposeQuery}
        width={760}
        destroyOnClose
      >
        <React.Suspense fallback="Loading...">
          {queryReference != null && (
            <Form form={form} preserve={false} onFinish={onFinish}>
              <Form.Item
                {...formItemLayout}
                name="brandId"
                label="Brand"
                rules={[{ required: false, message: 'required' }]}
              >
                <SelectBrand queryReference={queryReference} handleChange={brandChange} />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="storeIds"
                label="Stores"
                rules={[{ required: true, message: 'required' }]}
              >
                <TreeSelectStores queryReference={queryReference} handleChange={storesChange} />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="emails"
                label="Emails"
                rules={[
                  { required: true, message: 'required' },
                  { validator: checkEmails },
                ]}
              >
                <Select
                  placeholder="Emails"
                  mode="tags"
                  allowClear
                />
              </Form.Item>
            </Form>
          )}
        </React.Suspense>
      </Modal>
    </div>
  )
};

BulkUpdateStoresBdmEmails.propTypes = {};

const SelectBrand = ({ queryReference, handleChange }) => {
  const { viewer } = usePreloadedQuery(query, queryReference);
  const brands = get(viewer, 'brands.edges', []);

  return (
    <Select
      allowClear
      showSearch
      onChange={handleChange}
      optionFilterProp="label"
      options={brands.map(({node: b}) => ({ value: b.id, label: b.name }))}
    />
  )
};

SelectBrand.propTypes = {
  queryReference: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
};

const TreeSelectStores = ({ queryReference, handleChange }) => {
  const { viewer } = usePreloadedQuery(query, queryReference);
  const stores = get(viewer, 'stores.edges', []);
  const groupByState = groupBy(stores, ({node}) => node.state);
  const treeData = Object.entries(groupByState).map(([key, values]) => {
    return {
      title: key,
      value: key,
      key,
      children: values.map(({node}) => {
        return {
          title: node.name,
          value: node.id,
          key: node.id,
        }
      }),
    }
  });

  return (
    <TreeSelect
      treeData={treeData}
      onChange={handleChange}
      treeCheckable
      filterTreeNode={(input, treenode) => treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      showCheckedStrategy={SHOW_CHILD}
      placeholder="Please select stores"
      style={{ width: '100%' }}
      allowClear
    />
  )
}

TreeSelectStores.propTypes = {
  queryReference: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default BulkUpdateStoresBdmEmails;
