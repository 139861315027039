import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddAttributeMutation($input: AddAttributeInput!) {
    addAttribute(input:$input) {
      attributeEdge {
        cursor
        node {
          id
          name
          code
          options
          multi
          visibleToFrontend
          includeInSearch
          position
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
