/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesTrend_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_SalesTrend_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>
|};
export type routes_SalesTrend_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SalesTrend_viewer$ref
  |}
|};
export type routes_SalesTrend_Query = {|
  variables: routes_SalesTrend_QueryVariables,
  response: routes_SalesTrend_QueryResponse,
|};
*/


/*
query routes_SalesTrend_Query(
  $filterBy: [FilterBy]
) {
  viewer {
    ...SalesTrend_viewer
    id
  }
}

fragment SalesTrend_viewer on Admin {
  salesTrend(filterBy: $filterBy)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SalesTrend_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SalesTrend_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_SalesTrend_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              }
            ],
            "kind": "ScalarField",
            "name": "salesTrend",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "74d9a934774aa82ba23a3c266f37cbf9",
    "id": null,
    "metadata": {},
    "name": "routes_SalesTrend_Query",
    "operationKind": "query",
    "text": "query routes_SalesTrend_Query(\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...SalesTrend_viewer\n    id\n  }\n}\n\nfragment SalesTrend_viewer on Admin {\n  salesTrend(filterBy: $filterBy)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e314e6733b6760b2f1077dd68d5a6f0f';

module.exports = node;
