/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePriceDropsInput = {|
  mode?: ?string,
  prices: any,
|};
export type UpdatePriceDropsMutationVariables = {|
  input: UpdatePriceDropsInput
|};
export type UpdatePriceDropsMutationResponse = {|
  +updatePriceDrops: ?{|
    +result: ?any
  |}
|};
export type UpdatePriceDropsMutation = {|
  variables: UpdatePriceDropsMutationVariables,
  response: UpdatePriceDropsMutationResponse,
|};
*/


/*
mutation UpdatePriceDropsMutation(
  $input: UpdatePriceDropsInput!
) {
  updatePriceDrops(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePriceDropsPayload",
    "kind": "LinkedField",
    "name": "updatePriceDrops",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePriceDropsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePriceDropsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c0f7338e7226f5c28404786dd127ec2",
    "id": null,
    "metadata": {},
    "name": "UpdatePriceDropsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePriceDropsMutation(\n  $input: UpdatePriceDropsInput!\n) {\n  updatePriceDrops(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad41d07236b0bfcd10341867c12435b2';

module.exports = node;
