import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import GiftCardUsageForm from './GiftCardUsageForm';
import { UpdateGiftCardUsageMutation } from './mutations';

class GiftCardUsageView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      giftCardUsages: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateGiftCardUsageMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const giftCardUsage = get(viewer, 'giftCardUsages.edges[0].node', {});

    return (
      <div>
        <Helmet title={giftCardUsage.name} />
        <h1>{giftCardUsage.name}</h1>
        <GiftCardUsageForm
          key={giftCardUsage.id}
          match={match}
          viewer={viewer}
          giftCardUsage={giftCardUsage}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(GiftCardUsageView, {
  viewer: graphql`
    fragment GiftCardUsageView_viewer on Admin {
      ...GiftCardUsageForm_viewer
      giftCardUsages(first: 1, ids: $ids) {
        edges {
          node {
            id
            code
            amount
            remainingAmount
            insertedAt
            expiry
            giftCard {
              id
              name
            }
            fromOrder {
              id
              name
            }
            onOrders(first: 999) {
              edges {
                node {
                  id
                  amount
                  order {
                    id
                    name
                  }
                }
              }
            }
            status
            ...GiftCardUsageHistory_giftCardUsage
          }
        }
      }
    }
  `,
});
