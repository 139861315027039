/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FreebieForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FreebieNew_viewer$ref: FragmentReference;
declare export opaque type FreebieNew_viewer$fragmentType: FreebieNew_viewer$ref;
export type FreebieNew_viewer = {|
  +$fragmentRefs: FreebieForm_viewer$ref,
  +$refType: FreebieNew_viewer$ref,
|};
export type FreebieNew_viewer$data = FreebieNew_viewer;
export type FreebieNew_viewer$key = {
  +$data?: FreebieNew_viewer$data,
  +$fragmentRefs: FreebieNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreebieNew_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreebieForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '305aa9cc048ac45730203f551472599b';

module.exports = node;
