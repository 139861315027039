import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddFooterMutation($input: AddFooterInput!) {
    addFooter(input:$input) {
      footerEdge {
        cursor
        node {
          id
          name
          position
          urls {
            name
            type
            url
            properties
            position
          }
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
