import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Button, Col, Form, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout } from '~/components/form';
import { checkEmails } from '~/helper';

const { Item: FormItem } = Form;

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

export default class BdmEmails extends React.Component {
  static propTypes = {
    brands: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      })
    ).isRequired,
    store: PropTypes.shape({}).isRequired,
  }

  // Make sure the first index of the list is brandId = null ( sydneytools emails )
  ensureList = (store) => {
    const bdmEmails = get(store, 'bdmEmails', []);
    const list = Object.assign([], bdmEmails);

    const listIndex = list.findIndex(({ brandId }) => brandId === null);

    if (list.length > 0) {
      if (listIndex === -1) {
        list.unshift({ brandId: null, emails: [] });
      } else {
        const [element] = list.splice(listIndex, 1);
        list.unshift(element);
      }
    }

    return list;
  }

  render() {
    const { brands, store } = this.props;

    const bdmEmails = this.ensureList(store);

    const initialValue = bdmEmails.length ? bdmEmails.slice(0) : [{}]

    return (
      <Form.List name="bdmEmails" initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => {
              let formStyle = {
                brandCol: { xs: 24, sm: 8, md: 6 },
                emailsCol: { xs: 24, sm: 16, md: 18 },
              };

              if (index === 0) {
                formStyle = {
                  brandCol: { xs: 0 },
                  emailsCol: { xs: 24, sm: 24, md: 24 },
                }
              }

              return (
                <FormItem
                  key={key}
                  label={index === 0 ? 'Email Groups' : ''}
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  style={{ marginBottom: '0px' }}
                >
                  <Row>
                    <Col {...formStyle.brandCol}>
                      <FormItem name={[name, "brandId"]}>
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="label"
                          placeholder="Brand"
                          options={brands.map(({node: b}) => ({ value: b.id, label: b.name }))}
                        />
                      </FormItem>
                    </Col>

                    <Col {...formStyle.emailsCol}>
                      <FormItem
                        style={{ display: 'inline-block', width: index === 0 ? '100%' : 'calc(100% - 30px)' }}
                        name={[name, "emails"]}
                        rules={[
                          { required: index > 0, message: 'required' },
                          { validator: checkEmails },
                        ]}
                        extra={index === 0 ? "For Sydneytools internal emails" : null}
                      >
                        <Select
                          mode="tags"
                          allowClear
                          placeholder="BDM Emails"
                          style={{ width: '100%' }}
                        />
                      </FormItem>
                      {index !== 0 ? (
                        <MinusCircleOutlined
                          style={{ marginLeft: '10px' }}
                          onClick={() => remove(name)}
                        />
                      ): null}
                    </Col>
                  </Row>
                </FormItem>
            )})}
            <FormItem {...formItemLayoutWithOutLabel}>
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add New Supplier Emails
              </Button>
            </FormItem>
          </>
        )}
      </Form.List>
    )
  }
}
