import React from 'react';

export default class NotFound extends React.Component {

  render() {
    return (
      <div>
        Not Found
      </div>
    );
  }
}
