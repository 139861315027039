import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { get, groupBy } from 'lodash';
import { Button, Table } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import socket from '~/socket';

class Status extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      appVersion: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      users: [],
    }
  }

  componentDidMount() {
    socket.frontendStatus(this.props, this.callback);
  }

  callback = (users) => {
    this.setState({
      users,
    })
  }

  renderTotalUser = (users) => {
    const count = users.length;

    return (
      <div>
        Total User: {count}
      </div>
    );
  }

  renderVersionInUse = (viewer, users) => {
    const appVersion = get(viewer, 'appVersion', "");

    const versions = groupBy(users, ({metas}) => metas[0].version);
    const versionList = Object.entries(versions).map((v, index) => ({
      key: `v${index}`,
      name: v[0],
      count: v[1].length,
      tabOpen: v[1],
    }));

    return (
      <div>
        <div style={{ display: 'block' }}>
          <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
            Version in use: {appVersion}
          </h4>
          <Button
            style={{ display: 'inline-block' }}
            onClick={() => this.props.relay.refetch()}
          >
            <SyncOutlined />
          </Button>
        </div>
        <Table
          dataSource={versionList}
          pagination={false}
          size="small"
          columns={[
            {
              title: 'Version Name',
              dataIndex: 'name',
              key: 'name',
            }, {
              title: 'Count',
              dataIndex: 'count',
              key: 'count',
              defaultSortOrder: 'descend',
              sorter: (a, b) => (a.count - b.count),
            }, {
              title: 'Tab Open',
              dataIndex: 'tabOpen',
              key: 'tabOpen',
              render: (v) => {
                const tabs = v.flatMap((user) => user.metas);

                return (
                  tabs.length
                );
              }
            }
          ]}
        />
      </div>
    );
  }

  render() {
    const { viewer } = this.props;
    const users = get(this.state, 'users', []);

    return (
      <div style={{display: 'block'}}>
        <h3>Status: </h3>
        {this.renderTotalUser(users)}
        {this.renderVersionInUse(viewer, users)}
      </div>
    )
  }
}
export default createRefetchContainer(
  Status,
  {
    viewer: graphql`
      fragment Status_viewer on Admin {
        id
        appVersion
      }
    `
  },
  graphql`
  query StatusRefetchQuery {
    viewer {
      id
      appVersion
    }
  }
  `
);
