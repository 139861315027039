import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation RemoveCategoriesFromProductsMutation($input: RemoveCategoriesFromProductsInput!) {
    removeCategoriesFromProducts(input:$input) {
      categoryIds
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
