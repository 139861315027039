import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';

import { fromGlobalId } from '../../../helper';
import { AddRedemptionMutation } from './mutations';
import RedemptionForm from './RedemptionForm';

class RedemptionNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues, { setLoading }) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      redeemables: formValues.redeemables.map(p => p.key),
    };

    if (!isEmpty(formValues.brandIds)) {
      values.excludeProducts = formValues.excludeProducts.map(p => p.key);
    } else {
      values.onProducts = formValues.onProducts.map(p => p.key);
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: { insert: setLoading },
      onCompleted: (resp) => {
        window.location.href = `/promotion/redemption/${fromGlobalId(resp.addRedemption.redemptionEdge.node.id).id}`;
      },
    };
    AddRedemptionMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Redemption" />

        <h1>New Redemption</h1>
        <RedemptionForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(RedemptionNew, {
  viewer: graphql`
    fragment RedemptionNew_viewer on Admin {
      id
      ...RedemptionForm_viewer
    }
  `,
});
