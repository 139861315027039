/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveSearchTermsInput = {|
  ids: $ReadOnlyArray<?string>
|};
export type RemoveSearchTermsMutationVariables = {|
  input: RemoveSearchTermsInput
|};
export type RemoveSearchTermsMutationResponse = {|
  +removeSearchTerms: ?{|
    +removedSearchTermIds: ?$ReadOnlyArray<?string>
  |}
|};
export type RemoveSearchTermsMutation = {|
  variables: RemoveSearchTermsMutationVariables,
  response: RemoveSearchTermsMutationResponse,
|};
*/


/*
mutation RemoveSearchTermsMutation(
  $input: RemoveSearchTermsInput!
) {
  removeSearchTerms(input: $input) {
    removedSearchTermIds
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveSearchTermsPayload",
    "kind": "LinkedField",
    "name": "removeSearchTerms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedSearchTermIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveSearchTermsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveSearchTermsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e9ed36c415005391ab46f22cd0282903",
    "id": null,
    "metadata": {},
    "name": "RemoveSearchTermsMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveSearchTermsMutation(\n  $input: RemoveSearchTermsInput!\n) {\n  removeSearchTerms(input: $input) {\n    removedSearchTermIds\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f23dd3d46f93d108aac9c2b00bd8215';

module.exports = node;
