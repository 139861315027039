import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { Button, Divider, Menu, Radio, Select, Spin, Switch, Table, Tabs } from 'antd';
import { DatePicker } from '~/components/form';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { getFrontEndUrl } from '~/helper';
import SearchCustomer from './SearchCustomer';
import SearchProduct from './SearchProduct';
import { CurrencyFormatter, PercentageFormatter, RenderCustomDate, CurrentMonth, DateShortcuts, STATES, TIMEZONE, DifferenceFormatter } from './helper';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

const colours = {
  qty: "#8884d8",
  row_total: "#82ca9d",
}

const entityName = 'Performance Report';

const BY_BRAND = {
  default: { value: 'by_brand', label: 'All Brands'},
  local: { value: 'by_brand_local', label: 'Local Brands'},
  import: { value: 'by_brand_import', label: 'Import Brands'},
};

const DATASOURCE = {
  edges: [
    {
      node: {
        id: 'online_instore',
        name: 'Online + In Store (Top 5000)',
      },
    },
    {
      node: {
        id: 'instore',
        name: 'In Store (Top 5000)',
      }
    },
    {
      node: {
        id: 'online',
        name: 'Online'
      },
    },
  ]
};

export const TYPE = ({ byBrand, byBrandFilter = "by_brand" }) => {
  if (byBrand) {
    return byBrandFilter;
  }
    return null;
}

class BySubcategoryReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      barChartReport: PropTypes.shape({
        revenues: PropTypes.number,
        last_year_revenues: PropTypes.number,
      }).isRequired,
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      params: PropTypes.shape({
      }).isRequired,
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { brands, categories, subcategories } = props.viewer;
    const { viewer, match } = this.props;
    const initVariables = match.route.prepareVariables(match.params, match);
    const allSuppliers = get(viewer, "suppliers", []);
    const roles = get(viewer, "roles.edges", []);
    const brandManager = roles.filter(({node}) => (node.name === "Brand Manager"));
    const isBrandManager = brandManager.length > 0;

    this.isImportProducts = () => {
      if (isBrandManager) {
        const defaultValues = get(brandManager[0], "node.defaultValues", []).find(({ name }) => name === "barChartReport");
        const isImportProducts = get(defaultValues, "values.importProducts", []);
        return isImportProducts;
      }

      return false;
    }

    this.defaultSuppliers = () => {
      const importProducts = this.isImportProducts();

      if (isBrandManager && !importProducts) {
        const defaultValues = get(brandManager[0], "node.defaultValues", []).find(({ name }) => name === "barChartReport");
        const suppliers = get(defaultValues, "values.suppliers", []);
        return allSuppliers.filter((supplier) => (suppliers.includes(supplier.code)));
      }

      return allSuppliers;
    }

    const { filterBy,
            orderBy: { field: order },
            byUnlistedProduct = false,
            byDateGpCreated = false,
            dataSource = DATASOURCE.edges[0].node.id,
            byBrandFilter = 'by_brand',
            operators = [],
            locations = [],
            tab = "General",
    } = initVariables;

    let { byBrand = false } = initVariables;

    if (this.isImportProducts()) {
      byBrand = true
    }

    const [ { filter: dateFilter }] = filterBy;
    const {filter: subcategoryId} = filterBy.find(item => item.field === 'subcategory_id') || {};
    const {filter: categoryId} = filterBy.find(item => item.field === 'category_id') || {};
    const {filter: brandId} = filterBy.find(item => item.field === 'brand_id') || {};
    const {filter: custCode} = filterBy.find(item => item.field === 'cust_code') || {};
    const {filter: branches} = filterBy.find(item => item.field === 'branches') || {};
    const {filter: dateGpCreated} = filterBy.find(item => item.field === 'date_GP_created') || {};

    const initDateRange = dateFilter.split(',').map(d => moment(d));
    const initDateGPrange = dateGpCreated?.split(',').map(d => moment(d));

    this.inputs = {
      brand: {
        label: "Brand",
        list: brands,
        onChange: this.onBrandChange,
      },
      category: {
        label: "Category",
        list: categories,
        onChange: this.onCategoryChange,
      },
      dataSource: {
        label: "Data Source",
        list: DATASOURCE,
        onChange: this.onDataSourceChange,
      },
      subcategory: {
        label: "Subcategory",
        list: subcategories,
        onChange: this.onSubcategoryChange,
      },
    };

    this.state = {
      brandId,
      categoryId,
      custCode,
      dateRange: initDateRange,
      dateGpCreated: initDateGPrange,
      dataSource,
      tableSource: [],
      subcategoryId,
      yDataKey: byBrand ? 'brand' :'model',
      byBrand,
      byBrandFilter,
      byDateGpCreated,
      byUnlistedProduct,
      loading: false,
      orderBy: order,
      locations: !isEmpty(locations) ? locations : (branches || "").split(",").filter((branch) => branch),
      operators,
      suppliers: [],
      expandedRowKeys: [],
      custActiveKey: 'Trend',
      activeKey: tab,
      query: this.getCustName(),
      radioValue: 'byMonth',
      tableSortBy: "percentage",
      sortColumn: null,
      sortOrder: null,
      showAllBar: false,
      tagNos: [],
    };
  }

  onDateChange = (dateRange) => {
    this.setState({dateRange}, () => {
      this.refetch();
    });
  }

  onDateGPcreatedChange = (dateGpCreated) => {
    this.setState({dateGpCreated}, () => {
      this.refetch();
    });
  }

  onBrandChange = (brandId) => {
    if (brandId) {
      this.setState({byBrand: false, yDataKey: 'model'});
    }

    this.setState({ brandId, byUnlistedProduct: false }, () => {
      this.refetch();
    });
  }

  onCategoryChange = (categoryId) => {
    this.setState({ categoryId, byUnlistedProduct: false }, () => {
      this.refetch();
    });
  }

  onDataSourceChange = (dataSource) => {
    this.setState({dataSource}, () => {
      this.refetch();
    });
  }

  onSubcategoryChange = (subcategoryId) => {
    this.setState({ subcategoryId, byUnlistedProduct: false }, () => {
      this.refetch();
    });
  }

  onCustActiveKeyChange = (custActiveKey) => {
    if (custActiveKey === 'By Category') {
      this.setState({ yDataKey: 'group_field' });
    }

    this.setState({ custActiveKey});
  }

  onActiveKeyChange = (activeKey) => {
    const { custActiveKey, custCode, byBrand } = this.state;

    this.setState( {loading: true} )

    if (activeKey === 'By Customer' && custCode) {
      this.onCustActiveKeyChange(custActiveKey);
    } else if (activeKey === 'General') {
      this.setState({ yDataKey: byBrand ? 'brand' : 'model' });
    }

    this.setState({ activeKey }, () => { this.refetch() });
  }

  onByBrandChange = (byBrand) => {
    const { byUnlistedProduct } = this.state;

    const yDataKey = byBrand ? "brand" : "model";

    const rest = {};

    if (byBrand && byUnlistedProduct) {
      rest.byUnlistedProduct = false;
    }

    this.setState({ byBrand, yDataKey, brandId: undefined, ...rest }, () => {
      this.refetch();
    });
  }

  onOrderByChange = (orderBy) => {
    this.setState({orderBy}, () => {
      this.refetch();
    });
  }

  onByDateGPCreatedChange = (byDateGpCreated) => {
    this.setState({ byDateGpCreated });

    if (!byDateGpCreated) {
      this.setState({ dateGpCreated: null }, () => {
        this.refetch();
      });
    }
  }

  onByUnlistedProductChange = (byUnlistedProduct) => {
    const { activeKey, byBrand } = this.state;
    const rest = {};

    if (byBrand && byUnlistedProduct) {
      rest.byBrand = false;
    }

    if (activeKey === 'General') {
      this.setState( {yDataKey: 'model'} );
    }

    this.setState({ byUnlistedProduct, expandedRowKeys: [], ...rest }, () => {
      this.refetch();
    });
  }

  onLocationChange = (locations) => {
    this.setState({locations}, () => { this.refetch()} );
  }

  onOperChange = (operators) => {
    this.setState({operators}, () => { this.refetch()} );
  }

  onSupplierChange = (suppliers) => {
    this.setState({suppliers}, () => { this.refetch()} );
  }

  onRadioChange = (e) => {
    this.setState({ radioValue: e.target.value })
  }

  setDateRange = (dateRange) => {
    this.setState({dateRange}, () => { this.refetch() });
  }

  setSearchText = (query, custCode, force = false) => {
    const callback = custCode || force ? this.refetch : null;
    this.setState({query, custCode}, callback);
  };

  setSearchProductText = (tagNos) => {
    this.setState({tagNos}, () => { this.refetch(); });
  };

  getCustName = () => {
    const custName = get(this.props.viewer, 'barChartReport.cust_name', '');
    return custName;
  }

  getConditions = (state) => {
    const { brandId, categoryId, dataSource, subcategoryId } = state;

    const clickHouseData = dataSource !== 'online';

    return {
      clickHouseData,
      showUnlistedProduct: clickHouseData && (!categoryId && !subcategoryId && !brandId),
    }
  }

  getSuppliers = (viewer) => get(viewer, 'suppliers', []).reduce((obj, item) => {
    obj[item.code] = item.name;
    return obj;
  }, {})

  generateMonthRange = (start, end) => {
    const months = [];

    const localStart = moment.tz(start, TIMEZONE).startOf('month');
    const localEnd = moment.tz(end, TIMEZONE).endOf('month');

    while (localStart <= localEnd) {
      // Format the date to 'YYYY-MM'
      months.push(localStart.format('YYYY-MM'));
      // Move to the next month
      localStart.add(1, 'month');
    }

    return months;
  };

  fillMissingMonths = (data, startDate, endDate) => {
    const monthRange = this.generateMonthRange(startDate, endDate);
    const dataMap = new Map(data.map(item => [item.created_year_month, item]));

    return monthRange.map(month => {
      if (dataMap.has(month)) {
          return dataMap.get(month);
      }

      return {
          created_year_month: month,
          gross_amount: 0,
          qty: 0
      };
    });
  };

  groupBrandDataByYear = (data, startDate, endDate) => {
    const startYear = moment.tz(startDate, TIMEZONE).year();
    const endYear = moment.tz(endDate, TIMEZONE).year();

    const grouped = data.reduce((acc, item) => {
      const year = get(item, 'created_year_month', '').split('-')[0];

      if (!acc[year]) {
        acc[year] = {
          created_year_month: year,
          gross_amount: 0,
          qty: 0
        };
      }

      acc[year].gross_amount += item.gross_amount;
      acc[year].qty += item.qty;

      return acc;
    }, {});

    for (let year = startYear; year <= endYear; year += 1) {
      const yearStr = year.toString();
      if (!grouped[yearStr]) {
        grouped[yearStr] = {
          created_year_month: yearStr,
          gross_amount: 0,
          qty: 0
        };
      }
    }

    return Object.values(grouped);
  };

  handleTableSortChange = (pagination, filters, sorter) => {
    this.setState({
        sortColumn: sorter.columnKey,
        sortOrder: sorter.order,
    });
  };

  handleTableSort = (source) => {
    const { sortColumn, sortOrder, tableSortBy } = this.state;

    const sortedData = [...source].sort((a, b) => {
      let aValue; let bValue;
      if (tableSortBy === 'percentage') {
        aValue = get(a.data.percentage, sortColumn, 0);
        bValue = get(b.data.percentage, sortColumn, 0);
      } else {
        aValue = get(a.data, sortColumn, 0);
        bValue = get(b.data, sortColumn, 0);
      }

      if (sortOrder === 'ascend') {
        return aValue - bValue;
      } else if (sortOrder === 'descend') {
          return bValue - aValue;
      }
      return 0;
    });

    this.setState( {tableSource: sortedData, loading: false} );
  }

  prepareVariables = () => {
    const { brandId, byBrand, byBrandFilter, byUnlistedProduct, categoryId, dateRange, dataSource, subcategoryId, custCode, locations, operators, suppliers, dateGpCreated, activeKey, tagNos } = this.state;

    const dateRangeFilter = [moment(dateRange[0]).startOf('day').utc().format(), moment(dateRange[1]).endOf('day').utc().format()].join(",");

    const filterBy = [
      {
        field: "inserted_at",
        filter: dateRangeFilter,
        filterType: "text",
        type: "inRange",
      }
    ];

    if ( dateGpCreated ) {
      const dateGPRangeFilter = [moment(dateGpCreated[0]).startOf('day').utc().format(), moment(dateGpCreated[1]).endOf('day').utc().format()].join(",");

      filterBy.push({
        field: "date_GP_created",
        filter: dateGPRangeFilter,
        filterType: "text",
        type: "inRange",
      })
    }

    if (subcategoryId) {
      filterBy.push({
        field: "subcategory_id",
        filter: subcategoryId,
        filterType: "text",
        type: "equals",
      })
    }

    if (categoryId) {
      filterBy.push({
        field: "category_id",
        filter: categoryId,
        filterType: "text",
        type: "equals",
      })
    }

    if (brandId) {
      filterBy.push({
        field: "brand_id",
        filter: brandId,
        filterType: "text",
        type: "equals",
      })
    }

    if (custCode) {
      filterBy.push({
        field: "cust_code",
        filter: custCode,
        filterType: "text",
        type: "equals",
      })
    }

    if (tagNos.length > 0) {
      filterBy.push({
        field: "tag_no",
        filter: tagNos.join(','),
        filterType: "text",
        type: "equals",
      })
    }

    if (locations.length > 0) {
      filterBy.push({
        field: "branches",
        filter: locations.join(','),
        filterType: "text",
        type: "equals",
      })
    }

    if (operators.length > 0) {
      filterBy.push({
        field: "operators",
        filter: operators.join(','),
        filterType: "text",
        type: "equals",
      })
    }

    if (suppliers.length > 0) {
      filterBy.push({
        field: "supplier_code",
        filter: suppliers.join(','),
        filterType: "text",
        type: "equals",
      })
    }

    const orderBy = {
      field: this.state.orderBy,
      direction: 'desc'
    };

    const type = TYPE({ dataSource, byBrand, byBrandFilter });

    if (byUnlistedProduct) {
      filterBy.push({
        field: "unlisted_product",
        filter: byUnlistedProduct.toString(),
        filterType: "boolean",
        type: "equals",
      })
    }

    return { filterBy, orderBy, dataSource, type, tab: activeKey };
  }

  refetch = () => {
    const { dateRange } = this.state;

    if (!dateRange) {
      return;
    }

    const refetchVariables = this.prepareVariables()

    this.setState({ loading: true });
    this.props.relay.refetch(
      refetchVariables,
      null,
      () => {
        this.setState({ loading: false, sortColumn: null, sortOrder: null, tableSource: [] });
      }
    );
  }

  handleDownload = () => {
    const { filterBy, orderBy, dataSource, type } = this.prepareVariables();
    const DownloadVariables = {filter_by: filterBy, order_by: orderBy, data_source: dataSource, type, tab: this.state.activeKey};
    const encodedParams = encodeURIComponent(JSON.stringify(DownloadVariables));
    const downloadLink = `/api/report/by-category/download?params=${encodedParams}`;

    window.location.href = downloadLink;
  }

  handleBrandClick = (data) => {
    if (this.state.byBrand){
      const brands = get(this.props.viewer, 'brands.edges', []);
      const brand = brands.find(item => item.node.name === data.brand);
      const brandId = brand ? brand.node.id : null;

      this.setState(
        { byBrand: false, brandId, yDataKey: 'model'},
        () => { this.refetch(this.dateRange) }
      );
    }
  }

  handleOperClick = (oper) => {
    this.setState({locations: [], operators: [oper]}, () => { this.refetch() })
  }

  branchCodeToName = (stores = get(this.props.viewer, 'stores.edges', [])) => {
    return (
      stores.reduce((acc, branch) => {
        const { branchCode, name } = branch.node;
        acc[branchCode] = name;
        return acc;
      }, {})
    )
  }

  ExpandedRowContent = (barchartContent, revenuesByStores) => {
    const {yDataKey, orderBy} = this.state;
    return this.renderBarChart(barchartContent, yDataKey, orderBy, revenuesByStores)
  };

  columns = (state = this.state) => {
    const { viewer } = this.props;
    const branchCodeToName = this.branchCodeToName()
    const revenues = get(viewer, 'barChartReport.revenues', 0);
    const lastYearRevenues = get(viewer, 'barChartReport.last_year_revenues', 0);
    const getDiff = (prev, curr) => {
      return (curr - prev)/prev;
    }

    const columns =
      [
        {
          title: <strong>{state.activeKey === "By Store" ? "Store" : "Operator"}</strong>,
          dataIndex: 'entity_id',
          key: 'entity_id',
          align: 'center',
          render: (branchCode) => branchCodeToName[branchCode] || branchCode,
          sorter: (a, b) => {
            const nameA = branchCodeToName[a.entity_id] || a.entity_id;
            const nameB = branchCodeToName[b.entity_id] || b.entity_id;
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: <strong>State</strong>,
          dataIndex: 'state',
          key: 'state',
          align: 'center',
          sorter: (a, b) => a.state.localeCompare(b.state),
          filters: STATES().map(_state => ({ text: _state, value: _state })),
          onFilter: (value, record) => record.state === value,
        },
        {
          title: <strong>No of Invoice</strong>,
          dataIndex: 'no_of_invoice',
          key: 'no_of_invoice',
          align: 'center',
          sorter: (a, b) => a.no_of_invoice - b.no_of_invoice,
        },
        {
          title: <strong>Average Ticket Price</strong>,
          dataIndex: 'average_price_per_invoice',
          key: 'average_price_per_invoice',
          align: 'center',
          render: average => {
            return (
              <div>
                <CurrencyFormatter amount={average} />
              </div>
            );
          },
          sorter: (a, b) => a.average_price_per_invoice - b.average_price_per_invoice,
        },
        {
          title: <strong>Profit</strong>,
          dataIndex: 'profit',
          key: 'profit',
          align: 'center',
          render: profit => {
            return (
              <div>
                <CurrencyFormatter amount={profit} />
              </div>
            );
          },
          sorter: (a, b) => a.profit - b.profit,
        },
        {
          title: <strong>Sales</strong>,
          dataIndex: 'revenues_by_entities',
          key: 'revenues_by_entities',
          align: 'center',
          render: revenuesByStores => {
            return (
              <div>
                <CurrencyFormatter amount={revenuesByStores} />
                <PercentageFormatter numerator={revenuesByStores} denominator={revenues} />
              </div>
            );
          },
          sorter: (a, b) => a.revenues_by_entities - b.revenues_by_entities,
        },
        {
          title: <strong>Last Year Sales</strong>,
          dataIndex: 'last_year_revenues_by_entities',
          key: 'last_year_revenues_by_entities',
          align: 'center',
          render: lastYearRevenuesByStore => {
            return (
              <div>
                <CurrencyFormatter amount={lastYearRevenuesByStore} />
                <PercentageFormatter numerator={lastYearRevenuesByStore} denominator={lastYearRevenues} />
              </div>
            );
          },
          sorter: (a, b) => a.last_year_revenues_by_entities - b.last_year_revenues_by_entities,
        },
        {
          title: <strong>Difference</strong>,
          key: 'difference',
          align: 'center',
          render: (text, record) => <DifferenceFormatter diff={getDiff(record.last_year_revenues_by_entities, record.revenues_by_entities)} />,
          sorter: (a, b) => getDiff(a.last_year_revenues_by_entities, a.revenues_by_entities) - getDiff(b.last_year_revenues_by_entities, b.revenues_by_entities),
        }
      ]

    if (state.activeKey === "By Operator") {
      columns.splice(1, 0,
        {
          title: <strong>Staff Name</strong>,
          dataIndex: 'staff_name',
          key: 'staff_name',
          align: 'center',
          sorter: (a, b) => { return a.staff_name.localeCompare(b.staff_name) }
        },
        {
          title: <strong>Title</strong>,
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          sorter: (a, b) => { return a.title.localeCompare(b.title) }
        },
      )
    }

    return columns;
  }

  trendColumns = () => {
    const { dateRange, orderBy, tableSortBy } = this.state;
    const startDate = moment(dateRange ? dateRange[0] : '');
    const endDate = moment(dateRange ? dateRange[1] : '');

    return (
      [
        {
          title: <strong>{startDate.clone().subtract(3, 'year').format('YYYY-MM-DD')} To {endDate.clone().subtract(3, 'year').format('YYYY-MM-DD')}</strong>,
            dataIndex: 'data',
            key: '0',
            align: 'center',
            render: data => {
              return (
                <div>
                  {orderBy === 'row_total' ? <CurrencyFormatter amount={get(data, "0", 0)} /> : get(data, "0", 0)}
                </div>
              );
            },
            sorter: (a, b) => get(a.data, "0", 0) - get(b.data, "0", 0),
        },
        {
          title: <strong>{startDate.clone().subtract(2, 'year').format('YYYY-MM-DD')} To {endDate.clone().subtract(2, 'year').format('YYYY-MM-DD')}</strong>,
            dataIndex: 'data',
            key: '1',
            align: 'center',
            render: data => {
              return (
                <div>
                  {orderBy === 'row_total' ? <CurrencyFormatter amount={get(data, "1", 0)} /> : get(data, "1", 0)}
                  <DifferenceFormatter diff={get(data, "percentage.1", 0)} />
                </div>
              );
            },
            sorter: (a, b) => {
              if (tableSortBy === 'percentage') {
                return get(a.data, "percentage.1", 0) - get(b.data, "percentage.1", 0);
              }
                return get(a.data, "1", 0) - get(b.data, "1", 0);

            },
        },
        {
          title: <strong>{startDate.clone().subtract(1, 'year').format('YYYY-MM-DD')} To {endDate.clone().subtract(1, 'year').format('YYYY-MM-DD')}</strong>,
            dataIndex: 'data',
            key: '2',
            align: 'center',
            render: data => {
              return (
                <div>
                  {orderBy === 'row_total' ? <CurrencyFormatter amount={get(data, "2", 0)} /> : get(data, "2", 0)}
                  <DifferenceFormatter diff={get(data, "percentage.2", 0)} />
                </div>
              );
            },
            sorter: (a, b) => {
              if (tableSortBy === 'percentage') {
                return get(a.data, "percentage.2", 0) - get(b.data, "percentage.2", 0);
              }
                return get(a.data, "2", 0) - get(b.data, "2", 0);

            },
        },
        {
          title: <strong>{startDate.clone().format('YYYY-MM-DD')} To {endDate.clone().format('YYYY-MM-DD')}</strong>,
            dataIndex: 'data',
            key: '3',
            align: 'center',
            render: data => {
              return (
                <div>
                  {orderBy === 'row_total' ? <CurrencyFormatter amount={get(data, "3", 0)} /> : get(data, "3", 0)}
                  <DifferenceFormatter diff={get(data, "percentage.3", 0)} />
                </div>
              );
            },
            sorter: (a, b) => {
              if (tableSortBy === 'percentage') {
                return get(a.data, "percentage.3", 0) - get(b.data, "percentage.3", 0);
              }
                return get(a.data, "3", 0) - get(b.data, "3", 0);

            },
        },
        {
          title: <strong>Trend</strong>,
            dataIndex: 'data',
            key: 'trend',
            align: 'center',
            render: data => this.renderTableLineChart(data),
        },
      ]
    )
  }

  renderHeader = () => (
    <>
      <Helmet title={`${entityName} List`} />
      <h1>{entityName}</h1>
    </>
  )

  renderTotal = () => {
    const revenues = get(this.props.viewer, 'barChartReport.revenues', 0);
    const lastYearRevenues = get(this.props.viewer, 'barChartReport.last_year_revenues', 0);

    return (
      <div style={{marginBottom: '5px'}}>
        Total Revenue: <CurrencyFormatter amount={revenues} /><br />
        Last Year Revenue: <CurrencyFormatter amount={lastYearRevenues} />
      </div>
    )
  }

  renderOnlineTotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.row_total, 0)

    return (
      <div>
        Total Revenue: <CurrencyFormatter amount={total} />
      </div>
    );
  }

  renderDropdown = (input, val, { disabled = false } = {}) => {
    const node = input.list.edges.find(item => item.node.id === val);
    const defaultValue = node ? node.node.name : val;

    return (
      <Select
        allowClear
        style={{width: '50%', display: 'block', marginTop: '10px'}}
        placeholder={input.label}
        optionFilterProp="children"
        onChange={input.onChange}
        filterOption={(i, option) => option.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
        value={defaultValue}
        showSearch
        disabled={disabled}
      >
        {
        input.list.edges.map((edge) => {
          const e = edge.node;
          return <Option key={e.id} value={e.id}>{e.name}</Option>;
        })
      }
      </Select>
    )
  }

  renderGlobalFilter = (placeholder, value, onChange, source) => {
    return (
      <Select
        allowClear
        style={{width: '50%', display: 'block', marginTop: '10px'}}
        mode="multiple"
        placeholder={placeholder}
        value={value}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(i, option) => option.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
        showSearch
      >
        {
        source.map((e) => {
          return <Option key={e.code} value={e.code}>{`${e.name} (${e.code})`}</Option>;
        })
      }
      </Select>
    )
  }

  renderCustomerFilter = (custCodeToName) => {
    return (
      <Select
        allowClear
        style={{width: '300px'}}
        placeholder="Select a Customer"
        optionFilterProp="children"
        onChange={code => {this.setSearchText(custCodeToName[code], code, true)}}
        filterOption={(i, option) => option.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
        showSearch
      >
        {
        Object.keys(custCodeToName).map((custCode) => {
          const name = custCodeToName[custCode];
          return <Option key={custCode} value={custCode}>{`${name} (${custCode})`}</Option>;
        })
      }
      </Select>
    )
  }

  renderBrandFilter = () => {
    return (
      <Select
        value={this.state.byBrandFilter}
        options={Object.values(BY_BRAND)}
        onChange={(byBrandFilter) => {
          this.setState({ byBrandFilter }, () => {
            this.refetch();
          });
        }}
        placeholder=""
        style={{marginLeft: '20px', minWidth: '200px'}}
      />
    )
  }

  renderBarChart = (source, yDataKey, orderBy, revenues) => {
    const barChartReport = source.length > 1000 && !this.state.showAllBar ? source.slice(0, 1000) : source;

    return(
      <ResponsiveContainer height={barChartReport.length < 50 ? 800 : barChartReport.length * 25}>
        <BarChart
          data={barChartReport}
          layout="vertical"
          onClick={(chart) => {
            const urlSlug = get(chart, 'activePayload[0].payload.url_slug');
            if (urlSlug) {
              window.open(`${getFrontEndUrl()}/product/${urlSlug}`, '_blank');
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey={yDataKey} width={200} interval={0} />
          <Tooltip content={(e) => this.renderToolTip(e, revenues)} />
          <Legend verticalAlign="top" />
          <Bar dataKey={orderBy} fill={colours[orderBy]} onClick={this.handleBrandClick} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  renderLoading = (loading) => {
    if (loading) {
      return <Spin size="large" />;
    }
    return null;
  }

  renderSortBy = (orderBy) => (
    <div>
      Sort By:
      <Select
        placeholder="Sort By"
        defaultValue={orderBy}
        onChange={this.onOrderByChange}
        style={{ width: '100px', display: 'inline-block', marginTop: '10px', marginLeft: '5px' }}
      >
        <Option value="row_total">Revenue</Option>
        <Option value="qty">Qty</Option>
      </Select>
    </div>
  )

  renderShowAllBtn = (source) => {
    const { showAllBar } = this.state;

    if (source?.length > 1000) {
      return (
        <Button style={{margin: '5px'}} onClick={() => {this.setState({showAllBar: !showAllBar})}}>
          {showAllBar ? "Show Less Bars" : "Show All Bars"}
        </Button>
      )
    }
    return null;
  }

  renderTableSortBy = (source) => {
    const {sortColumn, sortOrder} = this.state;

    return (
      <div style={{marginBottom: '10px'}}>
        <strong>Table Sort By:</strong>
        <Radio.Group
          buttonStyle="solid"
          onChange={(e) => {
            this.setState({ tableSortBy: e.target.value }, () => {
              if (sortColumn && sortOrder && sortColumn !== "0") {
                this.setState({ loading: true }, () => {
                  this.handleTableSort(source);
                });
              }
            });
          }}
          value={this.state.tableSortBy}
          style={{marginLeft: '5px'}}
        >
          <Radio.Button value="percentage">Percentage</Radio.Button>
          <Radio.Button value="revenue">Revenue</Radio.Button>
        </Radio.Group>
      </div>
    )
  };

  renderTableSummary = (pageData) => {
    let totalRevenues = 0;
    let totalRevenuesLastYear = 0;

    pageData.forEach((record) => {
      totalRevenues += get(record, "revenues_by_entities", 0);
      totalRevenuesLastYear += get(record, "last_year_revenues_by_entities", 0);
    });

    return (
      <Table.Summary.Row style={{ textAlign: 'center' }}>
        <Table.Summary.Cell index={0} colSpan={this.state.activeKey === "By Store" ? 6 : 8}>
          <strong>Total</strong>
        </Table.Summary.Cell>

        <Table.Summary.Cell index={1}>
          <CurrencyFormatter amount={totalRevenues} />
        </Table.Summary.Cell>

        <Table.Summary.Cell index={2}>
          <CurrencyFormatter amount={totalRevenuesLastYear} />
        </Table.Summary.Cell>

        <Table.Summary.Cell index={3}>
          <DifferenceFormatter diff={(totalRevenues - totalRevenuesLastYear) / totalRevenuesLastYear} />
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  }

  renderTableLineChart = (data) => {
    if (!data) {
      return null;
    }

    const transformedData = [];

    for (let i = 0; i < 4; i += 1) {
        const key = i.toString();
        const value = data[key] || 0;

        transformedData.push({ x: key, y: value });
    }

    return (
      <LineChart data={transformedData} width={80} height={30}>
        <Line type="linear" dataKey="y" stroke={colours[this.state.orderBy]} dot={false} />
      </LineChart>
    );
  }

  renderTrendBarChart = (data) => {
    const { orderBy, dateRange, radioValue } = this.state;
    const yValue = orderBy === 'row_total' ? 'gross_amount' : 'qty';
    const brandTrend = [];
    const brandData = radioValue === 'byMonth' ? this.fillMissingMonths(data, dateRange[0], dateRange[1]) : this.groupBrandDataByYear(data, dateRange[0], dateRange[1])

    brandData.forEach(item => {
      brandTrend.push({ x: item.created_year_month, y: get(item, yValue, 0) });
    });

    return (
      <BarChart
        data={brandTrend}
        margin={{ top: 20, right: 30, left: 50, bottom: 20 }}
        width={radioValue === 'byYear' || brandTrend.length < 20 ? 400 : brandTrend.length * 20}
        height={400}
      >
        <XAxis dataKey="x" angle={-45} textAnchor="end" />
        <YAxis tickFormatter={(tick) => orderBy === 'row_total' ? new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(tick) : tick} />
        <Tooltip content={(e) => this.renderBrandTrendToolTip(e)} />
        <Bar dataKey="y" fill={colours[orderBy]} />
      </BarChart>
    );
  }

  renderCustomerTrend = (data) => {
    if (!data) {
      return null;
    }

    const transformedData = [];
    const { dateRange, orderBy } = this.state;
    const YEAR = dateRange ? dateRange[1].year() : moment().year()

    for (let i = 0; i < 4; i += 1) {
        const key = i.toString();
        const value = data[key] || 0;

        transformedData.push({ x: key, y: value });
    }

    return (
      <BarChart data={transformedData} margin={{ top: 20, right: 30, left: 50, bottom: 20 }} width={400} height={300}>
        <XAxis dataKey="x" tickFormatter={(tick) => `${parseInt(YEAR, 10) + parseInt(tick, 10) - 3}`} />
        <YAxis tickFormatter={(tick) => orderBy === 'row_total' ? new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(tick) : tick} />
        <Tooltip content={(e) => this.renderTrendToolTip(e)} />
        <Bar dataKey="y" fill={colours[orderBy]} />
      </BarChart>
    );
  }

  renderTableByCust = (source) => {
    const { loading, tableSource } = this.state;
    const custCodeToName = source.reduce((acc, item) => {
        const custCode = get(item, "cust_code", '');
        const custName = get(item, "data.cust_name", '');
        acc[custCode] = custName;
        return acc;
    }, {});

    const columns = this.trendColumns()
    columns.splice(0, 0,
      {
        title: <strong>Customer</strong>,
          dataIndex: 'cust_code',
          key: 'cust_code',
          align: 'center',
          filterDropdown: this.renderCustomerFilter(custCodeToName),
          render: custCode => (
            <Button
              onClick={() => {this.setSearchText(custCodeToName[custCode], custCode, true)}}
              style={{
                background: 'none',
                color: '#cb0000',
                border: 'none',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
              }}
            >
              {`${custCodeToName[custCode]} (${custCode})`}
            </Button>
          )
      })

    return (
      <div>
        {this.renderDownloadBtn()}
        {this.renderTableSortBy(source)}
        <Table
          dataSource={
            (tableSource.length > 0 ?
              tableSource.map(item => ({ ...item, key: item.cust_code })) :
              source.map(item => ({ ...item, key: item.cust_code })))
          }
          rowClassName={(_record, index) => {
            return index % 2 === 1 ? "ant-alert-warning" : "";
          }}
          columns={columns}
          loading={loading}
          pagination={{
            position: 'both',
            defaultPageSize: 100,
          }}
          onChange={this.handleTableSortChange}
        />
      </div>
    )
  }

  renderTableBySupplier = (barchart, suppliers) => {
    const { loading, tableSource } = this.state;

    const columns = this.trendColumns()
    columns.splice(0, 0,
      {
        title: <strong>Supplier</strong>,
        dataIndex: 'field',
        key: 'supplier_code',
        align: 'center',
        render: supplierCode => {
          return (
            <Button
              onClick={() => {this.onSupplierChange([supplierCode]); this.setState({activeKey: "By Brand"})}}
              style={{
                background: 'none',
                color: '#cb0000',
                border: 'none',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
              }}
            >
              <span style={{
                display: 'inline-block',
                maxWidth: '30ch',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
              }}
              >
                {`${suppliers[supplierCode]} (${supplierCode})`}
              </span>
            </Button>
          )
        }
      })

    return (
      <div>
        {this.renderTableSortBy(barchart)}
        <Table
          dataSource={
            (tableSource.length > 0 ?
              tableSource.map(item => ({ ...item, key: item.field })) :
              barchart.map(item => ({ ...item, key: item.field })))
          }
          rowClassName={(_record, index) => {
            return index % 2 === 1 ? "ant-alert-warning" : "";
          }}
          columns={columns}
          loading={loading}
          pagination={{
            position: 'both',
            defaultPageSize: 100,
          }}
          onChange={this.handleTableSortChange}
        />
      </div>
    )
  }

  renderTrendTable = (source) => {
    const { loading, activeKey, tableSource } = this.state;

    const entity = {
      'By Subcategory': {
        label: 'Subcategory',
        data: get(this.props.viewer, "subcategories", {}),
        onClick: this.onSubcategoryChange,
      },
      'By Brand': {
        label: 'Brand',
        data: get(this.props.viewer, "brands", {}),
        onClick: this.onBrandChange,
      },
      'By Category': {
        label: 'Category',
        data: get(this.props.viewer, "categories", {}),
        onClick: this.onCategoryChange,
      },
    }

    const getID = (name) => {
      const node = get(entity[activeKey], 'data', []).edges.find(item => item.node.name === name);
      return node ? node.node.id : undefined;
    }

    const columns = this.trendColumns()
    columns.splice(0, 0,
      {
        title: <strong>{get(entity[activeKey], 'label', '')}</strong>,
        dataIndex: 'field',
        key: 'field',
        align: 'center',
        render: field => {
          return (
            <Button
              onClick={() => {entity[activeKey].onClick(getID(field))}}
              style={{
                background: 'none',
                color: '#cb0000',
                border: 'none',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
              }}
            >
              {field}
            </Button>
          )
        }
      })

    return (
      <div>
        {this.renderTableSortBy(source)}
        <Table
          dataSource={
            (tableSource.length > 0 ?
              tableSource.map(item => ({ ...item, key: item.field })) :
              source.map(item => ({ ...item, key: item.field })))
          }
          columns={columns}
          loading={loading}
          rowClassName={(_record, index) => {
            return index % 2 === 1 ? "ant-alert-warning" : "";
          }}
          pagination={{
            position: 'both',
            defaultPageSize: 100,
          }}
          onChange={this.handleTableSortChange}
        />
      </div>
    )
  }

  renderTablebyEntity = (dataSource, state = this.state) => {
    return (
      <div>
        {this.renderDownloadBtn()}
        <Table
          dataSource={dataSource.map(item => ({ ...item, key: `${item.entity_id} ${item.state}` }))}
          columns={this.columns(state)}
          pagination={{
            position: 'both',
            defaultPageSize: 1000,
          }}
          summary={this.renderTableSummary}
          rowClassName={(_record, index) => {
            return index % 2 === 1 ? "ant-alert-warning" : "";
          }}
          expandable={{
            expandedRowRender: (record) => this.ExpandedRowContent(record.barchart, record.revenues_by_entities),
            rowExpandable: (record) => !!record.barchart,
            expandedRowKeys: state.expandedRowKeys,
            onExpandedRowsChange: (keys) => this.setState({ expandedRowKeys: keys }),
          }}
        />
      </div>
    )
  }

  renderToolTip = ({payload, label}, revenues) => {
    if (typeof label === 'undefined' || !payload || payload.length !== 1) {
      return null;
    }

    return (
      <div
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <div>{label}</div>
        {payload[0].payload.name && <div>Name: {payload[0].payload.name}</div>}
        <div style={{color: colours.qty}}>Qty: {payload[0].payload.qty}</div>
        <div style={{color: colours.qty}}>Stock: {payload[0].payload.stock}</div>
        <div style={{color: colours.row_total}}>
          Revenue: <CurrencyFormatter amount={payload[0].payload.row_total} />
          <PercentageFormatter numerator={payload[0].payload.row_total} denominator={revenues} />
        </div>
        <div>
          <img width="50" src={`/assets/images/products/${payload[0].payload.image}`} alt="" />
        </div>
      </div>
    );
  }

  renderTrendToolTip = ({ active, payload }) => {
    if (!this.state.dateRange) {
      return null;
    }

    const startDate = moment(this.state.dateRange[0]);
    const endDate = moment(this.state.dateRange[1]);

    if (active && payload && payload.length) {
      const { x, y } = payload[0].payload;

      const adjustedStartDate = startDate.clone().subtract(3 - x, 'year').format('YYYY-MM-DD');
      const adjustedEndDate = endDate.clone().subtract(3 - x, 'year').format('YYYY-MM-DD');

      return (
        <div
          style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
        >
          <p><strong>Period: </strong>{`${adjustedStartDate} To ${adjustedEndDate}`}</p>

          {this.state.orderBy === 'row_total' ?
            <p><strong>Revenue: </strong><CurrencyFormatter amount={y} /></p>
           :
            <p><strong>Qty: </strong>{y}</p>
          }
        </div>
      );
    }

    return null;
  }

  renderBrandTrendToolTip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { x, y } = payload[0].payload;

      return (
        <div
          style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
        >
          <p><strong>Period: </strong>{x}</p>

          {this.state.orderBy === 'row_total' ?
            <p><strong>Revenue: </strong><CurrencyFormatter amount={y} /></p>
           :
            <p><strong>Qty: </strong>{y}</p>
          }
        </div>
      );
    }

    return null;
  }

  renderCustomDateDropdown = () => {
    const { loading } = this.state;

    const menu = (
      <Menu style={{display: 'flex', alignContent: 'center', justifyContent: 'center', 'flexDirection': 'column'}}>
        <Menu.Item key="currentMonth">
          <CurrentMonth setDateRange={this.setDateRange} />
        </Menu.Item>
        <Menu.Item key="customDateDays">
          <RenderCustomDate indicator='days' setDateRange={this.setDateRange} loading={loading} />
        </Menu.Item>
        <Menu.Item key="customDateMonths">
          <RenderCustomDate indicator='months' setDateRange={this.setDateRange} loading={loading} />
        </Menu.Item>
      </Menu>
    );

    return (
      <DateShortcuts menu={menu} />
    )
  }

  renderCustTabs = () => {
    const { custActiveKey, yDataKey, orderBy } = this.state;
    const { viewer } = this.props;

    const customerLineChart = get(viewer, 'barChartReport.trend', []);
    const customerBarchartByCat = get(viewer, 'barChartReport.by_category', []);
    const revenues = get(viewer, 'barChartReport.revenues', 0);

    return (
      <Tabs activeKey={custActiveKey} onChange={this.onCustActiveKeyChange}>
        <TabPane tab="Trend" key="Trend">
          {customerLineChart.length > 0 && this.renderCustomerTrend(customerLineChart[0].data)}
        </TabPane>

        <TabPane tab="By Category" key="By Category">
          {customerBarchartByCat.length > 0 && this.renderBarChart(customerBarchartByCat, yDataKey, orderBy, revenues)}
        </TabPane>
      </Tabs>
    )
  }

  renderTrendTabs = (source, Id) => {
    const { yDataKey, orderBy, loading } = this.state;
    const revenues = get(this.props.viewer, 'barChartReport.revenues', 0);

    return (
      <div>
        {this.renderDownloadBtn()}
        {source.length > 0 && Id && (
        <div>
          <strong style={{marginRight: '15px'}}>{source[0].field}</strong>
          <Radio.Group buttonStyle="solid" onChange={this.onRadioChange} value={this.state.radioValue} style={{marginBottom: '15px'}}>
            <Radio.Button value="byMonth">By Month</Radio.Button>
            <Radio.Button value="byYear">By Year</Radio.Button>
          </Radio.Group>
        </div>
        )}

        {source.length > 0 && !Id && !loading && this.renderTrendTable(source, yDataKey, orderBy, revenues)}
        {source.length > 0 && Id && this.renderTrendBarChart(source)}
      </div>
    )
  }

  renderDownloadBtn = () => (
    <Button style={{marginTop: '5px', marginBottom: '5px'}} onClick={this.handleDownload}>
      Download
    </Button>
  )

  render() {
    const { viewer } = this.props;
    const barChartReport = get(viewer, 'barChartReport.barchart', []);
    const general = get(viewer, 'barChartReport.general', []);
    const bySubcategory = get(viewer, 'barChartReport.overall_by_subcategory', []);
    const byCategory = get(viewer, 'barChartReport.overall_by_category', []);
    const brandTrend = get(viewer, 'barChartReport.brand_trend', []);
    const supplierTrend = get(viewer, 'barChartReport.overall_by_suppliers', []);
    const revenues = get(viewer, 'barChartReport.revenues', 0);
    const { brandId, byBrand, categoryId, custCode, dateRange, dataSource, byUnlistedProduct, loading, orderBy, subcategoryId, yDataKey, query, activeKey, byDateGpCreated, dateGpCreated, tagNos } = this.state;
    const branches = get(viewer, "stores.edges", []).map(store => ({
      code: store.node.branchCode,
      name: store.node.name
    }));

    const { clickHouseData, showUnlistedProduct } = this.getConditions(this.state);

    const suppliers = this.getSuppliers(viewer);

    return (
      <div>
        {this.renderHeader()}

        <RangePicker key={dateRange} value={dateRange} onChange={this.onDateChange} timezone={TIMEZONE} />

        {this.renderCustomDateDropdown()}

        {this.renderDropdown(this.inputs.dataSource, dataSource)}

        {this.renderDropdown(this.inputs.category, categoryId)}

        {this.renderDropdown(this.inputs.subcategory, subcategoryId)}

        {this.renderDropdown(this.inputs.brand, brandId)}

        {clickHouseData && this.renderGlobalFilter("Supplier", this.state.suppliers, this.onSupplierChange, this.defaultSuppliers())}

        {clickHouseData && this.renderGlobalFilter("Opeator", this.state.operators, this.onOperChange, get(viewer, "staff", []))}

        {clickHouseData && this.renderGlobalFilter("Store", this.state.locations, this.onLocationChange, branches)}

        {clickHouseData && <SearchCustomer viewer={this.props.viewer} searchQuery={query} setSearchText={this.setSearchText} />}
        {clickHouseData && <SearchProduct viewer={this.props.viewer} tagNos={tagNos} setSearchText={this.setSearchProductText} />}

        {this.renderSortBy(orderBy)}

        <div style={{marginTop: '5px'}}>
          Date GP Created: <Switch style={{"marginRight": "10px"}} defaultChecked={byDateGpCreated} checked={byDateGpCreated} onChange={this.onByDateGPCreatedChange}  />

          {byDateGpCreated && <RangePicker key={dateGpCreated} value={dateGpCreated} onChange={this.onDateGPcreatedChange} />}
        </div>

        {!this.isImportProducts() && (
        <div style={{marginTop: '5px'}}>
          By Brand: <Switch defaultChecked={byBrand} checked={byBrand} onChange={this.onByBrandChange}  />

          {byBrand && this.renderBrandFilter()}
        </div>
)}

        {showUnlistedProduct && (
          <div style={{ marginTop: '5px' }}>
            By Unlisted Product: <Switch defaultValue={byUnlistedProduct} checked={byUnlistedProduct} onChange={this.onByUnlistedProductChange} />
          </div>
        )}
        <Divider />

        {this.renderLoading(loading)}

        {clickHouseData ? this.renderTotal() : this.renderOnlineTotal(barChartReport)}

        {!clickHouseData && barChartReport.length > 0 && this.renderBarChart(barChartReport, yDataKey, orderBy, revenues)}

        {clickHouseData && (
        <Tabs activeKey={activeKey} onChange={this.onActiveKeyChange}>
          <TabPane tab="General" key="General">
            {this.renderDownloadBtn()}
            {this.renderShowAllBtn(general)}
            {general && this.renderBarChart(general, "model", orderBy, revenues)}
          </TabPane>

          <TabPane tab="By Subcategory" key="By Subcategory">
            {this.renderTrendTabs(bySubcategory, subcategoryId)}
          </TabPane>

          <TabPane tab="By Category" key="By Category">
            {this.renderTrendTabs(byCategory, categoryId)}
          </TabPane>

          <TabPane tab="By Brand" key="By Brand">
            {this.renderTrendTabs(brandTrend, brandId)}
          </TabPane>

          <TabPane tab="By Supplier" key="By Supplier">
            {this.renderDownloadBtn()}
            {!loading && this.renderTableBySupplier(supplierTrend, suppliers)}
          </TabPane>

          <TabPane tab="By Operator" key="By Operator">
            {!loading && activeKey === "By Operator" && this.renderTablebyEntity(barChartReport)}
          </TabPane>

          <TabPane tab="By Store" key="By Store">
            {!loading && activeKey === "By Store" && this.renderTablebyEntity(barChartReport)}
          </TabPane>

          <TabPane tab="By Customer" key="By Customer">
            {!custCode && activeKey === "By Customer" && !loading && this.renderTableByCust(barChartReport)}
            {custCode && activeKey === "By Customer" && !loading && this.renderCustTabs()}
          </TabPane>
        </Tabs>
)}
      </div>
    );
  }
}

export default BySubcategoryReport;
