import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../mutation';

const mutation = graphql`
  mutation AddReviewReplyMutation($input: AddReviewReplyInput!) {
    addReviewReply(input:$input) {
      replyEdge {
        __typename
        cursor
        node {
          id
          customer {
            firstname
            lastname
          }
          customerName
          content
          status
          insertedAt
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addReviewReply', edgeName: 'replyEdge', connectionKey: 'ReviewView_replies' }),
});

export default { commit };

