/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFooterInput = {|
  id: string,
  name?: ?string,
  position?: ?number,
  status?: ?boolean,
  urls?: ?$ReadOnlyArray<?InputFooterUrls>,
|};
export type InputFooterUrls = {|
  name: string,
  position: number,
  properties?: ?string,
  type: string,
  url: string,
|};
export type UpdateFooterMutationVariables = {|
  input: UpdateFooterInput
|};
export type UpdateFooterMutationResponse = {|
  +updateFooter: ?{|
    +footer: ?{|
      +id: string,
      +name: ?string,
      +position: ?number,
      +urls: ?$ReadOnlyArray<?{|
        +name: ?string,
        +type: ?string,
        +url: ?string,
        +properties: ?string,
        +position: ?number,
      |}>,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateFooterMutation = {|
  variables: UpdateFooterMutationVariables,
  response: UpdateFooterMutationResponse,
|};
*/


/*
mutation UpdateFooterMutation(
  $input: UpdateFooterInput!
) {
  updateFooter(input: $input) {
    footer {
      id
      name
      position
      urls {
        name
        type
        url
        properties
        position
      }
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFooterPayload",
    "kind": "LinkedField",
    "name": "updateFooter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Footer",
        "kind": "LinkedField",
        "name": "footer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FooterUrl",
            "kind": "LinkedField",
            "name": "urls",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "properties",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFooterMutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateFooterMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "db66b40e7e7e911f9b5e5683754eec1e",
    "id": null,
    "metadata": {},
    "name": "UpdateFooterMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFooterMutation(\n  $input: UpdateFooterInput!\n) {\n  updateFooter(input: $input) {\n    footer {\n      id\n      name\n      position\n      urls {\n        name\n        type\n        url\n        properties\n        position\n      }\n      status\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e99134a704c36be8b8b12cedaf26b896';

module.exports = node;
