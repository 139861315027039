import React from 'react';
import Download from './Download';

const bulkActions = [
  {
    title: 'Download',
    node: <Download />,
  },
];

export default bulkActions;
