import AddCmsBannerMutation from './AddCmsBannerMutation';
import RemoveCmsBannerMutation from './RemoveCmsBannerMutation';
import UpdateCmsBannerMutation from './UpdateCmsBannerMutation';

module.exports = {
  AddCmsBannerMutation,
  RemoveCmsBannerMutation,
  UpdateCmsBannerMutation,
};

