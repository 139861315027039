/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UpdatePricesSchedule_brand_viewer$ref = any;
type UpdatePricesSchedule_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UpdatePrices_viewer$ref: FragmentReference;
declare export opaque type UpdatePrices_viewer$fragmentType: UpdatePrices_viewer$ref;
export type UpdatePrices_viewer = {|
  +id: string,
  +$fragmentRefs: UpdatePricesSchedule_brand_viewer$ref & UpdatePricesSchedule_viewer$ref,
  +$refType: UpdatePrices_viewer$ref,
|};
export type UpdatePrices_viewer$data = UpdatePrices_viewer;
export type UpdatePrices_viewer$key = {
  +$data?: UpdatePrices_viewer$data,
  +$fragmentRefs: UpdatePrices_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdatePrices_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdatePricesSchedule_brand_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdatePricesSchedule_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0eaa25b72148ac8e64a2e9c25fc4cc73';

module.exports = node;
