import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, Select } from 'antd';
import { DatePicker, FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

class AlertForm extends FormBase {
  static propTypes = {
    alert: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      content: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    alert: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match } = this.props;
    const a = get(this.props, 'alert', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={a.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Name"
          name="name"
          rules={[{ required: true, message: 'required' }]}
          initialValue={a.name}
        >
          <Input placeholder="Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Content"
          name="content"
          rules={[{ required: true, message: 'required' }]}
          initialValue={a.content}
        >
          <TextArea rows={5} />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Start Date"
          name="startDate"
          rules={[{ required: true, message: 'required' }]}
          initialValue={a.startDate ? moment(a.startDate) : null}
        >
          <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="End Date"
          name="endDate"
          rules={[{ required: true, message: 'required' }]}
          initialValue={a.endDate ? moment(a.endDate) : null}
          help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
        >
          <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(a, 'status', 0) ? 1 : 0}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

        <FormItem
          name="type"
          rules={[{ required: true, message: 'required' }]}
          initialValue={1}
          hidden
        >
          <Input />
        </FormItem>
      </Form>
    );
  }
}
export default createFragmentContainer(AlertForm, {
  viewer: graphql`
    fragment AlertForm_viewer on Admin {
      id
    }
  `,
});
