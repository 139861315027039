import React from 'react';
import { Route } from 'found';

import AdjacentBannerRoutes from './adjacent_banner/routes';
import BonusRoutes from './bonus/routes';
import BuildComboRoutes from './combo_freebie/routes';
import CouponRoutes from './coupon/routes';
import CreditRoutes from './credit/routes';
import FreebieRoutes from './freebie/routes';
import PromoCardRoutes from './promo_card/routes';
import RedemptionRoutes from './redemption/routes';
import LabelRoutes from './label/routes';
import CommonPromoRoutes from './common_promo/routes';
import PurchaseLimitRoutes from './purchase_limit/routes';
import AlertRoutes from './alert/routes';

const routes = (
  <Route
    path="/promotion"
  >
    {AdjacentBannerRoutes}
    {FreebieRoutes}
    {CreditRoutes}
    {BonusRoutes}
    {BuildComboRoutes}
    {LabelRoutes}
    {RedemptionRoutes}
    {CouponRoutes}
    {PromoCardRoutes}
    {PurchaseLimitRoutes}
    {CommonPromoRoutes}
    {AlertRoutes}
  </Route>
);

export default routes;
