/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RedemptionHistory_redemption$ref = any;
export type UpdateRedemptionInput = {|
  brandIds?: ?$ReadOnlyArray<?string>,
  endDate?: ?string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  id: string,
  images?: ?$ReadOnlyArray<?InputRedemptionImage>,
  name?: ?string,
  onProducts?: ?$ReadOnlyArray<?string>,
  redeemables?: ?$ReadOnlyArray<?string>,
  startDate?: ?string,
  status?: ?boolean,
|};
export type InputRedemptionImage = {|
  file?: ?any,
  id?: ?string,
|};
export type UpdateRedemptionMutationVariables = {|
  input: UpdateRedemptionInput
|};
export type UpdateRedemptionMutationResponse = {|
  +updateRedemption: ?{|
    +redemption: ?{|
      +name: ?string,
      +brandIds: ?$ReadOnlyArray<?string>,
      +startDate: ?string,
      +endDate: ?string,
      +excludeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +status: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +onProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +redeemables: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +status: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +images: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +imageUrl: ?string,
          |}
        |}>
      |},
      +status: ?boolean,
      +$fragmentRefs: RedemptionHistory_redemption$ref,
    |}
  |}
|};
export type UpdateRedemptionMutation = {|
  variables: UpdateRedemptionMutationVariables,
  response: UpdateRedemptionMutationResponse,
|};
*/


/*
mutation UpdateRedemptionMutation(
  $input: UpdateRedemptionInput!
) {
  updateRedemption(input: $input) {
    redemption {
      name
      brandIds
      startDate
      endDate
      excludeProducts(first: 999) {
        edges {
          node {
            id
            name
            status
            mainImage {
              id
              url
            }
          }
        }
      }
      onProducts(first: 999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      redeemables(first: 999) {
        edges {
          node {
            id
            name
            status
            mainImage {
              id
              url
            }
          }
        }
      }
      images(first: 999) {
        edges {
          node {
            id
            imageUrl
          }
        }
      }
      status
      ...RedemptionHistory_redemption
      id
    }
  }
}

fragment RedemptionHistory_redemption on Redemption {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandIds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v2/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "excludeProducts",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "excludeProducts(first:999)"
},
v12 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "onProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            (v2/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "onProducts(first:999)"
},
v13 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "redeemables",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "redeemables(first:999)"
},
v14 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "RedemptionImageConnection",
  "kind": "LinkedField",
  "name": "images",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RedemptionImageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RedemptionImage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "images(first:999)"
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRedemptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRedemptionPayload",
        "kind": "LinkedField",
        "name": "updateRedemption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Redemption",
            "kind": "LinkedField",
            "name": "redemption",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RedemptionHistory_redemption"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRedemptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRedemptionPayload",
        "kind": "LinkedField",
        "name": "updateRedemption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Redemption",
            "kind": "LinkedField",
            "name": "redemption",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v8/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "RedemptionHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13c15aedf34fbe2e1293092a3919135e",
    "id": null,
    "metadata": {},
    "name": "UpdateRedemptionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRedemptionMutation(\n  $input: UpdateRedemptionInput!\n) {\n  updateRedemption(input: $input) {\n    redemption {\n      name\n      brandIds\n      startDate\n      endDate\n      excludeProducts(first: 999) {\n        edges {\n          node {\n            id\n            name\n            status\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      onProducts(first: 999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      redeemables(first: 999) {\n        edges {\n          node {\n            id\n            name\n            status\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      images(first: 999) {\n        edges {\n          node {\n            id\n            imageUrl\n          }\n        }\n      }\n      status\n      ...RedemptionHistory_redemption\n      id\n    }\n  }\n}\n\nfragment RedemptionHistory_redemption on Redemption {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '218c35fe954694a61649a824afcb6a91';

module.exports = node;
