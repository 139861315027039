/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AttributeForm_viewer$ref = any;
type AttributeHistory_attribute$ref = any;
type WebsiteRef_attribute$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AttributeView_viewer$ref: FragmentReference;
declare export opaque type AttributeView_viewer$fragmentType: AttributeView_viewer$ref;
export type AttributeView_viewer = {|
  +attributes: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +code: ?string,
        +options: ?any,
        +dictates: ?any,
        +multi: ?boolean,
        +visibleToFrontend: ?boolean,
        +includeInSearch: ?boolean,
        +position: ?number,
        +images: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +imageUrl: ?string,
              +option: ?string,
              +brands: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                  |}
                |}>
              |},
              +categories: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                  |}
                |}>
              |},
              +subcategories: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +$fragmentRefs: AttributeHistory_attribute$ref & WebsiteRef_attribute$ref,
      |}
    |}>
  |},
  +$fragmentRefs: AttributeForm_viewer$ref,
  +$refType: AttributeView_viewer$ref,
|};
export type AttributeView_viewer$data = AttributeView_viewer;
export type AttributeView_viewer$key = {
  +$data?: AttributeView_viewer$data,
  +$fragmentRefs: AttributeView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttributeView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "AttributeConnection",
      "kind": "LinkedField",
      "name": "attributes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AttributeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Attribute",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "options",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dictates",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "multi",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "visibleToFrontend",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeInSearch",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v2/*: any*/),
                  "concreteType": "AttributeImageConnection",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AttributeImageEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AttributeImage",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "imageUrl",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "option",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": (v2/*: any*/),
                              "concreteType": "BrandConnection",
                              "kind": "LinkedField",
                              "name": "brands",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BrandEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Brand",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": (v3/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "brands(first:9999)"
                            },
                            {
                              "alias": null,
                              "args": (v2/*: any*/),
                              "concreteType": "CategoryConnection",
                              "kind": "LinkedField",
                              "name": "categories",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CategoryEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Category",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": (v3/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "categories(first:9999)"
                            },
                            {
                              "alias": null,
                              "args": (v2/*: any*/),
                              "concreteType": "SubcategoryConnection",
                              "kind": "LinkedField",
                              "name": "subcategories",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SubcategoryEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Subcategory",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": (v3/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "subcategories(first:9999)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "images(first:9999)"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AttributeHistory_attribute"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WebsiteRef_attribute"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttributeForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '037b728d0d4735d5b272b2a5a764c245';

module.exports = node;
