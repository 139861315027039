import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import SearchCanonicalForm from './SearchCanonicalForm';

import { UpdateSearchCanonicalMutation } from "./mutations";
import RemoveSearchCanonicalMutation from './mutations/RemoveSearchCanonicalMutation';

// eslint-disable-next-line camelcase
const SearchCanonicalView_viewer = graphql`
  fragment SearchCanonicalView_viewer on Admin {
    id
    searchCanonicals(first: 1, ids: $ids) {
      edges {
        node {
          id
          queries
          url
          status
        }
      }
    }
  }
`;

const SearchCanonicalView = (props) => {
  const { match, router } = props;
  const viewer = useFragment(SearchCanonicalView_viewer, props.viewer);
  const environment = useRelayEnvironment();

  const canonical = get(viewer, 'searchCanonicals.edges[0].node', {});
  const title = get(canonical, 'queries', []).join(', ');

  const handleSubmit = (form, values) => {
    const mutation = {
      environment,
      variables: { input: values },
      viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    }

    UpdateSearchCanonicalMutation.commit(mutation);
  }

  const handleRemove = (id) => {
    const mutation = {
      environment,
      variables: { input: { id } },
      viewer,
      safeGuard: "delete",
      onCompleted: () => {
        router.push("/search/canonical");
      },
    }

    RemoveSearchCanonicalMutation.commit(mutation);
  }

  return (
    <div>
      <Helmet title={title} />
      <h1>{title}</h1>

      <SearchCanonicalForm
        key={canonical.id}
        viewer={viewer}
        match={match}
        onSubmit={handleSubmit}
        remove={handleRemove}
      />
    </div>
  )
}
SearchCanonicalView.propTypes = {
  match: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  viewer: PropTypes.shape({}).isRequired,
}

export default SearchCanonicalView;
