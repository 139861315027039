/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddStateManagerInput = {|
  areaManagers?: ?$ReadOnlyArray<?InputAreaManager>,
  dispatchAssistants?: ?$ReadOnlyArray<?string>,
  emails: $ReadOnlyArray<?string>,
  state: string,
|};
export type InputAreaManager = {|
  email: string,
  id?: ?string,
  stores: $ReadOnlyArray<?string>,
|};
export type AddStateManagerMutationVariables = {|
  input: AddStateManagerInput
|};
export type AddStateManagerMutationResponse = {|
  +addStateManager: ?{|
    +stateManagerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +emails: ?$ReadOnlyArray<?string>,
        +state: ?string,
        +areaManagers: ?$ReadOnlyArray<?{|
          +id: string,
          +email: ?string,
          +stores: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
        |}>,
        +stateManagerAssistants: ?$ReadOnlyArray<?{|
          +email: ?string,
          +roles: ?$ReadOnlyArray<?string>,
        |}>,
      |},
    |}
  |}
|};
export type AddStateManagerMutation = {|
  variables: AddStateManagerMutationVariables,
  response: AddStateManagerMutationResponse,
|};
*/


/*
mutation AddStateManagerMutation(
  $input: AddStateManagerInput!
) {
  addStateManager(input: $input) {
    stateManagerEdge {
      cursor
      node {
        id
        emails
        state
        areaManagers {
          id
          email
          stores {
            id
          }
        }
        stateManagerAssistants {
          email
          roles
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AreaManager",
  "kind": "LinkedField",
  "name": "areaManagers",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Store",
      "kind": "LinkedField",
      "name": "stores",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddStateManagerPayload",
        "kind": "LinkedField",
        "name": "addStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManagerEdge",
            "kind": "LinkedField",
            "name": "stateManagerEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StateManager",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StateManagerAssistant",
                    "kind": "LinkedField",
                    "name": "stateManagerAssistants",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddStateManagerPayload",
        "kind": "LinkedField",
        "name": "addStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManagerEdge",
            "kind": "LinkedField",
            "name": "stateManagerEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StateManager",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StateManagerAssistant",
                    "kind": "LinkedField",
                    "name": "stateManagerAssistants",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be8ed10381c7c1b0c19c6b875cb92092",
    "id": null,
    "metadata": {},
    "name": "AddStateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation AddStateManagerMutation(\n  $input: AddStateManagerInput!\n) {\n  addStateManager(input: $input) {\n    stateManagerEdge {\n      cursor\n      node {\n        id\n        emails\n        state\n        areaManagers {\n          id\n          email\n          stores {\n            id\n          }\n        }\n        stateManagerAssistants {\n          email\n          roles\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d871996dbdd67f322077a26bf984105';

module.exports = node;
