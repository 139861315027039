import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation SearchPostcodeSuburbMutation($input: SearchPostcodeSuburbInput!) {
    searchPostcodeSuburb(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };

