/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CopyProduct_viewer$ref: FragmentReference;
declare export opaque type CopyProduct_viewer$fragmentType: CopyProduct_viewer$ref;
export type CopyProduct_viewer = {|
  +copyBonus?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +copyRedemption?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +copyCredit?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +copyFreebie?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +copyCategory?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +$refType: CopyProduct_viewer$ref,
|};
export type CopyProduct_viewer$data = CopyProduct_viewer;
export type CopyProduct_viewer$key = {
  +$data?: CopyProduct_viewer$data,
  +$fragmentRefs: CopyProduct_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "desc",
      "field": "id"
    }
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isBonus"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isCategory"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isCredit"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isFreebie"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isRedemption"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CopyProduct_viewer",
  "selections": [
    {
      "condition": "isBonus",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "copyBonus",
          "args": (v0/*: any*/),
          "concreteType": "BonusConnection",
          "kind": "LinkedField",
          "name": "bonuses",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BonusEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Bonus",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "bonuses(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
        }
      ]
    },
    {
      "condition": "isRedemption",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "copyRedemption",
          "args": (v0/*: any*/),
          "concreteType": "RedemptionConnection",
          "kind": "LinkedField",
          "name": "redemptions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RedemptionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Redemption",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "redemptions(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
        }
      ]
    },
    {
      "condition": "isCredit",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "copyCredit",
          "args": (v0/*: any*/),
          "concreteType": "CreditConnection",
          "kind": "LinkedField",
          "name": "credits",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreditEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Credit",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "credits(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
        }
      ]
    },
    {
      "condition": "isFreebie",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "copyFreebie",
          "args": (v0/*: any*/),
          "concreteType": "FreebieConnection",
          "kind": "LinkedField",
          "name": "freebies",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FreebieEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Freebie",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "freebies(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
        }
      ]
    },
    {
      "condition": "isCategory",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "copyCategory",
          "args": (v0/*: any*/),
          "concreteType": "CategoryConnection",
          "kind": "LinkedField",
          "name": "categories",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CategoryEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Category",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "categories(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
        }
      ]
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b10df19c294b3f119a7f7391dc17e9b7';

module.exports = node;
