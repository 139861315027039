import React from 'react';
import AssignProductsToCategories from './AssignProductsToCategories';
import AssignProductsToSubcategories from './AssignProductsToSubcategories';
import BulkUpdateProduct from './BulkUpdateProduct';
import CopyFromWebsite from './CopyFromWebsite';
import ExportProducts from './ExportProducts';
import ImportProducts from './ImportProducts';
import RemoveCategoriesFromProducts from './RemoveCategoriesFromProducts';
import RemoveSubcategoriesFromProducts from './RemoveSubcategoriesFromProducts';
import UpdatePrices from './UpdatePrices';

const bulkActions = [
  {
    title: 'Assign Subcategories',
    node: <AssignProductsToSubcategories />,
  },
  {
    title: 'Assign Categories',
    node: <AssignProductsToCategories />,
  },
  {
    title: 'Remove Subcategories',
    node: <RemoveSubcategoriesFromProducts />,
  },
  {
    title: 'Remove Categories',
    node: <RemoveCategoriesFromProducts />,
  },
  {
    title: 'Update Product',
    node: <BulkUpdateProduct />,
  },
  {
    title: 'Export Products',
    node: <ExportProducts />,
  },
  {
    title: 'Import Products',
    node: <ImportProducts />,
  },
  {
    title: 'Update Price Drops',
    node: <UpdatePrices priceDrop />,
  },
  {
    title: 'Update Prices',
    node: <UpdatePrices />,
  },
];

if (window.location.host.includes('co.nz')) {
  bulkActions.push({
    title: 'Copy Products from AU',
    node: <CopyFromWebsite />,
  });
}

export default bulkActions;
