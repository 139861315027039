/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CouponHistory_coupon$ref = any;
export type UpdateCouponInput = {|
  autoApply?: ?boolean,
  codes?: ?$ReadOnlyArray<?string>,
  content?: ?$ReadOnlyArray<?InputCouponContent>,
  endDate?: ?string,
  id: string,
  name?: ?string,
  showLabel?: ?boolean,
  startDate?: ?string,
  status?: ?boolean,
  usageLimit?: ?number,
  usagePerCustomer?: ?number,
|};
export type InputCouponContent = {|
  actions?: ?InputCouponCondActObject,
  additionDate?: ?string,
  additionProductIds?: ?$ReadOnlyArray<?string>,
  conditions?: ?InputCouponCondActObject,
  discountAmount?: ?number,
  discountType?: ?number,
  id?: ?string,
  removalDate?: ?string,
  removalProductIds?: ?$ReadOnlyArray<?string>,
|};
export type InputCouponCondActObject = {|
  comparator: string,
  dataType: string,
  key: string,
  value: $ReadOnlyArray<?string>,
|};
export type UpdateCouponMutationVariables = {|
  input: UpdateCouponInput
|};
export type UpdateCouponMutationResponse = {|
  +updateCoupon: ?{|
    +coupon: ?{|
      +name: ?string,
      +codes: ?$ReadOnlyArray<?string>,
      +content: ?$ReadOnlyArray<?{|
        +discountType: ?number,
        +discountAmount: ?number,
        +conditions: ?any,
        +actions: ?any,
        +removalDate: ?string,
        +removalProducts: ?any,
        +additionDate: ?string,
        +additionProducts: ?any,
      |}>,
      +usageLimit: ?number,
      +usagePerCustomer: ?number,
      +timesUsed: ?number,
      +startDate: ?string,
      +endDate: ?string,
      +status: ?boolean,
      +showLabel: ?boolean,
      +autoApply: ?boolean,
      +$fragmentRefs: CouponHistory_coupon$ref,
    |}
  |}
|};
export type UpdateCouponMutation = {|
  variables: UpdateCouponMutationVariables,
  response: UpdateCouponMutationResponse,
|};
*/


/*
mutation UpdateCouponMutation(
  $input: UpdateCouponInput!
) {
  updateCoupon(input: $input) {
    coupon {
      name
      codes
      content {
        discountType
        discountAmount
        conditions
        actions
        removalDate
        removalProducts
        additionDate
        additionProducts
        id
      }
      usageLimit
      usagePerCustomer
      timesUsed
      startDate
      endDate
      status
      showLabel
      autoApply
      ...CouponHistory_coupon
      id
    }
  }
}

fragment CouponHistory_coupon on Coupon {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codes",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountAmount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conditions",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removalDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removalProducts",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionProducts",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usageLimit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usagePerCustomer",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timesUsed",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showLabel",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoApply",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCouponPayload",
        "kind": "LinkedField",
        "name": "updateCoupon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Coupon",
            "kind": "LinkedField",
            "name": "coupon",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CouponContent",
                "kind": "LinkedField",
                "name": "content",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CouponHistory_coupon"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCouponPayload",
        "kind": "LinkedField",
        "name": "updateCoupon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Coupon",
            "kind": "LinkedField",
            "name": "coupon",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CouponContent",
                "kind": "LinkedField",
                "name": "content",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": (v21/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v21/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CouponHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "021d267c025a4f14a24d5286b260ff0e",
    "id": null,
    "metadata": {},
    "name": "UpdateCouponMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCouponMutation(\n  $input: UpdateCouponInput!\n) {\n  updateCoupon(input: $input) {\n    coupon {\n      name\n      codes\n      content {\n        discountType\n        discountAmount\n        conditions\n        actions\n        removalDate\n        removalProducts\n        additionDate\n        additionProducts\n        id\n      }\n      usageLimit\n      usagePerCustomer\n      timesUsed\n      startDate\n      endDate\n      status\n      showLabel\n      autoApply\n      ...CouponHistory_coupon\n      id\n    }\n  }\n}\n\nfragment CouponHistory_coupon on Coupon {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e9a482d68bcedd17e128c360b3a565b';

module.exports = node;
