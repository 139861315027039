import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { fromGlobalId } from '~/helper';

import { AddSearchRedirectMutation } from './mutations';
import SearchRedirectForm from './SearchRedirectForm';

class SearchRedirectNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/search/redirect/${fromGlobalId(resp.addSearchRedirect.searchRedirectEdge.node.id).id}`;
      },
    };

    AddSearchRedirectMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Redirect" />

        <h1>New Redirect</h1>
        <SearchRedirectForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(SearchRedirectNew, {
  viewer: graphql`
    fragment SearchRedirectNew_viewer on Admin {
      id
    }
  `,
});
