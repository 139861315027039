/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCmsCarouselImageInput = {|
  bg1?: ?any,
  bg2?: ?any,
  endDate?: ?string,
  id: string,
  link?: ?string,
  main1?: ?any,
  main2?: ?any,
  position?: ?number,
  startDate?: ?string,
  status?: ?boolean,
  title?: ?string,
|};
export type UpdateCmsCarouselImageMutationVariables = {|
  input: UpdateCmsCarouselImageInput
|};
export type UpdateCmsCarouselImageMutationResponse = {|
  +updateCmsCarouselImage: ?{|
    +cmsCarouselImage: ?{|
      +title: ?string,
      +link: ?string,
      +main1: ?string,
      +main2: ?string,
      +bg1: ?string,
      +bg2: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +position: ?number,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateCmsCarouselImageMutation = {|
  variables: UpdateCmsCarouselImageMutationVariables,
  response: UpdateCmsCarouselImageMutationResponse,
|};
*/


/*
mutation UpdateCmsCarouselImageMutation(
  $input: UpdateCmsCarouselImageInput!
) {
  updateCmsCarouselImage(input: $input) {
    cmsCarouselImage {
      title
      link
      main1
      main2
      bg1
      bg2
      startDate
      endDate
      position
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "main1",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "main2",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bg1",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bg2",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCmsCarouselImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCmsCarouselImagePayload",
        "kind": "LinkedField",
        "name": "updateCmsCarouselImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsCarouselImage",
            "kind": "LinkedField",
            "name": "cmsCarouselImage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCmsCarouselImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCmsCarouselImagePayload",
        "kind": "LinkedField",
        "name": "updateCmsCarouselImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsCarouselImage",
            "kind": "LinkedField",
            "name": "cmsCarouselImage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e70be83d2ec575d273004e5561e32bd2",
    "id": null,
    "metadata": {},
    "name": "UpdateCmsCarouselImageMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCmsCarouselImageMutation(\n  $input: UpdateCmsCarouselImageInput!\n) {\n  updateCmsCarouselImage(input: $input) {\n    cmsCarouselImage {\n      title\n      link\n      main1\n      main2\n      bg1\n      bg2\n      startDate\n      endDate\n      position\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23840f6bf79dbb2eb994f91fbddf1d0c';

module.exports = node;
