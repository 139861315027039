/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AlertForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AlertNew_viewer$ref: FragmentReference;
declare export opaque type AlertNew_viewer$fragmentType: AlertNew_viewer$ref;
export type AlertNew_viewer = {|
  +id: string,
  +$fragmentRefs: AlertForm_viewer$ref,
  +$refType: AlertNew_viewer$ref,
|};
export type AlertNew_viewer$data = AlertNew_viewer;
export type AlertNew_viewer$key = {
  +$data?: AlertNew_viewer$data,
  +$fragmentRefs: AlertNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlertNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AlertForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'fbd7f2b9435916e17eef81c99c62eee7';

module.exports = node;
