/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFooterInput = {|
  name: string,
  position: number,
  status?: ?boolean,
  urls?: ?$ReadOnlyArray<?InputFooterUrls>,
|};
export type InputFooterUrls = {|
  name: string,
  position: number,
  properties?: ?string,
  type: string,
  url: string,
|};
export type AddFooterMutationVariables = {|
  input: AddFooterInput
|};
export type AddFooterMutationResponse = {|
  +addFooter: ?{|
    +footerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +position: ?number,
        +urls: ?$ReadOnlyArray<?{|
          +name: ?string,
          +type: ?string,
          +url: ?string,
          +properties: ?string,
          +position: ?number,
        |}>,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddFooterMutation = {|
  variables: AddFooterMutationVariables,
  response: AddFooterMutationResponse,
|};
*/


/*
mutation AddFooterMutation(
  $input: AddFooterInput!
) {
  addFooter(input: $input) {
    footerEdge {
      cursor
      node {
        id
        name
        position
        urls {
          name
          type
          url
          properties
          position
        }
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFooterPayload",
    "kind": "LinkedField",
    "name": "addFooter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FooterEdge",
        "kind": "LinkedField",
        "name": "footerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Footer",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FooterUrl",
                "kind": "LinkedField",
                "name": "urls",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "properties",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddFooterMutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddFooterMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "80ed81339e70d51d824544915319a615",
    "id": null,
    "metadata": {},
    "name": "AddFooterMutation",
    "operationKind": "mutation",
    "text": "mutation AddFooterMutation(\n  $input: AddFooterInput!\n) {\n  addFooter(input: $input) {\n    footerEdge {\n      cursor\n      node {\n        id\n        name\n        position\n        urls {\n          name\n          type\n          url\n          properties\n          position\n        }\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '20885a8a5be3e075dc15cd1ef6f8a0f0';

module.exports = node;
