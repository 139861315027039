import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation BulkUpdateProductsMutation($input: BulkUpdateProductsInput!) {
    bulkUpdateProducts(input:$input) {
      productIds
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
