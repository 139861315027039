import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { get, startCase } from 'lodash';
import { Alert, Button, Col, Divider, InputNumber, List, Modal, Popconfirm, Row } from 'antd';
import { GenerateGiftCardUsageMutation } from '~/components/gift_card/usage/mutations';
import { GenerateCreditMutation } from '~/components/promotion/credit/mutations';
import AddressForm from '~/components/customer/AddressForm';
import { FormBase } from '~/components/form';
import Presence from '~/components/Presence';
import SalesOrderHistory from './SalesOrderHistory';
import GetByDate from '../GetByDate';

import { fromGlobalId, getFrontEndUrl } from '../../../helper';
import SalesOrderVerification from './SalesOrderVerification';
import {
  CancelOrderMutation,
  CompleteOrderPaymentMutation,
  MarkShippedMutation,
  SubmitOrderMutation,
  UpdateSalesAddressMutation
} from './mutations';
import { ORDER_STATUSES } from './SalesOrderList';
import Tracking from './Tracking';
import ShippingStatus from './Status';

class SalesOrderView extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

 constructor(props) {
   super(props);

   this.creditIdRef = React.createRef();

   this.state = {
     addressFormVisible: false,
     address: null,
   };
  }

  cancelOrder = (order) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: order.id,
        },
      },
      viewer: this.props.viewer,
      safeGuard: "update",
    };
    CancelOrderMutation.commit(mutation);
  }

  completePayment = (order) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: order.id,
        },
      },
      viewer: this.props.viewer,
      safeGuard: "update",
    };
    CompleteOrderPaymentMutation.commit(mutation);
  }

  markShipped = (o) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: o.id,
        },
      },
      viewer: this.props.viewer,
    };
    MarkShippedMutation.commit(mutation);
  }

  submitOrder = (order) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: order.id,
        },
      },
      viewer: this.props.viewer,
    };
    SubmitOrderMutation.commit(mutation);
  }

  generateGiftCard = (order) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          orderId: order.id,
        },
      },
      viewer: this.props.viewer,
    };
    GenerateGiftCardUsageMutation.commit(mutation);
  }

  generateCredit = (order) => {
    const creditId = this.creditIdRef.current.inputNumberRef.input.value;

    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          orderId: order.id,
          creditId: creditId ? parseInt(creditId, 10) : null,
        },
      },
      viewer: this.props.viewer,
    };
    GenerateCreditMutation.commit(mutation);
  }

  updateAddress = (address) => {
    this.setState({ address });
    this.showAddressFormModal();
  }

  showAddressFormModal = () => {
    this.setState({
      addressFormVisible: true,
    });
  }

  hideAddressFormModal = () => {
    this.setState({
      addressFormVisible: false,
    });
  }

  renderAddress= (address) => { // eslint-disable-line react/display-name
    if (address) {
      address = address.node;
      return (
        <div>
          <h3>{startCase(address.addressType)} Address</h3>
          {address.editable && <Button onClick={()=>this.updateAddress(address)}>Edit</Button>}
          <p>{address.firstname} {address.lastname}</p>
          <p>{address.telephone}</p>
          <p>
            {address.street} {address.city} {address.postcode}
          </p>
          <p>
            {address.region} {address.country.name}
          </p>
          <p>
            {address.company} {address.fax}
          </p>
        </div>
      );
    }
    return null;
  }

  renderDiscounts = (discounts) => {
    if (discounts.length > 0) {
      return discounts.map(d => (
        <div key={d.name}>
          Discount: {d.name} {d.amount && <span className="price">${d.amount}</span>}
        </div>
        ))
    }

    return null;
  }

  renderName = (line) => {
    const productName = <b>{line.name}</b>;

    if (line.product) {
      return (
        <Link to={`/product/${fromGlobalId(line.product.id).id}`} target="_blank" rel="noopener noreferrer">
          {productName}
        </Link>
      );
    }

    return productName;
  }

  renderLine = (line) => ( // eslint-disable-line react/display-name
    <Row key={line.id}>
      <Col span={12}>
        {this.renderName(line)}
        <GetByDate date={get(line, 'priorityShipping.getByDate')} shippingMethod={get(line, 'priorityShipping.shippingMethod')} />
        <b><Tracking line={line} /></b>
        {line.priceMatch && (
          <Link to={`/price-match/${fromGlobalId(line.priceMatch.id).id}`} style={{marginLeft: '5px'}}>Price Matched</Link>
        )}
      </Col>
      <Col span={6}>
        <b>{line.quantity} x ${line.unitPrice - line.unitDiscount + line.unitSurcharge} = ${line.rowTotal}</b>
      </Col>
      <Col span={6}>
        {line.product && (
          <small><code>{line.product.adenCode}</code></small>
        )}
      </Col>
      {line.children && line.children.edges.length > 0 && (
      <Col offset={1} span={23}>
        {line.children.edges.filter(({node}) => node.canExport).map(edge => this.renderLine(edge.node))}
      </Col>
      )}
    </Row>
  )

  renderAllocatedToStore = (shippingAddress) => {
    const distance = get(shippingAddress, 'node.distanceFromStore');
    const storeName = get(shippingAddress, 'node.store.name');

    if (storeName) {
      return [
        <span key="1">
          Order Allocated To: Sydney Tools {storeName} {distance ? (`(${distance.toFixed(2)} km)`) : null}
        </span>,
        <br key="2" />
      ]
    }

    return null;
  }

  render() {
    const { match, viewer } = this.props;
    const order = get(viewer, 'salesOrders.edges[0].node', {});
    const customer = get(order, 'customer', {});

    const lines = get(order, 'lines.edges', []);

    const addresses = get(order, 'addresses.edges', []);
    const billingAddress = addresses.find(addr => addr.node.addressType === 'billing');
    const shippingAddress = addresses.find(addr => addr.node.addressType === 'shipping');
    const verification = get(order, 'verification');
    return (
      <div key={order.id}>
        <Helmet title={order.name} />
        <Presence match={match} disableButton={this.handleDisableBtn} />
        <h1>Order #{order.name} | {moment(order.insertedAt).format('DD/MM/YYYY HH:mm:ss')}</h1>

        <p><b>Status: {ORDER_STATUSES[order.status]}</b></p>
        {ORDER_STATUSES[order.status] === 'payment_review' && (
          <Alert message="Order Not Paid" type="error" />
        )}
        {order.paymentMethod === 'till' && ORDER_STATUSES[order.status] === 'payment_failed' && (
          <div>
            <Alert
              message={
                <div>
                  <p>Order Not Fully Paid</p>
                  <Popconfirm title="Are you sure?" onConfirm={() => this.completePayment(order)} >
                    <Button type="danger" disabled={this.shouldDisableBtn()}>Payment Completed</Button>
                  </Popconfirm>
                  <Popconfirm title="Are you sure?" onConfirm={() => this.cancelOrder(order)}>
                    <Button type="danger" style={{marginLeft: '10px'}} disabled={this.shouldDisableBtn()}>Cancel Order</Button>
                  </Popconfirm>
                  <div style={{display: 'inline', marginLeft: '10px'}}>
                    <Button
                      type="danger"
                      href={`${getFrontEndUrl()}/checkout/paymentfailed/${order.id}`}
                      target="_blank"
                    >
                      Capture Payment
                    </Button>
                  </div>
                </div>
              }
              type="error"
            />
          </div>
        )}
        <p>
          <span>Customer Name: </span>
          {customer && (
          <a
            href={`/customer/${fromGlobalId(customer.id).id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {billingAddress.node.firstname} {billingAddress.node.lastname}
          </a>
          )}

          {!customer &&
            <span>{billingAddress.node.firstname} {billingAddress.node.lastname}</span>
          }
        </p>

        <p>Email: {order.email} </p>
        <p>IP Addr: {order.remoteIp} </p>
        <ShippingStatus order={order} />
        {order.shipping.status !== 'Shipped' && (
          <Popconfirm title="Mark order as shipped?" onConfirm={() => this.markShipped(order)}>
            <Button ghost danger>Mark as shipped</Button>
          </Popconfirm>
        )}

        {(order.status !== 3 || order.verified === 2) && (
        <div>
          <SalesOrderVerification viewer={viewer} order={order} />
          <Popconfirm title="Submit this order to ERP system?" onConfirm={() => this.submitOrder(order)} >
            <Button>Submit Order</Button>
          </Popconfirm>

          <Popconfirm title="Generate Gift Cards?" onConfirm={() => this.generateGiftCard(order)} >
            <Button>Generate Gift Cards</Button>
          </Popconfirm>

          <Popconfirm
            title={
              <div>
                <p>Generate And Send Store Credit?</p>
                <InputNumber ref={this.creditIdRef} style={{width: '150px'}} placeholder="Optional Credit ID" />
              </div>
          }
            onConfirm={() => this.generateCredit(order)}
          >
            <Button>Generate Store Credit</Button>
          </Popconfirm>
        </div>
        )}

        <Divider />
        <Row>
          <Col span={24}>
            Shipping Description: {order.shippingDescription} <br />
            Shipping Method: {order.shippingMethod} <br />
            {this.renderAllocatedToStore(shippingAddress)}
            Payment Method: {order.paymentMethod}
            <Divider />
          </Col>

          <Modal
            title="Address"
            visible={this.state.addressFormVisible}
            onCancel={this.hideAddressFormModal}
            footer={null}
          >
            <AddressForm
              viewer={viewer}
              address={this.state.address}
              updateAddress={this.hideAddressFormModal}
              updateMutation={UpdateSalesAddressMutation}
            />
          </Modal>

          <Col span={12}>
            {this.renderAddress(billingAddress)}
          </Col>

          <Col span={12}>
            {this.renderAddress(shippingAddress)}
          </Col>

          <Col span={24}>
            <Divider />
            {lines.map((edge) => {
              const line = edge.node;
              return this.renderLine(line);
            })}
            <Divider />
          </Col>
          <Col span={24}><b>Subtotal: ${order.subtotal}</b></Col>

          {order.surcharge > 0 && (
          <Col span={24}><b>AMEX Surcharge: ${order.surcharge || 0}</b></Col>
          )}

          {order.surcharges && order.surcharges.map(surcharge => (
            <Col span={24} key={surcharge.name}><b>{surcharge.name}: ${surcharge.amount}</b></Col>
          ))}

          {order.discount > 0 && (
          <Col span={24}><b>Discount: {order.discount > 0 && <span>${order.discount} {order.discountDescription}</span>}</b>
            {this.renderDiscounts(order.discounts)}
          </Col>
          )}

          <Col span={24}><b>Shipping Total: ${order.shippingTotal}</b></Col>
          <Col span={24}><b>Order Total: ${order.grandTotal}</b></Col>
        </Row>

        <Divider />
        {verification && (
        <List>
          <List.Item>Last 4 Digits: {verification.last4}</List.Item>
          <List.Item>1st Amount: ${verification.firstAmount}</List.Item>
          <List.Item>2nd Amount: ${verification.secondAmount}</List.Item>
          <List.Item>Attempts: {verification.attempts}</List.Item>
          <List.Item>Verified At: {verification.verifiedAt ? moment(verification.verifiedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</List.Item>
          {verification.verifiedBy && (
          <List.Item>Verified By: {verification.verifiedBy}</List.Item>
          )}
          {verification.completedBy && (
          <List.Item>Completed By: {verification.completedBy}</List.Item>
          )}
          {verification.voidedBy && (
          <List.Item>VoidedBy By: {verification.voidedBy}</List.Item>
          )}
          {verification.cancelledBy && (
          <List.Item>Cancelled By: {verification.cancelledBy}</List.Item>
          )}
        </List>
        )}
        <Divider />
        <SalesOrderHistory
          salesOrder={order}
          viewer={viewer}
        />

      </div>
    );
  }
}

export default createFragmentContainer(SalesOrderView, {
  viewer: graphql`
    fragment SalesOrderView_viewer on Admin {
      ...SalesOrderVerification_viewer
      ...AddressForm_viewer
      ...SalesOrderHistory_viewer
      salesOrders(first: 1, ids: $ids) {
        edges {
          node {
            ...SalesOrderVerification_order
            ...SalesOrderHistory_salesOrder
            id
            name
            email
            subtotal
            grandTotal
            shippingTotal
            shippingDescription
            shippingMethod
            paymentMethod
            discount
            discountDescription
            surcharge
            surcharges {
              name
              amount
            }
            verified
            status
            shipments
            shipping
            verification {
              firstAmount
              secondAmount
              verifiedAt
              verifiedBy
              completedBy
              last4
              voidedBy
              cancelledBy
              attempts
            }
            remoteIp
            insertedAt
            customer {
              id
              firstname
              lastname
            }
            discounts {
              id
              name
              code
              amount
            }
            addresses(first: 2) {
              edges {
                node {
                  id
                  addressType
                  firstname
                  lastname
                  street
                  city
                  postcode
                  region
                  country {
                    name
                  }
                  telephone
                  company
                  fax
                  editable
                  distanceFromStore
                  store {
                    id
                    name
                  }
                }
              }
            }
            lines(first: 99) {
              edges {
                node {
                  id
                  name
                  canExport
                  quantity
                  unitPrice
                  unitDiscount
                  unitSurcharge
                  rowTotal
                  priceMatch {
                    id
                  }
                  product {
                    id
                    adenCode
                  }
                  shipments {
                    tracking
                    location
                    status
                    timestamp
                  }
                  priorityShipping {
                    getByDate
                    shippingMethod
                  }
                  children(first: 99) {
                    edges {
                      node {
                        id
                        name
                        canExport
                        product {
                          id
                          adenCode
                        }
                        quantity
                        unitPrice
                        unitDiscount
                        unitSurcharge
                        rowTotal
                        shipments {
                          tracking
                          location
                          status
                          timestamp
                        }
                        priorityShipping {
                          getByDate
                          shippingMethod
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
