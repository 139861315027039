import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation UpdateSynonymMutation($input: UpdateSynonymInput!) {
    updateSynonym(input:$input) {
      synonym {
        synonyms
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
