/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type GenPromoCardPdf_viewer$ref: FragmentReference;
declare export opaque type GenPromoCardPdf_viewer$fragmentType: GenPromoCardPdf_viewer$ref;
export type GenPromoCardPdf_viewer = {|
  +id: string,
  +$refType: GenPromoCardPdf_viewer$ref,
|};
export type GenPromoCardPdf_viewer$data = GenPromoCardPdf_viewer;
export type GenPromoCardPdf_viewer$key = {
  +$data?: GenPromoCardPdf_viewer$data,
  +$fragmentRefs: GenPromoCardPdf_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenPromoCardPdf_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0bcdac22d439372ce1e7b1c84234dd52';

module.exports = node;
