import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
} from 'react-relay';
import { Link } from 'found';
import { Modal, Table } from 'antd';
import { get } from 'lodash';

import { fromGlobalId } from '~/helper';
import { currencyFormatter } from '../grid';

const query = graphql`
  query ParentBundleModalQuery ($ids: [ID]) {
    viewer {
      id
      products(first: 1, ids: $ids) {
        edges {
          node {
            id
            parentBundles {
              id
              name
              model
              adenCode
              finalPrice
            }
          }
        }
      }
    }
  }
`;

const ParentBundleModal = (props) => {
  const { visible, product, dismiss } = props;
  const id = get(product, 'id');
  const name = get(product, 'name');

  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);

  useEffect(() => {
    if (visible) {
      loadQuery({ids: [id]}, {fetchPolicy: 'store-and-network'});
    }
  }, [visible]);

  return (
    <Modal
      title={
        <>
          <span>Parent Bundles</span>
          <br />
          <span>{name}</span>
        </>
      }
      visible={visible}
      onCancel={() => {
        dismiss();
        disposeQuery();
      }}
      footer={null}
      width={760}
      destroyOnClose
    >
      <React.Suspense fallback="Loading...">
        {queryReference != null && (
          <BundleTable queryReference={queryReference} />
        )}
      </React.Suspense>
    </Modal>
  )
};

ParentBundleModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

ParentBundleModal.defaultProps = {
  product: null,
};

const BundleTable = ({ queryReference }) => {
  const { viewer } = usePreloadedQuery(query, queryReference);
  const data = get(viewer, 'products.edges[0].node.parentBundles', []).map((d) => ({ key: d.id, ...d }));

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => ( fromGlobalId(id).id ),
      sorter: (a, b) => (fromGlobalId(a.id).id - fromGlobalId(b.id).id),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, params) => {
        return (
          <Link to={`/product/${fromGlobalId(params.id).id}`} target="_blank" rel="noopener noreferrer">
            {name}
          </Link>
        )
      },
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Aden Code',
      dataIndex: 'adenCode',
      key: 'adenCode',
    },
    {
      title: 'Final Price',
      dataIndex: 'finalPrice',
      key: 'finalPrice',
      render: (price) => ( currencyFormatter({value: price}) ),
      sorter: (a, b) => (a.finalPrice - b.finalPrice),
    },
  ]

  return (
    <Table columns={columns} dataSource={data} size="small" />
  )
}

BundleTable.propTypes = {
  queryReference: PropTypes.shape({}).isRequired,
};

export default ParentBundleModal;
