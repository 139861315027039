/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConfigForm_viewer$ref = any;
export type routes_ConfigForm_QueryVariables = {||};
export type routes_ConfigForm_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ConfigForm_viewer$ref
  |}
|};
export type routes_ConfigForm_Query = {|
  variables: routes_ConfigForm_QueryVariables,
  response: routes_ConfigForm_QueryResponse,
|};
*/


/*
query routes_ConfigForm_Query {
  viewer {
    ...ConfigForm_viewer
    id
  }
}

fragment ConfigForm_viewer on Admin {
  id
  ...Status_viewer
  configs
  brands(first: 1000, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
}

fragment Status_viewer on Admin {
  id
  appVersion
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_ConfigForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigForm_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_ConfigForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "configs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1000
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": {
                  "direction": "asc",
                  "field": "name"
                }
              }
            ],
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "brands(first:1000,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "171e1e5add8fb86522a1e89b0eb1108c",
    "id": null,
    "metadata": {},
    "name": "routes_ConfigForm_Query",
    "operationKind": "query",
    "text": "query routes_ConfigForm_Query {\n  viewer {\n    ...ConfigForm_viewer\n    id\n  }\n}\n\nfragment ConfigForm_viewer on Admin {\n  id\n  ...Status_viewer\n  configs\n  brands(first: 1000, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment Status_viewer on Admin {\n  id\n  appVersion\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e7c89e7f8d43d10f765bbbc362a073a2';

module.exports = node;
