import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';
import moment from 'moment-timezone';

import { fromGlobalId } from '../../helper';
import { BooleanDropdownFilter, Grid, iDGetter, valueGetter } from '../grid';
import bulkActions from './bulkActions';

const entityName = 'Store';

class StoreList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/store/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'id', headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: true, filter: false,
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 150,
      },
      {
        colId: 'state', headerName: 'State', valueGetter: valueGetter.bind(this, 'state')
      },
      { colId: 'canPickup', headerName: 'Store Pickup', valueGetter: valueGetter.bind(this, 'canPickup'), width: 130, ...BooleanDropdownFilter },
      { colId: 'branchCode', headerName: 'Branch Code', valueGetter: valueGetter.bind(this, 'branchCode'), width: 120 },
      { colId: 'status', headerName: 'Status', valueGetter: valueGetter.bind(this, 'status'), ...BooleanDropdownFilter },
      { colId: 'regional', headerName: 'Regional', valueGetter: valueGetter.bind(this, 'regional'), ...BooleanDropdownFilter },
      { colId: 'distributionHub', headerName: 'Distribution Hub', valueGetter: valueGetter.bind(this, 'distributionHub'), width: 120, ...BooleanDropdownFilter },
      { colId: 'staffDelivery', headerName: 'Staff Delivery', valueGetter: valueGetter.bind(this, 'staffDelivery'), width: 120, ...BooleanDropdownFilter },
      { colId: 'canHusqvarnaOpe', headerName: 'Husqvarna OPE', valueGetter: valueGetter.bind(this, 'canHusqvarnaOpe'), width: 120, ...BooleanDropdownFilter },
      { colId: 'excludeAllocation.startDate', headerName: 'Disable Allocation', width: 120,
        valueGetter: (data) => {
          const allocation = valueGetter('excludeAllocation', data);

          if (allocation) {
            return `${moment(allocation.startDate).local().format()} - ${moment(allocation.endDate).local().format()}`;
          }

          return null;
        }
      },
    ];

    this.datasource = Grid.getDataSource.call(this, 'stores');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/store/new"><Button>Add Store</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
          bulkActions={bulkActions}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  StoreList, {
    viewer: graphql`
    fragment StoreList_viewer on Admin {
      stores(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "StoreList_stores") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            regional
            canPickup
            distributionHub
            staffDelivery
            state
            status
            branchCode
            canHusqvarnaOpe
            excludeAllocation {
              startDate
              endDate
            }
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.stores;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query StoreListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...StoreList_viewer
      }
    }
  `,
  },
);
