/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubcategoryHistory_viewer$ref: FragmentReference;
declare export opaque type SubcategoryHistory_viewer$fragmentType: SubcategoryHistory_viewer$ref;
export type SubcategoryHistory_viewer = {|
  +id: string,
  +$refType: SubcategoryHistory_viewer$ref,
|};
export type SubcategoryHistory_viewer$data = SubcategoryHistory_viewer;
export type SubcategoryHistory_viewer$key = {
  +$data?: SubcategoryHistory_viewer$data,
  +$fragmentRefs: SubcategoryHistory_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubcategoryHistory_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '96fe7d55d62d0a17645ae8928b199b5d';

module.exports = node;
