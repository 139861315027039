import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import History, { addHistory, historyPropTypes } from '~/components/history';

const ProductHistory = (props) => {
  const historyFn = addHistory({props, connectionKey: "ProductHistory_histories"});

  // eslint-disable-next-line react/prop-types
  const { product: entity, relay } = props;

  return (
    <History relay={relay} entity={entity} addHistory={historyFn} />
  );
};

ProductHistory.propTypes = historyPropTypes({entity: 'product'});

export default createRefetchContainer(
  ProductHistory,
  {
    viewer: graphql`
      fragment ProductHistory_viewer on Admin {
        id
      }
    `,
    product: graphql`
      fragment ProductHistory_product on Product @argumentDefinitions(
        count: {type: "Int!", defaultValue: 10},
      ) {
        id
        histories(first: $count) @connection(key: "ProductHistory_histories") {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              changes
              user
              insertedAt
            }
          }
        }
      }
    `,
  },
  graphql`
    query ProductHistoryRefetchQuery($ids: [ID!], $count: Int!) {
      viewer {
        products(first: 1, ids: $ids) {
          edges {
            node {
              ...ProductHistory_product @arguments(count: $count)
            }
          }
       }
      }
    }
  `,
);
