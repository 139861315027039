/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddRedemptionInput = {|
  brandIds?: ?$ReadOnlyArray<?string>,
  endDate: string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  images?: ?$ReadOnlyArray<?InputRedemptionImage>,
  name: string,
  onProducts?: ?$ReadOnlyArray<?string>,
  redeemables?: ?$ReadOnlyArray<?string>,
  startDate: string,
  status: boolean,
|};
export type InputRedemptionImage = {|
  file?: ?any,
  id?: ?string,
|};
export type AddRedemptionMutationVariables = {|
  input: AddRedemptionInput
|};
export type AddRedemptionMutationResponse = {|
  +addRedemption: ?{|
    +redemptionEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +images: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +imageUrl: ?string,
            |}
          |}>
        |},
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddRedemptionMutation = {|
  variables: AddRedemptionMutationVariables,
  response: AddRedemptionMutationResponse,
|};
*/


/*
mutation AddRedemptionMutation(
  $input: AddRedemptionInput!
) {
  addRedemption(input: $input) {
    redemptionEdge {
      cursor
      node {
        id
        name
        startDate
        endDate
        images(first: 999) {
          edges {
            node {
              id
              imageUrl
            }
          }
        }
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddRedemptionPayload",
    "kind": "LinkedField",
    "name": "addRedemption",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RedemptionEdge",
        "kind": "LinkedField",
        "name": "redemptionEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Redemption",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 999
                  }
                ],
                "concreteType": "RedemptionImageConnection",
                "kind": "LinkedField",
                "name": "images",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RedemptionImageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RedemptionImage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "images(first:999)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddRedemptionMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddRedemptionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8e7e2c9a61e73e7e111911152d7cd46d",
    "id": null,
    "metadata": {},
    "name": "AddRedemptionMutation",
    "operationKind": "mutation",
    "text": "mutation AddRedemptionMutation(\n  $input: AddRedemptionInput!\n) {\n  addRedemption(input: $input) {\n    redemptionEdge {\n      cursor\n      node {\n        id\n        name\n        startDate\n        endDate\n        images(first: 999) {\n          edges {\n            node {\n              id\n              imageUrl\n            }\n          }\n        }\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d9eca54622d7b0edf531ee2b22b53a8';

module.exports = node;
