import React, { useRef, useState } from 'react';
import { Form } from 'antd';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const { Item: FormItem } = Form;

const PRODUCTION_KEY = '714a2d5d-f5ea-4ff7-9f02-bb39c71c9618';
const DEVELOPMENT_KEY = '21b06bef-19cb-4d14-bb9a-a0cc264bdb73';

const SITE_KEY = process.env.NODE_ENV === 'production' ? PRODUCTION_KEY : DEVELOPMENT_KEY;
const RATE_LIMIT = 5;

const useCapture = (form) => {
  const captchaRef = useRef();
  const [errorCount, setErrorCount] = useState(0);

  const failLoginHandler = (msg = "") => {
    let count = errorCount;

    if (msg.includes('Security Error') && errorCount < RATE_LIMIT) {
      count = RATE_LIMIT;
    } else {
      count += 1;
    }

    setErrorCount(count);

    form.setFieldsValue({ captchaToken: null });

    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  };

  const onVerify = (captchaToken) => {
    form.setFieldsValue({ captchaToken });
  };

  const Captcha = () => {
    return errorCount >= RATE_LIMIT ? (
      <>
        <FormItem noStyle>
          <HCaptcha
            ref={captchaRef}
            sitekey={SITE_KEY}
            onVerify={onVerify}
          />
        </FormItem>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => !getFieldValue('captchaToken') ? (
            <div style={{color: '#f5222d'}}>Required</div>
          ) : null}
        </FormItem>
        <FormItem
          name="captchaToken"
          rules={[
            {required: true, Message: 'Required'},
          ]}
          hidden
        >
          <div />
        </FormItem>
      </>
    ) : null;
  };

  return [Captcha, failLoginHandler];
};

module.exports = {
  useCapture
}
