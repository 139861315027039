import UrlRefresher from './UrlRefresher';
import UrlRewriteList from './UrlRewriteList';
import UrlRewriteView from './UrlRewriteView';
import UrlRewriteNew from './UrlRewriteNew';
import routes from './routes';

module.exports = {
  routes,
  UrlRefresher,
  UrlRewriteList,
  UrlRewriteView,
  UrlRewriteNew,
};
