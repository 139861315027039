/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUrlRewriteInput = {|
  from: string,
  httpCode: number,
  id: string,
  status: boolean,
  to: string,
  urlType: number,
|};
export type UpdateUrlRewriteMutationVariables = {|
  input: UpdateUrlRewriteInput
|};
export type UpdateUrlRewriteMutationResponse = {|
  +updateUrlRewrite: ?{|
    +urlRewrite: ?{|
      +from: ?string,
      +to: ?string,
      +httpCode: ?number,
      +lastAccessed: ?string,
      +urlType: ?number,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateUrlRewriteMutation = {|
  variables: UpdateUrlRewriteMutationVariables,
  response: UpdateUrlRewriteMutationResponse,
|};
*/


/*
mutation UpdateUrlRewriteMutation(
  $input: UpdateUrlRewriteInput!
) {
  updateUrlRewrite(input: $input) {
    urlRewrite {
      from
      to
      httpCode
      lastAccessed
      urlType
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "from",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "to",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "httpCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastAccessed",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUrlRewriteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUrlRewritePayload",
        "kind": "LinkedField",
        "name": "updateUrlRewrite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlRewrite",
            "kind": "LinkedField",
            "name": "urlRewrite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUrlRewriteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUrlRewritePayload",
        "kind": "LinkedField",
        "name": "updateUrlRewrite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlRewrite",
            "kind": "LinkedField",
            "name": "urlRewrite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2547afcbfa48af3b119fce4a4c0f456",
    "id": null,
    "metadata": {},
    "name": "UpdateUrlRewriteMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUrlRewriteMutation(\n  $input: UpdateUrlRewriteInput!\n) {\n  updateUrlRewrite(input: $input) {\n    urlRewrite {\n      from\n      to\n      httpCode\n      lastAccessed\n      urlType\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99df0fa80bfa8c657dc0dae9fed705ff';

module.exports = node;
