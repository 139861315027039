/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecreateIndexMutationVariables = {||};
export type RecreateIndexMutationResponse = {|
  +recreateIndex: ?{|
    +result: ?string
  |}
|};
export type RecreateIndexMutation = {|
  variables: RecreateIndexMutationVariables,
  response: RecreateIndexMutationResponse,
|};
*/


/*
mutation RecreateIndexMutation {
  recreateIndex {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RecreateIndexPayload",
    "kind": "LinkedField",
    "name": "recreateIndex",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecreateIndexMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RecreateIndexMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a35a4adbf1b85d34481a02c0a6d7a910",
    "id": null,
    "metadata": {},
    "name": "RecreateIndexMutation",
    "operationKind": "mutation",
    "text": "mutation RecreateIndexMutation {\n  recreateIndex {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50b8449dcc6bd188dd6d0fabc4512c5b';

module.exports = node;
