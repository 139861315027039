/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CategoryForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryNew_viewer$ref: FragmentReference;
declare export opaque type CategoryNew_viewer$fragmentType: CategoryNew_viewer$ref;
export type CategoryNew_viewer = {|
  +id: string,
  +$fragmentRefs: CategoryForm_viewer$ref,
  +$refType: CategoryNew_viewer$ref,
|};
export type CategoryNew_viewer$data = CategoryNew_viewer;
export type CategoryNew_viewer$key = {
  +$data?: CategoryNew_viewer$data,
  +$fragmentRefs: CategoryNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategoryForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b943bbb86bdd92a2ab618f211c2c040c';

module.exports = node;
