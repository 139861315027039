import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '~/components/form';

export default class DateFilter extends Component {
  static propTypes = {
    parentFilterInstance: PropTypes.func.isRequired,
  }

  onDateChanged = (date) => {
    const currentValue = date ? date.startOf('day').toISOString() : null;
    this.props.parentFilterInstance( (instance) => {
      instance.onFloatingFilterChanged('equals', currentValue);
    });
  }

  render() {
    return (
      <DatePicker onChange={this.onDateChanged} />
    );
  }
}
