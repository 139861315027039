import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddFaqMutation($input: AddFaqInput!) {
    addFaq(input:$input) {
      faqEdge {
        cursor
        node {
          id
          question
          answer
          position
          status
          brands(first: 999) {
            edges {
              node {
                id
                name
              }
            }
          }
          categories(first: 999) {
            edges {
              node {
                id
                name
              }
            }
          }
          subcategories(first: 9999) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

