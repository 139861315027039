import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Popconfirm, Select, Tabs } from 'antd';

import { fromGlobalId } from '~/helper';
import { DatePicker, FormBase, formItemLayout, SelectOrder } from '~/components/form';
import Presence from '~/components/Presence';
import GiftCardUsageHistory from './GiftCardUsageHistory';
import { SendGiftCardUsageEmailMutation }  from './mutations';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class GiftCardUsageForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      giftCards: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired
    }).isRequired,
    giftCardUsage: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      amount: PropTypes.number,
      remainingAmount: PropTypes.number,
      insertedAt: PropTypes.string,
      expiry: PropTypes.string,
      status: PropTypes.bool,
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    giftCardUsage: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  sendEmail = ({id}) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id } },
      viewer: this.props.viewer,
    };
    SendGiftCardUsageEmailMutation.commit(mutation);
  }

  updateRelated = (_, opt) => {
    const { amount, expiryMonths } = opt.props.giftcard;
    const expiry = moment().add(expiryMonths, 'months');

    this.formRef.current.setFieldsValue({ amount });
    this.formRef.current.setFieldsValue({ remainingAmount: amount });

    this.formRef.current.setFieldsValue({ expiry});
  }

  renderOrderLink = (order) => {
    if (order) {
      return <a href={`/sales/order/${fromGlobalId(order.id).id}`} rel="noopener noreferrer" target="_blank">{order.name}</a>;
    }

    return 'N/A';
  }

  renderGiftCardLink = (card) => {
    if (card) {
      return <a href={`/gift-card/setup/${fromGlobalId(card.id).id}`} rel="noopener noreferrer" target="_blank">{card.name}</a>;
    }

    return 'N/A';
  }

  render() {
    const { match, viewer } = this.props;
    const giftCards = get(viewer, 'giftCards.edges', []);
    const giftCardUsage = get(this.props, 'giftCardUsage', {});
    let expiry = giftCardUsage.expiry ? moment(giftCardUsage.expiry) : null;

    if (!giftCardUsage.id) {
      expiry = moment().add(3, 'years');
    }

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>

            {giftCardUsage.id && (
              <Popconfirm
                title="Resend email ONLY IF customer DID NOT receive any. It is not an usual task"
                onConfirm={()=> this.sendEmail(giftCardUsage)}
                okText="Yes"
                cancelText="No"
              >
                <Button>Send Email</Button>
              </Popconfirm>
            )}
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={giftCardUsage.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Code"
            >
              <Input defaultValue={giftCardUsage.code} readOnly disabled placeholder="code" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="From Order"
            >
              {giftCardUsage.id && this.renderOrderLink(giftCardUsage.fromOrder)}
              {!giftCardUsage.id && (
                <FormItem
                  name="orderId"
                  rules={[{ required: true, message: 'required' }]}
                  initialValue={giftCardUsage.fromOrder}
                >
                  <SelectOrder
                    placeholder="Order"
                    showSearch
                    filterOption={false}
                    viewer={viewer}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="From Gift Card Setup"
            >
              {giftCardUsage.id && this.renderGiftCardLink(giftCardUsage.giftCard)}
              {!giftCardUsage.id && (
                <FormItem
                  name="giftCardId"
                  rules={[{ required: true, message: 'required' }]}
                  initialValue={get(giftCardUsage.fromGiftCard, 'id')}
                >
                  <Select
                    placeholder="Gift Card"
                    optionFilterProp="children"
                    optionLabelProp="children"
                    filterOption={false}
                    onChange={this.updateRelated}
                  >
                    {giftCards.map(edge => {
                      const c = edge.node;
                      return <Option key={c.id} value={c.id} giftcard={c}>{c.name}</Option>;
                    })}
                  </Select>
                </FormItem>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Amount"
              name="amount"
              rules={[{ required: true, message: 'required' }]}
              initialValue={giftCardUsage.amount}
            >
              <InputNumber placeholder="Amount" disabled />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Remaining Amount"
              name="remainingAmount"
              rules={[
                { required: true, message: 'required' },
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    const amount = getFieldValue('amount');

                    if (value < 0) {
                      return Promise.reject(new Error('Cannot be negative'));
                    } else if (value > amount) {
                      return Promise.reject(new Error('Cannot be greater than the amount'));
                    }
                    return Promise.resolve();
                  }
                }),
              ]}
              initialValue={giftCardUsage.remainingAmount}
            >
              <InputNumber placeholder="Remaining Amount" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Expiry"
              name="expiry"
              rules={[{ required: true, message: 'required' }]}
              initialValue={expiry}
              extra="Default 3 years"
            >
              <DatePicker />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Spent"
            >
              {
                get(giftCardUsage.onOrders, 'edges', []).map(edge => {
                  const n = edge.node;
                  const { order } = n;

                  return (
                    <p key={order.id}>
                      <span>${n.amount}</span> on {this.renderOrderLink(order)}
                    </p>
                  );
                })
              }
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Created At"
            >
              {giftCardUsage.insertedAt ? moment(giftCardUsage.insertedAt).format('DD/MM/YYYY HH:mm:ss') : ''}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(giftCardUsage, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          <TabPane tab="History" key="history">
            <GiftCardUsageHistory giftCardUsage={giftCardUsage} viewer={viewer} />
          </TabPane>
        </Tabs>

      </Form>
    );
  }
}
export default createFragmentContainer(GiftCardUsageForm, {
  viewer: graphql`
    fragment GiftCardUsageForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      giftCards(first: 999){
        edges {
          node {
            id
            name
            amount
            expiryMonths
          }
        }
      }
      ...GiftCardUsageHistory_viewer
      ...SelectOrder_viewer
    }
  `,
});
