import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { Alert, Button, Col, Row } from 'antd';
import JSONTree from 'react-json-tree';

import { FormBase } from '~/components/form';
import Presence from '~/components/Presence';
import SalesQuoteHistory from './SalesQuoteHistory';
import { fromGlobalId } from '../../../helper';
import { UpdateQuoteRecoveryStatusMutation } from './mutations';
import { RECOVERY_STATUSES } from './QuoteList';

class QuoteView extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      salesQuotes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  updateRecoveryStatus = (quote, status) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: quote.id,
          status,
        },
      },
      viewer: this.props.viewer,
      safeGuard: "update",
    };

    UpdateQuoteRecoveryStatusMutation.commit(mutation);
  }

  renderLine = (line) => ( // eslint-disable-line react/display-name
    <Row key={line.id}>
      <Col span={12}>
        <a
          href={line.product.fullUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {line.name}
        </a>
      </Col>
      <Col span={12}><b>{line.quantity} x ${line.unitPrice - line.unitDiscount + line.unitSurcharge} = ${line.rowTotal}</b></Col>
      {line.children && line.children.edges.length > 0 && (
      <Col offset={1} span={24}>
        <Row>
          {line.children.edges.map(edge => this.renderLine(edge.node))}
        </Row>
      </Col>
      )}
    </Row>
  )

  render() {
    const { match, viewer } = this.props;
    const quote = get(viewer, 'salesQuotes.edges[0].node', {});

    if (!quote.id) {
      return (<p>Quote Not Found. Possibly it has been converted.</p>);
    }

    const customer = get(quote, 'customer', {});

    const lines = get(quote, 'lines.edges', []);

    const quoteId = fromGlobalId(quote.id).id;

    return (
      <div>
        <Helmet title={`Quote ${quoteId}`} />
        <Presence match={match} disableButton={this.handleDisableBtn} />
        <p>Quote ID: {quoteId}</p>
        {quote.deletedAt && (
          <Alert message={`Quote has been deleted on: ${moment(quote.deletedAt).format('DD/MM/YYYY HH:mm:ss')}`} type="error" />
        )}
        <p>Created At: {moment(quote.insertedAt).format('DD/MM/YYYY HH:mm:ss')}</p>
        <p>Updated At: {moment(quote.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</p>

        {!quote.deletedAt && (
          <div>
            <p>Recovery: {RECOVERY_STATUSES[quote.recoveryStatus]}</p>

            <p>
              <Button onClick={() => this.updateRecoveryStatus(quote, 5)} title="5" type="dashed" disabled={this.shouldDisableBtn()}>In Progress</Button>
            </p>
            <p>
              <Button onClick={() => this.updateRecoveryStatus(quote, 1)} title="1" type="primary" disabled={this.shouldDisableBtn()}>Recovered</Button>
            </p>
            <p>
              <Button onClick={() => this.updateRecoveryStatus(quote, 2)} title="2" type="danger" disabled={this.shouldDisableBtn()}>Lost</Button>
            </p>
            <p>
              <Button onClick={() => this.updateRecoveryStatus(quote, 4)} title="4" type="default" disabled={this.shouldDisableBtn()}>Missing Contact</Button>
            </p>
            <p>
              <Button onClick={() => this.updateRecoveryStatus(quote, 3)} title="3" type="default" disabled={this.shouldDisableBtn()}>N/A</Button>
            </p>
          </div>
        )}

        <p>
          Customer Name: {customer && (
          <a
            href={`/customer/${fromGlobalId(customer.id).id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {customer.firstname} {customer.lastname}
          </a>
          )}
        </p>

        <JSONTree hideRoot data={quote.serialisation.form || {}} />

        <div>
          {lines.map((edge) => {
            const line = edge.node;
            return this.renderLine(line);
          })}
        </div>

        <p>Shipping: Unknown</p>
        <p>Subtotal: ${quote.subtotal}</p>
        <p>Grand Total: ${quote.grandTotal}</p>

        <SalesQuoteHistory salesQuote={quote} viewer={viewer} />
      </div>
    );
  }
}

export default createFragmentContainer(QuoteView, {
  viewer: graphql`
    fragment QuoteView_viewer on Admin {
      ...SalesQuoteHistory_viewer
      salesQuotes(first: 1, ids: $ids, reload: true) {
        edges {
          node {
            ...SalesQuoteHistory_salesQuote
            id
            subtotal
            grandTotal
            insertedAt
            updatedAt
            deletedAt
            recoveryStatus
            serialisation
            customer {
              id
              firstname
              lastname
            }
            lines(first: 99) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  unitDiscount
                  unitSurcharge
                  rowTotal
                  product {
                    id
                    fullUrl
                  }
                  children(first: 99) {
                    edges {
                      node {
                        id
                        name
                        quantity
                        unitPrice
                        unitDiscount
                        unitSurcharge
                        rowTotal
                        product {
                          id
                          fullUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
