/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SortingRefetchQueryVariables = {|
  ids?: ?$ReadOnlyArray<string>,
  value?: ?number,
|};
export type SortingRefetchQueryResponse = {|
  +viewer: ?{|
    +products: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +sorting: ?number,
          +searchScoreMultiplier: ?number,
        |}
      |}>
    |}
  |}
|};
export type SortingRefetchQuery = {|
  variables: SortingRefetchQueryVariables,
  response: SortingRefetchQueryResponse,
|};
*/


/*
query SortingRefetchQuery(
  $ids: [ID!]
  $value: Float
) {
  viewer {
    products(first: 1, ids: $ids) {
      edges {
        node {
          sorting: sortingFactor(value: $value, dynamic: true)
          searchScoreMultiplier
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": "sorting",
  "args": [
    {
      "kind": "Literal",
      "name": "dynamic",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "value",
      "variableName": "value"
    }
  ],
  "kind": "ScalarField",
  "name": "sortingFactor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchScoreMultiplier",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SortingRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SortingRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c9a7028a6ee54312f43bc578232aa01",
    "id": null,
    "metadata": {},
    "name": "SortingRefetchQuery",
    "operationKind": "query",
    "text": "query SortingRefetchQuery(\n  $ids: [ID!]\n  $value: Float\n) {\n  viewer {\n    products(first: 1, ids: $ids) {\n      edges {\n        node {\n          sorting: sortingFactor(value: $value, dynamic: true)\n          searchScoreMultiplier\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '876bfbb3419fe439a283f3a6d0a97d4b';

module.exports = node;
