/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BonusHistory_bonus$ref = any;
export type UpdateBonusInput = {|
  endDate?: ?string,
  id: string,
  images?: ?$ReadOnlyArray<?InputBonusImage>,
  name?: ?string,
  onProducts?: ?$ReadOnlyArray<?string>,
  products?: ?$ReadOnlyArray<?InputBonusProduct>,
  quantityRequired?: ?number,
  startDate?: ?string,
  status?: ?boolean,
  stealthMode?: ?boolean,
|};
export type InputBonusImage = {|
  file?: ?any,
  id?: ?string,
|};
export type InputBonusProduct = {|
  id?: ?string,
  optional: boolean,
  price: number,
  productId: string,
  quantity: number,
|};
export type UpdateBonusMutationVariables = {|
  input: UpdateBonusInput
|};
export type UpdateBonusMutationResponse = {|
  +updateBonus: ?{|
    +bonus: ?{|
      +name: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +status: ?boolean,
      +stealthMode: ?boolean,
      +quantityRequired: ?number,
      +onProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +products: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +product: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |},
            +price: ?number,
            +quantity: ?number,
            +optional: ?boolean,
          |}
        |}>
      |},
      +images: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +imageUrl: ?string,
          |}
        |}>
      |},
      +$fragmentRefs: BonusHistory_bonus$ref,
    |}
  |}
|};
export type UpdateBonusMutation = {|
  variables: UpdateBonusMutationVariables,
  response: UpdateBonusMutationResponse,
|};
*/


/*
mutation UpdateBonusMutation(
  $input: UpdateBonusInput!
) {
  updateBonus(input: $input) {
    bonus {
      name
      startDate
      endDate
      status
      stealthMode
      quantityRequired
      onProducts(first: 999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      products(first: 999) {
        edges {
          node {
            product {
              id
              name
              mainImage {
                id
                url
              }
            }
            price
            quantity
            optional
            id
          }
        }
      }
      images(first: 999) {
        edges {
          node {
            id
            imageUrl
          }
        }
      }
      ...BonusHistory_bonus
      id
    }
  }
}

fragment BonusHistory_bonus on Bonus {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stealthMode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityRequired",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": (v8/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "onProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "onProducts(first:999)"
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optional",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": (v8/*: any*/),
  "concreteType": "BonusImageConnection",
  "kind": "LinkedField",
  "name": "images",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BonusImageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BonusImage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "images(first:999)"
},
v17 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBonusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBonusPayload",
        "kind": "LinkedField",
        "name": "updateBonus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bonus",
            "kind": "LinkedField",
            "name": "bonus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "BonusProductConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BonusProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BonusProduct",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "products(first:999)"
              },
              (v16/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BonusHistory_bonus"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBonusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBonusPayload",
        "kind": "LinkedField",
        "name": "updateBonus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bonus",
            "kind": "LinkedField",
            "name": "bonus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "BonusProductConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BonusProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BonusProduct",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "products(first:999)"
              },
              (v16/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "BonusHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e1aaa7ae9977c389bc4965d52aa15ea",
    "id": null,
    "metadata": {},
    "name": "UpdateBonusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBonusMutation(\n  $input: UpdateBonusInput!\n) {\n  updateBonus(input: $input) {\n    bonus {\n      name\n      startDate\n      endDate\n      status\n      stealthMode\n      quantityRequired\n      onProducts(first: 999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      products(first: 999) {\n        edges {\n          node {\n            product {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n            price\n            quantity\n            optional\n            id\n          }\n        }\n      }\n      images(first: 999) {\n        edges {\n          node {\n            id\n            imageUrl\n          }\n        }\n      }\n      ...BonusHistory_bonus\n      id\n    }\n  }\n}\n\nfragment BonusHistory_bonus on Bonus {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '212203cbcc85e0b8896b8b1c8735e63d';

module.exports = node;
