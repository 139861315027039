/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddSearchCanonicalInput = {|
  queries: $ReadOnlyArray<?string>,
  status: boolean,
  url: string,
|};
export type AddSearchCanonicalMutationVariables = {|
  input: AddSearchCanonicalInput
|};
export type AddSearchCanonicalMutationResponse = {|
  +addSearchCanonical: ?{|
    +searchCanonicalEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +queries: ?$ReadOnlyArray<?string>,
        +url: ?string,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddSearchCanonicalMutation = {|
  variables: AddSearchCanonicalMutationVariables,
  response: AddSearchCanonicalMutationResponse,
|};
*/


/*
mutation AddSearchCanonicalMutation(
  $input: AddSearchCanonicalInput!
) {
  addSearchCanonical(input: $input) {
    searchCanonicalEdge {
      cursor
      node {
        id
        queries
        url
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddSearchCanonicalPayload",
    "kind": "LinkedField",
    "name": "addSearchCanonical",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SearchCanonicalEdge",
        "kind": "LinkedField",
        "name": "searchCanonicalEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchCanonical",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "queries",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSearchCanonicalMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSearchCanonicalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "321ff51760bbcebd556a47dd0988ce30",
    "id": null,
    "metadata": {},
    "name": "AddSearchCanonicalMutation",
    "operationKind": "mutation",
    "text": "mutation AddSearchCanonicalMutation(\n  $input: AddSearchCanonicalInput!\n) {\n  addSearchCanonical(input: $input) {\n    searchCanonicalEdge {\n      cursor\n      node {\n        id\n        queries\n        url\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5ec1eee5f2bf9283111589ec05c14f3';

module.exports = node;
