/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Sorting_product$ref: FragmentReference;
declare export opaque type Sorting_product$fragmentType: Sorting_product$ref;
export type Sorting_product = {|
  +id: string,
  +enableDynamicSorting: ?boolean,
  +sortingFactor: ?number,
  +searchScoreMultiplier: ?number,
  +sorting: ?number,
  +$refType: Sorting_product$ref,
|};
export type Sorting_product$data = Sorting_product;
export type Sorting_product$key = {
  +$data?: Sorting_product$data,
  +$fragmentRefs: Sorting_product$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "value"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sorting_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableDynamicSorting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortingFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchScoreMultiplier",
      "storageKey": null
    },
    {
      "alias": "sorting",
      "args": [
        {
          "kind": "Literal",
          "name": "dynamic",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "value",
          "variableName": "value"
        }
      ],
      "kind": "ScalarField",
      "name": "sortingFactor",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3cc624540f4dcfaecb1d7a4aab8321f9';

module.exports = node;
