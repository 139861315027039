/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ParentBundleModalQueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type ParentBundleModalQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +products: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +parentBundles: ?$ReadOnlyArray<?{|
            +id: string,
            +name: ?string,
            +model: ?string,
            +adenCode: ?string,
            +finalPrice: ?number,
          |}>,
        |}
      |}>
    |},
  |}
|};
export type ParentBundleModalQuery = {|
  variables: ParentBundleModalQueryVariables,
  response: ParentBundleModalQueryResponse,
|};
*/


/*
query ParentBundleModalQuery(
  $ids: [ID]
) {
  viewer {
    id
    products(first: 1, ids: $ids) {
      edges {
        node {
          id
          parentBundles {
            id
            name
            model
            adenCode
            finalPrice
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Admin",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1
          },
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "products",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "parentBundles",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "model",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "adenCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "finalPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ParentBundleModalQuery",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ParentBundleModalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3a63bf6bdf8f4486a8f856cf106c7c4b",
    "id": null,
    "metadata": {},
    "name": "ParentBundleModalQuery",
    "operationKind": "query",
    "text": "query ParentBundleModalQuery(\n  $ids: [ID]\n) {\n  viewer {\n    id\n    products(first: 1, ids: $ids) {\n      edges {\n        node {\n          id\n          parentBundles {\n            id\n            name\n            model\n            adenCode\n            finalPrice\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48b049efba6c41d0e70747611293ae02';

module.exports = node;
