import UpdateBrandMutation from './UpdateBrandMutation';
import AddBrandMutation from './AddBrandMutation';
import RemoveBrandMutation from './RemoveBrandMutation';
import ExportBrandsMutation from './ExportBrandsMutation';

module.exports = {
  UpdateBrandMutation,
  AddBrandMutation,
  RemoveBrandMutation,
  ExportBrandsMutation,
};
