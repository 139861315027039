import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddAdjacentBannerMutation } from './mutations';
import AdjacentBannerForm from './AdjacentBannerForm';

class AdjacentBannerNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;
    const uploadables = {};
    ['image'].forEach(key => {
      if (values[key]) {
        const file = values[key][0];
        values[key] = key;
        uploadables[key] = file.originFileObj;
      }
    });

    values.productId = values.productId.key;
    values.categoryIds = values.categoryIds.map(p => p.key);
    values.subcategoryIds = values.subcategoryIds.map(p => p.key);

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/promotion/adjacent-banner/${fromGlobalId(resp.addAdjacentBanner.adjacentBannerEdge.node.id).id}`;
      },
    };

    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }

    AddAdjacentBannerMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Adjacent Banner" />

        <h1>New Adjacent Banner</h1>
        <AdjacentBannerForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(AdjacentBannerNew, {
  viewer: graphql`
    fragment AdjacentBannerNew_viewer on Admin {
      id
      ...AdjacentBannerForm_viewer
    }
  `,
});
