import React from 'react';
import { Route } from 'found';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/coupon"
    getComponent={() => (
      import(/* webpackChunkName: "CouponList" */'./CouponList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_CouponList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...CouponList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >
    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "CouponNew" */'./CouponNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_CouponNew_Query {
          viewer {
            ...CouponNew_viewer
          }
        }
      `}
    />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "CouponView" */'./CouponView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_CouponView_Query ($ids: [ID]) {
          viewer {
            ...CouponView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('Coupon', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
