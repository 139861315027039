import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../../mutation';

const mutation = graphql`
  mutation RemoveRedemptionMutation($input: RemoveRedemptionInput!) {
    removeRedemption(input:$input) {
      removedRedemptionId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeRedemption', edgeName: 'removedRedemptionId', connectionKey: 'RedemptionList_redemptions' }),
});
export default { commit };
