import React from 'react';
import PropTypes from 'prop-types';

import { fromGlobalId } from '~/helper';

const QuickLink = ({ form, fieldName }) => {
  const val = form.getFieldValue(fieldName);

  const type = fieldName.replace('Id', '');

  if (val && type === 'category') {
    return (
      <a href={`/${type}/${fromGlobalId(val).id}`} target="_blank" rel="noopener noreferrer">Quick Link</a>
    );
  } else if (val) {
    return (
      <a href={`/promotion/${type}/${fromGlobalId(val).id}`} target="_blank" rel="noopener noreferrer">Quick Link</a>
    );
  }

  return null;
}

QuickLink.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default QuickLink;
