/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateGiftCardUsageInput = {|
  orderId: string
|};
export type GenerateGiftCardUsageMutationVariables = {|
  input: GenerateGiftCardUsageInput
|};
export type GenerateGiftCardUsageMutationResponse = {|
  +generateGiftCardUsage: ?{|
    +salesOrder: ?{|
      +id: string
    |}
  |}
|};
export type GenerateGiftCardUsageMutation = {|
  variables: GenerateGiftCardUsageMutationVariables,
  response: GenerateGiftCardUsageMutationResponse,
|};
*/


/*
mutation GenerateGiftCardUsageMutation(
  $input: GenerateGiftCardUsageInput!
) {
  generateGiftCardUsage(input: $input) {
    salesOrder {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateGiftCardUsagePayload",
    "kind": "LinkedField",
    "name": "generateGiftCardUsage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SalesOrder",
        "kind": "LinkedField",
        "name": "salesOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateGiftCardUsageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateGiftCardUsageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "575b862b83d2f9a58d1a9f95055fc194",
    "id": null,
    "metadata": {},
    "name": "GenerateGiftCardUsageMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateGiftCardUsageMutation(\n  $input: GenerateGiftCardUsageInput!\n) {\n  generateGiftCardUsage(input: $input) {\n    salesOrder {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0de7284d556ce3a466cf6404c9a0f836';

module.exports = node;
