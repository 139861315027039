import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import { RemoveSearchRedirectMutation, UpdateSearchRedirectMutation } from './mutations';

import SearchRedirectForm from './SearchRedirectForm';

class SearchRedirectView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      searchRedirects: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  remove = (redirect) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: redirect.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/search/redirect');
      },
    };
    RemoveSearchRedirectMutation.commit(mutation);
  }

  handleSubmit = (form, values) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateSearchRedirectMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const redirects = get(viewer, 'searchRedirects.edges[0].node', {});

    return (
      <div>
        <Helmet title={(redirects.query)} />
        <h1>{redirects.query}</h1>
        <SearchRedirectForm
          key={redirects.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          remove={this.remove}
        />
      </div>
    );
  }
}

export default createFragmentContainer(SearchRedirectView, {
  viewer: graphql`
    fragment SearchRedirectView_viewer on Admin {
      id
      searchRedirects(first: 1, ids: $ids) {
        edges {
          node {
            id
            query
            url
            status
          }
        }
      }
    }
  `,
});
