import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddSearchCanonicalMutation($input: AddSearchCanonicalInput!) {
    addSearchCanonical(input:$input) {
      searchCanonicalEdge {
        cursor
        node {
          id
          queries
          url
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
