import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, snakeCase } from 'lodash';

import { Affix, Button, Col, Divider, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Switch, Table, Tabs, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DictatesAttribute, FormBase, fileValidator, formItemLayout, imageValidator } from '~/components/form';
import Presence from '~/components/Presence';
import AttributeHistory from './AttributeHistory';
import { CopyToNzBtn, Loading } from '../product/WebsiteRef';

import './style.css';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

const FormTable = (props) => {
  const form = Form.useFormInstance();
  const { viewer, attribute } = props;
  const attributes = get(viewer, 'allAttributes.edges', []);

  const brands = get(viewer, 'brands.edges', []);
  const categories = get(viewer, 'categories.edges', []);
  const subcategories = get(viewer, 'subcategories.edges', []);

  const attributesOptions = attributes.map(({ node: opt }) => (
    <Option key={opt.id} value={opt.id}>{opt.name}</Option>
  ));
  const categoriesOptions = categories.map(({ node: c}) => (
    <Option key={c.id} value={c.id}>{c.name}</Option>
  ));
  const subcategoriesOptions = subcategories.map(({ node: c }) => (
    <Option key={c.id} value={c.id}>{c.name}</Option>
  ));
  const brandOptions = brands.map(({ node }) => (
    <Option key={node.id} value={node.id}>{node.name}</Option>
  ));

  const renderOptions = ({ name }) => {
    const { option: o, readOnly } = form.getFieldValue(["table", name]) || {};

    return (
      <FormItem
        name={[name, "option"]}
        rules={[{ required: !readOnly, message: 'required' }]}
      >
        {readOnly ? (
          <span>{o}</span>
        ) : (
          <Input placeholder="Value" />
        )}
      </FormItem>
    );
  };

  const renderDictates = (field) => {
    const attrsNamePath = ["table", field.name, "dictates", "attributes"];

    return (
      <>
        <FormItem name={[field.name, "dictates", "attrs"]}>
          <Select
            placeholder="Attributes"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            onSelect={(value) => {
              const attrs = form.getFieldValue(attrsNamePath) || [];
              const { node: attr } = attributes.find(({ node }) => node.id === value) || {};

              if (attr) {
                form.setFieldValue(attrsNamePath, [...attrs, {
                  id: attr.id,
                  code: attr.code,
                  name: attr.name,
                  required: false,
                }]);
              }
            }}
            onChange={(values) => {
              const attrs = form.getFieldValue(attrsNamePath) || [];

              if (values.length < attrs.length) {
                form.setFieldValue(attrsNamePath, attrs.filter(({ id }) => values.includes(id)));
              }
            }}
          >
            {attributesOptions}
          </Select>
        </FormItem>
        <Form.List name={[field.name, "dictates", "attributes"]}>
          {(fields) => fields.map(({ key, ...restField }) => (
            <DictatesAttribute
              key={key}
              parentNamePath={["table", field.name, "dictates", "attributes"]}
              attributes={attributes}
              {...restField}
            />
          ))}
        </Form.List>
      </>
    )
  }

  const renderAction = ({ name }) => {
    const { option: o, readOnly } = form.getFieldValue(["table", name]) || {};

    return readOnly ? (
      <div>
        <div>
          <a href="#" onClick={() => props.showRenameModal(attribute.id, o)}>Rename</a>
        </div>
        <Popconfirm
          title="It is irreversible! Are you sure to delete this option?"
          onConfirm={() => props.removeOption(attribute.id, o, () => form.resetFields())}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">Delete</a>
        </Popconfirm>
      </div>
    ) : null
  }

  const renderImagesOptions = (field) => {
    const o = form.getFieldValue(["table", field.name, "option"]);

    return (
      <Form.List name={[field.name, "images"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <React.Fragment key={key}>
                {index > 0 && <Divider />}
                <FormItem noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const id = getFieldValue(["table", field.name, "images", name, "id"]);
                    return id ? (
                      <FormItem
                        name={[name, "id"]}
                        hidden
                      >
                        <Input />
                      </FormItem>
                    ) : null
                  }}
                </FormItem>

                <Row>
                  <Col md={24} lg={24} xl={8}>
                    <FormItem
                      label={`${o} - Image ${index}`}
                    >
                      <div>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            const imageUrl = getFieldValue(["table", field.name, "images", name, "imageUrl"]);
                            return imageUrl ? (
                              <img alt="" src={imageUrl} width="120" height="120" />
                            ) : null
                          }}
                        </FormItem>
                        <MinusCircleOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() => remove(name)}
                        />
                      </div>

                      <FormItem
                        name={[name, "file"]}
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e && e.fileList;
                        }}
                        rules={[
                          ({ getFieldValue }) => {
                            const b = getFieldValue(["table", field.name, "images", name]) || {};
                            return { required: !b.imageUrl, message: 'required' }
                          },
                          { required: true, message: 'File cannot be over 1MB', validator: (rule, value) => fileValidator(1, rule, value) },
                          { required: true, message: 'Image size should be 240x240', validator: (rule, value) => imageValidator([[240, 240]], rule, value) },
                        ]}
                      >
                        <Upload
                          accept="image/gif,image/png,image/jpeg"
                          beforeUpload={() => false}
                          listType="picture"
                        >
                          <Button>
                            <UploadOutlined /> Upload
                          </Button>
                        </Upload>
                      </FormItem>
                    </FormItem>
                  </Col>
                  <Col md={24} lg={24} xl={16}>
                    <FormItem
                      label="On Categories"
                      name={[name, "categoryIds"]}
                      rules={[{ required: false, message: 'required' }]}
                      style={{ marginBottom: "0px" }}
                    >
                      <Select
                        placeholder="Categories"
                        mode="multiple"
                        optionFilterProp="children"
                        optionLabelProp="children"
                        labelInValue
                        style={{ width: '100%' }}
                      >
                        {categoriesOptions}
                      </Select>
                    </FormItem>

                    <FormItem
                      label="On Subcategories"
                      name={[name, "subcategoryIds"]}
                      rules={[{ required: false, message: 'required' }]}
                      style={{ marginBottom: "0px" }}
                    >
                      <Select
                        placeholder="Subcategories"
                        mode="multiple"
                        optionFilterProp="children"
                        optionLabelProp="children"
                        labelInValue
                        style={{ width: '100%' }}
                      >
                        {subcategoriesOptions}
                      </Select>
                    </FormItem>

                    <FormItem
                      label="On Brands"
                      name={[name, "brandIds"]}
                      rules={[{ required: false, message: 'required' }]}
                      style={{ marginBottom: "0px" }}
                    >
                      <Select
                        placeholder="Brands"
                        mode="multiple"
                        optionFilterProp="children"
                        optionLabelProp="children"
                        labelInValue
                        style={{ width: '100%' }}
                      >
                        {brandOptions}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>
            ))}

            <Button
              onClick={() => {
                const { readOnly } = form.getFieldValue(["table", field.name]) || {};

                if (readOnly) {
                  add();
                } else {
                  message.warn('Please save options first');
                }
              }}
              style={{ marginLeft: '5px' }}
            >
              <PlusOutlined />
            </Button>
          </>
        )}
      </Form.List>
    )
  }

  const columns = [
    {
      key: "options",
      title: "Options",
      width: "30%",
      render: renderOptions,
    },
    {
      key: "dictates",
      title: "Dictates",
      render: renderDictates,
    },
    {
      key: "action",
      title: "Action",
      width: "5%",
      render: renderAction,
    },
  ];

  return (
    <Table
      className="table-style"
      pagination={{ position: "none", pageSize: 9999 }}
      columns={columns}
      expandable={{
        expandedRowKeys: props.expandedRowKeys,
        expandedRowRender: renderImagesOptions,
        onExpand: props.onExpand,
        rowExpandable: () => true,
      }}
      dataSource={props.fields}
    />
  )
};

FormTable.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  attribute: PropTypes.shape({
    id: PropTypes.string
  }),
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  showRenameModal: PropTypes.func,
  removeOption: PropTypes.func,
  expandedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  onExpand: PropTypes.func.isRequired,
};

FormTable.defaultProps = {
  attribute: {},
  showRenameModal: null,
  removeOption: null,
};

class AttributeForm extends FormBase {
  static propTypes = {
    entity: PropTypes.shape({
      id: PropTypes.string
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      allAttributes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    removeOption: PropTypes.func,
    renameOption: PropTypes.func,
  }

  static defaultProps = {
    entity: {},
    match: {},
    removeOption: null,
    renameOption: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      tableExpanded: [],
    };
  }


  setTableExpand = (keys) => {
    this.setState({
      tableExpanded: keys
    })
  }


  showRenameModal = (id, option) => {
    this.setState({
      renameVisible: true,
      renameId: id,
      renameOption: option,
      renameNew: option,
    });
  }

  handleRename = () => {
    this.setState({
      renameVisible: false,
      renameId: null,
      renameOption: null,
      renameNew: null,
    });
    const { renameId, renameOption, renameNew } = this.state;
    this.props.renameOption(renameId, renameOption, renameNew, () => {
      const table = this.formRef.current.getFieldValue(["table"]) || [];

      this.formRef.current.setFieldsValue({
        table: table.map((t) => {
          if (t.option === renameOption) {
            t.option = renameNew
          }
          return t;
        }),
      });
    });
  }

  handleCancel = () => {
    this.setState({
      renameVisible: false,
      renameId: null,
      renameOption: null,
      renameNew: null,
    });
  }

  render() {
    const { match, viewer, entity: attribute } = this.props;

    const allDictates = get(attribute, 'dictates') || [];
    const allImages = get(attribute, 'images.edges');

    const initialValue = get(attribute, 'options', []).map((o) => {
      const attrDictates = allDictates.filter(({ value }) => value === o);
      const readOnly = attribute.options ? attribute.options.includes(o) : false;
      const images = allImages.filter(({ node }) => node.option === o).map(({ node }) => ({
        id: node.id,
        imageUrl: node.imageUrl,
        brandIds: get(node, 'brands.edges', []).map(({ node: b }) => ({ key: b.id, label: b.name })),
        categoryIds: get(node, 'categories.edges', []).map(({ node: c }) => ({ key: c.id, label: c.name })),
        subcategoryIds: get(node, 'subcategories.edges', []).map(({ node: s }) => ({ key: s.id, label: s.name })),
      }));

      return {
        option: o,
        dictates: {
          attrs: attrDictates.map(({ attribute: a }) => a.id),
          attributes: attrDictates.map(({ attribute: a }) => ({ ...a, options: a.options || undefined })),
        },
        readOnly,
        images,
      }
    });

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }} key={get(attribute, "id", "new")}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()} style={{ marginRight: '10px' }}>Save</Button>
            {attribute.id && (
              <React.Suspense fallback={<Loading />}>
                <CopyToNzBtn viewer={viewer} entity={attribute} />
              </React.Suspense>
            )}
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={attribute.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={attribute.name}
            >
              <Input
                placeholder="Name"
                onChange={(e) => {
                  if (!attribute.id) {
                    const code = snakeCase(e.target.value);
                    this.formRef.current.setFieldsValue({ code });
                  }
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Code"
              name="code"
              rules={[{ required: true, message: 'required' }]}
              initialValue={attribute.code}
            >
              <Input readOnly placeholder="Code" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Multi Value"
              name="multi"
              valuePropName="checked"
              initialValue={attribute.multi}
              extra="This action is irreversible, think carefully!"
            >
              <Switch placeholder="Multi Value" disabled={attribute.multi === true} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Visible To Frontend"
              name="visibleToFrontend"
              valuePropName="checked"
              initialValue={attribute.visibleToFrontend}
            >
              <Switch placeholder="Visible To Frontend" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Include In Search"
              name="includeInSearch"
              valuePropName="checked"
              initialValue={attribute.includeInSearch}
            >
              <Switch placeholder="Include In Search" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name="position"
              initialValue={get(attribute, 'position', 0)}
              extra="Controls attribute position at frontend"
            >
              <InputNumber placeholder="Position" />
            </FormItem>

            <Modal
              title="Rename Option"
              visible={this.state.renameVisible}
              onOk={this.handleRename}
              onCancel={this.handleCancel}
            >
              <Input placeholder="Option Name" value={this.state.renameNew} onChange={e => this.setState({ renameNew: e.target.value })} />
            </Modal>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const table = getFieldValue("table") || [];
                const keys = table.map((value, index) => index);

                return (
                  <Button
                    onClick={() => {
                      if (this.state.tableExpanded.length === keys.length) {
                        this.setTableExpand([]);
                      } else {
                        this.setTableExpand(keys);
                      }
                    }}
                  >
                    {this.state.tableExpanded.length !== keys.length ? "Show all Images" : "Hide all Images"}
                  </Button>
                )
              }}
            </FormItem>

            <Form.List name="table" initialValue={initialValue} shouldUpdate>
              {(fields, { add }) => (
                <>
                  <FormTable
                    viewer={viewer}
                    attribute={attribute}
                    fields={fields}
                    showRenameModal={this.showRenameModal}
                    removeOption={this.props.removeOption}
                    expandedRowKeys={this.state.tableExpanded}
                    onExpand={(expanded, record) => {
                      if (expanded) {
                        const keys = this.state.tableExpanded.concat([record.key]);
                        this.setTableExpand(keys);
                      } else {
                        const keys = this.state.tableExpanded.filter(key => key !== record.key);
                        this.setTableExpand(keys);
                      }
                    }}
                  />
                  <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    <Button type="primary" onClick={() => add()}>Add</Button>
                  </div>
                </>
              )}
            </Form.List>
          </TabPane>

          {attribute.id && (
            <TabPane tab="History" key="history">
              <AttributeHistory viewer={viewer} attribute={attribute} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(AttributeForm, {
  viewer: graphql`
    fragment AttributeForm_viewer on Admin {
      id
      ...AttributeHistory_viewer
      allAttributes: attributes(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            code
            options
            multi
          }
        }
      }
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
