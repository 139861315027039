import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddCategoryMutation($input: AddCategoryInput!) {
    addCategory(input:$input) {
      categoryEdge {
        cursor
        node {
          id
          name
          urlSlug
          description
          description2
          includeInMenu
          includeInSearch
          includeInSitemap
          includeInFilter
          internalPriceMatch
          featured
          position
          aliases
          displayMode
          brands(first: 9999) {
            edges {
              node {
                id
                name
                imageUrl
              }
            }
          }
          scheduleAssignments {
            id
            datetime
            products(first: 9999) {
              edges {
                node {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
          }
          scheduleRemovals {
            id
            datetime
            products(first: 9999) {
              edges {
                node {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
          }
          status
          enableStartDate
          enableEndDate
          previewDescription
          metaTitle
          metaDescription
          wideImage
          jsonLd
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
