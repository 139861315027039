/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSynonymInput = {|
  id: string,
  synonyms: $ReadOnlyArray<?string>,
|};
export type UpdateSynonymMutationVariables = {|
  input: UpdateSynonymInput
|};
export type UpdateSynonymMutationResponse = {|
  +updateSynonym: ?{|
    +synonym: ?{|
      +synonyms: ?$ReadOnlyArray<?string>
    |}
  |}
|};
export type UpdateSynonymMutation = {|
  variables: UpdateSynonymMutationVariables,
  response: UpdateSynonymMutationResponse,
|};
*/


/*
mutation UpdateSynonymMutation(
  $input: UpdateSynonymInput!
) {
  updateSynonym(input: $input) {
    synonym {
      synonyms
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "synonyms",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSynonymMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSynonymPayload",
        "kind": "LinkedField",
        "name": "updateSynonym",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Synonym",
            "kind": "LinkedField",
            "name": "synonym",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSynonymMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSynonymPayload",
        "kind": "LinkedField",
        "name": "updateSynonym",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Synonym",
            "kind": "LinkedField",
            "name": "synonym",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e58c4ca990ce3b2d549fe2f45931f7da",
    "id": null,
    "metadata": {},
    "name": "UpdateSynonymMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSynonymMutation(\n  $input: UpdateSynonymInput!\n) {\n  updateSynonym(input: $input) {\n    synonym {\n      synonyms\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd03a8c2d55aacb2359ef737790758534';

module.exports = node;
