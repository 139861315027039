import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddSynonymMutation($input: AddSynonymInput!) {
    addSynonym(input:$input) {
      synonymEdge {
        cursor
        node {
          id
          synonyms
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
