import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import History, { addHistory, historyPropTypes } from '~/components/history';

const CouponHistory = (props) => {
  const historyFn = addHistory({props, connectionKey: "CouponHistory_histories"});

  // eslint-disable-next-line react/prop-types
  const { coupon: entity, relay } = props;

  return (
    <History relay={relay} entity={entity} addHistory={historyFn} />
  );
};

CouponHistory.propTypes = historyPropTypes({entity: 'coupon'});

export default createRefetchContainer(
  CouponHistory,
  {
    viewer: graphql`
      fragment CouponHistory_viewer on Admin {
        id
      }
    `,
    coupon: graphql`
      fragment CouponHistory_coupon on Coupon @argumentDefinitions(
        count: {type: "Int!", defaultValue: 10},
      ) {
        id
        histories(first: $count) @connection(key: "CouponHistory_histories") {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              changes
              user
              insertedAt
            }
          }
        }
      }
    `,
  },
  graphql`
    query CouponHistoryRefetchQuery($ids: [ID!], $count: Int!) {
      viewer {
        coupons(first: 1, ids: $ids) {
          edges {
            node {
              ...CouponHistory_coupon @arguments(count: $count)
            }
          }
       }
      }
    }
  `,
);
