/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchRedirectNew_viewer$ref: FragmentReference;
declare export opaque type SearchRedirectNew_viewer$fragmentType: SearchRedirectNew_viewer$ref;
export type SearchRedirectNew_viewer = {|
  +id: string,
  +$refType: SearchRedirectNew_viewer$ref,
|};
export type SearchRedirectNew_viewer$data = SearchRedirectNew_viewer;
export type SearchRedirectNew_viewer$key = {
  +$data?: SearchRedirectNew_viewer$data,
  +$fragmentRefs: SearchRedirectNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchRedirectNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '85d6e80ebf7b0e5effbc5c91a719a26d';

module.exports = node;
