/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddGiftCardInput = {|
  amount?: ?number,
  expiryMonths?: ?number,
  name?: ?string,
  products?: ?$ReadOnlyArray<?string>,
|};
export type AddGiftCardMutationVariables = {|
  input: AddGiftCardInput
|};
export type AddGiftCardMutationResponse = {|
  +addGiftCard: ?{|
    +giftCardEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +amount: ?number,
        +expiryMonths: ?number,
        +products: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type AddGiftCardMutation = {|
  variables: AddGiftCardMutationVariables,
  response: AddGiftCardMutationResponse,
|};
*/


/*
mutation AddGiftCardMutation(
  $input: AddGiftCardInput!
) {
  addGiftCard(input: $input) {
    giftCardEdge {
      cursor
      node {
        id
        name
        amount
        expiryMonths
        products(first: 99) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddGiftCardPayload",
    "kind": "LinkedField",
    "name": "addGiftCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GiftCardEdge",
        "kind": "LinkedField",
        "name": "giftCardEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GiftCard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiryMonths",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 99
                  }
                ],
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductImage",
                            "kind": "LinkedField",
                            "name": "mainImage",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "products(first:99)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddGiftCardMutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddGiftCardMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "445c2910eb330ff663b67c6f4660e2ad",
    "id": null,
    "metadata": {},
    "name": "AddGiftCardMutation",
    "operationKind": "mutation",
    "text": "mutation AddGiftCardMutation(\n  $input: AddGiftCardInput!\n) {\n  addGiftCard(input: $input) {\n    giftCardEdge {\n      cursor\n      node {\n        id\n        name\n        amount\n        expiryMonths\n        products(first: 99) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8dc156395b7925ea962bdd96a5f0a8f4';

module.exports = node;
