/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserView_viewer$ref = any;
export type routes_UserView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_UserView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserView_viewer$ref
  |}
|};
export type routes_UserView_Query = {|
  variables: routes_UserView_QueryVariables,
  response: routes_UserView_QueryResponse,
|};
*/


/*
query routes_UserView_Query(
  $ids: [ID]
) {
  viewer {
    ...UserView_viewer
    id
  }
}

fragment UserView_viewer on Admin {
  schemaTypes {
    name
    fields
  }
  adminRoles(first: 999) {
    edges {
      node {
        id
        name
      }
    }
  }
  adminUsers(first: 1, ids: $ids) {
    edges {
      node {
        id
        username
        firstname
        lastname
        email
        status
        roles {
          id
          name
        }
        permissions {
          id
          obj
          act
        }
        suppliers {
          importProducts
          supplierCodes
        }
      }
    }
  }
  suppliers
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_UserView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_UserView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaType",
            "kind": "LinkedField",
            "name": "schemaTypes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fields",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              }
            ],
            "concreteType": "AdminRoleConnection",
            "kind": "LinkedField",
            "name": "adminRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "adminRoles(first:999)"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "AdminUserConnection",
            "kind": "LinkedField",
            "name": "adminUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstname",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastname",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdminRole",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": true,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Permission",
                        "kind": "LinkedField",
                        "name": "permissions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "obj",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "act",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplierPermission",
                        "kind": "LinkedField",
                        "name": "suppliers",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "importProducts",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "supplierCodes",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "suppliers",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "227117123ab986eb505077b6923e59a3",
    "id": null,
    "metadata": {},
    "name": "routes_UserView_Query",
    "operationKind": "query",
    "text": "query routes_UserView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...UserView_viewer\n    id\n  }\n}\n\nfragment UserView_viewer on Admin {\n  schemaTypes {\n    name\n    fields\n  }\n  adminRoles(first: 999) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  adminUsers(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        username\n        firstname\n        lastname\n        email\n        status\n        roles {\n          id\n          name\n        }\n        permissions {\n          id\n          obj\n          act\n        }\n        suppliers {\n          importProducts\n          supplierCodes\n        }\n      }\n    }\n  }\n  suppliers\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'af8d68c23bea96c5a2835e64d2296020';

module.exports = node;
