/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAlertInput = {|
  content: string,
  endDate: string,
  name: string,
  startDate: string,
  status: boolean,
  type: number,
|};
export type AddAlertMutationVariables = {|
  input: AddAlertInput
|};
export type AddAlertMutationResponse = {|
  +addAlert: ?{|
    +alertEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +content: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddAlertMutation = {|
  variables: AddAlertMutationVariables,
  response: AddAlertMutationResponse,
|};
*/


/*
mutation AddAlertMutation(
  $input: AddAlertInput!
) {
  addAlert(input: $input) {
    alertEdge {
      cursor
      node {
        id
        name
        content
        startDate
        endDate
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAlertPayload",
    "kind": "LinkedField",
    "name": "addAlert",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AlertEdge",
        "kind": "LinkedField",
        "name": "alertEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAlertMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAlertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7254e096c5ec8fad484fc5911fe24905",
    "id": null,
    "metadata": {},
    "name": "AddAlertMutation",
    "operationKind": "mutation",
    "text": "mutation AddAlertMutation(\n  $input: AddAlertInput!\n) {\n  addAlert(input: $input) {\n    alertEdge {\n      cursor\n      node {\n        id\n        name\n        content\n        startDate\n        endDate\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aef2676f817ec78114849deb2e64528a';

module.exports = node;
