import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation LinkProductMutation($input: LinkProductInput!) {
    linkProduct(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
