import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddFreebieMutation($input: AddFreebieInput!) {
    addFreebie(input:$input) {
      freebieEdge {
        cursor
        node {
          id
          brandIds
          name
          tiers(first: 999) {
            edges {
              node {
                id
                price
                quantity
                mode
                products {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
          }
          startDate
          endDate
          stealthMode
          status
          excludeProducts(first: 99999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
          includeProducts(first: 99999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
