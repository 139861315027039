/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UrlRewriteForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UrlRewriteView_viewer$ref: FragmentReference;
declare export opaque type UrlRewriteView_viewer$fragmentType: UrlRewriteView_viewer$ref;
export type UrlRewriteView_viewer = {|
  +urlRewrites: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +from: ?string,
        +to: ?string,
        +httpCode: ?number,
        +lastAccessed: ?string,
        +urlType: ?number,
        +status: ?boolean,
      |}
    |}>
  |},
  +$fragmentRefs: UrlRewriteForm_viewer$ref,
  +$refType: UrlRewriteView_viewer$ref,
|};
export type UrlRewriteView_viewer$data = UrlRewriteView_viewer;
export type UrlRewriteView_viewer$key = {
  +$data?: UrlRewriteView_viewer$data,
  +$fragmentRefs: UrlRewriteView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UrlRewriteView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "UrlRewriteConnection",
      "kind": "LinkedField",
      "name": "urlRewrites",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UrlRewriteEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UrlRewrite",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "from",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "to",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "httpCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastAccessed",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UrlRewriteForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e4ff2d22fb582afc3e93dfa4f46661a0';

module.exports = node;
