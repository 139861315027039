import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateFreebieMutation } from './mutations';
import FreebieForm from './FreebieForm';

class FreebieView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      freebies: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      includeProducts: formValues.includeProducts.map(p => p.key),
      excludeProducts: formValues.excludeProducts.map(p => p.key),
      tiers: formValues.tiers.map((t) => ({ ...t, productIds: t.productIds.map(p => p.key) })),
      stealthMode: !!formValues.stealthMode,
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateFreebieMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const freebie = get(viewer, 'freebies.edges[0].node', {});

    return (
      <div>
        <Helmet title={freebie.name} />
        <h1>{freebie.name}</h1>
        <FreebieForm
          key={freebie.id}
          match={match}
          viewer={viewer}
          freebie={freebie}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(FreebieView, {
  viewer: graphql`
    fragment FreebieView_viewer on Admin {
      ...FreebieForm_viewer
      freebies(first: 1, ids: $ids) {
        edges {
          node {
            id
            brandIds
            name
            tiers(first: 999) {
              edges {
                node {
                  id
                  price
                  quantity
                  mode
                  products {
                    id
                    name
                    status
                    mainImage {
                      id
                      url
                    }
                  }
                }
              }
            }
            excludeProducts(first: 99999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            includeProducts(first: 99999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            startDate
            endDate
            stealthMode
            status
            salesOrders(first: 999999) {
              edges {
                node {
                  id
                  name
                  grandTotal
                  insertedAt
                }
              }
            }
            labels (first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...FreebieHistory_freebie
          }
        }
      }
    }
  `,
});
