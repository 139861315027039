import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import {toGlobalId} from '~/helper';

const GetProductIDByFile = ({gridApi, setProductIds}) => {
  const [ checked, setChecked ] = useState(false)

  const getEncodedUri = () => {
    const headers = [['ID']];

    const csvHeaders = headers;

    const csvContent = `data:text/csv;charset=utf-8,${ csvHeaders.map(e => e.join(",")).join("\n") }`;

    return encodeURI(csvContent);
  }

  const handleFileChange = (info) => {
    const fileList = [...info.fileList];

    gridApi.deselectAll();
    setProductIds([]);

    if (fileList.length > 1) {
      message.error('One import file at a time');
      return;
    }

    if (fileList.length === 0) {
      message.error('Please upload a csv file.');
      return;
    }

    const file = fileList[0].originFileObj;

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const IDs = result.data
            .filter(row => (row.ID !== "0" && row.ID !== ""))
            .map(row => toGlobalId("Product", row.ID));

          setProductIds(IDs);
        }
      });
    }
  };

  return (
    <div style={{display: 'inline-block'}}>
      <Checkbox checked={checked} onChange={()=>{setChecked(!checked)}} style={{marginLeft: '5px'}}>
        Filter By Files
      </Checkbox>

      {checked && <a href={getEncodedUri()} download="assign_categories.csv" style={{margin: '0 5px'}} >Template</a>}

      {checked && (
        <Upload
          className="importProduct"
          accept=".csv"
          beforeUpload={() => false}
          listType="picture"
          onChange={handleFileChange}
        >
          <Button style={{marginRight: '5px'}}>
            <UploadOutlined /> Upload
          </Button>
        </Upload>
)}
    </div>
  )
}

GetProductIDByFile.propTypes = {
  gridApi: PropTypes.shape({
    deselectAll: PropTypes.func.isRequired,
  }).isRequired,
  setProductIds: PropTypes.func.isRequired,
};

export default GetProductIDByFile;;