/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCmsCarouselImageInput = {|
  bg1?: ?any,
  bg2?: ?any,
  endDate: string,
  link?: ?string,
  main1?: ?any,
  main2?: ?any,
  position?: ?number,
  startDate: string,
  status: boolean,
  title: string,
|};
export type AddCmsCarouselImageMutationVariables = {|
  input: AddCmsCarouselImageInput
|};
export type AddCmsCarouselImageMutationResponse = {|
  +addCmsCarouselImage: ?{|
    +cmsCarouselImageEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +title: ?string,
        +link: ?string,
        +main1: ?string,
        +main2: ?string,
        +bg1: ?string,
        +bg2: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +position: ?number,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddCmsCarouselImageMutation = {|
  variables: AddCmsCarouselImageMutationVariables,
  response: AddCmsCarouselImageMutationResponse,
|};
*/


/*
mutation AddCmsCarouselImageMutation(
  $input: AddCmsCarouselImageInput!
) {
  addCmsCarouselImage(input: $input) {
    cmsCarouselImageEdge {
      cursor
      node {
        id
        title
        link
        main1
        main2
        bg1
        bg2
        startDate
        endDate
        position
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCmsCarouselImagePayload",
    "kind": "LinkedField",
    "name": "addCmsCarouselImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsCarouselImageEdge",
        "kind": "LinkedField",
        "name": "cmsCarouselImageEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CmsCarouselImage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "main1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "main2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bg1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bg2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCmsCarouselImageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCmsCarouselImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "086fa8fe638a01aa2f30d948625450fc",
    "id": null,
    "metadata": {},
    "name": "AddCmsCarouselImageMutation",
    "operationKind": "mutation",
    "text": "mutation AddCmsCarouselImageMutation(\n  $input: AddCmsCarouselImageInput!\n) {\n  addCmsCarouselImage(input: $input) {\n    cmsCarouselImageEdge {\n      cursor\n      node {\n        id\n        title\n        link\n        main1\n        main2\n        bg1\n        bg2\n        startDate\n        endDate\n        position\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1b4f2a677022294fb955377a6d16bd4';

module.exports = node;
