import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Form } from 'antd';
import { formItemLayout, SelectProduct } from '~/components/form';

const { Item: FormItem } = Form;

class Related extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      related: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    viewer: PropTypes.shape({
    }).isRequired,
    onImport: PropTypes.func,
  }

  static defaultProps = {
    onImport: null,
  }

  render() {
    const { product, viewer, onImport } = this.props;

    return (
      <FormItem
        {...formItemLayout}
        label="Related Product"
        name="related"
        initialValue={get(product, 'related.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
      >
        <SelectProduct
          mode="multiple"
          placeholder="Products"
          labelInValue
          showSearch
          filterOption={false}
          viewer={viewer}
          importExport={{
            onImport: (value) => onImport(value, 'related'),
            listToExport: get(product, 'related.edges', [])
          }}
        />
      </FormItem>
    );
  }
}
export default createFragmentContainer(Related, {
  viewer: graphql`
    fragment Related_viewer on Admin {
      ...SelectProduct_viewer
    }
  `,
  product: graphql`
    fragment Related_product on Product {
      related(first: 99) {
        edges {
          node {
            id
            name
            sku
            status
            mainImage {
              id
              url
            }
          }
        }
      }
    }
  `,
});
