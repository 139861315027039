import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveBrandMutation($input: RemoveBrandInput!) {
    removeBrand(input:$input) {
      brand {
        id
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
