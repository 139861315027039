import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal, Table } from 'antd';

const columns = [
  {
    title: 'Branch Code',
    dataIndex: 'branch',
    key: 'branch',
    sorter: (a, b) => (Number(a.branch) - Number(b.branch)),
  },
  {
    title: 'Stock',
    dataIndex: 'stock',
    key: 'stock',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.stock - b.stock),
  },
];

const StockModal = (props) => {
  const { visible, product, dismiss } = props;
  const { name, stock } = product || {};

  if (!name || !stock) {
    return null;
  }

  const data = Object.entries(stock).map(([key, val]) => ({
    branch: key,
    key,
    stock: val,
  }));

  return (
    <Modal
      title={name}
      visible={visible}
      onCancel={dismiss}
      footer={null}
      width={760}
      destroyOnClose
    >
      <Alert message={<b>Stocks are updated ~hourly by Aden, please be mindful of this delay</b>} type="warning" />
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    </Modal>
  )
};

StockModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string,
    stock: PropTypes.shape({}),
  }),
};

StockModal.defaultProps = {
  product: null,
};

export default StockModal;
