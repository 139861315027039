import React from 'react';
import BulkUpdateStoresBdmEmails from './BulkUpdateStoresBdmEmails';
import BulkUpdateStoresDescription from './BulkUpdateStoresDescription';
import ExportStores from './ExportStores';

const bulkActions = [
  {
    title: 'Update Stores Description',
    node: <BulkUpdateStoresDescription />,
  },
  {
    title: 'Update Stores BDM Emails',
    node: <BulkUpdateStoresBdmEmails />,
  },
  {
    title: 'Export Stores',
    node: <ExportStores />,
  },
];

export default bulkActions;
