import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, Select } from 'antd';

import { getNodeIds } from '~/helper';
import GetProductIDByFile from './GetProductIDByFile'
import { RemoveSubcategoriesFromProductsMutation } from './mutations';

const { Option } = Select;

class RemoveSubcategoriesFromProducts extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    gridApi: PropTypes.shape({
      getSelectedRows: PropTypes.func.isRequired,
    }).isRequired,
  }

  setProductIds = (productIds) => {this.setState({productIds})};

  stats = {
    value: null,
    productIds: [],
  }

  handleChange = (value) => {
    this.setState({
      value
    });
  }

  handleSubmit = () => {
    const { value: subcategoryIds } = this.state;
    const selectedProducts = getNodeIds(this.props.gridApi.getSelectedRows());
    const productIds = [...new Set([...this.state.productIds || [], ...selectedProducts])];

    if (subcategoryIds.length && productIds.length) {
      RemoveSubcategoriesFromProductsMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { productIds, subcategoryIds } },
        viewer: this.props.viewer,
      });
    }
  }

  render() {

    return (
      <div style={{display: 'inline-block'}}>
        <GetProductIDByFile
          gridApi={this.props.gridApi}
          setProductIds={this.setProductIds}
        />

        <Select
          key="subcategories"
          placeholder="subcategories"
          mode="multiple"
          optionFilterProp="children"
          onChange={this.handleChange}
          style={{ width: '200px' }}
        >
          {
            this.props.viewer.subcategories.edges.map((edge) => {
              const e = edge.node;
              return <Option key={e.id}>{e.name}</Option>;
            })
          }
        </Select>

        <Button
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
};

export default createFragmentContainer(RemoveSubcategoriesFromProducts, {
  viewer: graphql`
    fragment RemoveSubcategoriesFromProducts_viewer on Admin {
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
