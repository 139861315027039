/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevenueReport_viewer$ref: FragmentReference;
declare export opaque type RevenueReport_viewer$fragmentType: RevenueReport_viewer$ref;
export type RevenueReport_viewer = {|
  +revenueReport: ?any,
  +id: string,
  +$refType: RevenueReport_viewer$ref,
|};
export type RevenueReport_viewer$data = RevenueReport_viewer;
export type RevenueReport_viewer$key = {
  +$data?: RevenueReport_viewer$data,
  +$fragmentRefs: RevenueReport_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterBy"
    },
    {
      "kind": "RootArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./RevenueReportRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "RevenueReport_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "kind": "ScalarField",
      "name": "revenueReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6f9680094bc587e8db33a246cebf3a9d';

module.exports = node;
