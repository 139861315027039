/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFreebieInput = {|
  brandIds: $ReadOnlyArray<?string>,
  categoryId?: ?string,
  endDate: string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  freebieId?: ?string,
  includeProducts?: ?$ReadOnlyArray<?string>,
  name: string,
  startDate: string,
  status: boolean,
  stealthMode?: ?boolean,
  tiers: $ReadOnlyArray<?InputFreebieTier>,
  type?: ?number,
|};
export type InputFreebieTier = {|
  id?: ?string,
  mode?: ?number,
  price: number,
  productIds: $ReadOnlyArray<?string>,
  quantity?: ?number,
|};
export type AddFreebieMutationVariables = {|
  input: AddFreebieInput
|};
export type AddFreebieMutationResponse = {|
  +addFreebie: ?{|
    +freebieEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +brandIds: ?$ReadOnlyArray<?string>,
        +name: ?string,
        +tiers: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +price: ?number,
              +quantity: ?number,
              +mode: ?number,
              +products: $ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |}>,
            |}
          |}>
        |},
        +startDate: ?string,
        +endDate: ?string,
        +stealthMode: ?boolean,
        +status: ?boolean,
        +excludeProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +includeProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type AddFreebieMutation = {|
  variables: AddFreebieMutationVariables,
  response: AddFreebieMutationResponse,
|};
*/


/*
mutation AddFreebieMutation(
  $input: AddFreebieInput!
) {
  addFreebie(input: $input) {
    freebieEdge {
      cursor
      node {
        id
        brandIds
        name
        tiers(first: 999) {
          edges {
            node {
              id
              price
              quantity
              mode
              products {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        startDate
        endDate
        stealthMode
        status
        excludeProducts(first: 99999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        includeProducts(first: 99999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFreebiePayload",
    "kind": "LinkedField",
    "name": "addFreebie",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FreebieEdge",
        "kind": "LinkedField",
        "name": "freebieEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Freebie",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandIds",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 999
                  }
                ],
                "concreteType": "FreebieTierConnection",
                "kind": "LinkedField",
                "name": "tiers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FreebieTierEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FreebieTier",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "quantity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": true,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tiers(first:999)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stealthMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "excludeProducts",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "excludeProducts(first:99999)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "includeProducts",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "includeProducts(first:99999)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddFreebieMutation",
    "selections": (v6/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddFreebieMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "d112dfcc8b95b83cae0c66d44af5eb93",
    "id": null,
    "metadata": {},
    "name": "AddFreebieMutation",
    "operationKind": "mutation",
    "text": "mutation AddFreebieMutation(\n  $input: AddFreebieInput!\n) {\n  addFreebie(input: $input) {\n    freebieEdge {\n      cursor\n      node {\n        id\n        brandIds\n        name\n        tiers(first: 999) {\n          edges {\n            node {\n              id\n              price\n              quantity\n              mode\n              products {\n                id\n                name\n                mainImage {\n                  id\n                  url\n                }\n              }\n            }\n          }\n        }\n        startDate\n        endDate\n        stealthMode\n        status\n        excludeProducts(first: 99999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        includeProducts(first: 99999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78084121babfdb2f8a72a62231d87a45';

module.exports = node;
