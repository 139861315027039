import React from 'react';
import { Route } from 'found';
import { toGlobalId } from '~/helper';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

import StateManagerRoutes from './state_manager/routes';

const routes = (
  <Route
    path="/store"
  >
    {StateManagerRoutes}

    <Route
      path="/"
      getComponent={() => (
        import(/* webpackChunkName: "StoreList" */'./StoreList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_StoreList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...StoreList_viewer
          }
        }
      `}
      prepareVariables={() => ({
        count: getPageSize(),
        orderBy: { field: 'name', direction: 'asc' },
      })}
    >
      <Route />

      <Route
        path="/new"
        getComponent={() => (
          import(/* webpackChunkName: "StoreNew" */'./StoreNew').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_StoreNew_Query {
            viewer {
              ...StoreNew_viewer
            }
          }
        `}
      />

      <Route
        path="/:id"
        getComponent={() => (
          import(/* webpackChunkName: "StoreView" */'./StoreView').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_StoreView_Query ($ids: [ID]) {
            viewer {
              ...StoreView_viewer
            }
          }
        `}
        prepareVariables={(params) => {
          const globalId = toGlobalId('Store', params.id);
          return { ids: [globalId] };
        }}
      />
    </Route>
  </Route>
);

export default routes;
