import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Affix, Button, Form, Input, Popconfirm, Select } from 'antd';
import { FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;

class SynonymForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      synonyms: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const synonym = get(viewer, 'synonyms.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values)=>{this.props.onSubmit(this.formRef.current, values);}}>
        <p>
          All in lowercases and singular form.
          <a
            href="https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-synonym-tokenfilter.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            Reference
          </a>
        </p>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>

            {synonym.id && (
            <Popconfirm
              title="Are you sure delete this synonym?"
              onConfirm={() => { this.props.remove(synonym); }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={synonym.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Synonyms"
          name="synonyms"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(synonym, 'synonyms', [])}
        >
          <Select
            placeholder="Enter Synonyms"
            mode="tags"
          />
        </FormItem>

      </Form>
    );
  }
}
export default SynonymForm;
