/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CmsPageNew_viewer$ref = any;
export type routes_CmsPageNew_QueryVariables = {||};
export type routes_CmsPageNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CmsPageNew_viewer$ref
  |}
|};
export type routes_CmsPageNew_Query = {|
  variables: routes_CmsPageNew_QueryVariables,
  response: routes_CmsPageNew_QueryResponse,
|};
*/


/*
query routes_CmsPageNew_Query {
  viewer {
    ...CmsPageNew_viewer
    id
  }
}

fragment CmsPageNew_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CmsPageNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CmsPageNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_CmsPageNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c341bafaf3c30c3c6be4bfdfb582ff4f",
    "id": null,
    "metadata": {},
    "name": "routes_CmsPageNew_Query",
    "operationKind": "query",
    "text": "query routes_CmsPageNew_Query {\n  viewer {\n    ...CmsPageNew_viewer\n    id\n  }\n}\n\nfragment CmsPageNew_viewer on Admin {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '07a63f4b7917ef399b28548b37a6269b';

module.exports = node;
