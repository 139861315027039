import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, message, Select } from 'antd';
import { CopyFromWebsiteMutation } from './mutations';

const CopyFromWebsite = (props) => {
  const { viewer, relay } = props;

  const [options, setOptions] = useState([]);

  const handleSubmit = () => {
    const mutation = {
      environment: relay.environment,
      variables: { input: { ids: options } },
      viewer,
      onCompleted: () => {
        message.success('Copied');
        const { gridApi } = props;

        if (gridApi && gridApi.refreshInfiniteCache) {
          gridApi.refreshInfiniteCache();
        }
      },
    }

    CopyFromWebsiteMutation.commit(mutation);
  }

  return (
    <div>
      <Select
        placeholder="Product Ids"
        mode="tags"
        style={{ display: 'block', width: '100%', maxWidth: '600px' }}
        onChange={(v) => { setOptions(v) }}
        tokenSeparators={[',', ' ']}
        allowClear
      />
      <div style={{ display: 'block', margin: '2px 0px' }}>
        <p>
          Example: &#x7B;id 1&#x7D;-&#x7B;id 2&#x7D; or List of ids separated with comma or space
        </p>
        <p>
          To copy bundle or configurable product over. Simple products must be copy first.
        </p>
        <p>
          It is a background job, after clicking the Submit button if any product failed to copy over, an email report will be sent out to the designer group.
        </p>
      </div>
      <Button onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  )
}

CopyFromWebsite.propTypes = {
  viewer: PropTypes.shape({
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  gridApi: PropTypes.shape({
    refreshInfiniteCache: PropTypes.func.isRequired,
  }).isRequired,
}

export default createFragmentContainer(CopyFromWebsite, {
  viewer: graphql`
    fragment CopyFromWebsite_viewer on Admin {
      id
    }
  `,
});
