/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveBonusInput = {|
  id: string
|};
export type RemoveBonusMutationVariables = {|
  input: RemoveBonusInput
|};
export type RemoveBonusMutationResponse = {|
  +removeBonus: ?{|
    +removedBonusId: ?string
  |}
|};
export type RemoveBonusMutation = {|
  variables: RemoveBonusMutationVariables,
  response: RemoveBonusMutationResponse,
|};
*/


/*
mutation RemoveBonusMutation(
  $input: RemoveBonusInput!
) {
  removeBonus(input: $input) {
    removedBonusId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveBonusPayload",
    "kind": "LinkedField",
    "name": "removeBonus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedBonusId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveBonusMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveBonusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f0c87956a09f2392678cbfc427d84b3",
    "id": null,
    "metadata": {},
    "name": "RemoveBonusMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveBonusMutation(\n  $input: RemoveBonusInput!\n) {\n  removeBonus(input: $input) {\n    removedBonusId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '595e7198b6d0bf1b8e27e2b43214879a';

module.exports = node;
