import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';

const { Option } = AutoComplete;

class SearchCustomer extends React.Component {
  static propTypes = {
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      getCustomerSuggestion: PropTypes.arrayOf(PropTypes.shape({
        cust_code: PropTypes.string.isRequired,
        customer_name: PropTypes.string.isRequired,
      })),
    }).isRequired,
    searchQuery: PropTypes.string.isRequired,
    setSearchText: PropTypes.func.isRequired,
  }

  debouncedHandleSearch = debounce((query) => {
    if (query.length >= 3) {
      this.props.relay.refetch({ query });
    }
  }, 100);

  handleClear = () => {
    this.props.setSearchText('', '', true);
  }

  handleSelect = (custName, option) => {
    const custCode = get(option, "customer.cust_code", '');
    this.props.setSearchText(custName, custCode);
  }

  render() {
    const { viewer, searchQuery } = this.props;
    const customers = get(viewer, 'getCustomerSuggestion', []);

    return (
      <AutoComplete
        allowClear
        onClear={this.handleClear}
        style={{width: '50%', display: 'block', marginTop: '10px'}}
        placeholder="Search Customers by Name or Cust Code..."
        value={searchQuery}
        open={searchQuery && searchQuery.length >= 3}
        onChange={(query) => {
          this.props.setSearchText(query, '');
          this.debouncedHandleSearch(query);
        }}
        onSelect={this.handleSelect}
      >
        {customers.map((customer) => (
          <Option key={customer.cust_code + customer.customer_name} customer={customer} value={customer.customer_name}>
            {`${customer.customer_name} (${customer.cust_code})`}
          </Option>
        ))}
      </AutoComplete>
    );
  }
}

export default createRefetchContainer(SearchCustomer, {
    viewer: graphql`
      fragment SearchCustomer_viewer on Admin @argumentDefinitions(
        query: {type: "String", defaultValue: ""}
      ) {
        getCustomerSuggestion(query: $query)
      }
    `
  },
    graphql`
    query SearchCustomerRefetchQuery($query: String) {
      viewer {
        ...SearchCustomer_viewer @arguments(query: $query)
      }
    }
  `
  );
