import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Divider, Form, Input, Select, Switch } from 'antd';

const { Item: FormItem } = Form;
const { Option } = Select;

const DictatesAttribute = (props) => {
  const { parentNamePath, name, attributes, divider } = props;

  const form = Form.useFormInstance();
  const attr = form.getFieldValue([...parentNamePath, name]) || {};

  const attribute = attributes.find(({ node }) => node.id === attr.id);
  const options = get(attribute, 'node.options') || [];

  return (
    <>
      <FormItem
        name={[name, "id"]}
        hidden
      >
        <Input />
      </FormItem>

      <FormItem
        name={[name, "options"]}
        label={
          <span style={{ display: 'flex', alignItems: 'center'}}>
            {attr.name} - required
            <FormItem
              name={[name, "required"]}
              valuePropName="checked"
              style={{ marginBottom: '0px' }}
            >
              <Switch size="small" />
            </FormItem>
          </span>
        }
        style={{ marginBottom: "0px" }}
      >
        <Select placeholder={attr.name} mode="multiple">
          {options.map(opt => <Option key={opt} value={opt}>{opt}</Option>)}
        </Select>
      </FormItem>
      {divider && <Divider />}
    </>
  )
};

DictatesAttribute.propTypes = {
  parentNamePath: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  name: PropTypes.number.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  divider: PropTypes.bool,
};

DictatesAttribute.defaultProps = {
  divider: false,
};

export default DictatesAttribute;
