import React from 'react';
import PropTypes from 'prop-types';
import { useRelayEnvironment } from 'react-relay';
import { Affix, Button, message, Popconfirm } from 'antd';

import { getNodeIds } from '~/helper';
import { RemoveSearchTermsMutation } from './../mutations';

const RemoveSearchTerms = (props) => {
  const environment = useRelayEnvironment();

  const handleClick = () => {
    const { gridApi } = props;
    const ids = getNodeIds(gridApi.getSelectedRows());

    if (ids && ids.length) {
      RemoveSearchTermsMutation.commit({
        environment,
        variables: { input: { ids } },
        viewer: props.viewer,
        onCompleted: () => {
          message.success('Deleted');
          gridApi.deselectAll();
          gridApi.refreshInfiniteCache();
        },
      });
    }
  }

  const handleSelect = () => {
    const { gridApi } = props;

    gridApi.getRenderedNodes().forEach(node => {
      node.setSelected(true);
    });
  }

  return (
    <Affix style={{ display: 'inline-block' }}>
      <div>
        <Button onClick={handleSelect}>
          Select Shown
        </Button>
        <Popconfirm title="Delete those search terms?" onConfirm={handleClick} okText="Yes" cancelText="No">
          <Button type="danger">Delete Selected</Button>
        </Popconfirm>
      </div>
    </Affix>
  )
}

RemoveSearchTerms.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
  }),
  gridApi: PropTypes.shape({
    deselectAll: PropTypes.func.isRequired,
    getSelectedRows: PropTypes.func.isRequired,
    getRenderedNodes: PropTypes.func.isRequired,
    refreshInfiniteCache: PropTypes.func.isRequired,
  }),
};

RemoveSearchTerms.defaultProps = {
  viewer: {},
  gridApi: null,
}

export default RemoveSearchTerms;
