import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateStoreMutation($input: UpdateStoreInput!) {
    updateStore(input:$input) {
      store {
        name
        address
        city
        postcode
        state
        country {
          alpha2
          name
        }
        description
        fax
        phone
        managerEmails
        bdmEmails {
          brandId
          emails
        }
        emails
        stateManager {
          emails
        }
        lat
        lng
        status
        regional
        canPickup
        excludeBulkyGood
        inCallPool
        distributionHub
        staffDelivery
        allocationLimit
        excludeAllocation {
          brands {
            id
            name
          }
          startDate
          endDate
        }
        metaTitle
        metaDescription
        branchCode
        content
        canHusqvarnaOpe
        hours {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
        ...StoreHistory_store
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
