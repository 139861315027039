import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { toGlobalId } from '~/helper';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/faq"
    getComponent={() => (
      import(/* webpackChunkName: "FaqList" */'./FaqList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_FaqList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...FaqList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >
    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "FaqNew" */'./FaqNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_FaqNew_Query {
          viewer {
            ...FaqNew_viewer
          }
        }
      `}
    />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "FaqView" */'./FaqView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_FaqView_Query ($ids: [ID]) {
          viewer {
            ...FaqView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('Faq', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
