import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Select, Tabs } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class ComboFreebieForm extends FormBase {
  static propTypes = {
    entity: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    entity: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const comboFreebie = get(this.props, 'entity', {});
    const freebies = get(comboFreebie, 'freebies.edges', []);

    const initialValue = {
      freebies: freebies.length ? freebies.slice(0).map(({ node }) => ({
        productId: SelectProduct.productOptions(node.product),
        quantity: get(node, "quantity", 1),
      })) : [{ productId: undefined, quantity: 1 }],
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <div>
          <b>
            DO NOT put the same skins across multiple combo freebies.
            <br />
            If DLX1 + Skin A for one battery and DLX2 + Skin A for one battery are both active, a customer buys DLX1 + Skin A + DLX2 will get 2 batteries
          </b>
        </div>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            {comboFreebie.id && (
              <FormItem
                name="id"
                initialValue={comboFreebie.id}
                hidden
              >
                <Input />
              </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={comboFreebie.name}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Bases"
              required
              name="bases"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(comboFreebie, 'bases.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
            >
              <SelectProduct
                placeholder="Bases"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
                importExport={{
                  onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'bases'); },
                  listToExport: get(comboFreebie, 'bases.edges', [])
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Skins"
              required
              name="skins"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(comboFreebie, 'skins.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
            >
              <SelectProduct
                placeholder="Skins"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
                importExport={{
                  onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'skins'); },
                  listToExport: get(comboFreebie, 'skins.edges', [])
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Freebies"
            >
              <Form.List name="freebies" initialValue={initialValue.freebies}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={key} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
                        <FormItem
                          label="Product"
                          name={[name, "productId"]}
                          style={{ display: 'inline-block', width: '400px' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <SelectProduct
                            placeholder="Product"
                            labelInValue
                            filterOption={false}
                            viewer={viewer}
                            showSearch
                          />
                        </FormItem>

                        <FormItem
                          label="Max Quantity"
                          name={[name, "quantity"]}
                          style={{ display: 'inline-block', width: '95px' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber placeholder="Qty" />
                        </FormItem>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ cursor: 'pointer' }}
                            disabled={fields.length === 1}
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </div>
                    ))}

                    <Button onClick={() => add({ productId: undefined, quantity: 1 })}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={comboFreebie.startDate ? moment(comboFreebie.startDate) : null}
            >
              <DatePicker placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={comboFreebie.endDate ? moment(comboFreebie.endDate) : null}
            >
              <DatePicker placeholder="End Date" />
            </FormItem>


            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(comboFreebie, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(ComboFreebieForm, {
  viewer: graphql`
    fragment ComboFreebieForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...SelectProduct_viewer
    }
  `,
});
