/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePurchaseLimitInput = {|
  endDate: string,
  id: string,
  limit: number,
  productId: string,
  startDate: string,
  status: boolean,
|};
export type UpdatePurchaseLimitMutationVariables = {|
  input: UpdatePurchaseLimitInput
|};
export type UpdatePurchaseLimitMutationResponse = {|
  +updatePurchaseLimit: ?{|
    +purchaseLimit: ?{|
      +startDate: ?string,
      +endDate: ?string,
      +product: ?{|
        +id: string,
        +name: ?string,
        +sku: ?string,
        +mainImage: ?{|
          +id: string,
          +url: ?string,
        |},
        +status: ?string,
      |},
      +limit: ?number,
      +status: ?boolean,
    |}
  |}
|};
export type UpdatePurchaseLimitMutation = {|
  variables: UpdatePurchaseLimitMutationVariables,
  response: UpdatePurchaseLimitMutationResponse,
|};
*/


/*
mutation UpdatePurchaseLimitMutation(
  $input: UpdatePurchaseLimitInput!
) {
  updatePurchaseLimit(input: $input) {
    purchaseLimit {
      startDate
      endDate
      product {
        id
        name
        sku
        mainImage {
          id
          url
        }
        status
      }
      limit
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sku",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "mainImage",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "limit",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePurchaseLimitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePurchaseLimitPayload",
        "kind": "LinkedField",
        "name": "updatePurchaseLimit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseLimit",
            "kind": "LinkedField",
            "name": "purchaseLimit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePurchaseLimitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePurchaseLimitPayload",
        "kind": "LinkedField",
        "name": "updatePurchaseLimit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseLimit",
            "kind": "LinkedField",
            "name": "purchaseLimit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "609e9ea905b61a264b978366d781a4dd",
    "id": null,
    "metadata": {},
    "name": "UpdatePurchaseLimitMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePurchaseLimitMutation(\n  $input: UpdatePurchaseLimitInput!\n) {\n  updatePurchaseLimit(input: $input) {\n    purchaseLimit {\n      startDate\n      endDate\n      product {\n        id\n        name\n        sku\n        mainImage {\n          id\n          url\n        }\n        status\n      }\n      limit\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ea4a2814b076dac54c3ed47667ba5b6';

module.exports = node;
