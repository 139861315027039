import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation UpdateSearchRedirectMutation($input: UpdateSearchRedirectInput!) {
    updateSearchRedirect(input:$input) {
      searchRedirect {
        query
        url
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
