import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Divider } from 'antd';

import { fromGlobalId } from '../../helper';
import { Grid, iDGetter, dateGetter, valueGetter } from '../grid';

const entityName = 'PriceMatch';

const STATUSES = {
  0: 'Pending',
  1: 'Approved',
  2: 'Declined',
  3: 'Cancelled',
};

class PriceMatchList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      priceMatches: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/price-match/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false,
      },
      { colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name')},
      { colId: 'link', headerName: 'Link', valueGetter: valueGetter.bind(this, 'link'), width: 300 },
      {
        colId: 'product', headerName: 'Product', valueGetter: valueGetter.bind(this, 'product.name'), suppressMenu: true, sortable: false, filter: false, width: 300
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: valueGetter.bind(this, 'status'),
        filter: 'agNumberColumnFilter',
        valueFormatter: params => STATUSES[params.value],
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: STATUSES, suppressFilterButton: true },
      },
      { colId: 'insertedAt', headerName: 'Created At', valueGetter: dateGetter.bind(this, 'insertedAt') },
    ];

    this.datasource = Grid.getDataSource.call(this, 'priceMatches');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  PriceMatchList, {
    viewer: graphql`
    fragment PriceMatchList_viewer on Admin {
      priceMatches(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "PriceMatchList_priceMatches") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            link
            status
            product {
              name
            }
            insertedAt
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.priceMatches;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query PriceMatchListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...PriceMatchList_viewer
      }
    }
  `,
  },
);
