import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { message } from 'antd';
import NProgress from 'nprogress';
import { get } from 'lodash';

import Presence from '~/components/Presence';

function buildMutation(mutation, config = {}) {
  const commit = function commit({
    environment, variables = {}, viewer, uploadables, parent, safeGuard, ...rest
  }) {
    NProgress.start();
    if (safeGuard && typeof safeGuard.insert === 'function') {
      safeGuard.insert(true);
    }
    const c = {
      mutation,
      onCompleted: (response, errors) => {
        if (errors) {
          if (rest.onError) {
            rest.onError(errors);
          } else if (config.onError) {
            config.onError(errors);
          } else {
            message.error(errors[0].message);
          }
        } else {
          if (safeGuard === "update") {
            Presence.update.call();
          } else if (safeGuard === "delete") {
            Presence.delete.call();
          }

          if (rest.onCompleted) {
            rest.onCompleted(response, errors);
          } else if (config.onCompleted) {
            config.onCompleted(response, errors);
          } else {
            message.success('Saved');
          }
        }
        NProgress.done();
        if (safeGuard && typeof safeGuard.insert === 'function') {
          safeGuard.insert(false);
        }
      },
      onError: (error) => {
        if (rest.onError) {
          rest.onError(error);
        } else if (config.onError) {
          config.onError(error);
        } else {
          const e = get(error, '[0].message', 'Shit happened')
          message.error(e);
        }
        NProgress.done();
        if (safeGuard && typeof safeGuard.insert === 'function') {
          safeGuard.insert(false);
        }
      },
      variables: { ...variables },
    };

    if (config.optimisticResponse) {
      c.optimisticResponse = config.optimisticResponse;
    }
    if (config.updater) {
      c.updater = config.updater.bind(this, { viewer, parent });
    }

    if (config.configs) {
      c.configs = config.configs;
    }

    if (uploadables) {
      c.uploadables = uploadables;
    }

    return commitMutation(
      environment,
      c,
    );
  };

  return commit;
}

const getConnection = function getConnection(store, { viewer, parent }, connectionKey) {
  let parentProxy = null;
  if (parent) {
    parentProxy = store.get(parent.id);
  } else {
    parentProxy = store.get(viewer.id);
  }

  return ConnectionHandler.getConnection(
    parentProxy,
    connectionKey,
  );
};

const edgeUpdater = ({
  rootField, edgeName, connectionKey, insertPosition = 'after',
}, { viewer, parent }, store) => {
  const payload = store.getRootField(rootField);

  if (!payload) return;

  const newEdge = payload.getLinkedRecord(edgeName);

  const conn = getConnection(store, { viewer, parent }, connectionKey);

  if (insertPosition === 'after') {
    ConnectionHandler.insertEdgeAfter(conn, newEdge);
  } else {
    ConnectionHandler.insertEdgeBefore(conn, newEdge);
  }
};

const edgeDeleter = ({ rootField, edgeName, connectionKey }, { viewer, parent }, store) => {
  const deletedID = store.getRootField(rootField).getValue(edgeName);

  const conn = getConnection(store, { viewer, parent }, connectionKey);
  ConnectionHandler.deleteNode(
    conn,
    deletedID,
  );
};

module.exports = {
  buildMutation,
  edgeUpdater,
  edgeDeleter,
};
