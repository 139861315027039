/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RedemptionForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RedemptionNew_viewer$ref: FragmentReference;
declare export opaque type RedemptionNew_viewer$fragmentType: RedemptionNew_viewer$ref;
export type RedemptionNew_viewer = {|
  +id: string,
  +$fragmentRefs: RedemptionForm_viewer$ref,
  +$refType: RedemptionNew_viewer$ref,
|};
export type RedemptionNew_viewer$data = RedemptionNew_viewer;
export type RedemptionNew_viewer$key = {
  +$data?: RedemptionNew_viewer$data,
  +$fragmentRefs: RedemptionNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RedemptionNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RedemptionForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ad2943c4f92666d5885ffd2378294ed0';

module.exports = node;
