import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button } from 'antd';
import Cookies from 'universal-cookie';

import { LogoutMutation } from './mutations';

const cookies = new Cookies();

class Logout extends Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  logout = () => {
    LogoutMutation.commit({
      environment: this.props.relay.environment,
      viewer: this.props.viewer,
      onCompleted: () => {
        cookies.remove('auth_token', { path: '/' });
        window.location.href = '/';
      },
    });
  }
  render() {
    const { viewer } = this.props;
    return (viewer && <Button onClick={this.logout}>Logout</Button>) || null;
  }
}
export default createFragmentContainer(Logout, {
  viewer: graphql`
    fragment Logout_viewer on Admin {
      id
      username
    }
  `,
});
