import { makeRouteConfig, Route } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import App from './components/App';
import Home from './components/home';
import NotFound from './components/NotFound';
import ConfigRoutes from './components/config/routes';
import ProductRoutes from './components/product/routes';
import CategoryRoutes from './components/category/routes';
import SubcategoryRoutes from './components/subcategory/routes';
import BrandRoutes from './components/brand/routes';
import ReviewRoutes from './components/review/routes';
import AttributeRotues from './components/attribute/routes';
import StoreRoutes from './components/store/routes';
import CustomerRoutes from './components/customer/routes';
import SalesOrderRoutes from './components/sales/routes';
import SearchRoutes from './components/search/routes';
import SecurityRoutes from './components/security/routes';
import CmsRoutes from './components/cms/routes';
import PromotionRoutes from './components/promotion/routes';
import UserRoutes from './components/user/routes';
import ReportRoutes from './components/report/routes';
import GiftCardRoutes from './components/gift_card/routes';
import PriceMatchRoutes from './components/price_match/routes';
import UrlRoutes from './components/url/routes';

export default makeRouteConfig(
  <Route
    path="/"
    Component={App}
    query={graphql`
      query routes_App_Query {
        viewer {
          ...App_viewer
        }
      }
    `}
  >
    <Route
      Component={Home}
      query={graphql`
        query routes_Home_Query {
          viewer {
            ...home_viewer
          }
        }
      `}
    />

    {ConfigRoutes}
    {ProductRoutes}
    {CategoryRoutes}
    {SubcategoryRoutes}
    {BrandRoutes}
    {ReviewRoutes}
    {AttributeRotues}
    {StoreRoutes}
    {CustomerRoutes}
    {SalesOrderRoutes}
    {SearchRoutes}
    {SecurityRoutes}
    {CmsRoutes}
    {PromotionRoutes}
    {UserRoutes}
    {ReportRoutes}
    {GiftCardRoutes}
    {PriceMatchRoutes}
    {UrlRoutes}

    <Route
      path="/404"
      Component={NotFound}
    />
  </Route>
);
