/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WebsiteRef_subcategory$ref: FragmentReference;
declare export opaque type WebsiteRef_subcategory$fragmentType: WebsiteRef_subcategory$ref;
export type WebsiteRef_subcategory = {|
  +websiteReference: ?{|
    +id: ?string,
    +country: ?string,
  |},
  +id: string,
  +$refType: WebsiteRef_subcategory$ref,
|};
export type WebsiteRef_subcategory$data = WebsiteRef_subcategory;
export type WebsiteRef_subcategory$key = {
  +$data?: WebsiteRef_subcategory$data,
  +$fragmentRefs: WebsiteRef_subcategory$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./WebsiteRefSubcategoryRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "WebsiteRef_subcategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebsiteReference",
      "kind": "LinkedField",
      "name": "websiteReference",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Subcategory",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a5b444b1a9545db1e2e8faa598cc29c';

module.exports = node;
