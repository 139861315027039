import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { RemoveCmsBannerMutation, UpdateCmsBannerMutation } from './mutations';
import BannerForm from './BannerForm';

class BannerView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      cmsBanners: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const imageFields = ['homeBig', 'homeSmall', 'categoryBig', 'categorySmall'];

    const uploadables = {};
    imageFields.forEach((i) => {
      if (values[i] === 'delete') {
        values[i] = null;
      } else if (values[i]) {
        const { file } = values[i];
        values[i] = i;
        uploadables[i] = file;
      }
    });

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };

    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateCmsBannerMutation.commit(mutation);
  }

  removeBanner = (banner) => {
    RemoveCmsBannerMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: banner.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        window.location.href = "/cms/banner";
      },
    });
  }


  render() {
    const { match, viewer } = this.props;
    const banner = get(viewer, 'cmsBanners.edges[0].node', {});

    return (
      <div>
        <Helmet title={banner.name} />
        <h1>{banner.title}</h1>
        <BannerForm
          key={banner.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          removeBanner={this.removeBanner}
        />
      </div>
    );
  }
}

export default createFragmentContainer(BannerView, {
  viewer: graphql`
    fragment BannerView_viewer on Admin {
      cmsBanners(first: 1, ids: $ids) {
        edges {
          node {
            id
            title
            link
            homeBig
            homeSmall
            categoryBig
            categorySmall
            startDate
            endDate
            urlPaths
            position
            status
          }
        }
      }
    }
  `,
});
