import React, { useState } from 'react';
import { Button } from 'antd';
import { removeItem } from './helper';

export const useFormBase = () => {
  const [disableButton, setDisableButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  // No callback available
  const setLoading = (value) => {
    setLoadingButton(value);
  }

  const handleDisableBtn = (value) => {
    setDisableButton(value);
  }

  const renderSaveButton = () => (
    <Button type="primary" htmlType="submit" disabled={disableButton} loading={loadingButton}>
      Save
    </Button>
  )

  return {
    setLoading,
    handleDisableBtn,
    shouldDisableBtn: disableButton,
    renderSaveButton,
  };
}

class FormBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableButton: false,
      loadingButton: false,
    }
  }

  setLoading = (value, cb = null) => {
    this.setState({
      loadingButton: value,
    }, cb);
  }

  handleDisableBtn = (value) => {
    this.setState({
      disableButton: value,
    });
  }

  shouldDisableBtn = () => {
    const { disableButton } = this.state;
    return disableButton;
  }

  removeItem = removeItem;

  renderSaveButton = () => {
    const { disableButton, loadingButton } = this.state;

    return (
      <Button type="primary" htmlType="submit" disabled={disableButton} loading={loadingButton}>
        Save
      </Button>
    )
  }
}

export default FormBase;
