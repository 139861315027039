import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RenameAttributeOptionMutation($input: RenameAttributeOptionInput!) {
    renameAttributeOption(input:$input) {
      attribute {
        name
        code
        options
        dictates
        multi
        visibleToFrontend
        images(first: 9999) {
          edges {
            node {
              id
              imageUrl
              option
              brands(first: 9999) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              categories(first: 9999) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              subcategories(first: 9999) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        ...AttributeHistory_attribute
        ...WebsiteRef_attribute
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
