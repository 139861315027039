import AddCategoryMutation from './AddCategoryMutation';
import EmptyCategoryMutation from './EmptyCategoryMutation';
import UpdateCategoryMutation from './UpdateCategoryMutation';
import RemoveCategoryMutation from "./RemoveCategoryMutation";

module.exports = {
  AddCategoryMutation,
  EmptyCategoryMutation,
  UpdateCategoryMutation,
  RemoveCategoryMutation,
};
