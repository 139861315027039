/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmptyCategoryInput = {|
  id: string
|};
export type EmptyCategoryMutationVariables = {|
  input: EmptyCategoryInput
|};
export type EmptyCategoryMutationResponse = {|
  +emptyCategory: ?{|
    +id: ?string
  |}
|};
export type EmptyCategoryMutation = {|
  variables: EmptyCategoryMutationVariables,
  response: EmptyCategoryMutationResponse,
|};
*/


/*
mutation EmptyCategoryMutation(
  $input: EmptyCategoryInput!
) {
  emptyCategory(input: $input) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmptyCategoryPayload",
    "kind": "LinkedField",
    "name": "emptyCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmptyCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmptyCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e1ce9f7b18c471acf01ccbedfe7a67d",
    "id": null,
    "metadata": {},
    "name": "EmptyCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation EmptyCategoryMutation(\n  $input: EmptyCategoryInput!\n) {\n  emptyCategory(input: $input) {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3341111ffad9ebfe287dbe21abae7623';

module.exports = node;
