import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveAdminUserMutation($input: RemoveAdminUserInput!) {
    removeAdminUser(input:$input) {
      removedAdminUserId
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
