import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../mutation';

const mutation = graphql`
  mutation RemoveAddressMutation($input: RemoveAddressInput!) {
    removeAddress(input:$input) {
      removedAddressId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeAddress', edgeName: 'removedAddressId', connectionKey: 'CustomerView_addresses' }),
});

export default { commit };
