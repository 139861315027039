/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveCmsBannerInput = {|
  id: string
|};
export type RemoveCmsBannerMutationVariables = {|
  input: RemoveCmsBannerInput
|};
export type RemoveCmsBannerMutationResponse = {|
  +removeCmsBanner: ?{|
    +cmsBannerId: ?string
  |}
|};
export type RemoveCmsBannerMutation = {|
  variables: RemoveCmsBannerMutationVariables,
  response: RemoveCmsBannerMutationResponse,
|};
*/


/*
mutation RemoveCmsBannerMutation(
  $input: RemoveCmsBannerInput!
) {
  removeCmsBanner(input: $input) {
    cmsBannerId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveCmsBannerPayload",
    "kind": "LinkedField",
    "name": "removeCmsBanner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cmsBannerId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveCmsBannerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveCmsBannerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3728c1618ba437b454ac4f4a59e143c2",
    "id": null,
    "metadata": {},
    "name": "RemoveCmsBannerMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveCmsBannerMutation(\n  $input: RemoveCmsBannerInput!\n) {\n  removeCmsBanner(input: $input) {\n    cmsBannerId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dbde954c7b311afa6bc32d2b19019eb4';

module.exports = node;
