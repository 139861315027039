import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddCommonPromoMutation($input: AddCommonPromoInput!) {
    addCommonPromo(input:$input) {
      commonPromoEdge {
        cursor
        node {
          id
          name
          startDate
          endDate
          images (first: 999) {
            edges {
              node {
                id
                imageUrl
              }
            }
          }
          onProducts(first: 99999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
          type
          brandIds
          freebieId
          excludeProducts(first: 99999) {
            edges {
              node {
                id
                name
                status
                mainImage {
                  id
                  url
                }
              }
            }
          }
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addCommonPromo', edgeName: 'commonPromoEdge', connectionKey: 'CommonPromoList_commonPromos' }),
});

export default { commit };
