/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddReviewReplyInput = {|
  content: string,
  reviewId?: ?string,
  status: number,
|};
export type AddReviewReplyMutationVariables = {|
  input: AddReviewReplyInput
|};
export type AddReviewReplyMutationResponse = {|
  +addReviewReply: ?{|
    +replyEdge: ?{|
      +__typename: string,
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +customer: ?{|
          +firstname: ?string,
          +lastname: ?string,
        |},
        +customerName: ?string,
        +content: ?string,
        +status: ?number,
        +insertedAt: ?string,
      |},
    |}
  |}
|};
export type AddReviewReplyMutation = {|
  variables: AddReviewReplyMutationVariables,
  response: AddReviewReplyMutationResponse,
|};
*/


/*
mutation AddReviewReplyMutation(
  $input: AddReviewReplyInput!
) {
  addReviewReply(input: $input) {
    replyEdge {
      __typename
      cursor
      node {
        id
        customer {
          firstname
          lastname
          id
        }
        customerName
        content
        status
        insertedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddReviewReplyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddReviewReplyPayload",
        "kind": "LinkedField",
        "name": "addReviewReply",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewReplyEdge",
            "kind": "LinkedField",
            "name": "replyEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewReply",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddReviewReplyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddReviewReplyPayload",
        "kind": "LinkedField",
        "name": "addReviewReply",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewReplyEdge",
            "kind": "LinkedField",
            "name": "replyEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewReply",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e831ad3703c59a6459d76305bfb6543",
    "id": null,
    "metadata": {},
    "name": "AddReviewReplyMutation",
    "operationKind": "mutation",
    "text": "mutation AddReviewReplyMutation(\n  $input: AddReviewReplyInput!\n) {\n  addReviewReply(input: $input) {\n    replyEdge {\n      __typename\n      cursor\n      node {\n        id\n        customer {\n          firstname\n          lastname\n          id\n        }\n        customerName\n        content\n        status\n        insertedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5db2e2004ea07a0f172eb57b952c7255';

module.exports = node;
