/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveSynonymInput = {|
  id: string
|};
export type RemoveSynonymMutationVariables = {|
  input: RemoveSynonymInput
|};
export type RemoveSynonymMutationResponse = {|
  +removeSynonym: ?{|
    +removedSynonymId: ?string
  |}
|};
export type RemoveSynonymMutation = {|
  variables: RemoveSynonymMutationVariables,
  response: RemoveSynonymMutationResponse,
|};
*/


/*
mutation RemoveSynonymMutation(
  $input: RemoveSynonymInput!
) {
  removeSynonym(input: $input) {
    removedSynonymId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveSynonymPayload",
    "kind": "LinkedField",
    "name": "removeSynonym",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedSynonymId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveSynonymMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveSynonymMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2585582979494512166ea309c3de4ac",
    "id": null,
    "metadata": {},
    "name": "RemoveSynonymMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveSynonymMutation(\n  $input: RemoveSynonymInput!\n) {\n  removeSynonym(input: $input) {\n    removedSynonymId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '141453081962ef3b143ba1b5e9b56ae2';

module.exports = node;
