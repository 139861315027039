import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { AddReviewReplyMutation, UpdateProductReviewMutation } from './mutations';
import ReviewForm from './ReviewForm';

class ReviewView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      productReviews: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  addReply = (form, e) => {
    e.preventDefault();
    form.validateFields(['id', 'newReply'])
    .then((values) => {
      if (!values.newReply) {
        return;
      }

      const mutation = {
        environment: this.props.relay.environment,
        variables: {
          input: {
            reviewId: values.id,
            content: values.newReply,
            status: 1,
          },
        },
        parent: { id: values.id },
        viewer: this.props.viewer,
        safeGuard: "update",
        onCompleted: () => {
          message.success('Saved');
          form.resetFields(['newReply']);
        },
      };
      AddReviewReplyMutation.commit(mutation);
    })
    .catch(() => {});
  }

  handleSubmit = (form, values) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateProductReviewMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const review = get(viewer, 'productReviews.edges[0].node', {});

    return (
      <div>
        <Helmet title={review.productName} />
        <h1>{review.productName}</h1>
        <ReviewForm
          key={review.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          addReply={this.addReply}
        />
      </div>
    );
  }
}

export default createFragmentContainer(ReviewView, {
  viewer: graphql`
    fragment ReviewView_viewer on Admin {
      productReviews(first: 1, ids: $ids) {
        edges {
          node {
            id
            content
            status
            product {
              name
              fullUrl
            }
            productName
            replies(first: 1000) @connection(key: "ReviewView_replies"){
              edges {
                node {
                 id
                 content
                 customer {
                   firstname
                   lastname
                 }
                 customerName
                }
              }
            }
            ratings(first: 1000) {
              edges {
                node {
                  id
                  percentage
                  ratingType {
                    code
                  }
                  star
                }
              }
            }
            customer {
              firstname
              email
              lastname
            }
            customerName
            insertedAt
          }
        }
      }
    }
  `,
});
