import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Divider, Form, Input, InputNumber, Select } from 'antd';
import { DatePicker, FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';
import { fromGlobalId } from '~/helper';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;


const STATUSES = {
  Pending: 0,
  Approved: 1,
  Declined: 2,
  Cancelled: 3,
};

const filterStatues = (statuses, match) => {
  if (get(match, 'status') === STATUSES.Approved) {
    return {
      Approved: statuses.Approved,
      Cancelled: statuses.Cancelled,
    };
  }

  if (get(match, 'status') === STATUSES.Declined) {
    return {
      Declined: statuses.Declined,
    };
  }

  if (get(match, 'status') === STATUSES.Cancelled) {
    return {
      Approved: statuses.Approved,
      Cancelled: statuses.Cancelled,
      Declined: statuses.Declined,
    };
  }

  return statuses;
};

class PriceMatchForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      priceMatches: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }


  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  isRequired = (form, match) => {
    const { getFieldValue } = form;

    if (getFieldValue('status') === STATUSES.Approved || match.status === STATUSES.Approved) {
      return true;
    }

    return false;
  }

  render() {
    const { match: routerMatch, viewer } = this.props;
    const match = get(viewer, 'priceMatches.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values) }}>

        <Affix>
          <div>
            <Presence match={routerMatch} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={match.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Product"
        >
          <a href={get(match, 'product.fullUrl')} target="_blank" rel="noopener noreferrer">{get(match, 'product.name')}</a>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Customer"
        >
          {match.name} {match.email}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Link"
        >
          <a href={match.link} target="_blank" rel="noopener noreferrer">{match.link}</a>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Phone"
        >
          {match.phone}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Postcode"
        >
          {match.postcode}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Comment"
        >
          {match.comment}
        </FormItem>

        <Divider />
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              {(match.status === STATUSES.Approved || getFieldValue('status') === STATUSES.Approved) && (
                <FormItem
                  {...formItemLayout}
                  label="Matched Price"
                  name="matchedPrice"
                  rules={[
                    (form) => ({ required: this.isRequired(form, match), message: 'required' }),
                  ]}
                  initialValue={match.matchedPrice}
                >
                  <InputNumber min={0} formatter={value => `$${value}`} />
                </FormItem>
              )}

              {(match.status === STATUSES.Approved || getFieldValue('status') === STATUSES.Approved) && (
                <FormItem
                  {...formItemLayout}
                  label="Code Expiration"
                  name="expiryDate"
                  rules={[
                    (form) => ({ required: this.isRequired(form, match), message: 'required' }),
                  ]}
                  initialValue={match.expiryDate ? moment(match.expiryDate) : moment().add(+24, 'hours')}
                  extra="Default is 24 Hours"
                >
                  <DatePicker />
                </FormItem>
              )}
            </>
          )}
        </FormItem>

        {match.code && (
        <FormItem
          {...formItemLayout}
          label="Code"
        >
          {match.code}
        </FormItem>
        )}

        {get(match, 'salesOrders.edges', []).length > 0 && (
          <FormItem
            {...formItemLayout}
            label="On Orders"
          >
            {match.salesOrders.edges.map(({node}) => <a key={node.id} href={`/sales/order/${fromGlobalId(node.id).id}`} rel="noopener noreferrer" target="_blank">{node.name}</a>)}
          </FormItem>
        )}

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => (match.decline !== null || getFieldValue('status') === STATUSES.Declined) ? (
            <FormItem
              {...formItemLayout}
              label="Reason of Decline"
              name="reason"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(match, 'decline.reason')}
            >
              <TextArea placeholder="Reason" disabled={match.status === STATUSES.Declined} />
            </FormItem>
          ): null}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={match.status}
          extra="Approved or Declined status will have an email sent to customer"
        >
          <Select placeholder="Status">
            {Object.keys(filterStatues(STATUSES, match)).map(s => <Option key={s} value={STATUSES[s]}>{s}</Option>)}
          </Select>
        </FormItem>

      </Form>
    );
  }
}
export default PriceMatchForm;
