/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OauthLoginInput = {|
  tokenId: string
|};
export type OauthLoginMutationVariables = {|
  input: OauthLoginInput
|};
export type OauthLoginMutationResponse = {|
  +oauthLogin: ?{|
    +token: ?string
  |}
|};
export type OauthLoginMutation = {|
  variables: OauthLoginMutationVariables,
  response: OauthLoginMutationResponse,
|};
*/


/*
mutation OauthLoginMutation(
  $input: OauthLoginInput!
) {
  oauthLogin(input: $input) {
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OauthLoginPayload",
    "kind": "LinkedField",
    "name": "oauthLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OauthLoginMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OauthLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d3e135037d02a7a39d8a4206cd45849c",
    "id": null,
    "metadata": {},
    "name": "OauthLoginMutation",
    "operationKind": "mutation",
    "text": "mutation OauthLoginMutation(\n  $input: OauthLoginInput!\n) {\n  oauthLogin(input: $input) {\n    token\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bbc71207f1ce2abe69109bce7df4f772';

module.exports = node;
