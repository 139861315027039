/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GiftCardView_viewer$ref = any;
export type routes_GiftCardView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type routes_GiftCardView_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: GiftCardView_viewer$ref
  |}
|};
export type routes_GiftCardView_Query = {|
  variables: routes_GiftCardView_QueryVariables,
  response: routes_GiftCardView_QueryResponse,
|};
*/


/*
query routes_GiftCardView_Query(
  $ids: [ID]
) {
  viewer {
    ...GiftCardView_viewer
    id
  }
}

fragment GiftCardForm_viewer on Admin {
  ...GiftCardHistory_viewer
  ...SelectProduct_viewer
}

fragment GiftCardHistory_giftCard on GiftCard {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment GiftCardHistory_viewer on Admin {
  id
}

fragment GiftCardView_viewer on Admin {
  ...GiftCardForm_viewer
  giftCards(first: 1, ids: $ids) {
    edges {
      node {
        id
        name
        amount
        expiryMonths
        products(first: 99) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        ...GiftCardHistory_giftCard
      }
    }
  }
}

fragment SelectProduct_viewer on Admin {
  searchProducts: products(first: 20, query: "") {
    edges {
      node {
        id
        name
        type
        attributes
        sku
        status
        mainImage {
          id
          url
        }
        configurables(first: 99) {
          edges {
            node {
              id
              product {
                id
                name
                sku
                status
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_GiftCardView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GiftCardView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_GiftCardView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": "searchProducts",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributes",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "ProductConfigurableConnection",
                        "kind": "LinkedField",
                        "name": "configurables",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductConfigurableEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductConfigurable",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      (v4/*: any*/),
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "configurables(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "products(first:20,query:\"\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "ids"
              }
            ],
            "concreteType": "GiftCardConnection",
            "kind": "LinkedField",
            "name": "giftCards",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GiftCardEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GiftCard",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expiryMonths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "ProductConnection",
                        "kind": "LinkedField",
                        "name": "products",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "products(first:99)"
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "HistoryConnection",
                        "kind": "LinkedField",
                        "name": "histories",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HistoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "History",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "changes",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "user",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "insertedAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "histories(first:10)"
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "GiftCardHistory_histories",
                        "kind": "LinkedHandle",
                        "name": "histories"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da30d678d60fcc1fae9a8f4689dee94b",
    "id": null,
    "metadata": {},
    "name": "routes_GiftCardView_Query",
    "operationKind": "query",
    "text": "query routes_GiftCardView_Query(\n  $ids: [ID]\n) {\n  viewer {\n    ...GiftCardView_viewer\n    id\n  }\n}\n\nfragment GiftCardForm_viewer on Admin {\n  ...GiftCardHistory_viewer\n  ...SelectProduct_viewer\n}\n\nfragment GiftCardHistory_giftCard on GiftCard {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment GiftCardHistory_viewer on Admin {\n  id\n}\n\nfragment GiftCardView_viewer on Admin {\n  ...GiftCardForm_viewer\n  giftCards(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        name\n        amount\n        expiryMonths\n        products(first: 99) {\n          edges {\n            node {\n              id\n              name\n              status\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        ...GiftCardHistory_giftCard\n      }\n    }\n  }\n}\n\nfragment SelectProduct_viewer on Admin {\n  searchProducts: products(first: 20, query: \"\") {\n    edges {\n      node {\n        id\n        name\n        type\n        attributes\n        sku\n        status\n        mainImage {\n          id\n          url\n        }\n        configurables(first: 99) {\n          edges {\n            node {\n              id\n              product {\n                id\n                name\n                sku\n                status\n                mainImage {\n                  id\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c9f636d4a90a326c0872d496088c73bd';

module.exports = node;
