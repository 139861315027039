import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Affix, Button, Form, Input, Popconfirm, Select, Switch } from 'antd';

import { formItemLayout, useFormBase } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;

const SearchCanonicalForm = (props) => {
  const { viewer, match, onSubmit, remove } = props;
  const canonical = get(viewer, 'searchCanonicals.edges[0].node', {});

  const [form] = Form.useForm();
  const { shouldDisableBtn, handleDisableBtn } = useFormBase();

  return (
    <Form form={form} onFinish={(values) => onSubmit(form, values)}>
      <Affix>
        <div>
          <Presence match={match} disableButton={handleDisableBtn} />
          <Button type="primary" htmlType="submit" disabled={shouldDisableBtn}>Save</Button>

          {canonical.id && remove && (
            <Popconfirm
              title="Are you sure delete this canonical?"
              onConfirm={() => {
                remove(canonical.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={shouldDisableBtn}>Delete</Button>
            </Popconfirm>
          )}
        </div>
      </Affix>

      <FormItem
        name="id"
        initialValue={canonical.id}
        hidden
      >
        <Input />
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Queries"
        name="queries"
        rules={[{ required: true, message: 'required' }]}
        initialValue={canonical.queries}
      >
        <Select mode="tags" />
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="URL"
        name="url"
        rules={[{ required: true, message: 'required' }]}
        initialValue={canonical.url}
      >
        <Input />
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Status"
        name="status"
        valuePropName="checked"
        rules={[{ required: true, message: 'required' }]}
        initialValue={get(canonical, 'status', false)}
      >
        <Switch />
      </FormItem>
    </Form>
  )
}

SearchCanonicalForm.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  remove: PropTypes.func,
};

SearchCanonicalForm.defaultProps = {
  remove: null,
};

export default SearchCanonicalForm;
