import AddUrlRewriteMutation from './AddUrlRewriteMutation';
import RemoveUrlRewriteMutation from './RemoveUrlRewriteMutation';
import UpdateUrlRewriteMutation from './UpdateUrlRewriteMutation';
import UpdateUrlSlugMutation from './UpdateUrlSlugMutation';

module.exports = {
  AddUrlRewriteMutation,
  RemoveUrlRewriteMutation,
  UpdateUrlRewriteMutation,
  UpdateUrlSlugMutation,
};
