/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdateProductsInput = {|
  excludeGlobalFreeShipping?: ?boolean,
  ids: $ReadOnlyArray<?string>,
  inStoreOnly?: ?boolean,
  loyaltyMultiplier?: ?number,
  loyaltyMultiplierSchedule?: ?InputLoyaltyMultiplierSchedule,
  onlineOnly?: ?boolean,
  promoCard?: ?boolean,
  status?: ?string,
  visibility?: ?number,
|};
export type InputLoyaltyMultiplierSchedule = {|
  endDate?: ?string,
  multiplier?: ?number,
  startDate?: ?string,
|};
export type BulkUpdateProductsMutationVariables = {|
  input: BulkUpdateProductsInput
|};
export type BulkUpdateProductsMutationResponse = {|
  +bulkUpdateProducts: ?{|
    +productIds: ?$ReadOnlyArray<?string>
  |}
|};
export type BulkUpdateProductsMutation = {|
  variables: BulkUpdateProductsMutationVariables,
  response: BulkUpdateProductsMutationResponse,
|};
*/


/*
mutation BulkUpdateProductsMutation(
  $input: BulkUpdateProductsInput!
) {
  bulkUpdateProducts(input: $input) {
    productIds
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkUpdateProductsPayload",
    "kind": "LinkedField",
    "name": "bulkUpdateProducts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkUpdateProductsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkUpdateProductsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "531636c10718686abc35543be14c5f79",
    "id": null,
    "metadata": {},
    "name": "BulkUpdateProductsMutation",
    "operationKind": "mutation",
    "text": "mutation BulkUpdateProductsMutation(\n  $input: BulkUpdateProductsInput!\n) {\n  bulkUpdateProducts(input: $input) {\n    productIds\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '252dcd1d0dcddde992e1d543645e7ccd';

module.exports = node;
