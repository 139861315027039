import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation ReindexProductsMutation($input: ReindexProductsInput!) {
    reindexProducts(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});
export default { commit };
