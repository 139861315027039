import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import moment from 'moment-timezone';

import { UpdateCouponMutation } from './mutations';
import CouponForm from './CouponForm';
import { checkDuplicated } from './helper';

class CouponView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      coupons: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    checkDuplicated(formValues.content);

    const values = {
      ...formValues,
      status: !!formValues.status,
      showLabel: !!formValues.showLabel,
      content: formValues.content.map((c) => {
        const group = {
          ...c,
          conditions: { ...c.conditions, value: get(c, 'conditions.value', []).map(opt => opt.key)},
          actions: { ...c.actions, value: get(c, 'actions.value', []).map(opt => opt.key)},
        };

        if (c.additionProductIds) {
          group.additionProductIds = c.additionProductIds.map(p => p.key);
        }

        if (c.removalProductIds) {
          group.removalProductIds = c.removalProductIds.map(p => p.key);
        }

        return group;
      })
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
      onError: (errors) => {
        message.error(errors[0].message);
        form.setFields([{
          name: 'startDate', value: moment(values.startDate),
        }, {
          name: 'endDate', value: moment(values.endDate),
        }]);
      },
    };
    UpdateCouponMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const coupon = get(viewer, 'coupons.edges[0].node', {});

    return (
      <div>
        <Helmet title={coupon.name} />
        <h1>{coupon.name}</h1>
        <CouponForm
          key={coupon.id}
          match={match}
          viewer={viewer}
          coupon={coupon}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CouponView, {
  viewer: graphql`
    fragment CouponView_viewer on Admin {
      ...CouponForm_viewer
      coupons(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            codes
            content {
              id
              discountType
              discountAmount
              conditions
              actions
              removalDate
              removalProducts
              additionDate
              additionProducts
            }
            discountType
            discountAmount
            usageLimit
            usagePerCustomer
            conditions
            actions
            timesUsed
            startDate
            endDate
            status
            showLabel
            autoApply
            salesOrders(first: 9999999) {
              edges {
                node {
                  id
                  name
                  lines(first: 9999999) {
                    edges {
                      node {
                        id
                        name
                        unitDiscount
                      }
                    }
                  }
                  grandTotal
                  insertedAt
                }
              }
            }
            ...CouponHistory_coupon
          }
        }
      }
    }
  `,
});
