/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddComboFreebieInput = {|
  bases?: ?$ReadOnlyArray<?string>,
  endDate: string,
  freebies?: ?$ReadOnlyArray<?InputFreebieProduct>,
  name: string,
  skins?: ?$ReadOnlyArray<?string>,
  startDate: string,
  status: boolean,
|};
export type InputFreebieProduct = {|
  productId?: ?string,
  quantity?: ?number,
|};
export type AddComboFreebieMutationVariables = {|
  input: AddComboFreebieInput
|};
export type AddComboFreebieMutationResponse = {|
  +addComboFreebie: ?{|
    +comboFreebieEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +status: ?boolean,
        +bases: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +skins: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +freebies: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +quantity: ?number,
              +product: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |},
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type AddComboFreebieMutation = {|
  variables: AddComboFreebieMutationVariables,
  response: AddComboFreebieMutationResponse,
|};
*/


/*
mutation AddComboFreebieMutation(
  $input: AddComboFreebieInput!
) {
  addComboFreebie(input: $input) {
    comboFreebieEdge {
      cursor
      node {
        id
        name
        startDate
        endDate
        status
        bases(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        skins(first: 999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        freebies(first: 999) {
          edges {
            node {
              quantity
              product {
                id
                name
                mainImage {
                  id
                  url
                }
              }
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v9 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": (v8/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "bases",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "bases(first:999)"
},
v12 = {
  "alias": null,
  "args": (v8/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "skins",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "skins(first:999)"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddComboFreebieMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddComboFreebiePayload",
        "kind": "LinkedField",
        "name": "addComboFreebie",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ComboFreebieEdge",
            "kind": "LinkedField",
            "name": "comboFreebieEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ComboFreebie",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "FreebieProductConnection",
                    "kind": "LinkedField",
                    "name": "freebies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FreebieProductEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FreebieProduct",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "freebies(first:999)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddComboFreebieMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddComboFreebiePayload",
        "kind": "LinkedField",
        "name": "addComboFreebie",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ComboFreebieEdge",
            "kind": "LinkedField",
            "name": "comboFreebieEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ComboFreebie",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "FreebieProductConnection",
                    "kind": "LinkedField",
                    "name": "freebies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FreebieProductEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FreebieProduct",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "freebies(first:999)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ae1228d5cbb2b16d9b7d5a64e96e2d2",
    "id": null,
    "metadata": {},
    "name": "AddComboFreebieMutation",
    "operationKind": "mutation",
    "text": "mutation AddComboFreebieMutation(\n  $input: AddComboFreebieInput!\n) {\n  addComboFreebie(input: $input) {\n    comboFreebieEdge {\n      cursor\n      node {\n        id\n        name\n        startDate\n        endDate\n        status\n        bases(first: 999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        skins(first: 999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        freebies(first: 999) {\n          edges {\n            node {\n              quantity\n              product {\n                id\n                name\n                mainImage {\n                  id\n                  url\n                }\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16ffeefe8cb99d27d1267fc92a864521';

module.exports = node;
