import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddAlertMutation($input: AddAlertInput!) {
    addAlert(input:$input) {
      alertEdge {
        cursor
        node {
          id
          name
          content
          startDate
          endDate
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addAlert', edgeName: 'alertEdge', connectionKey: 'AlertList_alerts' }),
});

export default { commit };
