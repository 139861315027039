import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { message, Divider } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { fromGlobalId } from '../../../helper';
import { Grid, currencyFormatter, dateTimeGetter, iDGetter, valueGetter } from '../../grid';
import ExportSalesOrder from './ExportSalesOrder';

const entityName = 'Sales Order';

export const VERIFICATION_STATUS = {
  3: 'FAILED',
  2: 'VOIDED',
  1: 'YES',
  0: 'No',
};

let PAYMENT_METHODS = {};
if (process.env.COUNTRY === 'AU') {
  PAYMENT_METHODS = {
    till_googlepay: 'Google Pay',
    tyro_googlepay: 'Google Pay Tyro',
    till_applepay: 'Apple Pay',
    tyro_applepay: 'Apple Pay Tyro',
    latitude_financial: 'Latitude Financial',
    latitude_pay: 'Latitude Pay',
    afterpay: 'AfterPay',
    humm: 'Humm',
    securepay: 'Secure Pay',
    paypal: 'Paypal',
    zip: 'Zip',
    till: 'Till',
    tyro: 'Tyro',
    direct_deposit: 'Direct Deposit',
    free: 'Free',
  };
} else if (process.env.COUNTRY === 'NZ') {
  PAYMENT_METHODS = {
    afterpay: 'AfterPay',
    stripe: 'Stripe',
    free: 'Free',
  };
}

let SHIPPING_METHODS = {};
if (process.env.COUNTRY === 'AU') {
  SHIPPING_METHODS = {
    austpost_on_demand: 'AusPost On Demand',
    austpost_standard: 'Auspost Standard',
    austpost_express: 'Auspost Express',
    centurycouriers: 'Century',
    freeshipping: 'Freeshipping',
    northline: 'Northline',
    tnt_road_express: 'Tnt',
    storepickup: 'Storepickup',
    staff_delivery: 'Staff Delivery',
    priority: 'Priority',
  };
} else if (process.env.COUNTRY === 'NZ') {
  SHIPPING_METHODS = {
    nzpost: 'NZ Post',
    pace: 'NZ Post Pace (Express)',
    mainfreight: "Mainfreight",
    freeshipping: 'Freeshipping',
    storepickup: 'Storepickup',
  };
}

export const ORDER_STATUSES = {
  0: 'processing',
  1: 'payment_review',
  2: 'payment_failed',
  3: 'cancelled'
};

class SalesOrderList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      gridApi: null,
    };

    this.gridRef = React.createRef();

    const STORES = {};
    props.viewer.stores.edges.forEach(({node}) => {
      STORES[node.id] = node.name;
    });

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/sales/order/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false, width: 80
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 120,
        cellRendererFramework: ({data}) => {
          if (data) {
            const { node } = data;

            return (
              <>
                <span>{node.name}</span>
                <CopyToClipboard text={node.name} onCopy={() => message.success("copied")}>
                  <CopyOutlined style={{marginLeft: '5px', fontSize: '20px', cursor: 'pointer' }} title="Copy" />
                </CopyToClipboard>
              </>
            );
          }

          return null;
        }
      },
      {
        colId: 'email', headerName: 'Email', valueGetter: valueGetter.bind(this, 'email'), width: 200,
      },
      {
        colId: 'paymentMethod',
        headerName: 'Payment Method',
        valueGetter: valueGetter.bind(this, 'paymentMethod'),
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: PAYMENT_METHODS, suppressFilterButton: true },
        width: 150,
      },
      {
        colId: 'shippingMethod',
        headerName: 'Shipping Method',
        cellRenderer: ({data}) => {
          if (data) {
            const { node: { shippingMethod, extra } } = data;
            const priorityShipping = get(extra, 'priorityShipping', []);

            if (shippingMethod) {
              return [shippingMethod].concat(priorityShipping).join("<br/>");
            }
          }
          return null;
        },
        filter: 'agTextColumnFilter',
        filterParams: { defaultOption: 'contains' },
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: SHIPPING_METHODS, suppressFilterButton: true },
        width: 150,
      },
      {
        colId: 'addresses.store_id',
        headerName: 'Allocated To',
        suppressMenu: true,
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: STORES, globalId: true, suppressFilterButton: true },
        valueGetter: ({data}) => {
          if (data) {
            const { node: { addresses } } = data;
            const shipping = addresses.edges.find(({node: { addressType }}) => addressType === 'shipping');
            const store = get(shipping, 'node.store.name');

            if (store) {
              return store;
            }
          }
          return null;
        },
        sortable: false,
        width: 150,
      },
      {
        colId: 'grand_total',
        headerName: 'Grand Total',
        filter: 'agNumberColumnFilter',
        valueGetter: valueGetter.bind(this, 'grandTotal'),
        valueFormatter: currencyFormatter,
        width: 110,
      },
      {
        colId: 'distance',
        headerName: 'Distance',
        filter: false,
        sortable: false,
        valueGetter: ({ data = {} }) => {
          const { node } = data;
          if (node) {
            const shipping = node.addresses.edges.find(({node: { addressType, distanceFromStore }}) => addressType === 'shipping' && distanceFromStore !== null);

            if (shipping) {
              const distance = get(shipping, 'node.distanceFromStore', 0).toFixed(2);
              return `${distance} km`;
            }
          }

          return null;
        },
        width: 110,
      },
      {
        colId: 'verified',
        headerName: 'Verified',
        valueGetter: valueGetter.bind(this, 'verified'),
        filter: 'agNumberColumnFilter',
        valueFormatter: params => VERIFICATION_STATUS[params.value],
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: VERIFICATION_STATUS, suppressFilterButton: true },
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: valueGetter.bind(this, 'status'),
        filter: 'agNumberColumnFilter',
        valueFormatter: params => ORDER_STATUSES[params.value],
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: ORDER_STATUSES, suppressFilterButton: true },
      },
      {
        colId: 'shipments.shipping',
        headerName: 'Shipping Status',
        valueGetter: valueGetter.bind(this, 'shipping.status'),
        filter: false,
        sortable: false,
        width: 140
      },
      {
        filter: 'agDateColumnFilter',
        colId: 'insertedAt',
        headerName: 'Created At',
        valueGetter: dateTimeGetter.bind(this, 'insertedAt'),
        width: 180,
        filterParams: {
          browserDatePicker: true
        }
      },
      {
        colId: 'lfPaymentRef',
        headerName: 'LF Payment Ref',
        valueGetter: valueGetter.bind(this, 'extra.lFPaymentRef'),
        sortable: false,
        filter: false,
        width: 110,
      },
    ];

    this.datasource = Grid.getDataSource.call(this, 'salesOrders');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <ExportSalesOrder gridApi={this.state.gridApi} />
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
          onGridReady={({api: gridApi}) => this.setState({gridApi})}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  SalesOrderList, {
    viewer: graphql`
    fragment SalesOrderList_viewer on Admin {
      salesOrders(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "SalesOrderList_salesOrders") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            email
            grandTotal
            paymentMethod
            shippingMethod
            verified
            status
            shipping
            insertedAt
            extra
            addresses(first: 2) {
              edges {
                node {
                  id
                  addressType
                  distanceFromStore
                  store {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.salesOrders;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor, orderBy }, fragmentVariables) {
      const { filterBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query SalesOrderListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...SalesOrderList_viewer
      }
    }
  `,
  },
);
