/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LinkProductInput = {|
  productId: string,
  referenceId: number,
  username: string,
|};
export type LinkProductMutationVariables = {|
  input: LinkProductInput
|};
export type LinkProductMutationResponse = {|
  +linkProduct: ?{|
    +result: ?boolean
  |}
|};
export type LinkProductMutation = {|
  variables: LinkProductMutationVariables,
  response: LinkProductMutationResponse,
|};
*/


/*
mutation LinkProductMutation(
  $input: LinkProductInput!
) {
  linkProduct(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LinkProductPayload",
    "kind": "LinkedField",
    "name": "linkProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkProductMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LinkProductMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "69536bb488a98ea786ea8d8f43c413fe",
    "id": null,
    "metadata": {},
    "name": "LinkProductMutation",
    "operationKind": "mutation",
    "text": "mutation LinkProductMutation(\n  $input: LinkProductInput!\n) {\n  linkProduct(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54d966f17ac2b26039d0c9c891f28764';

module.exports = node;
