import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation GetAddressDetailMutation($input: GetAddressDetailInput!) {
    getAddressDetail(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };
