import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation SendGiftCardUsageEmailMutation($input: SendGiftCardUsageEmailInput!) {
    sendGiftCardUsageEmail(input:$input) {
      giftCardUsage {
        id
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
