import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../../mutation';

const mutation = graphql`
  mutation AddAdjacentBannerMutation($input: AddAdjacentBannerInput!) {
    addAdjacentBanner(input:$input) {
      adjacentBannerEdge {
        cursor
        node {
          id
          name
          product {
            id
            name
            mainImage {
              id
              url
            }
          }
          link
          imageUrl
          placement
          position
          startDate
          endDate
          status
          categories(first: 999) {
            edges {
              node {
                id
                name
              }
            }
          }
          subcategories(first: 9999) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addAdjacentBanner', edgeName: 'adjacentBannerEdge', connectionKey: 'AdjacentBannerList_adjacentBanners' }),
});

export default { commit };
