/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GiftCardHistory_giftCard$ref = any;
export type UpdateGiftCardInput = {|
  amount?: ?number,
  expiryMonths?: ?number,
  id?: ?string,
  name?: ?string,
  products?: ?$ReadOnlyArray<?string>,
|};
export type UpdateGiftCardMutationVariables = {|
  input: UpdateGiftCardInput
|};
export type UpdateGiftCardMutationResponse = {|
  +updateGiftCard: ?{|
    +giftCard: ?{|
      +name: ?string,
      +amount: ?number,
      +expiryMonths: ?number,
      +products: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +$fragmentRefs: GiftCardHistory_giftCard$ref,
    |}
  |}
|};
export type UpdateGiftCardMutation = {|
  variables: UpdateGiftCardMutationVariables,
  response: UpdateGiftCardMutationResponse,
|};
*/


/*
mutation UpdateGiftCardMutation(
  $input: UpdateGiftCardInput!
) {
  updateGiftCard(input: $input) {
    giftCard {
      name
      amount
      expiryMonths
      products(first: 99) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      ...GiftCardHistory_giftCard
      id
    }
  }
}

fragment GiftCardHistory_giftCard on GiftCard {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiryMonths",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 99
    }
  ],
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductImage",
              "kind": "LinkedField",
              "name": "mainImage",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "products(first:99)"
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateGiftCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGiftCardPayload",
        "kind": "LinkedField",
        "name": "updateGiftCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GiftCard",
            "kind": "LinkedField",
            "name": "giftCard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GiftCardHistory_giftCard"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateGiftCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGiftCardPayload",
        "kind": "LinkedField",
        "name": "updateGiftCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GiftCard",
            "kind": "LinkedField",
            "name": "giftCard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "GiftCardHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b75eac621be95bb6edd447b4d6b848f9",
    "id": null,
    "metadata": {},
    "name": "UpdateGiftCardMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateGiftCardMutation(\n  $input: UpdateGiftCardInput!\n) {\n  updateGiftCard(input: $input) {\n    giftCard {\n      name\n      amount\n      expiryMonths\n      products(first: 99) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      ...GiftCardHistory_giftCard\n      id\n    }\n  }\n}\n\nfragment GiftCardHistory_giftCard on GiftCard {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5c671099d7e564033a4c7250e853c1a';

module.exports = node;
