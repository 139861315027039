import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { RemoveLabelMutation, UpdateLabelMutation } from './mutations';
import LabelForm from './LabelForm';


class LabelView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      labels: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
    };

    const uploadables = {};
    ['image', 'productPageImage'].forEach(key => {
      if (formValues[key]) {
        const file = formValues[key][0];
        values[key] = key;
        uploadables[key] = file.originFileObj;
      }
    });

    if (formValues.type === null) {
      if (formValues.brandId) {
        values.excludeProducts = formValues.excludeProducts.map(p => p.key);
      } else {
        values.products = formValues.products.map(p => p.key);
      }
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateLabelMutation.commit(mutation);
  }

  remove = (label) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: label.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/promotion/label');
      },
    };
    RemoveLabelMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const label = get(viewer, 'labels.edges[0].node', {});

    return (
      <div>
        <Helmet title={label.name} />
        <h1>{label.name}</h1>
        <LabelForm
          key={label.id}
          match={match}
          viewer={viewer}
          label={label}
          remove={this.remove}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(LabelView, {
  viewer: graphql`
    fragment LabelView_viewer on Admin {
      id
      labels(first: 1, ids: $ids) {
        edges {
          node {
            id
            brandId
            name
            imageUrl
            productPageImageUrl
            placement
            startDate
            endDate
            type
            position
            status
            fuseImage
            fuseImageJobs {
              id
              args
              state
              insertedAt
            }
            bonusId
            categoryId
            redemptionId
            creditId
            freebieId
            excludeProducts(first: 99999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            products(first: 99999) {
              edges {
                node {
                  id
                  name
                  sku
                  mainImage {
                    id
                    url
                  }
                  status
                }
              }
            }
            ...LabelHistory_label
          }
        }
      }
      ...LabelForm_viewer
    }
  `,
});
