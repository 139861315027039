import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Affix, Button, Form, Input, InputNumber, Select } from 'antd';
import { Editor, FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const FormItem = Form.Item;
const { Option } = Select;

class FaqForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      faqs: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const brands = get(viewer, 'brands.edges', []);
    const categories = get(viewer, 'categories.edges', []);
    const subcategories = get(viewer, 'subcategories.edges', []);
    const faq = get(this.props, 'faq', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={faq.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Question"
          name="question"
          rules={[{ required: true, message: 'required' }]}
          initialValue={faq.question}
        >
          <Input placeholder="Question" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Answer"
        >
          <Editor
            name="answer"
            editorState={faq.answer}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Brands"
          name="brands"
          initialValue={get(faq, 'brands.edges', []).map(({ node }) => ({
            key: node.id,
            label: node.name,
          }))}
        >
          <Select
            placeholder="Brands"
            mode="multiple"
            optionFilterProp="children"
            labelInValue
          >
            {
              brands.map((edge) => {
                const e = edge.node;
                return <Option key={e.id} value={e.id}>{e.name}</Option>;
              })
            }
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Categories"
          name="categories"
          initialValue={get(faq, 'categories.edges', []).map(({ node }) => ({
            key: node.id,
            label: node.name,
          }))}
        >
          <Select
            placeholder="Categories"
            mode="multiple"
            optionFilterProp="children"
            labelInValue
          >
            {
              categories.map((edge) => {
                const e = edge.node;
                return <Option key={e.id} value={e.id}>{e.name}</Option>;
              })
            }
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Subcategories"
          name="subcategories"
          initialValue={get(faq, 'subcategories.edges', []).map(({ node }) => ({
            key: node.id,
            label: node.name,
          }))}
        >
          <Select
            placeholder="Subcategories"
            optionFilterProp="children"
            mode="multiple"
            labelInValue
          >
            {
              subcategories.map((edge) => {
                const e = edge.node;
                return <Option key={e.id} value={e.id}>{e.name}</Option>;
              })
            }
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Position"
          name="position"
          initialValue={get(faq, 'position', 0)}
          extra="Ordering Position, Higher Numbers Appear First"
        >
          <InputNumber placholder="Number" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(faq, 'status') ? 1 : 0}
        >

          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>
      </Form>
    );
  }
}
export default createFragmentContainer(FaqForm, {
  viewer: graphql`
    fragment FaqForm_viewer on Admin {
      brands(first: 999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
