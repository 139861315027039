/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PurchaseLimitNew_viewer$ref = any;
export type routes_PurchaseLimitNew_QueryVariables = {||};
export type routes_PurchaseLimitNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PurchaseLimitNew_viewer$ref
  |}
|};
export type routes_PurchaseLimitNew_Query = {|
  variables: routes_PurchaseLimitNew_QueryVariables,
  response: routes_PurchaseLimitNew_QueryResponse,
|};
*/


/*
query routes_PurchaseLimitNew_Query {
  viewer {
    ...PurchaseLimitNew_viewer
    id
  }
}

fragment PurchaseLimitForm_viewer on Admin {
  id
  ...SelectProduct_viewer
}

fragment PurchaseLimitNew_viewer on Admin {
  id
  ...PurchaseLimitForm_viewer
}

fragment SelectProduct_viewer on Admin {
  searchProducts: products(first: 20, query: "") {
    edges {
      node {
        id
        name
        type
        attributes
        sku
        status
        mainImage {
          id
          url
        }
        configurables(first: 99) {
          edges {
            node {
              id
              product {
                id
                name
                sku
                status
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PurchaseLimitNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PurchaseLimitNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_PurchaseLimitNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "searchProducts",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributes",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 99
                          }
                        ],
                        "concreteType": "ProductConfigurableConnection",
                        "kind": "LinkedField",
                        "name": "configurables",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductConfigurableEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductConfigurable",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v0/*: any*/),
                                      (v1/*: any*/),
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "configurables(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "products(first:20,query:\"\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b9604f8fd60f1c47e8123d30fbac9a4",
    "id": null,
    "metadata": {},
    "name": "routes_PurchaseLimitNew_Query",
    "operationKind": "query",
    "text": "query routes_PurchaseLimitNew_Query {\n  viewer {\n    ...PurchaseLimitNew_viewer\n    id\n  }\n}\n\nfragment PurchaseLimitForm_viewer on Admin {\n  id\n  ...SelectProduct_viewer\n}\n\nfragment PurchaseLimitNew_viewer on Admin {\n  id\n  ...PurchaseLimitForm_viewer\n}\n\nfragment SelectProduct_viewer on Admin {\n  searchProducts: products(first: 20, query: \"\") {\n    edges {\n      node {\n        id\n        name\n        type\n        attributes\n        sku\n        status\n        mainImage {\n          id\n          url\n        }\n        configurables(first: 99) {\n          edges {\n            node {\n              id\n              product {\n                id\n                name\n                sku\n                status\n                mainImage {\n                  id\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36355d74b49796de3490eb8da6ab33eb';

module.exports = node;
