import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Item: FormItem } = Form;

const formItemLayout = {
  labelAlign: 'left',
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  },
};

const TierPrice = (props) => {
  const { product } = props;
  const initialValue = product.tierPrices ? product.tierPrices.slice(0) : [];

  return (
    <Form.List name="tierPrices" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => (
            <div key={key}>
              <FormItem
                {...formItemLayout}
                label="Qty"
                name={[name, "quantity"]}
                rules={[{ required: true, message: 'required' }]}
                style={{ display: 'inline-block', width: '100px' }}
              >
                <InputNumber min={1} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Unit Price"
                name={[name, "price"]}
                rules={[{ required: true, message: 'required' }]}
                style={{ display: 'inline-block', width: '100px' }}
              >
                <InputNumber
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Tier Price"
                  style={{ width: '120px' }}
                />
              </FormItem>

              <MinusCircleOutlined
                style={{ cursor: 'pointer' }}
                disabled={fields.length === 1}
                onClick={() => remove(name)}
              />
            </div>
          ))}

          <Button
            onClick={() => add()}
          >
            <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  );
}

TierPrice.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    tierPrices: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default TierPrice;
