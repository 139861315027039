/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FreebieHistory_freebie$ref = any;
export type UpdateFreebieInput = {|
  brandIds: $ReadOnlyArray<?string>,
  categoryId?: ?string,
  endDate?: ?string,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  freebieId?: ?string,
  id: string,
  includeProducts?: ?$ReadOnlyArray<?string>,
  name?: ?string,
  startDate?: ?string,
  status?: ?boolean,
  stealthMode?: ?boolean,
  tiers?: ?$ReadOnlyArray<?InputFreebieTier>,
  type?: ?number,
|};
export type InputFreebieTier = {|
  id?: ?string,
  mode?: ?number,
  price: number,
  productIds: $ReadOnlyArray<?string>,
  quantity?: ?number,
|};
export type UpdateFreebieMutationVariables = {|
  input: UpdateFreebieInput
|};
export type UpdateFreebieMutationResponse = {|
  +updateFreebie: ?{|
    +freebie: ?{|
      +brandIds: ?$ReadOnlyArray<?string>,
      +name: ?string,
      +tiers: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +price: ?number,
            +quantity: ?number,
            +mode: ?number,
            +products: $ReadOnlyArray<?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}>,
          |}
        |}>
      |},
      +startDate: ?string,
      +endDate: ?string,
      +stealthMode: ?boolean,
      +status: ?boolean,
      +excludeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +includeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +$fragmentRefs: FreebieHistory_freebie$ref,
    |}
  |}
|};
export type UpdateFreebieMutation = {|
  variables: UpdateFreebieMutationVariables,
  response: UpdateFreebieMutationResponse,
|};
*/


/*
mutation UpdateFreebieMutation(
  $input: UpdateFreebieInput!
) {
  updateFreebie(input: $input) {
    freebie {
      brandIds
      name
      tiers(first: 999) {
        edges {
          node {
            id
            price
            quantity
            mode
            products {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
      }
      startDate
      endDate
      stealthMode
      status
      excludeProducts(first: 99999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      includeProducts(first: 99999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      ...FreebieHistory_freebie
      id
    }
  }
}

fragment FreebieHistory_freebie on Freebie {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 999
    }
  ],
  "concreteType": "FreebieTierConnection",
  "kind": "LinkedField",
  "name": "tiers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FreebieTierEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FreebieTier",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "products",
              "plural": true,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "tiers(first:999)"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stealthMode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": (v11/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "excludeProducts",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": "excludeProducts(first:99999)"
},
v14 = {
  "alias": null,
  "args": (v11/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "includeProducts",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": "includeProducts(first:99999)"
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFreebieMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFreebiePayload",
        "kind": "LinkedField",
        "name": "updateFreebie",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Freebie",
            "kind": "LinkedField",
            "name": "freebie",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FreebieHistory_freebie"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateFreebieMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFreebiePayload",
        "kind": "LinkedField",
        "name": "updateFreebie",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Freebie",
            "kind": "LinkedField",
            "name": "freebie",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "FreebieHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0bacfe3aaf9f4f7850d901ed798757c",
    "id": null,
    "metadata": {},
    "name": "UpdateFreebieMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFreebieMutation(\n  $input: UpdateFreebieInput!\n) {\n  updateFreebie(input: $input) {\n    freebie {\n      brandIds\n      name\n      tiers(first: 999) {\n        edges {\n          node {\n            id\n            price\n            quantity\n            mode\n            products {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n      }\n      startDate\n      endDate\n      stealthMode\n      status\n      excludeProducts(first: 99999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      includeProducts(first: 99999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      ...FreebieHistory_freebie\n      id\n    }\n  }\n}\n\nfragment FreebieHistory_freebie on Freebie {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e320f11e1ee3665ee9be77fe800bee0a';

module.exports = node;
