import React, { useEffect, useRef, useState } from 'react';
import {
  useFragment,
  useRefetchableFragment,
  useRelayEnvironment,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get, lowerCase } from 'lodash';
import { Button, InputNumber, Popconfirm, message } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { getLink, fromGlobalId } from '~/helper';
import { LinkProductMutation, CopyToWebsiteMutation, UnlinkProductMutation } from './mutations';

const viewerQuery = graphql`
  fragment WebsiteRef_viewer on Admin {
    username
  }
`;

const attributeQuery = graphql`
  fragment WebsiteRef_attribute on Attribute @refetchable(queryName: "WebsiteRefAttributeRefetchQuery") {
    websiteReference {
      id
      country
    }
  }
`;

const brandQuery = graphql`
  fragment WebsiteRef_brand on Brand @refetchable(queryName: "WebsiteRefBrandRefetchQuery") {
    websiteReference {
      id
      country
    }
  }
`;

const categoryQuery = graphql`
  fragment WebsiteRef_category on Category @refetchable(queryName: "WebsiteRefCategoryRefetchQuery") {
    websiteReference {
      id
      country
    }
  }
`;

const productQuery = graphql`
  fragment WebsiteRef_product on Product @refetchable(queryName: "WebsiteRefProductRefetchQuery") {
    websiteReference {
      id
      country
    }
  }
`;

const subcategoryQuery = graphql`
  fragment WebsiteRef_subcategory on Subcategory @refetchable(queryName: "WebsiteRefSubcategoryRefetchQuery") {
    websiteReference {
      id
      country
    }
  }
`;

const getType = (entity) => {
  const { type } = fromGlobalId(entity.id);
  return lowerCase(type);
};

const getQuery = (type) => {
  if (type === "attribute") {
    return attributeQuery;
  } else if (type === "brand") {
    return brandQuery;
  } else if (type === "category") {
  return categoryQuery;
  } else if (type === "product") {
    return productQuery;
  } else if (type === "subcategory") {
    return subcategoryQuery;
  }
  return null;
};

export const Loading = () => <Button style={{ marginRight: '10px' }} loading>Copy to NZ</Button>;

export const CopyToNzBtn = (props) => {
  const { viewer, entity, useRefetchable } = props;

  const type = getType(entity);
  const query = getQuery(type);

  if (!query) {
    return null;
  }

  const [isCopyBtnClick, setCopyBtnClick] = useState(false);

  const [data, refetch] = !useRefetchable ? useRefetchableFragment(query, props.entity) : useRefetchable;
  const environment = useRelayEnvironment();

  const { country, id: refId } = get(data, 'websiteReference') || {};

  useEffect(() => {
    if (isCopyBtnClick && refId) {
      const link = getLink(type, country, refId);
      window.open(link, '_blank');
      setCopyBtnClick(false);
    }
  }, [refId]);

  const handleCopy = () => {
    setCopyBtnClick(true);

    CopyToWebsiteMutation.commit({
      environment,
      viewer,
      variables: { input: { ids: [entity.id] } },
      onCompleted: () => {
        refetch({}, { fetchPolicy: 'store-and-network' });
      }
    });
  };

  return (
    <>
      {process.env.COUNTRY === 'AU' && !country && (
        <Popconfirm
          title={`Copy this ${type} to NZ?`}
          onConfirm={handleCopy}
        >
          <Button style={{ marginRight: '10px' }}>Copy to NZ</Button>
        </Popconfirm>
      )}
    </>
  )
};

CopyToNzBtn.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  useRefetchable: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ])),
};

CopyToNzBtn.defaultProps = {
  useRefetchable: null,
};

const WebsiteRef = (props) => {
  const { viewer, product } = props;

  const type = getType(product);
  const query = getQuery(type);

  if (!query) {
    return null;
  }

  const inputRef = useRef();

  const { username } = useFragment(viewerQuery, viewer);
  const [data, refetch] = useRefetchableFragment(query, props.product);
  const environment = useRelayEnvironment();

  const { country, id: refId } = get(data, 'websiteReference') || {};

  const handleLink = () => {
    const referenceId = inputRef.current.value;

    if (!referenceId) {
      message.error("Please enter a Product ID.")
      return;
    }

    LinkProductMutation.commit({
      environment,
      viewer,
      variables: {
        input: {
          username,
          productId: product.id,
          referenceId: parseInt(referenceId, 10),
        },
      },
      onCompleted: () => {
        refetch({}, { fetchPolicy: 'store-and-network' });
      }
    });
  };

  const handleUnlink = () => {
    UnlinkProductMutation.commit({
      environment,
      viewer,
      variables: {
        input: {
          username,
          id: product.id,
        }
      },
      onCompleted: () => {
        refetch({}, { fetchPolicy: 'store-and-network' });
      }
    });
  };

  return (
    <>
      <CopyToNzBtn
        viewer={viewer}
        entity={product}
        useRefetchable={[data, refetch]}
      />

      {process.env.COUNTRY === 'NZ' && !country && (
        <Popconfirm
          title={
            <div>
              <p>Enter equivalent AU product ID:</p>
              <InputNumber ref={inputRef} placeholder="AU product ID" style={{ width: '100%' }} />
            </div>
          }
          onConfirm={handleLink}
        >
          <Button style={{ marginRight: '10px' }}>Link AU</Button>
        </Popconfirm>
      )}

      {country && (
        <>
          <div style={{ display: 'inline-flex', padding: '5px 10px', background: 'white' }}>
            <a href={getLink("product", country, refId)} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
              {country} Version <LinkOutlined />
            </a>
          </div>

          <Popconfirm
            title={`Are you sure to unlink this product from ${country} website?`}
            onConfirm={handleUnlink}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginRight: '10px' }}>Unlink {country}</Button>
          </Popconfirm>
        </>
      )}
    </>
  )
}

WebsiteRef.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default WebsiteRef;
