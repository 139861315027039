/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesOrderHistory_salesOrder$ref = any;
export type CancelOrderInput = {|
  id?: ?string
|};
export type CancelOrderMutationVariables = {|
  input: CancelOrderInput
|};
export type CancelOrderMutationResponse = {|
  +cancelOrder: ?{|
    +salesOrder: ?{|
      +status: ?number,
      +shipping: ?any,
      +verification: ?{|
        +cancelledBy: ?string
      |},
      +$fragmentRefs: SalesOrderHistory_salesOrder$ref,
    |}
  |}
|};
export type CancelOrderMutation = {|
  variables: CancelOrderMutationVariables,
  response: CancelOrderMutationResponse,
|};
*/


/*
mutation CancelOrderMutation(
  $input: CancelOrderInput!
) {
  cancelOrder(input: $input) {
    salesOrder {
      status
      shipping
      verification {
        cancelledBy
      }
      ...SalesOrderHistory_salesOrder
      id
    }
  }
}

fragment SalesOrderHistory_salesOrder on SalesOrder {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipping",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SalesOrderVerification",
  "kind": "LinkedField",
  "name": "verification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelledBy",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelOrderPayload",
        "kind": "LinkedField",
        "name": "cancelOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrder",
            "kind": "LinkedField",
            "name": "salesOrder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SalesOrderHistory_salesOrder"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelOrderPayload",
        "kind": "LinkedField",
        "name": "cancelOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrder",
            "kind": "LinkedField",
            "name": "salesOrder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "SalesOrderHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22c1d7be699fdd8d725c36bf827a655c",
    "id": null,
    "metadata": {},
    "name": "CancelOrderMutation",
    "operationKind": "mutation",
    "text": "mutation CancelOrderMutation(\n  $input: CancelOrderInput!\n) {\n  cancelOrder(input: $input) {\n    salesOrder {\n      status\n      shipping\n      verification {\n        cancelledBy\n      }\n      ...SalesOrderHistory_salesOrder\n      id\n    }\n  }\n}\n\nfragment SalesOrderHistory_salesOrder on SalesOrder {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a7076554e8d8758df644100428a54bb';

module.exports = node;
