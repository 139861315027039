import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'found';
import { fromGlobalId } from '~/helper';

import LabelList from './LabelList';
import LabelView from './LabelView';
import LabelNew from './LabelNew';
import routes from './routes';

const ShowLabel = (props) => {
  const labels = get(props, 'labels.edges', []);

  if (labels.length > 0) {
    return <div>Labels: {labels.map(({node}) => <Link key={node.id} to={`/promotion/label/${fromGlobalId(node.id).id}`}>{node.name}</Link>)}</div>;
  }

  return null;
};

ShowLabel.propTypes = {
  labels: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

module.exports = {
  LabelList,
  LabelView,
  LabelNew,
  routes,
  ShowLabel,
};
