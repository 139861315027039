/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type SalesTargetReportRefetchQueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
  tab?: ?string,
|};
export type SalesTargetReportRefetchQueryResponse = {|
  +viewer: ?{|
    +salesTargetReport: ?any
  |}
|};
export type SalesTargetReportRefetchQuery = {|
  variables: SalesTargetReportRefetchQueryVariables,
  response: SalesTargetReportRefetchQueryResponse,
|};
*/


/*
query SalesTargetReportRefetchQuery(
  $filterBy: [FilterBy]
  $tab: String
) {
  viewer {
    salesTargetReport(filterBy: $filterBy, tab: $tab)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tab"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filterBy",
      "variableName": "filterBy"
    },
    {
      "kind": "Variable",
      "name": "tab",
      "variableName": "tab"
    }
  ],
  "kind": "ScalarField",
  "name": "salesTargetReport",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesTargetReportRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalesTargetReportRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8db2da2a71a0190f926b8aa450f74ab4",
    "id": null,
    "metadata": {},
    "name": "SalesTargetReportRefetchQuery",
    "operationKind": "query",
    "text": "query SalesTargetReportRefetchQuery(\n  $filterBy: [FilterBy]\n  $tab: String\n) {\n  viewer {\n    salesTargetReport(filterBy: $filterBy, tab: $tab)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa65ebc51450bfa11a5ed04d6322d1d4';

module.exports = node;
