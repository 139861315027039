/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveUpdatePricesScheduleJobsInput = {|
  deleteAll?: ?boolean,
  jobIds?: ?$ReadOnlyArray<?string>,
|};
export type RemoveUpdatePricesScheduleJobsMutationVariables = {|
  input: RemoveUpdatePricesScheduleJobsInput
|};
export type RemoveUpdatePricesScheduleJobsMutationResponse = {|
  +removeUpdatePricesScheduleJobs: ?{|
    +result: ?boolean
  |}
|};
export type RemoveUpdatePricesScheduleJobsMutation = {|
  variables: RemoveUpdatePricesScheduleJobsMutationVariables,
  response: RemoveUpdatePricesScheduleJobsMutationResponse,
|};
*/


/*
mutation RemoveUpdatePricesScheduleJobsMutation(
  $input: RemoveUpdatePricesScheduleJobsInput!
) {
  removeUpdatePricesScheduleJobs(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveUpdatePricesScheduleJobsPayload",
    "kind": "LinkedField",
    "name": "removeUpdatePricesScheduleJobs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveUpdatePricesScheduleJobsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveUpdatePricesScheduleJobsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b40582b87480a78491943c64fc3b0c84",
    "id": null,
    "metadata": {},
    "name": "RemoveUpdatePricesScheduleJobsMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveUpdatePricesScheduleJobsMutation(\n  $input: RemoveUpdatePricesScheduleJobsInput!\n) {\n  removeUpdatePricesScheduleJobs(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5764ee77da4c7509ad13ced917351639';

module.exports = node;
