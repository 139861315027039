import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddFaqMutation } from './mutations';
import FaqForm from './FaqForm';

class FaqNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      brands: formValues.brands.map(p => p.key),
      categories: formValues.categories.map(p => p.key),
      subcategories: formValues.subcategories.map(p => p.key),
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/cms/faq/${fromGlobalId(resp.addFaq.faqEdge.node.id).id}`;
      },
    };

    AddFaqMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Faq" />

        <h1>New Faq</h1>
        <FaqForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(FaqNew, {
  viewer: graphql`
    fragment FaqNew_viewer on Admin {
      id
      ...FaqForm_viewer
    }
  `
});
