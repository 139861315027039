import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddCmsPageMutation($input: AddCmsPageInput!) {
    addCmsPage(input:$input) {
      cmsPageEdge {
        cursor
        node {
          id
          title
          content
          urlSlug
          excludeFromSitemap
          status
          metaDescription
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
