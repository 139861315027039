import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Login } from '../auth';

class Home extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }),
  }

  static defaultProps = {
    viewer: null,
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        {!viewer && <Login viewer={viewer} /> }
        {viewer &&
        <div>Gday, {viewer.username}</div>
       }
      </div>
    );
  }
}

export default createFragmentContainer(Home, {
  viewer: graphql`
    fragment home_viewer on Admin {
      id
      username
      ...Login_viewer
    }
  `,
});
