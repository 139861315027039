import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Select } from 'antd';
import { DatePicker, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;
const { Option } = Select;

class PurchaseLimitForm extends FormBase {
  static propTypes = {
    purchaseLimit: PropTypes.shape({
      id: PropTypes.string,
      limit: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
      product: PropTypes.shape({
      }),
    }),
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
    purchaseLimit: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const purchaseLimit = get(this.props, 'purchaseLimit', {});
    const product = purchaseLimit.product || {};

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <FormItem
          name="id"
          initialValue={purchaseLimit.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Product"
          name="productId"
          rules={[{ required: true, message: 'required' }]}
          initialValue={product.id ? {
            key: product.id,
            label: (
              <div>
                <img width="20" height="20" src={get(product, 'mainImage.url', '')} alt="" />
                {product.name}
              </div>
            )
          } : undefined}
        >
          <SelectProduct
            placeholder="Product"
            labelInValue
            showSearch
            filterOption={false}
            viewer={viewer}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Limit"
          name="limit"
          rules={[{ required: true, message: 'required' }]}
          initialValue={purchaseLimit.limit}
        >
          <InputNumber min={1} placeholder="Limit" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Start Date"
          name="startDate"
          rules={[{ required: true, message: 'required' }]}
          initialValue={purchaseLimit.startDate ? moment(purchaseLimit.startDate) : null}
        >
          <DatePicker placeholder="Start Date" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="End Date"
          name="endDate"
          rules={[{ required: true, message: 'required' }]}
          initialValue={purchaseLimit.endDate ? moment(purchaseLimit.endDate) : null}
        >
          <DatePicker placeholder="End Date" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(purchaseLimit, 'status', 0) ? 1 : 0}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>
      </Form>
    );
  }
}
export default createFragmentContainer(PurchaseLimitForm, {
  viewer: graphql`
    fragment PurchaseLimitForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      id
      ...SelectProduct_viewer
    }
  `,
});
