/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAttributeInput = {|
  code: string,
  includeInSearch?: ?boolean,
  multi?: ?boolean,
  name: string,
  options?: ?$ReadOnlyArray<?string>,
  position?: ?number,
  visibleToFrontend?: ?boolean,
|};
export type AddAttributeMutationVariables = {|
  input: AddAttributeInput
|};
export type AddAttributeMutationResponse = {|
  +addAttribute: ?{|
    +attributeEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +code: ?string,
        +options: ?any,
        +multi: ?boolean,
        +visibleToFrontend: ?boolean,
        +includeInSearch: ?boolean,
        +position: ?number,
      |},
    |}
  |}
|};
export type AddAttributeMutation = {|
  variables: AddAttributeMutationVariables,
  response: AddAttributeMutationResponse,
|};
*/


/*
mutation AddAttributeMutation(
  $input: AddAttributeInput!
) {
  addAttribute(input: $input) {
    attributeEdge {
      cursor
      node {
        id
        name
        code
        options
        multi
        visibleToFrontend
        includeInSearch
        position
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAttributePayload",
    "kind": "LinkedField",
    "name": "addAttribute",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AttributeEdge",
        "kind": "LinkedField",
        "name": "attributeEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Attribute",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "options",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "multi",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibleToFrontend",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "includeInSearch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAttributeMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAttributeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d8bb2d0e702383cff98ed0e049299fd5",
    "id": null,
    "metadata": {},
    "name": "AddAttributeMutation",
    "operationKind": "mutation",
    "text": "mutation AddAttributeMutation(\n  $input: AddAttributeInput!\n) {\n  addAttribute(input: $input) {\n    attributeEdge {\n      cursor\n      node {\n        id\n        name\n        code\n        options\n        multi\n        visibleToFrontend\n        includeInSearch\n        position\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'da75505a564a528353390a1810e54c3d';

module.exports = node;
