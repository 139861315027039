import AddCmsCarouselImageMutation from './AddCmsCarouselImageMutation';
import RemoveCmsCarouselImageMutation from './RemoveCmsCarouselImageMutation';
import UpdateCmsCarouselImageMutation from './UpdateCmsCarouselImageMutation';

module.exports = {
  AddCmsCarouselImageMutation,
  RemoveCmsCarouselImageMutation,
  UpdateCmsCarouselImageMutation,
};

