import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../mutation';

const mutation = graphql`
  mutation RemoveProductFileMutation($input: RemoveProductFileInput!) {
    removeProductFile(input:$input) {
      removedProductFileId
    }
  }
`;


const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeProductFile', edgeName: 'removedProductFileId', connectionKey: 'ProductView_files' }),
});
export default { commit };
