import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveAttributeOptionMutation($input: RemoveAttributeOptionInput!) {
    removeAttributeOption(input:$input) {
      attribute {
        name
        code
        options
        dictates
        multi
        visibleToFrontend
        images(first: 9999) {
          edges {
            node {
              id
              imageUrl
              option
              categories(first: 9999) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        ...AttributeHistory_attribute
        ...WebsiteRef_attribute
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
