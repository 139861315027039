import React from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { graphql, createFragmentContainer } from 'react-relay';
import { get, union } from 'lodash';
import { Button, message, Select, Upload } from 'antd';
import { UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { fromGlobalId, genCsv, toGlobalId } from '../../helper';

const { Option } = Select;

class SelectBrand extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      searchBrands: PropTypes.shape({
        brands: PropTypes.shape({
          edges: PropTypes.arrayOf({
            node: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        }),
      }),
    }).isRequired,
    importExport: PropTypes.shape({
      onImport: PropTypes.func,
      listToExport: PropTypes.arrayOf(PropTypes.string),
    }),
  }

  static defaultProps = {
    importExport: {
      onImport: null,
      listToExport: []
    },
  }

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  handleClick = () => {
    const { listToExport } = this.props.importExport;
    const csvHeaders = ['id', 'name'];
    const brands = get(this.props.viewer, 'searchBrands.edges', []);

    const transformedList = listToExport.map((brandId) => {
      const { node: { name } } = brands.find(({ node }) => node.id === brandId);
      const localId = fromGlobalId(brandId).id;

      return {
        id: localId,
        name,
      };
    });

    if (transformedList.length > 0) {
      genCsv(
        transformedList,
        csvHeaders,
        (timeNow) => `Export-Brands-List-${timeNow}`
      );
    }
  }

  handleBeforeUpload = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        if (result.meta.fields.includes('id')) {
          this.processUploadedFile(result.data);
        } else {
          message.error("No 'id' in the file", 5);
        }
      },
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true
    });

    return false;
  };

  processUploadedFile = (brands) => {
    const { onImport } = this.props.importExport;

    try {
      if (brands.length > 1000) {
        throw new Error('Import file must not contain more than 1000 brands');
      }
      if (brands.length === 0) {
        throw new Error('Import file must not be empty');
      }

      const invalidBrand = brands.find(brand => !Number.isInteger(brand.id) || brand.id < 1);
      if (invalidBrand) {
        throw new Error(`Invalid brand id: ${invalidBrand.id}`);
      }

      const options = brands
        .map(brand => (toGlobalId("Brand", brand.id)));

      if (typeof onImport === 'function' && options.length > 0) {
        onImport(options);
      }
    } catch (error) {
      message.error(error.message, 5);
    }
  };

  render() {
    const { viewer, importExport: { onImport, listToExport }, ...selectProps } = this.props;
    const brands = get(viewer, 'searchBrands.edges', []);

    return (
      <div>
        <Select
          allowClear
          {...selectProps}
        >
          {brands.map(({ node: brand }) => (
            <Option key={brand.id} value={brand.id}>{brand.name}</Option>
          ))}
        </Select>

        {onImport && (
          <div style={{ color: 'grey' }}>
            {listToExport.length} selected
          </div>
        )}

        {onImport && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Button onClick={this.handleClick} style={{ marginTop: '10px', width: '100px'  }}>
              <VerticalAlignBottomOutlined />Export
            </Button>
            <Upload
              className="importBrand"
              accept=".csv"
              maxCount={1}
              ref={this.ref}
              beforeUpload={this.handleBeforeUpload}
              showUploadList={false}
            >
              <Button style={{ width: '100px' }}>
                <UploadOutlined /> Import
              </Button>
            </Upload>
          </div>
        )}
      </div>
    )
  }
}


const Hoc = createFragmentContainer(
  SelectBrand, {
    viewer: graphql`
    fragment SelectBrand_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      searchBrands: brands(first: 1000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  },
)

Hoc.updateSelect = (form, value, field) => {
  if (value && value.length > 0) {
    const { getFieldValue, setFieldsValue } = form;
    const prevBrands = getFieldValue(field) || [];
    const newBrands = union(prevBrands, value);
    setFieldsValue({
      [field]: newBrands,
    });
  }
};

export default Hoc;