/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCreditInput = {|
  applyBrandIds?: ?$ReadOnlyArray<?string>,
  applyCreditMode?: ?number,
  creditFromProducts?: ?$ReadOnlyArray<?InputCreditFromProducts>,
  endDate?: ?string,
  excludeBrandIds?: ?$ReadOnlyArray<?string>,
  excludeNonStock?: ?boolean,
  excludePriceDrop?: ?boolean,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  excludeProductsSpend?: ?$ReadOnlyArray<?string>,
  excludeSpecialOrder?: ?boolean,
  expiry?: ?number,
  fromBrandIds?: ?$ReadOnlyArray<?string>,
  id: string,
  includeNonStockBrands?: ?$ReadOnlyArray<?string>,
  includePriceDropBrands?: ?$ReadOnlyArray<?string>,
  includeProducts?: ?$ReadOnlyArray<?string>,
  minSpent?: ?number,
  name?: ?string,
  percentage?: ?number,
  promoItems?: ?$ReadOnlyArray<?string>,
  startDate?: ?string,
  status?: ?boolean,
  stealthMode?: ?boolean,
  tiers?: ?$ReadOnlyArray<?InputCreditTier>,
|};
export type InputCreditFromProducts = {|
  creditAmount: number,
  id?: ?string,
  productId: string,
|};
export type InputCreditTier = {|
  creditAmount: number,
  id?: ?string,
  image?: ?any,
  price: number,
|};
export type UpdateCreditMutationVariables = {|
  input: UpdateCreditInput
|};
export type UpdateCreditMutationResponse = {|
  +updateCredit: ?{|
    +credit: ?{|
      +fromBrandIds: ?$ReadOnlyArray<?string>,
      +name: ?string,
      +tiers: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +price: ?number,
            +creditAmount: ?number,
            +imageUrl: ?string,
          |}
        |}>
      |},
      +promoItems: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +status: ?string,
          |}
        |}>
      |},
      +creditFromProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +product: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |},
            +creditAmount: ?number,
          |}
        |}>
      |},
      +percentage: ?number,
      +minSpent: ?number,
      +applyBrandIds: ?$ReadOnlyArray<?string>,
      +applyCreditMode: ?number,
      +excludeBrandIds: ?$ReadOnlyArray<?string>,
      +excludeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +excludeProductsSpend: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +includeProducts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +mainImage: ?{|
              +id: string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +startDate: ?string,
      +endDate: ?string,
      +expiry: ?number,
      +stealthMode: ?boolean,
      +excludeNonStock: ?boolean,
      +includeNonStockBrands: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
      +excludePriceDrop: ?boolean,
      +includePriceDropBrands: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
      +status: ?boolean,
    |}
  |}
|};
export type UpdateCreditMutation = {|
  variables: UpdateCreditMutationVariables,
  response: UpdateCreditMutationResponse,
|};
*/


/*
mutation UpdateCreditMutation(
  $input: UpdateCreditInput!
) {
  updateCredit(input: $input) {
    credit {
      fromBrandIds
      name
      tiers(first: 999) {
        edges {
          node {
            id
            price
            creditAmount
            imageUrl
          }
        }
      }
      promoItems(first: 999) {
        edges {
          node {
            id
            name
            status
          }
        }
      }
      creditFromProducts(first: 999) {
        edges {
          node {
            id
            product {
              id
              name
              mainImage {
                id
                url
              }
            }
            creditAmount
          }
        }
      }
      percentage
      minSpent
      applyBrandIds
      applyCreditMode
      excludeBrandIds
      excludeProducts(first: 9999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      excludeProductsSpend(first: 9999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      includeProducts(first: 9999) {
        edges {
          node {
            id
            name
            mainImage {
              id
              url
            }
          }
        }
      }
      startDate
      endDate
      expiry
      stealthMode
      excludeNonStock
      includeNonStockBrands {
        id
        name
      }
      excludePriceDrop
      includePriceDropBrands {
        id
        name
      }
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fromBrandIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creditAmount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "CreditTierConnection",
  "kind": "LinkedField",
  "name": "tiers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreditTierEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditTier",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "tiers(first:999)"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "promoItems",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v3/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "promoItems(first:999)"
},
v10 = [
  (v5/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "CreditFromProductsConnection",
  "kind": "LinkedField",
  "name": "creditFromProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreditFromProductsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditFromProducts",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": (v10/*: any*/),
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "creditFromProducts(first:999)"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minSpent",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applyBrandIds",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applyCreditMode",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludeBrandIds",
  "storageKey": null
},
v17 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v18 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v10/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v19 = {
  "alias": null,
  "args": (v17/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "excludeProducts",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": "excludeProducts(first:9999)"
},
v20 = {
  "alias": null,
  "args": (v17/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "excludeProductsSpend",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": "excludeProductsSpend(first:9999)"
},
v21 = {
  "alias": null,
  "args": (v17/*: any*/),
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "includeProducts",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": "includeProducts(first:9999)"
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiry",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stealthMode",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludeNonStock",
  "storageKey": null
},
v27 = [
  (v5/*: any*/),
  (v3/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "Brand",
  "kind": "LinkedField",
  "name": "includeNonStockBrands",
  "plural": true,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludePriceDrop",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "Brand",
  "kind": "LinkedField",
  "name": "includePriceDropBrands",
  "plural": true,
  "selections": (v27/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCreditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCreditPayload",
        "kind": "LinkedField",
        "name": "updateCredit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Credit",
            "kind": "LinkedField",
            "name": "credit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCreditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCreditPayload",
        "kind": "LinkedField",
        "name": "updateCredit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Credit",
            "kind": "LinkedField",
            "name": "credit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v8/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5048feb3318c4200a10970cf2b2ae94d",
    "id": null,
    "metadata": {},
    "name": "UpdateCreditMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCreditMutation(\n  $input: UpdateCreditInput!\n) {\n  updateCredit(input: $input) {\n    credit {\n      fromBrandIds\n      name\n      tiers(first: 999) {\n        edges {\n          node {\n            id\n            price\n            creditAmount\n            imageUrl\n          }\n        }\n      }\n      promoItems(first: 999) {\n        edges {\n          node {\n            id\n            name\n            status\n          }\n        }\n      }\n      creditFromProducts(first: 999) {\n        edges {\n          node {\n            id\n            product {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n            creditAmount\n          }\n        }\n      }\n      percentage\n      minSpent\n      applyBrandIds\n      applyCreditMode\n      excludeBrandIds\n      excludeProducts(first: 9999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      excludeProductsSpend(first: 9999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      includeProducts(first: 9999) {\n        edges {\n          node {\n            id\n            name\n            mainImage {\n              id\n              url\n            }\n          }\n        }\n      }\n      startDate\n      endDate\n      expiry\n      stealthMode\n      excludeNonStock\n      includeNonStockBrands {\n        id\n        name\n      }\n      excludePriceDrop\n      includePriceDropBrands {\n        id\n        name\n      }\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb725f0b3ba11f8733556306f8f78263';

module.exports = node;
