import UpdateCustomerMutation from './UpdateCustomerMutation';
import AddCustomerMutation from './AddCustomerMutation';
import GetAddressDetailMutation from './GetAddressDetailMutation';
import RemoveAddressMutation from './RemoveAddressMutation';
import SearchAddressMutation from './SearchAddressMutation';
import SearchPostcodeSuburbMutation from './SearchPostcodeSuburbMutation';
import UpdateCustomerAddressMutation from './UpdateCustomerAddressMutation';
import UpdateCustomerCreditMutation from './UpdateCustomerCreditMutation';
import UpdateCustomerEmailMutation from './UpdateCustomerEmailMutation';
import ResetPasswordMutation from './ResetPasswordMutation';

module.exports = {
  UpdateCustomerMutation,
  AddCustomerMutation,
  GetAddressDetailMutation,
  RemoveAddressMutation,
  ResetPasswordMutation,
  SearchAddressMutation,
  SearchPostcodeSuburbMutation,
  UpdateCustomerAddressMutation,
  UpdateCustomerCreditMutation,
  UpdateCustomerEmailMutation,
};
