import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Affix, Button, Form, Input, message, Popconfirm, Select } from 'antd';
import { FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';
import { RemoveUrlRewriteMutation } from './mutations';

const { Item: FormItem } = Form;
const { Option } = Select;

class UrlRewriteForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
    }).isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    entity: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleDelete = (id) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id } },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success('Deleted');
        this.props.router.go(-1);
      },
    };
    RemoveUrlRewriteMutation.commit(mutation);
  }

  render() {
    const { entity: rewrite, match } = this.props;

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
            {rewrite.id && (
            <Popconfirm title="Delete this rewrite?" onConfirm={() => this.handleDelete(rewrite.id)} okText="Yes" cancelText="No">
              <Button type="danger" htmlType="submit" disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={rewrite.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="From"
          name="from"
          rules={[{ required: true, message: 'required' }]}
          initialValue={rewrite.from}
        >
          <Input placeholder="URL" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="To"
          name='to'
          rules={[{ required: true, message: 'required' }]}
          initialValue={rewrite.to}
        >
          <Input placeholder="To" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="HTTP Status Code"
          name="httpCode"
          rules={[{ required: true, message: 'required' }]}
          initialValue={rewrite.httpCode ? rewrite.httpCode : 302}
          extra="Think twice before selecting the correct HTTP status code"
        >
          <Select placeholder="Status">
            <Option value={301}>301 Permanent Redirect</Option>
            <Option value={302}>302 Temporary Redirect</Option>
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="URL Type"
          name="urlType"
          rules={[{ required: true, message: 'required' }]}
          initialValue={rewrite.urlType}
          extra={(
            <div>
              <p>URL Type controls what portion of URL will be replaced. For example, to rewrite makita to milwaukee in a URL `/category/by-brand/makita/rebar-cutters`, URL Type should set to `Brand` and with `from` set to `/makita` and `to` set to `/milwaukee`</p>
              <p>If you are intended to rewrite/redirect a whole URL, URL Type `Unspecified` should be selected</p>
            </div>
          )}
        >
          <Select placeholder="Url Type">
            <Option value={0}>Unspecified</Option>
            <Option value={1}>Category</Option>
            <Option value={2}>Subcategory</Option>
            <Option value={3}>Brand</Option>
            <Option value={4}>Product</Option>
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Last Accessed"
        >
          {rewrite.lastAccessed}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={rewrite.status ? 1 : 0}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

      </Form>
    );
  }
}
export default createFragmentContainer(UrlRewriteForm, {
  viewer: graphql`
    fragment UrlRewriteForm_viewer on Admin {
      id
    }
  `,
});
