/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProductReviewInput = {|
  content?: ?string,
  id?: ?string,
  status?: ?number,
|};
export type UpdateProductReviewMutationVariables = {|
  input: UpdateProductReviewInput
|};
export type UpdateProductReviewMutationResponse = {|
  +updateProductReview: ?{|
    +productReview: ?{|
      +content: ?string,
      +status: ?number,
    |}
  |}
|};
export type UpdateProductReviewMutation = {|
  variables: UpdateProductReviewMutationVariables,
  response: UpdateProductReviewMutationResponse,
|};
*/


/*
mutation UpdateProductReviewMutation(
  $input: UpdateProductReviewInput!
) {
  updateProductReview(input: $input) {
    productReview {
      content
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProductReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProductReviewPayload",
        "kind": "LinkedField",
        "name": "updateProductReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductReview",
            "kind": "LinkedField",
            "name": "productReview",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProductReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProductReviewPayload",
        "kind": "LinkedField",
        "name": "updateProductReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductReview",
            "kind": "LinkedField",
            "name": "productReview",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c69e3a8dc2ac298b61c3b645c0aca3e5",
    "id": null,
    "metadata": {},
    "name": "UpdateProductReviewMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProductReviewMutation(\n  $input: UpdateProductReviewInput!\n) {\n  updateProductReview(input: $input) {\n    productReview {\n      content\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50aaab8da1ac93917019a29f70c8999c';

module.exports = node;
