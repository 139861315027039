/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type BySubcategoryRefetchQueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
  orderBy?: ?OrderBy,
  type?: ?string,
  dataSource?: ?string,
  tab?: ?string,
|};
export type BySubcategoryRefetchQueryResponse = {|
  +viewer: ?{|
    +barChartReport: ?any
  |}
|};
export type BySubcategoryRefetchQuery = {|
  variables: BySubcategoryRefetchQueryVariables,
  response: BySubcategoryRefetchQueryResponse,
|};
*/


/*
query BySubcategoryRefetchQuery(
  $filterBy: [FilterBy]
  $orderBy: OrderBy
  $type: String
  $dataSource: String
  $tab: String
) {
  viewer {
    barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource, tab: $tab)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dataSource"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tab"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "dataSource",
      "variableName": "dataSource"
    },
    {
      "kind": "Variable",
      "name": "filterBy",
      "variableName": "filterBy"
    },
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy"
    },
    {
      "kind": "Variable",
      "name": "tab",
      "variableName": "tab"
    },
    {
      "kind": "Variable",
      "name": "type",
      "variableName": "type"
    }
  ],
  "kind": "ScalarField",
  "name": "barChartReport",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BySubcategoryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "BySubcategoryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "662318dbd4449da0dbaecb501c8db3dd",
    "id": null,
    "metadata": {},
    "name": "BySubcategoryRefetchQuery",
    "operationKind": "query",
    "text": "query BySubcategoryRefetchQuery(\n  $filterBy: [FilterBy]\n  $orderBy: OrderBy\n  $type: String\n  $dataSource: String\n  $tab: String\n) {\n  viewer {\n    barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource, tab: $tab)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '047690ded084047d7c901bfb828979f6';

module.exports = node;
