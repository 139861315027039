import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation BulkUpdateStoresDescriptionMutation($input: BulkUpdateStoresDescriptionInput!) {
    bulkUpdateStoresDescription(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
