/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FreebieHistory_viewer$ref: FragmentReference;
declare export opaque type FreebieHistory_viewer$fragmentType: FreebieHistory_viewer$ref;
export type FreebieHistory_viewer = {|
  +id: string,
  +$refType: FreebieHistory_viewer$ref,
|};
export type FreebieHistory_viewer$data = FreebieHistory_viewer;
export type FreebieHistory_viewer$key = {
  +$data?: FreebieHistory_viewer$data,
  +$fragmentRefs: FreebieHistory_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreebieHistory_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '636710e98e6e1ce981e534da68103a81';

module.exports = node;
