import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddBonusMutation } from './mutations';
import BonusForm from './BonusForm';

class BonusNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues, { setLoading }) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      onProducts: formValues.onProducts.map(p => p.key),
      stealthMode: !!formValues.stealthMode,
      products: formValues.p.map((p) => ({ ...p, productId: p.productId.key })),
    };
    delete values.p;
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: { insert: setLoading },
      onCompleted: (resp) => {
        window.location.href = `/promotion/bonus/${fromGlobalId(resp.addBonus.bonusEdge.node.id).id}`;
      },
    };

    AddBonusMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Bonus" />

        <h1>New Bonus</h1>
        <BonusForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(BonusNew, {
  viewer: graphql`
    fragment BonusNew_viewer on Admin {
      id
      ...BonusForm_viewer
    }
  `,
});
