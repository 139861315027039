/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFaqInput = {|
  answer: string,
  brands?: ?$ReadOnlyArray<?string>,
  categories?: ?$ReadOnlyArray<?string>,
  position?: ?number,
  question: string,
  status?: ?boolean,
  subcategories?: ?$ReadOnlyArray<?string>,
|};
export type AddFaqMutationVariables = {|
  input: AddFaqInput
|};
export type AddFaqMutationResponse = {|
  +addFaq: ?{|
    +faqEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +question: ?string,
        +answer: ?string,
        +position: ?number,
        +status: ?boolean,
        +brands: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +categories: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +subcategories: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type AddFaqMutation = {|
  variables: AddFaqMutationVariables,
  response: AddFaqMutationResponse,
|};
*/


/*
mutation AddFaqMutation(
  $input: AddFaqInput!
) {
  addFaq(input: $input) {
    faqEdge {
      cursor
      node {
        id
        question
        answer
        position
        status
        brands(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        categories(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories(first: 9999) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFaqPayload",
    "kind": "LinkedField",
    "name": "addFaq",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FaqEdge",
        "kind": "LinkedField",
        "name": "faqEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Faq",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "BrandConnection",
                "kind": "LinkedField",
                "name": "brands",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Brand",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "brands(first:999)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CategoryConnection",
                "kind": "LinkedField",
                "name": "categories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CategoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Category",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "categories(first:999)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 9999
                  }
                ],
                "concreteType": "SubcategoryConnection",
                "kind": "LinkedField",
                "name": "subcategories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubcategoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Subcategory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "subcategories(first:9999)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddFaqMutation",
    "selections": (v4/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddFaqMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "ae1df8ff431712b48562e3a12e3d5f97",
    "id": null,
    "metadata": {},
    "name": "AddFaqMutation",
    "operationKind": "mutation",
    "text": "mutation AddFaqMutation(\n  $input: AddFaqInput!\n) {\n  addFaq(input: $input) {\n    faqEdge {\n      cursor\n      node {\n        id\n        question\n        answer\n        position\n        status\n        brands(first: 999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        categories(first: 999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        subcategories(first: 9999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6eac121c4cb72d2bed54a619b3f97e6c';

module.exports = node;
