/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type WebsiteRef_subcategory$ref = any;
export type WebsiteRefSubcategoryRefetchQueryVariables = {|
  id: string
|};
export type WebsiteRefSubcategoryRefetchQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: WebsiteRef_subcategory$ref
  |}
|};
export type WebsiteRefSubcategoryRefetchQuery = {|
  variables: WebsiteRefSubcategoryRefetchQueryVariables,
  response: WebsiteRefSubcategoryRefetchQueryResponse,
|};
*/


/*
query WebsiteRefSubcategoryRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...WebsiteRef_subcategory
    id
  }
}

fragment WebsiteRef_subcategory on Subcategory {
  websiteReference {
    id
    country
  }
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WebsiteRefSubcategoryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WebsiteRef_subcategory"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WebsiteRefSubcategoryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebsiteReference",
                "kind": "LinkedField",
                "name": "websiteReference",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Subcategory",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3f6683b7d7533254fff459b0430d01e",
    "id": null,
    "metadata": {},
    "name": "WebsiteRefSubcategoryRefetchQuery",
    "operationKind": "query",
    "text": "query WebsiteRefSubcategoryRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...WebsiteRef_subcategory\n    id\n  }\n}\n\nfragment WebsiteRef_subcategory on Subcategory {\n  websiteReference {\n    id\n    country\n  }\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a5b444b1a9545db1e2e8faa598cc29c';

module.exports = node;
