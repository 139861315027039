/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssignProductsToCategories_viewer$ref: FragmentReference;
declare export opaque type AssignProductsToCategories_viewer$fragmentType: AssignProductsToCategories_viewer$ref;
export type AssignProductsToCategories_viewer = {|
  +categories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +$refType: AssignProductsToCategories_viewer$ref,
|};
export type AssignProductsToCategories_viewer$data = AssignProductsToCategories_viewer;
export type AssignProductsToCategories_viewer$key = {
  +$data?: AssignProductsToCategories_viewer$data,
  +$fragmentRefs: AssignProductsToCategories_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignProductsToCategories_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 9999
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "asc",
            "field": "name"
          }
        }
      ],
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "categories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a49d19d7b5a5831307452965b7932f85';

module.exports = node;
