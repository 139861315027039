import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Input, Modal, Space } from "antd";

const { Item: FormItem } = Form;

const ChangeEmailPopup = (props) => {
  const { customerId, handleSubmit } = props;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  }

  const onSubmitCompleted = () => {
    form.resetFields();
    toggleModal();
  }

  return (
    <>
      <Button type="danger" onClick={toggleModal} htmlType="button" style={{  width: '120px' }}>
        Update Email
      </Button>

      <Modal
        title="Update Customer Email"
        onCancel={toggleModal}
        okText="Confirm"
        cancelText="Cancel"
        visible={isModalOpen}
        footer={null}
      >
        <Form form={form} onFinish={(formValues) => handleSubmit(formValues, customerId, onSubmitCompleted)}>
          <FormItem
            labelCol={{ span: 24 }}
            label="Enter new email address"
            name='email'
            rules={[{ required: true, message: 'required' }, { type: 'email', message: 'Invalid Email' }]}
          >
            <Input placeholder="Email" />
          </FormItem>

          <FormItem
            labelCol={{ span: 24 }}
            label="What is the magic phrase?"
            name='magicPhrase'
            rules={[({getFieldValue}) => ({
              validator(_, value) {
                if (getFieldValue("email") && !value) {
                  return Promise.reject(new Error('Enter magic phrase'));
                }
                return Promise.resolve();
              }
            })]}
          >
            <Input placeholder="Magic Phrase" />
          </FormItem>

          <FormItem style={{ marginBottom: "10px" }}>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end' }}>
              <Button onClick={toggleModal} htmlType="button" >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" >
                Confirm
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  )
}

ChangeEmailPopup.propTypes = {
  customerId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ChangeEmailPopup;