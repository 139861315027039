import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

export const STATUS_STYLES = {
  'processing': {
    color: 'black',
    meaning: 'Aden accepted the orders but there is about it. No picking or whatsoever yet.'
  },
  'allocatingStock': {
    color: '#cb0000',
  },
  'beingPicked': {
    color: '#cb0000',
    meaning: 'Picking slips are printed, should be picked within one day.'
  },
  'partiallyPicked': {
    color: '#cb0000',
  },
  'backOrder': {
    color: '#cb0000',
  },
  'partiallyShipped': {
    color: 'green',
  },
  'shipped': {
    color: 'green',
  },
  // ampersand as a hack
  'click_ampersand_collect': {
    color: 'black',
  },
  'cancelled': {
    color: 'black',
  },
}

export default class Status extends React.Component {
  static propTypes = {
    order: PropTypes.shape({
      shipping: PropTypes.shape({
        status: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
  }

  render() {
    const { order: { shipping } } = this.props;

    return (
      <div>
        <b style={STATUS_STYLES[camelCase(shipping.status)]}>Shipping Status: {shipping.status}</b>
        {shipping.status === 'Shipped' && <span style={{marginLeft: '5px'}}><b><i>(Track and trace is available on each item)</i></b></span>}
      </div>
    )
  }
}
