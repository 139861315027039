import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddUrlRewriteMutation($input: AddUrlRewriteInput!) {
    addUrlRewrite(input:$input) {
      urlRewriteEdge {
        cursor
        node {
          id
          from
          to
          httpCode
          lastAccessed
          urlType
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
