/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SubcategoryForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubcategoryNew_viewer$ref: FragmentReference;
declare export opaque type SubcategoryNew_viewer$fragmentType: SubcategoryNew_viewer$ref;
export type SubcategoryNew_viewer = {|
  +$fragmentRefs: SubcategoryForm_viewer$ref,
  +$refType: SubcategoryNew_viewer$ref,
|};
export type SubcategoryNew_viewer$data = SubcategoryNew_viewer;
export type SubcategoryNew_viewer$key = {
  +$data?: SubcategoryNew_viewer$data,
  +$fragmentRefs: SubcategoryNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubcategoryNew_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubcategoryForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7c61f33669436eebf427cadd5cc6f705';

module.exports = node;
