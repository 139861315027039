import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateLabelMutation($input: UpdateLabelInput!) {
    updateLabel(input:$input) {
      label {
        name
        brandId
        imageUrl
        productPageImageUrl
        placement
        startDate
        endDate
        type
        position
        bonusId
        categoryId
        redemptionId
        creditId
        freebieId
        excludeProducts(first: 99999) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        products(first: 99999) {
          edges {
            node {
              id
              name
              sku
              mainImage {
                id
                url
              }
              status
            }
          }
        }
        status
        fuseImage
        fuseImageJobs {
          id
          args
          state
          insertedAt
        }
        ...LabelHistory_label
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
