/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BrandForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BrandNew_viewer$ref: FragmentReference;
declare export opaque type BrandNew_viewer$fragmentType: BrandNew_viewer$ref;
export type BrandNew_viewer = {|
  +id: string,
  +$fragmentRefs: BrandForm_viewer$ref,
  +$refType: BrandNew_viewer$ref,
|};
export type BrandNew_viewer$data = BrandNew_viewer;
export type BrandNew_viewer$key = {
  +$data?: BrandNew_viewer$data,
  +$fragmentRefs: BrandNew_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandNew_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrandForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '07cdf1a4c1aa50db927eedccde6831f1';

module.exports = node;
