import { buildMutation, edgeUpdater } from '~/mutation';
import { mutation } from '~/components/history/mutations/AddHistoryMutation'

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, {
    rootField: 'addHistory', edgeName: 'historyEdge', connectionKey: 'SalesOrderHistory_histories', insertPosition: 'before',
  }),
});

export default { commit };
