import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation RemoveCmsCarouselImageMutation($input: RemoveCmsCarouselImageInput!) {
    removeCmsCarouselImage(input:$input) {
      cmsCarouselImageId
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

