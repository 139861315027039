import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/card"
    getComponent={() => (
      import(/* webpackChunkName: "PromoCardList" */'./PromoCardList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_PromoCardList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...PromoCardList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
      filterBy: [
        {
          field: 'visibility', filter: '4', filterType: 'number', type: 'equals',
        },
      ],
    })}
  />
);

export default routes;
