/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserList_viewer$ref: FragmentReference;
declare export opaque type UserList_viewer$fragmentType: UserList_viewer$ref;
export type UserList_viewer = {|
  +adminRoles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +adminUsers: ?{|
    +totalCount: ?number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +username: ?string,
        +firstname: ?string,
        +lastname: ?string,
        +email: ?string,
        +status: ?boolean,
        +roles: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
      |}
    |}>,
  |},
  +$refType: UserList_viewer$ref,
|};
export type UserList_viewer$data = UserList_viewer;
export type UserList_viewer$key = {
  +$data?: UserList_viewer$data,
  +$fragmentRefs: UserList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "filterBy"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "adminUsers"
        ]
      }
    ]
  },
  "name": "UserList_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 999
        }
      ],
      "concreteType": "AdminRoleConnection",
      "kind": "LinkedField",
      "name": "adminRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "adminRoles(first:999)"
    },
    {
      "alias": "adminUsers",
      "args": [
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "AdminUserConnection",
      "kind": "LinkedField",
      "name": "__UserList_adminUsers_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            (v2/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminUser",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AdminRole",
                  "kind": "LinkedField",
                  "name": "roles",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ea96af8e64725e1b9dde59186100db8';

module.exports = node;
