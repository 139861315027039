import AssignProductsToCategoriesMutation from './AssignProductsToCategoriesMutation';
import AssignProductsToSubcategoriesMutation from './AssignProductsToSubcategoriesMutation';
import BulkUpdateProductsMutation from './BulkUpdateProductsMutation';
import CopyFromWebsiteMutation from './CopyFromWebsiteMutation';
import ExportProductsMutation from './ExportProductsMutation';
import ImportProductsMutation from './ImportProductsMutation';
import RemoveCategoriesFromProductsMutation from './RemoveCategoriesFromProductsMutation';
import RemoveSubcategoriesFromProductsMutation from './RemoveSubcategoriesFromProductsMutation';
import RemoveUpdatePricesScheduleJobs from './RemoveUpdatePricesScheduleJobs';
import UpdatePriceDropsMutation from './UpdatePriceDropsMutation';
import UpdatePricesMutation from './UpdatePricesMutation';

module.exports = {
  AssignProductsToCategoriesMutation,
  AssignProductsToSubcategoriesMutation,
  BulkUpdateProductsMutation,
  CopyFromWebsiteMutation,
  ExportProductsMutation,
  ImportProductsMutation,
  RemoveCategoriesFromProductsMutation,
  RemoveSubcategoriesFromProductsMutation,
  RemoveUpdatePricesScheduleJobs,
  UpdatePriceDropsMutation,
  UpdatePricesMutation,
};
