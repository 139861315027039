/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type GiftCardUsageForm_viewer$ref = any;
type GiftCardUsageHistory_giftCardUsage$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type GiftCardUsageView_viewer$ref: FragmentReference;
declare export opaque type GiftCardUsageView_viewer$fragmentType: GiftCardUsageView_viewer$ref;
export type GiftCardUsageView_viewer = {|
  +giftCardUsages: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +code: ?string,
        +amount: ?number,
        +remainingAmount: ?number,
        +insertedAt: ?string,
        +expiry: ?string,
        +giftCard: ?{|
          +id: string,
          +name: ?string,
        |},
        +fromOrder: ?{|
          +id: string,
          +name: ?string,
        |},
        +onOrders: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +amount: ?number,
              +order: ?{|
                +id: string,
                +name: ?string,
              |},
            |}
          |}>
        |},
        +status: ?boolean,
        +$fragmentRefs: GiftCardUsageHistory_giftCardUsage$ref,
      |}
    |}>
  |},
  +$fragmentRefs: GiftCardUsageForm_viewer$ref,
  +$refType: GiftCardUsageView_viewer$ref,
|};
export type GiftCardUsageView_viewer$data = GiftCardUsageView_viewer;
export type GiftCardUsageView_viewer$key = {
  +$data?: GiftCardUsageView_viewer$data,
  +$fragmentRefs: GiftCardUsageView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GiftCardUsageView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "GiftCardUsageConnection",
      "kind": "LinkedField",
      "name": "giftCardUsages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GiftCardUsageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GiftCardUsage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remainingAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "insertedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiry",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GiftCard",
                  "kind": "LinkedField",
                  "name": "giftCard",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrder",
                  "kind": "LinkedField",
                  "name": "fromOrder",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 999
                    }
                  ],
                  "concreteType": "SpentOnOrderConnection",
                  "kind": "LinkedField",
                  "name": "onOrders",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SpentOnOrderEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SpentOnOrder",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SalesOrder",
                              "kind": "LinkedField",
                              "name": "order",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "onOrders(first:999)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GiftCardUsageHistory_giftCardUsage"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GiftCardUsageForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd84ed25ddfe2ed46e8ded3f840b17216';

module.exports = node;
