import React from 'react';
import { Route } from 'found';

import CanonicalRoutes from './canonical/routes';
import SearchTermsReportRoutes from './search_terms_report/routes';
import SynonymRoutes from './synonym/routes';
import RedirectRoutes from './redirect/routes';

const routes = (
  <Route
    path="/search"
  >
    {CanonicalRoutes}
    {SearchTermsReportRoutes}
    {SynonymRoutes}
    {RedirectRoutes}
  </Route>
);

export default routes;
