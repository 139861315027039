import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateCustomerEmailMutation($input: UpdateCustomerEmailInput!) {
    updateCustomerEmail(input:$input) {
      customer {
        id
        email
        subscription {
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };