import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation BustCacheMutation($input: BustCacheInput!) {
    bustCache(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

