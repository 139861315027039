import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateFaqMutation } from './mutations';
import FaqForm from './FaqForm';

class FaqView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      faqs: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      brands: formValues.brands.map(p => p.key),
      categories: formValues.categories.map(p => p.key),
      subcategories: formValues.subcategories.map(p => p.key),
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };

    UpdateFaqMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const faq = get(viewer, 'faqs.edges[0].node', {});

    return (
      <div>
        <Helmet title={faq.name} />
        <h1>{faq.title}</h1>
        <FaqForm
          key={faq.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          faq={faq}
        />
      </div>
    );
  }
}

export default createFragmentContainer(FaqView, {
  viewer: graphql`
    fragment FaqView_viewer on Admin {
      faqs(first: 1, ids: $ids) {
        edges {
          node {
            id
            question
            answer
            position
            status
            brands(first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            categories(first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            subcategories(first: 9999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...FaqForm_viewer
    }
  `}
);
