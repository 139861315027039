import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation SubmitOrderMutation($input: SubmitOrderInput!) {
    submitOrder(input:$input) {
      salesOrder {
        id
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

