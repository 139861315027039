import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Popconfirm, Select, Space, Tabs, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, fileValidator, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import AdjacentBannerHistory from './AdjacentBannerHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class AdjacentBannerForm extends FormBase {
  static propTypes = {
    adjacentBanner: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      product: PropTypes.shape({
      }),
      categories: PropTypes.shape({
      }),
      subcategories: PropTypes.shape({
      }),
      imageUrl: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      categories: PropTypes.shape({
      }),
      subcategories: PropTypes.shape({
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    adjacentBanner: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const adjacentBanner = get(this.props, 'adjacentBanner', {});
    const categories = get(viewer, 'categories.edges', []);
    const subcategories = get(viewer, 'subcategories.edges', []);

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
            {adjacentBanner.id && (
            <Popconfirm
              title="Are you sure delete this banner?"
              onConfirm={() => { this.props.remove(adjacentBanner); }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <div>
              Banner will not show in search page.
              <ul>
                <li>
                  If no categories or subcategories are provided, banner will show wherever the attached product shows in listing.
                </li>

                <li>
                  If categories are provided but subcategories are not provided, banner will only show in those categories, regardless what subcategories the product is in.
                </li>

                <li>
                  If categories are not provided but subcategories are provided, banner will show in those subcategories, regardless what categories the product is in.
                </li>

                <li>
                  If both categories and subcategories are provided, banner will only show in those categories and subcategories which the product presents.
                </li>

                <li>
                  For example, if <b>Categories</b> is selected as <b>Home Page, Cordless Tools</b> and <b>Subcategories</b> is selected as <b>Battery</b>, banner will only show in <b>Battery</b> subcategory which is under <b>Cordless Tools</b> and it will never appear in <b>Home Page</b> as <b>Battery</b> subcategory will never appear in <b>Home Page</b>.
                </li>
              </ul>
            </div>

            <FormItem
              name="id"
              initialValue={adjacentBanner.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={adjacentBanner.name}
            >
              <Input placeholder="Name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Attach To Product"
              name="productId"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(adjacentBanner, 'product.id') ? {
                key: adjacentBanner.product.id,
                label: (
                  <div>
                    <img width="20" height="20" src={get(adjacentBanner.product, 'mainImage.url')} alt="" />
                    {get(adjacentBanner.product, 'name')}
                  </div>
                )
              } : undefined}
            >
              <SelectProduct
                placeholder="Product"
                labelInValue
                filterOption={false}
                showSearch
                viewer={viewer}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Categories"
              name="categoryIds"
              initialValue={get(adjacentBanner, 'categories.edges', []).map(({ node }) => ({
                key: node.id,
                label: node.name
              }))}
            >
              <Select
                placeholder="Categories"
                mode="multiple"
                optionFilterProp="children"
                labelInValue
              >
                {
                  categories.map(({ node }) => <Option key={node.id} value={node.id}>{node.name}</Option>)
                }
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Subcategories"
              name="subcategoryIds"
              initialValue={get(adjacentBanner, 'subcategories.edges', []).map(({ node }) => ({
                key: node.id,
                label: node.name
              }))}
            >
              <Select
                placeholder="Subcategories"
                optionFilterProp="children"
                mode="multiple"
                labelInValue
              >
                {
                  subcategories.map(({ node }) => <Option key={node.id} value={node.id}>{node.name}</Option>)
                }
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Image"
              extra="Make sure height is 408px"
            >
              <Space>
                {adjacentBanner.imageUrl && <img alt="" src={adjacentBanner.imageUrl} width="120" />}
                <FormItem
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e && e.fileList;
                  }}
                  rules={[
                    { required: !adjacentBanner.imageUrl, message: 'required' },
                    { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) }
                  ]}
                >
                  <Upload
                    accept="image/gif,image/png,image/jpeg"
                    beforeUpload={() => false}
                    listType="picture"
                  >
                    <Button>
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                </FormItem>
              </Space>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Link"
              name="link"
              initialValue={get(adjacentBanner, 'link')}
            >
              <Input placeholder="URL" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Placement"
              name="placement"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(adjacentBanner, 'placement', 'B')}
              extra="Place Banner before or after product"
            >
              <Select placeholder="Placement">
                <Option value='B'>Before</Option>
                <Option value='A'>After</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name="position"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(adjacentBanner, 'position', 0.0)}
            >
              <InputNumber placeholder="Position" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={adjacentBanner.startDate ? moment(adjacentBanner.startDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={adjacentBanner.endDate ? moment(adjacentBanner.endDate) : null}
              help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
            >
              <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(adjacentBanner, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          {adjacentBanner.id && (
            <TabPane tab="History" key="history">
              <AdjacentBannerHistory adjacentBanner={adjacentBanner} viewer={viewer} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(AdjacentBannerForm, {
  viewer: graphql`
    fragment AdjacentBannerForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""}
    ) {
      id
      ...SelectProduct_viewer
      ...AdjacentBannerHistory_viewer
      categories(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
