import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { RemoveBonusMutation, UpdateBonusMutation } from './mutations';
import BonusForm from './BonusForm';

class BonusView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      bonuses: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      onProducts: formValues.onProducts.map(p => p.key),
      stealthMode: !!formValues.stealthMode,
      products: formValues.p.map((p) => ({ ...p, productId: p.productId.key })),
      images: formValues.images.map((i) => i.id ? { id: i.id, file: i.file } : { file: i.file }),
    };
    delete values.p;

    const uploadables = {};
    get(values, 'images', []).forEach((image, i) => {
      const { file } = image;
      if (file) {
        const name = `image${i}`;
        values.images[i].file = name;
        uploadables[name] = file[0].originFileObj;
      }
    });

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateBonusMutation.commit(mutation);
  }

  remove = (bonus) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: bonus.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/promotion/bonus');
      },
    };
    RemoveBonusMutation.commit(mutation);
  }


  render() {
    const { match, viewer } = this.props;
    const bonus = get(viewer, 'bonuses.edges[0].node', {});

    return (
      <div>
        <Helmet title={bonus.name} />
        <h1>{bonus.name}</h1>
        <BonusForm
          key={bonus.id}
          match={match}
          viewer={viewer}
          bonus={bonus}
          remove={this.remove}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(BonusView, {
  viewer: graphql`
    fragment BonusView_viewer on Admin {
      id
      bonuses(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
            stealthMode
            quantityRequired
            onProducts(first: 999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            products(first: 999) {
              edges {
                node {
                  product {
                    id
                    name
                    status
                    mainImage {
                      id
                      url
                    }
                  }
                  price
                  quantity
                  optional
                }
              }
            }
            images (first: 999) {
              edges {
                node {
                  id
                  imageUrl
                }
              }
            }
            labels (first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...BonusHistory_bonus
          }
        }
      }
      ...BonusForm_viewer
    }
  `,
});
