import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { AutoComplete, Button, Form, Input, message, Select } from 'antd';
import {
  GetAddressDetailMutation,
  SearchAddressMutation,
  SearchPostcodeSuburbMutation,
  UpdateCustomerAddressMutation,
} from './mutations';
import { getCountry, renderRegion } from '../form';

const { Item: FormItem } = Form;
const { Option } = Select;


const renderOption = function renderOption(propName, item) {
  const label = [item.location, item.postcode].join(" ").trim();

  return (
    <Option key={item.id} label={item[propName]} item={item}>
      {label}
    </Option>
  );
};

class AddressForm extends React.Component {
  static propTypes = {
    address: PropTypes.shape({
      city: PropTypes.string,
      postcode: PropTypes.string,
    }),
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    updateAddress: PropTypes.func.isRequired,
    updateMutation: PropTypes.shape({
      commit: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    address: {},
    updateMutation: UpdateCustomerAddressMutation,
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      options: [], // used for NZ
      dataSource: [],
      city: this.props.address.city,
      postcode: this.props.address.postcode,
    };
  }

  onSearch = (v) => {
    const query = v.trim();

    if (query.length >= 4) {
      SearchAddressMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { query } },
        onCompleted: (resp) => {
          const options = get(resp, 'searchAddress.result', []).map((o) => ({
            addressid: o.id,
            value: o.fullAddress,
            label: <span style={{ fontSize: '13px'}}>{o.fullAddress}</span>,
          }));

          this.setState({ options });
        },
      });
    }
  }

  onSelect = (value, option) => {
    const { addressid: id } = option

    GetAddressDetailMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id } },
      onCompleted: (resp) => {
        const result = get(resp, 'getAddressDetail.result') || {};

        if (!isEmpty(result)) {
          this.formRef.current.setFieldsValue({
            'street': result.street,
            'city': result.suburb,
            'postcode': result.postcode,
            'region': result.city,
          });
        }
      },
    });
  }

  handleAddressSelect = (value, option) => {
    const selection = option.props.item;
    const city = selection.location;
    const { postcode } = selection;

    this.formRef.current.setFieldsValue({
      city,
      postcode,
    });

    this.setState({
      city,
      postcode,
    });
  }

  handleSearch = (value) => {
    if (value.length < 3) return;

    SearchPostcodeSuburbMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { query: value } },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const { result } = resp.searchPostcodeSuburb;
        if (result && result.localities) {
          this.setState({ dataSource: result.localities.locality });
        } else {
          this.setState({ dataSource: [] });
        }
      },
    });
  }

  handleSubmit = (values) => {
    values.city = this.state.city;
    values.postcode = this.state.postcode;
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success('Saved');
        this.props.updateAddress();
        this.formRef.current.resetFields();
      },
    };
    this.props.updateMutation.commit(mutation);
  }

  renderStreet = () => {
    const placeholder = "Street";
    const { options } = this.state;

    return window.location.host.includes('co.nz') ? (
      <AutoComplete
        allowClear
        placeholder={placeholder}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
        options={options}
      />
    ) : (
      <Input placeholder={placeholder} />
    )
  }

  renderCity = (dataSource) => {
    const placeholder = "Suburb";

    return window.location.host.includes('co.nz') ? (
      <Input placeholder={placeholder} />
    ) : (
      <AutoComplete
        allowClear
        dataSource={dataSource.map(renderOption.bind(this, 'location'))}
        onSearch={this.handleSearch}
        onSelect={this.handleAddressSelect}
        style={{ width: '100%' }}
        placeholder={placeholder}
      />
    )
  }

  renderPostcode = (dataSource) => {
    const placeholder = "Postcode";

    return window.location.host.includes('co.nz') ? (
      <Input placeholder={placeholder} />
    ) : (
      <AutoComplete
        allowClear
        dataSource={dataSource.map(renderOption.bind(this, 'postcode'))}
        onSearch={this.handleSearch}
        onSelect={this.handleAddressSelect}
        style={{ width: '100%' }}
        placeholder={placeholder}
      />
    )
  }

  renderRegion = () => renderRegion.call(this)

  render() {
    const { address } = this.props;
    const { dataSource } = this.state;

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <FormItem
          name="id"
          initialValue={get(address, 'id')}
          hidden
        >
          <Input />
        </FormItem>
        <FormItem
          name="lastname"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'lastname', '')}
          hasFeedback

        >
          <Input placeholder="Last Name" />
        </FormItem>

        <FormItem
          name="firstname"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'firstname', '')}
          hasFeedback
        >
          <Input placeholder="First Name" />
        </FormItem>

        <FormItem
          name="telephone"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'telephone', '')}
          hasFeedback
        >
          <Input placeholder="Telephone" />
        </FormItem>

        <FormItem
          name="street"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'street', '')}
          hasFeedback
        >
          {this.renderStreet()}
        </FormItem>

        <FormItem
          name="city"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'city', '')}
          hasFeedback
        >
          {this.renderCity(dataSource)}
        </FormItem>

        <FormItem
          name="postcode"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'postcode', '')}
          hasFeedback
        >
          {this.renderPostcode(dataSource)}
        </FormItem>
        <FormItem
          name="region"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={get(address, 'region')}
          hasFeedback
        >
          {this.renderRegion()}
        </FormItem>

        <FormItem
          name="country"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={getCountry(address)}
          hasFeedback
        >
          <Select placeholder="Country" disabled>
            <Option value="AU">Australia</Option>
            <Option value="NZ">New Zealand</Option>
          </Select>
        </FormItem>
        <FormItem
          name="company"
          rules={[{ required: false, message: 'Required' }]}
          initialValue={get(address, 'company', '')}
          hasFeedback
        >
          <Input placeholder="Company" />
        </FormItem>

        <FormItem
          name="fax"
          rules={[{ required: false, message: 'Required' }]}
          initialValue={get(address, 'fax', '')}
          hasFeedback
        >
          <Input placeholder="Fax" />
        </FormItem>

        <Button type="primary" htmlType="submit" size="large">Submit</Button>
      </Form>
    );
  }
}
export default createFragmentContainer(AddressForm, {
  viewer: graphql`
    fragment AddressForm_viewer on Admin {
      id
    }
 `,
});
