import React from 'react';
import PropTypes from 'prop-types';

const isUrlValid = (url) => {
  if (!url) {
    return false;
  }

  if (url.includes('http')) {
    return true;
  }

  return false;
}

export default class Shipment extends React.Component {
  static propTypes = {
    shipment: PropTypes.shape({
      tracking: PropTypes.shape({
        trackingUrl: PropTypes.string
      }),
      location: PropTypes.string,
      status: PropTypes.string
    }).isRequired,
  }

  render() {
    const { shipment } = this.props;
    const { location, status, tracking } = shipment || {};
    const { trackingUrl } = tracking || {};

    return (
      <div>
        {isUrlValid(trackingUrl) && (
          <a href={trackingUrl} style={{color: '#1a0dab'}} target="_blank" rel="noopener noreferrer">
            Track & Trace (Number: {tracking.trackingNumber} By {tracking.carrier})
          </a>
        )}

        {trackingUrl !== 'N/A' && location && ` (Shipped from ${location})`}

        {!status && trackingUrl === 'N/A' && location && ` (Being picked from ${location})`}
        {status && <span>{status}</span>}
      </div>
    )
  }
}
