/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveBrandInput = {|
  id: string
|};
export type RemoveBrandMutationVariables = {|
  input: RemoveBrandInput
|};
export type RemoveBrandMutationResponse = {|
  +removeBrand: ?{|
    +brand: ?{|
      +id: string
    |}
  |}
|};
export type RemoveBrandMutation = {|
  variables: RemoveBrandMutationVariables,
  response: RemoveBrandMutationResponse,
|};
*/


/*
mutation RemoveBrandMutation(
  $input: RemoveBrandInput!
) {
  removeBrand(input: $input) {
    brand {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveBrandPayload",
    "kind": "LinkedField",
    "name": "removeBrand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Brand",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveBrandMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveBrandMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80fdd525d145d549ec96b5c8a41d6fae",
    "id": null,
    "metadata": {},
    "name": "RemoveBrandMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveBrandMutation(\n  $input: RemoveBrandInput!\n) {\n  removeBrand(input: $input) {\n    brand {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fb3985c2b63a7b257a3a2cc5cefb573';

module.exports = node;
