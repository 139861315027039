/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesTrend_viewer$ref: FragmentReference;
declare export opaque type SalesTrend_viewer$fragmentType: SalesTrend_viewer$ref;
export type SalesTrend_viewer = {|
  +salesTrend: ?any,
  +$refType: SalesTrend_viewer$ref,
|};
export type SalesTrend_viewer$data = SalesTrend_viewer;
export type SalesTrend_viewer$key = {
  +$data?: SalesTrend_viewer$data,
  +$fragmentRefs: SalesTrend_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterBy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesTrend_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        }
      ],
      "kind": "ScalarField",
      "name": "salesTrend",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0070cbd0361695ba7a815bfbee38561d';

module.exports = node;
