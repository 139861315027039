import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Affix, Button, Form, Input, InputNumber, message, Popconfirm, Select, Switch, Tabs, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Editor, FormBase, fileValidator, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';
import { UrlRefresher } from '~/components/url';
import { RemoveBrandMutation } from './mutations';
import BrandHistory from './BrandHistory';
import { CopyToNzBtn, Loading } from '../product/WebsiteRef';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

class BrandForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
    }).isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string
    }),
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    entity: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleDelete = () => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: {id: this.props.entity.id} },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        message.success('Deleted');
        this.props.router.go(-1);
      },
    };
    RemoveBrandMutation.commit(mutation);
  }

  render() {
    const { match, entity: brand, viewer } = this.props;

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()} style={{ marginRight: '10px' }}>Save</Button>
            {brand.id && (
              <React.Suspense fallback={<Loading />}>
                <CopyToNzBtn viewer={viewer} entity={brand} />
              </React.Suspense>
            )}
            {brand.id && (
            <Popconfirm title="Delete this brand?" onConfirm={this.handleDelete} okText="Yes" cancelText="No">
              <Button type="danger" htmlType="submit" disabled={this.shouldDisableBtn()}>Delete</Button>
            </Popconfirm>
            )}
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={brand.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true, message: 'required' }]}
              initialValue={brand.name}
            >
              <Input
                placeholder="Name"
                onChange={(e) => {
                  if (!brand.id) {
                    const urlSlug = e.target.value;
                    this.formRef.current.setFieldsValue({ urlSlug });
                  }
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="URL"
            >
              <div>
                <Input placeholder="URL" value={get(brand, 'urlSlug')} readOnly />
                <UrlRefresher entity={brand} viewer={viewer} />
              </div>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Image"
              required
            >
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                {brand.imageUrl && <img alt="" src={brand.imageUrl} width="120" />}

                <FormItem
                  name="image"
                  rules={[
                    { required: !brand.imageUrl, message: 'required' },
                    { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e.fileList;
                  }}
                  style={{ marginBottom: '0px' }}
                >
                  <Upload
                    beforeUpload={() => false}
                    accept="image/gif,image/png,image/jpeg"
                    customRequest={() => {}}
                    listType="picture"
                  >
                    <Button>
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                </FormItem>
              </div>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Private Label"
              name="privateLabel"
              valuePropName="checked"
              initialValue={get(brand, 'privateLabel')}
              extra="Is this a private label?"
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Search Score Multiplier"
              name="searchScoreMultiplier"
              initialValue={get(brand, 'searchScoreMultiplier', 1.0)}
              extra="1.1 means 10% increase in scoring. For search page only. Don't be crazy!"
            >
              <InputNumber />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Priority Shipping"
              name="expressShipping"
              valuePropName="checked"
              initialValue={get(brand, 'expressShipping', true)}
              extra="Enable Priority shipping on all products under this brand"
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Title"
              name="metaTitle"
              initialValue={brand.metaTitle}
            >
              <TextArea rows={2} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Description"
              name="metaDescription"
              initialValue={brand.metaDescription}
            >
              <TextArea rows={3} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description"
            >
              <Editor
                name="description"
                editorState={brand.description}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description 2"
            >
              <Editor
                name="description2"
                editorState={brand.description2}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[
                { required: true, message: 'required' }
              ]}
              initialValue={get(brand, 'status') ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          {brand.id && (
            <TabPane tab="History" key="history">
              <BrandHistory viewer={viewer} brand={brand} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(BrandForm, {
  viewer: graphql`
    fragment BrandForm_viewer on Admin {
      id
      ...BrandHistory_viewer
      ...UrlRefresher_viewer
    }
  `,
});
