import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button } from 'antd';
import { UpdateUrlSlugMutation } from './mutations';

class UrlRefresher extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    entity: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    viewer: PropTypes.shape({
    }).isRequired,
    rewrite: PropTypes.bool,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    rewrite: false,
    children: undefined,
    disabled: false,
  }

  refreshUrl = (entity) => {
    const { id } = entity;
    const { viewer, rewrite } = this.props;
    const username = get(viewer, 'username');

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { username, id, rewrite } },
      viewer: this.props.viewer
    };

    UpdateUrlSlugMutation.commit(mutation);
  }

  render() {
    const { children, entity, disabled } = this.props;

    if (!entity) {
      return null;
    }

    return (
      <Button onClick={() => this.refreshUrl(entity)} disabled={disabled}>
        {!children && "Refresh"}
        {children && children}
      </Button>
);
  }
}

export default createFragmentContainer(UrlRefresher, {
  viewer: graphql`
    fragment UrlRefresher_viewer on Admin {
      id
      username
    }
  `,
});
