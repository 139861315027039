import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateSecurityMutation($input: UpdateSecurityInput!) {
    updateSecurity(input:$input) {
      securities
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

