import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';
import { message } from 'antd';

import { RemoveCommonPromoMutation, UpdateCommonPromoMutation } from './mutations';
import CommonPromoForm from './CommonPromoForm';

class CommonPromoView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      commonPromos: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      images: formValues.images.map((i) => i.id ? { id: i.id, file: i.file } : { file: i.file }),
    };

    if (values.type === null) {
      if (!isEmpty(values.brandIds)) {
        values.excludeProducts = formValues.excludeProducts.map(p => p.key);
      } else {
        values.onProducts = formValues.onProducts.map(p => p.key);
      }
    }

    const uploadables = {};
    get(values, 'images', []).forEach((image, i) => {
      const { file } = image;
      if (file) {
        const name = `image${i}`;

        values.images[i].file = name;
        uploadables[name] = file[0].originFileObj;
      }
    });

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateCommonPromoMutation.commit(mutation);
  }

  remove = (commonPromo) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: commonPromo.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/promotion/common-promo');
      },
    };
    RemoveCommonPromoMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const commonPromo = get(viewer, 'commonPromos.edges[0].node', {});

    return (
      <div>
        <Helmet title={commonPromo.name} />
        <h1>{commonPromo.name}</h1>
        <CommonPromoForm
          key={commonPromo.id}
          match={match}
          viewer={viewer}
          commonPromo={commonPromo}
          remove={this.remove}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CommonPromoView, {
  viewer: graphql`
    fragment CommonPromoView_viewer on Admin {
      id
      commonPromos(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
            brandIds
            freebieId
            type
            excludeProducts(first: 99999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            onProducts(first: 99999) {
              edges {
                node {
                  id
                  name
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            images (first: 999) {
              edges {
                node {
                  id
                  imageUrl
                }
              }
            }
            ...CommonPromoHistory_commonPromo
          }
        }
      }
      ...CommonPromoForm_viewer
    }
  `,
});
