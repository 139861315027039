/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAdjacentBannerInput = {|
  categoryIds?: ?$ReadOnlyArray<?string>,
  endDate: string,
  image: any,
  link?: ?string,
  name: string,
  placement?: ?string,
  position?: ?number,
  productId?: ?string,
  startDate: string,
  status: boolean,
  subcategoryIds?: ?$ReadOnlyArray<?string>,
|};
export type AddAdjacentBannerMutationVariables = {|
  input: AddAdjacentBannerInput
|};
export type AddAdjacentBannerMutationResponse = {|
  +addAdjacentBanner: ?{|
    +adjacentBannerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +product: ?{|
          +id: string,
          +name: ?string,
          +mainImage: ?{|
            +id: string,
            +url: ?string,
          |},
        |},
        +link: ?string,
        +imageUrl: ?string,
        +placement: ?string,
        +position: ?number,
        +startDate: ?string,
        +endDate: ?string,
        +status: ?boolean,
        +categories: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +subcategories: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type AddAdjacentBannerMutation = {|
  variables: AddAdjacentBannerMutationVariables,
  response: AddAdjacentBannerMutationResponse,
|};
*/


/*
mutation AddAdjacentBannerMutation(
  $input: AddAdjacentBannerInput!
) {
  addAdjacentBanner(input: $input) {
    adjacentBannerEdge {
      cursor
      node {
        id
        name
        product {
          id
          name
          mainImage {
            id
            url
          }
        }
        link
        imageUrl
        placement
        position
        startDate
        endDate
        status
        categories(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories(first: 9999) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAdjacentBannerPayload",
    "kind": "LinkedField",
    "name": "addAdjacentBanner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdjacentBannerEdge",
        "kind": "LinkedField",
        "name": "adjacentBannerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdjacentBanner",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductImage",
                    "kind": "LinkedField",
                    "name": "mainImage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "placement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 999
                  }
                ],
                "concreteType": "CategoryConnection",
                "kind": "LinkedField",
                "name": "categories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CategoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Category",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "categories(first:999)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 9999
                  }
                ],
                "concreteType": "SubcategoryConnection",
                "kind": "LinkedField",
                "name": "subcategories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubcategoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Subcategory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "subcategories(first:9999)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAdjacentBannerMutation",
    "selections": (v4/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAdjacentBannerMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "83ec0a9042daf9f67a193ea22e3ad337",
    "id": null,
    "metadata": {},
    "name": "AddAdjacentBannerMutation",
    "operationKind": "mutation",
    "text": "mutation AddAdjacentBannerMutation(\n  $input: AddAdjacentBannerInput!\n) {\n  addAdjacentBanner(input: $input) {\n    adjacentBannerEdge {\n      cursor\n      node {\n        id\n        name\n        product {\n          id\n          name\n          mainImage {\n            id\n            url\n          }\n        }\n        link\n        imageUrl\n        placement\n        position\n        startDate\n        endDate\n        status\n        categories(first: 999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        subcategories(first: 9999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb4aaf43d09d1cabf31824d5e360c33f';

module.exports = node;
