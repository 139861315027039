import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { toGlobalId } from '~/helper';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/synonym"
    getComponent={() => (
      import(/* webpackChunkName: "SynonymList" */'./SynonymList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_SynonymList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...SynonymList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
    })}
  >
    <Route />

    <Route
      path="/new"
      getComponent={() => (
        import(/* webpackChunkName: "SynonymNew" */'./SynonymNew').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SynonymNew_Query {
          viewer {
            ...SynonymNew_viewer
          }
        }
      `}
    />

    <Route
      path="/:id"
      getComponent={() => (
        import(/* webpackChunkName: "SynonymView" */'./SynonymView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SynonymView_Query ($ids: [ID]) {
          viewer {
            ...SynonymView_viewer
          }
        }
      `}
      prepareVariables={(params) => {
        const globalId = toGlobalId('Synonym', params.id);
        return { ids: [globalId] };
      }}
    />
  </Route>
);

export default routes;
