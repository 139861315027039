import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

/*
 * Takes a iso8601 datetime and convert to Get by date message.
 */
const GetByDate = (props) => {
  const { date, shippingMethod } = props;

  if (!date) {
    return null;
  }

  const getByDate = moment(date);

  return (
    <div style={{ color: 'green' }}>
      Get it by <b>{getByDate.format("MMM DD")}</b> {shippingMethod ? (<>{shippingMethod}</>) : null}
    </div>
  )
}

GetByDate.propTypes = {
  date: PropTypes.string,
  shippingMethod: PropTypes.string,
};

GetByDate.defaultProps = {
  date: null,
  shippingMethod: null,
}

export default GetByDate;
