import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { RemoveAdjacentBannerMutation, UpdateAdjacentBannerMutation } from './mutations';
import AdjacentBannerForm from './AdjacentBannerForm';


class AdjacentBannerView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      adjacentBanners: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const uploadables = {};
    ['image'].forEach(key => {
      if (values[key]) {
        const file = values[key][0];
        values[key] = key;
        uploadables[key] = file.originFileObj;
      }
    });

    values.productId = values.productId.key;
    values.categoryIds = values.categoryIds.map(p => p.key);
    values.subcategoryIds = values.subcategoryIds.map(p => p.key);

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateAdjacentBannerMutation.commit(mutation);
  }

  remove = (banner) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: banner.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/promotion/adjacent-banner');
      },
    };
    RemoveAdjacentBannerMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const banner = get(viewer, 'adjacentBanners.edges[0].node', {});

    return (
      <div>
        <Helmet title={banner.name} />
        <h1>{banner.name}</h1>
        <AdjacentBannerForm
          key={banner.id}
          match={match}
          viewer={viewer}
          adjacentBanner={banner}
          remove={this.remove}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(AdjacentBannerView, {
  viewer: graphql`
    fragment AdjacentBannerView_viewer on Admin {
      id
      adjacentBanners(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            product {
              id
              name
              mainImage {
                id
                url
              }
            }
            link
            imageUrl
            placement
            position
            startDate
            endDate
            status
            categories(first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            subcategories(first: 9999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...AdjacentBannerHistory_adjacentBanner
          }
        }
      }
      ...AdjacentBannerForm_viewer
    }
  `,
});
