/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CopyProductRefetchQueryVariables = {|
  isBonus?: ?boolean,
  isRedemption?: ?boolean,
  isCredit?: ?boolean,
  isFreebie?: ?boolean,
  isCategory?: ?boolean,
|};
export type CopyProductRefetchQueryResponse = {|
  +viewer: ?{|
    +copyBonus?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +copyRedemption?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +copyCredit?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +copyFreebie?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +copyCategory?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |}
|};
export type CopyProductRefetchQuery = {|
  variables: CopyProductRefetchQueryVariables,
  response: CopyProductRefetchQueryResponse,
|};
*/


/*
query CopyProductRefetchQuery(
  $isBonus: Boolean
  $isRedemption: Boolean
  $isCredit: Boolean
  $isFreebie: Boolean
  $isCategory: Boolean
) {
  viewer {
    copyBonus: bonuses(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isBonus) {
      edges {
        node {
          id
          name
        }
      }
    }
    copyRedemption: redemptions(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isRedemption) {
      edges {
        node {
          id
          name
        }
      }
    }
    copyCredit: credits(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isCredit) {
      edges {
        node {
          id
          name
        }
      }
    }
    copyFreebie: freebies(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isFreebie) {
      edges {
        node {
          id
          name
        }
      }
    }
    copyCategory: categories(first: 9999, orderBy: {field: "id", direction: "desc"}) @include(if: $isCategory) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isBonus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCategory"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCredit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isFreebie"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRedemption"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "desc",
      "field": "id"
    }
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v8 = {
  "condition": "isBonus",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "copyBonus",
      "args": (v5/*: any*/),
      "concreteType": "BonusConnection",
      "kind": "LinkedField",
      "name": "bonuses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BonusEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Bonus",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "bonuses(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
    }
  ]
},
v9 = {
  "condition": "isRedemption",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "copyRedemption",
      "args": (v5/*: any*/),
      "concreteType": "RedemptionConnection",
      "kind": "LinkedField",
      "name": "redemptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RedemptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Redemption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "redemptions(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
    }
  ]
},
v10 = {
  "condition": "isCredit",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "copyCredit",
      "args": (v5/*: any*/),
      "concreteType": "CreditConnection",
      "kind": "LinkedField",
      "name": "credits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Credit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "credits(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
    }
  ]
},
v11 = {
  "condition": "isFreebie",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "copyFreebie",
      "args": (v5/*: any*/),
      "concreteType": "FreebieConnection",
      "kind": "LinkedField",
      "name": "freebies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FreebieEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Freebie",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "freebies(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
    }
  ]
},
v12 = {
  "condition": "isCategory",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "copyCategory",
      "args": (v5/*: any*/),
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "categories(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CopyProductRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CopyProductRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abe9b17badbd22e8a94b986ea13be40b",
    "id": null,
    "metadata": {},
    "name": "CopyProductRefetchQuery",
    "operationKind": "query",
    "text": "query CopyProductRefetchQuery(\n  $isBonus: Boolean\n  $isRedemption: Boolean\n  $isCredit: Boolean\n  $isFreebie: Boolean\n  $isCategory: Boolean\n) {\n  viewer {\n    copyBonus: bonuses(first: 9999, orderBy: {field: \"id\", direction: \"desc\"}) @include(if: $isBonus) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    copyRedemption: redemptions(first: 9999, orderBy: {field: \"id\", direction: \"desc\"}) @include(if: $isRedemption) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    copyCredit: credits(first: 9999, orderBy: {field: \"id\", direction: \"desc\"}) @include(if: $isCredit) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    copyFreebie: freebies(first: 9999, orderBy: {field: \"id\", direction: \"desc\"}) @include(if: $isFreebie) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    copyCategory: categories(first: 9999, orderBy: {field: \"id\", direction: \"desc\"}) @include(if: $isCategory) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9dccdb22d8303aaf2833d436fb5fc70';

module.exports = node;
