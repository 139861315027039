import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../helper';
import CategoryForm from './CategoryForm';
import { AddCategoryMutation } from './mutations';

class CategoryNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      scheduleAssignments: formValues.scheduleAssignments.filter(s => s.products.length > 0).map(s => ({
        datetime: s.datetime,
        products: s.products.map(b => ({ productId: b.key })),
      })),
      scheduleRemovals: formValues.scheduleRemovals.filter(s => s.products.length > 0).map(s => ({
        datetime: s.datetime,
        products: s.products.map(b => ({ productId: b.key })),
      })),
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/category/${fromGlobalId(resp.addCategory.categoryEdge.node.id).id}`;
      },
    };

    AddCategoryMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Category" />

        <h1>New Category</h1>
        <CategoryForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(CategoryNew, {
  viewer: graphql`
    fragment CategoryNew_viewer on Admin {
      ...CategoryForm_viewer
      id
    }
  `,
});
