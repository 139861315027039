import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateBrandMutation } from './mutations';
import BrandForm from './BrandForm';

class BrandView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const uploadables = {};
    if (values.image) {
      const file = values.image[0];
      values.image = 'image';
      uploadables.image = file.originFileObj;
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };

    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }

    UpdateBrandMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const brand = get(viewer, 'brands.edges[0].node', {});

    return (
      <div>
        <Helmet title={brand.name} />
        <h1>{brand.name}</h1>
        <BrandForm
          key={brand.id}
          match={match}
          viewer={viewer}
          router={this.props.router}
          entity={brand}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(BrandView, {
  viewer: graphql`
    fragment BrandView_viewer on Admin {
      ...BrandForm_viewer
      brands(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            imageUrl
            urlSlug
            status
            metaTitle
            metaDescription
            description
            description2
            expressShipping
            privateLabel
            searchScoreMultiplier
            ...BrandHistory_brand
            ...WebsiteRef_brand
          }
        }
      }
    }
  `,
});
