import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateAlertMutation } from './mutations';
import AlertForm from './AlertForm';

class AlertView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      alerts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateAlertMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const a = get(viewer, 'alerts.edges[0].node', {});

    return (
      <div>
        <Helmet title={a.name} />
        <h1>{a.name}</h1>
        <AlertForm
          key={a.id}
          match={match}
          viewer={viewer}
          alert={a}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(AlertView, {
  viewer: graphql`
    fragment AlertView_viewer on Admin {
      id
      alerts(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            content
            startDate
            endDate
            status
          }
        }
      }
      ...AlertForm_viewer
    }
  `,
});
