/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveProductFileInput = {|
  id: string
|};
export type RemoveProductFileMutationVariables = {|
  input: RemoveProductFileInput
|};
export type RemoveProductFileMutationResponse = {|
  +removeProductFile: ?{|
    +removedProductFileId: ?string
  |}
|};
export type RemoveProductFileMutation = {|
  variables: RemoveProductFileMutationVariables,
  response: RemoveProductFileMutationResponse,
|};
*/


/*
mutation RemoveProductFileMutation(
  $input: RemoveProductFileInput!
) {
  removeProductFile(input: $input) {
    removedProductFileId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveProductFilePayload",
    "kind": "LinkedField",
    "name": "removeProductFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedProductFileId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveProductFileMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveProductFileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0d292820c2f8b940151cd62f19bd48d4",
    "id": null,
    "metadata": {},
    "name": "RemoveProductFileMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveProductFileMutation(\n  $input: RemoveProductFileInput!\n) {\n  removeProductFile(input: $input) {\n    removedProductFileId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12d7881a5aebadb973ad0a7b120c7dc4';

module.exports = node;
