import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../helper';
import { AddStoreMutation } from './mutations';

import StoreForm from './StoreForm';

class StoreNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;

    if (values.excludeBulkyGood == null) {
      values.excludeBulkyGood = false;
    }

    const keys = Object.keys(values.hours);

    for (let i = 0, l = keys.length; i < l; i += 1) {
      const day = keys[i];
      values.hours[`${day}_open`] = values.hours[day].open ? values.hours[day].open.format('HH:mm:ss') : null;
      values.hours[`${day}_close`] = values.hours[day].close ? values.hours[day].close.format('HH:mm:ss') : null;
      delete values.hours[day];
    }

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/store/${fromGlobalId(resp.addStore.storeEdge.node.id).id}`;
      },
    };

    AddStoreMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Store" />

        <h1>New Store</h1>
        <StoreForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(StoreNew, {
  viewer: graphql`
    fragment StoreNew_viewer on Admin {
      id
      ...StoreForm_viewer
    }
  `,
});
