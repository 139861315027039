import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation UpdateGiftCardMutation($input: UpdateGiftCardInput!) {
    updateGiftCard(input:$input) {
      giftCard {
        name
        amount
        expiryMonths
        products(first: 99) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        ...GiftCardHistory_giftCard
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
