/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignProductsToSubcategoriesInput = {|
  productIds: $ReadOnlyArray<?string>,
  subcategoryIds: $ReadOnlyArray<?string>,
|};
export type AssignProductsToSubcategoriesMutationVariables = {|
  input: AssignProductsToSubcategoriesInput
|};
export type AssignProductsToSubcategoriesMutationResponse = {|
  +assignProductsToSubcategories: ?{|
    +subcategoryIds: ?$ReadOnlyArray<?string>
  |}
|};
export type AssignProductsToSubcategoriesMutation = {|
  variables: AssignProductsToSubcategoriesMutationVariables,
  response: AssignProductsToSubcategoriesMutationResponse,
|};
*/


/*
mutation AssignProductsToSubcategoriesMutation(
  $input: AssignProductsToSubcategoriesInput!
) {
  assignProductsToSubcategories(input: $input) {
    subcategoryIds
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssignProductsToSubcategoriesPayload",
    "kind": "LinkedField",
    "name": "assignProductsToSubcategories",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subcategoryIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignProductsToSubcategoriesMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignProductsToSubcategoriesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "73a0ea49a338cb8c8b1c69658ede325b",
    "id": null,
    "metadata": {},
    "name": "AssignProductsToSubcategoriesMutation",
    "operationKind": "mutation",
    "text": "mutation AssignProductsToSubcategoriesMutation(\n  $input: AssignProductsToSubcategoriesInput!\n) {\n  assignProductsToSubcategories(input: $input) {\n    subcategoryIds\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fd64935c428f9487ab32e1077c28574';

module.exports = node;
