/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PurchaseLimitForm_viewer$ref: FragmentReference;
declare export opaque type PurchaseLimitForm_viewer$fragmentType: PurchaseLimitForm_viewer$ref;
export type PurchaseLimitForm_viewer = {|
  +id: string,
  +$fragmentRefs: SelectProduct_viewer$ref,
  +$refType: PurchaseLimitForm_viewer$ref,
|};
export type PurchaseLimitForm_viewer$data = PurchaseLimitForm_viewer;
export type PurchaseLimitForm_viewer$key = {
  +$data?: PurchaseLimitForm_viewer$data,
  +$fragmentRefs: PurchaseLimitForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseLimitForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd9b551705116702aa9973777753fbc80';

module.exports = node;
