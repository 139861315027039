/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AttributeHistory_attribute$ref = any;
type WebsiteRef_attribute$ref = any;
export type RenameAttributeOptionInput = {|
  id: string,
  newOption: string,
  option: string,
|};
export type RenameAttributeOptionMutationVariables = {|
  input: RenameAttributeOptionInput
|};
export type RenameAttributeOptionMutationResponse = {|
  +renameAttributeOption: ?{|
    +attribute: ?{|
      +name: ?string,
      +code: ?string,
      +options: ?any,
      +dictates: ?any,
      +multi: ?boolean,
      +visibleToFrontend: ?boolean,
      +images: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +imageUrl: ?string,
            +option: ?string,
            +brands: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                |}
              |}>
            |},
            +categories: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                |}
              |}>
            |},
            +subcategories: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                |}
              |}>
            |},
          |}
        |}>
      |},
      +$fragmentRefs: AttributeHistory_attribute$ref & WebsiteRef_attribute$ref,
    |}
  |}
|};
export type RenameAttributeOptionMutation = {|
  variables: RenameAttributeOptionMutationVariables,
  response: RenameAttributeOptionMutationResponse,
|};
*/


/*
mutation RenameAttributeOptionMutation(
  $input: RenameAttributeOptionInput!
) {
  renameAttributeOption(input: $input) {
    attribute {
      name
      code
      options
      dictates
      multi
      visibleToFrontend
      images(first: 9999) {
        edges {
          node {
            id
            imageUrl
            option
            brands(first: 9999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            categories(first: 9999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            subcategories(first: 9999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...AttributeHistory_attribute
      ...WebsiteRef_attribute
      id
    }
  }
}

fragment AttributeHistory_attribute on Attribute {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment WebsiteRef_attribute on Attribute {
  websiteReference {
    id
    country
  }
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "options",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dictates",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multi",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibleToFrontend",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": (v8/*: any*/),
  "concreteType": "AttributeImageConnection",
  "kind": "LinkedField",
  "name": "images",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AttributeImageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AttributeImage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "option",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v8/*: any*/),
              "concreteType": "BrandConnection",
              "kind": "LinkedField",
              "name": "brands",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BrandEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Brand",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "brands(first:9999)"
            },
            {
              "alias": null,
              "args": (v8/*: any*/),
              "concreteType": "CategoryConnection",
              "kind": "LinkedField",
              "name": "categories",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CategoryEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Category",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "categories(first:9999)"
            },
            {
              "alias": null,
              "args": (v8/*: any*/),
              "concreteType": "SubcategoryConnection",
              "kind": "LinkedField",
              "name": "subcategories",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SubcategoryEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Subcategory",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "subcategories(first:9999)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "images(first:9999)"
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RenameAttributeOptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameAttributeOptionPayload",
        "kind": "LinkedField",
        "name": "renameAttributeOption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Attribute",
            "kind": "LinkedField",
            "name": "attribute",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AttributeHistory_attribute"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WebsiteRef_attribute"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RenameAttributeOptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RenameAttributeOptionPayload",
        "kind": "LinkedField",
        "name": "renameAttributeOption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Attribute",
            "kind": "LinkedField",
            "name": "attribute",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "AttributeHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WebsiteReference",
                "kind": "LinkedField",
                "name": "websiteReference",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69c2f5465821a4de8c508581df46a290",
    "id": null,
    "metadata": {},
    "name": "RenameAttributeOptionMutation",
    "operationKind": "mutation",
    "text": "mutation RenameAttributeOptionMutation(\n  $input: RenameAttributeOptionInput!\n) {\n  renameAttributeOption(input: $input) {\n    attribute {\n      name\n      code\n      options\n      dictates\n      multi\n      visibleToFrontend\n      images(first: 9999) {\n        edges {\n          node {\n            id\n            imageUrl\n            option\n            brands(first: 9999) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n            categories(first: 9999) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n            subcategories(first: 9999) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n      ...AttributeHistory_attribute\n      ...WebsiteRef_attribute\n      id\n    }\n  }\n}\n\nfragment AttributeHistory_attribute on Attribute {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment WebsiteRef_attribute on Attribute {\n  websiteReference {\n    id\n    country\n  }\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2151519ab0a721d0cd6670abf26d17f';

module.exports = node;
