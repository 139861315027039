/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BonusHistory_viewer$ref = any;
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BonusForm_viewer$ref: FragmentReference;
declare export opaque type BonusForm_viewer$fragmentType: BonusForm_viewer$ref;
export type BonusForm_viewer = {|
  +$fragmentRefs: SelectProduct_viewer$ref & BonusHistory_viewer$ref,
  +$refType: BonusForm_viewer$ref,
|};
export type BonusForm_viewer$data = BonusForm_viewer;
export type BonusForm_viewer$key = {
  +$data?: BonusForm_viewer$data,
  +$fragmentRefs: BonusForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BonusForm_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusHistory_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cc904eafa15eb3ba180652b737fb6cb8';

module.exports = node;
