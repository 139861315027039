import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AssignProductsToCategoriesMutation($input: AssignProductsToCategoriesInput!) {
    assignProductsToCategories(input:$input) {
      categoryIds
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
