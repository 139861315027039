import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdatePurchaseLimitMutation } from './mutations';
import PurchaseLimitForm from './PurchaseLimitForm';


class PurchaseLimitView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      purchaseLimits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      productId: formValues.productId.key,
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };

    UpdatePurchaseLimitMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const purchaseLimit = get(viewer, 'purchaseLimits.edges[0].node', {});

    return (
      <div>
        <Helmet title={purchaseLimit.product.name} />
        <h1>{purchaseLimit.product.name}</h1>
        <PurchaseLimitForm
          key={purchaseLimit.id}
          match={match}
          viewer={viewer}
          purchaseLimit={purchaseLimit}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(PurchaseLimitView, {
  viewer: graphql`
    fragment PurchaseLimitView_viewer on Admin {
      id
      purchaseLimits(first: 1, ids: $ids) {
        edges {
          node {
            id
            startDate
            endDate
            product {
              id
              name
              sku
              mainImage {
                id
                url
              }
              status
            }
            limit
            status
          }
        }
      }
      ...PurchaseLimitForm_viewer
    }
  `,
});
