/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CommonPromoHistory_viewer$ref = any;
type SelectProduct_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommonPromoForm_viewer$ref: FragmentReference;
declare export opaque type CommonPromoForm_viewer$fragmentType: CommonPromoForm_viewer$ref;
export type CommonPromoForm_viewer = {|
  +brands: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +freebies: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +startDate: ?string,
        +endDate: ?string,
      |}
    |}>
  |},
  +$fragmentRefs: SelectProduct_viewer$ref & CommonPromoHistory_viewer$ref,
  +$refType: CommonPromoForm_viewer$ref,
|};
export type CommonPromoForm_viewer$data = CommonPromoForm_viewer;
export type CommonPromoForm_viewer$key = {
  +$data?: CommonPromoForm_viewer$data,
  +$fragmentRefs: CommonPromoForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 9999
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommonPromoForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "asc",
            "field": "name"
          }
        }
      ],
      "concreteType": "BrandConnection",
      "kind": "LinkedField",
      "name": "brands",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Brand",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "brands(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "desc",
            "field": "id"
          }
        }
      ],
      "concreteType": "FreebieConnection",
      "kind": "LinkedField",
      "name": "freebies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FreebieEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Freebie",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "freebies(first:9999,orderBy:{\"direction\":\"desc\",\"field\":\"id\"})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommonPromoHistory_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fbf585758654b18a0910cdcd6fa2841';

module.exports = node;
