import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/search-terms-report"
    getComponent={() => (
      import(/* webpackChunkName: "SearchTermsReportList" */'./SearchTermsReportList').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={graphql`
      query routes_SearchTermsReportList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
        viewer {
          ...SearchTermsReportList_viewer
        }
      }
    `}
    prepareVariables={() => ({
      count: getPageSize(),
      orderBy: { field: 'updatedAt', direction: 'desc' },
    })}
  />
);

export default routes;
