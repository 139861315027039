/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UpdatePricesSchedule_viewer$ref: FragmentReference;
declare export opaque type UpdatePricesSchedule_viewer$fragmentType: UpdatePricesSchedule_viewer$ref;
export type UpdatePricesSchedule_viewer = {|
  +id: string,
  +updatePriceSchedules: ?$ReadOnlyArray<?{|
    +id: ?string,
    +product: ?{|
      +id: string,
      +name: ?string,
      +brandId: ?string,
      +urlSlug: ?string,
    |},
    +newPrice: ?number,
    +effectiveDate: ?string,
    +user: ?string,
  |}>,
  +$refType: UpdatePricesSchedule_viewer$ref,
|};
export type UpdatePricesSchedule_viewer$data = UpdatePricesSchedule_viewer;
export type UpdatePricesSchedule_viewer$key = {
  +$data?: UpdatePricesSchedule_viewer$data,
  +$fragmentRefs: UpdatePricesSchedule_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./UpdatePricesScheduleRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "UpdatePricesSchedule_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UpdatePriceSchedule",
      "kind": "LinkedField",
      "name": "updatePriceSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brandId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "effectiveDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '96185a99f3d0cdbd0bac48cad008bbb1';

module.exports = node;
