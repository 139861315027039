import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateCustomerCreditMutation($input: UpdateCustomerCreditInput!) {
    updateCustomerCredit(input:$input) {
      customerStoreCredit {
        creditRemaining
        expiry
      }
      customer {
        ...CustomerHistory_customer
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
