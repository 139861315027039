import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import History, { addHistory, historyPropTypes } from '~/components/history';

const SalesOrderHistory = (props) => {
  const historyFn = addHistory({props, connectionKey: "SalesOrderHistory_histories"});

  // eslint-disable-next-line react/prop-types
  const { salesOrder: entity, relay } = props;

  return (
    <History relay={relay} entity={entity} addHistory={historyFn} />
  );
};

SalesOrderHistory.propTypes = historyPropTypes({entity: 'salesOrder'});

export default createRefetchContainer(
  SalesOrderHistory,
  {
    viewer: graphql`
      fragment SalesOrderHistory_viewer on Admin {
        id
      }
    `,
    salesOrder: graphql`
      fragment SalesOrderHistory_salesOrder on SalesOrder @argumentDefinitions(
        count: {type: "Int!", defaultValue: 10},
      ) {
        id
        histories(first: $count) @connection(key: "SalesOrderHistory_histories") {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              changes
              user
              insertedAt
            }
          }
        }
      }
    `,
  },
  graphql`
    query SalesOrderHistoryRefetchQuery($ids: [ID!], $count: Int!) {
      viewer {
        salesOrders(first: 1, ids: $ids) {
          edges {
            node {
              ...SalesOrderHistory_salesOrder @arguments(count: $count)
            }
          }
       }
      }
    }
  `,
);
