/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type SelectProductRefetchQueryVariables = {|
  first?: ?number,
  query?: ?string,
  orderBy?: ?OrderBy,
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
|};
export type SelectProductRefetchQueryResponse = {|
  +viewer: ?{|
    +searchProducts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +type: ?string,
          +attributes: ?any,
          +sku: ?string,
          +status: ?string,
          +mainImage: ?{|
            +id: string,
            +url: ?string,
          |},
          +configurables: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +product: ?{|
                  +id: string,
                  +name: ?string,
                  +sku: ?string,
                  +status: ?string,
                  +mainImage: ?{|
                    +id: string,
                    +url: ?string,
                  |},
                |},
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type SelectProductRefetchQuery = {|
  variables: SelectProductRefetchQueryVariables,
  response: SelectProductRefetchQueryResponse,
|};
*/


/*
query SelectProductRefetchQuery(
  $first: Int
  $query: String
  $orderBy: OrderBy
  $filterBy: [FilterBy]
) {
  viewer {
    searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filterBy: $filterBy) {
      edges {
        node {
          id
          name
          type
          attributes
          sku
          status
          mainImage {
            id
            url
          }
          configurables(first: 99) {
            edges {
              node {
                id
                product {
                  id
                  name
                  sku
                  status
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": "searchProducts",
  "args": [
    {
      "kind": "Variable",
      "name": "filterBy",
      "variableName": "filterBy"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy"
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attributes",
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 99
                }
              ],
              "concreteType": "ProductConfigurableConnection",
              "kind": "LinkedField",
              "name": "configurables",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductConfigurableEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductConfigurable",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "product",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "configurables(first:99)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectProductRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectProductRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2151d93670c039e6b253e66865d65303",
    "id": null,
    "metadata": {},
    "name": "SelectProductRefetchQuery",
    "operationKind": "query",
    "text": "query SelectProductRefetchQuery(\n  $first: Int\n  $query: String\n  $orderBy: OrderBy\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filterBy: $filterBy) {\n      edges {\n        node {\n          id\n          name\n          type\n          attributes\n          sku\n          status\n          mainImage {\n            id\n            url\n          }\n          configurables(first: 99) {\n            edges {\n              node {\n                id\n                product {\n                  id\n                  name\n                  sku\n                  status\n                  mainImage {\n                    id\n                    url\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd78c845132d8c57183d1e4b8cd67a4fc';

module.exports = node;
