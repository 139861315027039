/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePricesInput = {|
  mode?: ?string,
  prices: any,
|};
export type UpdatePricesMutationVariables = {|
  input: UpdatePricesInput
|};
export type UpdatePricesMutationResponse = {|
  +updatePrices: ?{|
    +result: ?any
  |}
|};
export type UpdatePricesMutation = {|
  variables: UpdatePricesMutationVariables,
  response: UpdatePricesMutationResponse,
|};
*/


/*
mutation UpdatePricesMutation(
  $input: UpdatePricesInput!
) {
  updatePrices(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePricesPayload",
    "kind": "LinkedField",
    "name": "updatePrices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePricesMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePricesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b1f620fccf0846407170f2d3acc4c110",
    "id": null,
    "metadata": {},
    "name": "UpdatePricesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePricesMutation(\n  $input: UpdatePricesInput!\n) {\n  updatePrices(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd646ce395da340f9c5018af0d9fdee05';

module.exports = node;
