import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '~/mutation';

const mutation = graphql`
  mutation RemoveSynonymMutation($input: RemoveSynonymInput!) {
    removeSynonym(input:$input) {
      removedSynonymId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeSynonym', edgeName: 'removedSynonymId', connectionKey: 'SynonymList_synonyms' }),
});
export default { commit };
