import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import History, { addHistory, historyPropTypes } from '~/components/history';

const CustomerHistory = (props) => {
  const historyFn = addHistory({props, connectionKey: "CustomerHistory_histories"});

  // eslint-disable-next-line react/prop-types
  const { customer: entity, relay } = props;

  return (
    <History relay={relay} entity={entity} addHistory={historyFn} />
  );
};

CustomerHistory.propTypes = historyPropTypes({entity: 'customer'});

export default createRefetchContainer(
  CustomerHistory,
  {
    viewer: graphql`
      fragment CustomerHistory_viewer on Admin {
        id
      }
    `,
    customer: graphql`
      fragment CustomerHistory_customer on Customer @argumentDefinitions(
        count: {type: "Int!", defaultValue: 10},
      ) {
        id
        histories(first: $count) @connection(key: "CustomerHistory_histories") {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              changes
              user
              insertedAt
            }
          }
        }
      }
    `,
  },
  graphql`
  query CustomerHistoryRefetchQuery($ids: [ID!], $count: Int!) {
    viewer {
      customers(first: 1, ids: $ids) {
        edges {
          node {
            ...CustomerHistory_customer @arguments(count: $count)
          }
        }
     }
    }
  }
  `
);
