import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateProductReviewMutation($input: UpdateProductReviewInput!) {
    updateProductReview(input:$input) {
      productReview {
        content
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

