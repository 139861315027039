import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { Button, Divider, message, Popconfirm } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Grid, dateTimeGetter, iDGetter, valueGetter } from '~/components/grid';
import { getFrontEndUrl } from '~/helper';
import { RemoveSearchTermsMutation } from './mutations';
import bulkActions from './bulkActions';

const entityName = 'Search Terms Report';

class SearchTermsReportList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      searchTermsReports: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      environment: PropTypes.shape({}).isRequired,
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      gridApi: null,
    };

    this.columnDefs = [
      {
        width: 30,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        checkboxSelection: true,
      },
      {
        headerName: 'Action',
        width: 80,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          const id = get(params, 'data.node.id');

          if (id) {
            return (
              <Popconfirm title="Delete this search term?" onConfirm={() => this.handleDelete(id)} okText="Yes" cancelText="No">
                <Button type="link" style={{ padding: '0px', fontSize: '12px' }}>Delete</Button>
              </Popconfirm>
            )
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false,
      },
      {
        colId: 'query',
        headerName: 'query',
        valueGetter: valueGetter.bind(this, 'query'),
        cellRendererFramework: (params) => {
          if (params.value) {
              return (
                <div>
                  <a href={`${getFrontEndUrl()}/search?q=${params.value}`} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                  <CopyToClipboard text={params.value} onCopy={() => message.success("copied")}>
                    <CopyOutlined style={{ marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }} title="Copy" />
                  </CopyToClipboard>
                </div>
              )
          }

          return null;
        },
        width: 250
      },
      { colId: 'results', headerName: 'results', valueGetter: valueGetter.bind(this, 'results'), width: 100 },
      { colId: 'hits', headerName: 'hits', valueGetter: valueGetter.bind(this, 'hits'), width: 100 },
      { colId: 'insertedAt', headerName: 'Created At', valueGetter: dateTimeGetter.bind(this, 'insertedAt'), filter: false, width: 150 },
      { colId: 'updatedAt', headerName: 'Updated At', valueGetter: dateTimeGetter.bind(this, 'updatedAt'), filter: false, width: 150 },
    ];

    this.datasource = Grid.getDataSource.call(this, 'searchTermsReports');
  }

  handleDelete = (id) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { ids: [id] } },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success('Deleted');
        this.state.gridApi.refreshInfiniteCache();
      },
    }

    RemoveSearchTermsMutation.commit(mutation);
  }

  render() {
    const { children, viewer } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <Button onClick={() => { this.props.router.go(-1); }}>Back</Button>
        }
        { children }
        <Divider />
        <Grid
          viewer={viewer}
          columnDefs={this.columnDefs}
          datasource={this.datasource}
          onGridReady={({api: gridApi}) => this.setState({gridApi})}
          bulkActions={bulkActions}
        />
      </div>
    )
  }
}

export default createPaginationContainer(
  SearchTermsReportList, {
    viewer: graphql`
    fragment SearchTermsReportList_viewer on Admin {
      id
      searchTermsReports(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "SearchTermsReportList_searchTermsReports", filters: []) {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            query
            results
            hits
            insertedAt
            updatedAt
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.searchTermsReports;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query SearchTermsReportListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...SearchTermsReportList_viewer
      }
    }
  `,
  },
);
