/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PromoCardList_viewer$ref = any;
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type PromoCardListPaginationQueryVariables = {|
  count: number,
  cursor?: ?string,
  orderBy?: ?OrderBy,
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
|};
export type PromoCardListPaginationQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PromoCardList_viewer$ref
  |}
|};
export type PromoCardListPaginationQuery = {|
  variables: PromoCardListPaginationQueryVariables,
  response: PromoCardListPaginationQueryResponse,
|};
*/


/*
query PromoCardListPaginationQuery(
  $count: Int!
  $cursor: String
  $orderBy: OrderBy
  $filterBy: [FilterBy]
) {
  viewer {
    ...PromoCardList_viewer
    id
  }
}

fragment GenPromoCardPdf_viewer on Admin {
  id
}

fragment PromoCardList_viewer on Admin {
  ...GenPromoCardPdf_viewer
  brands(first: 2000, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  promoCard {
    files
    images
  }
  products(first: $count, after: $cursor, orderBy: $orderBy, filterBy: $filterBy) {
    totalCount(filterBy: $filterBy)
    edges {
      node {
        id
        brandId
        name
        adenCode
        sku
        finalPrice
        status
        categoryIds
        subcategoryIds
        promoCard
        mainImage {
          id
          thumbnail
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "direction": "asc",
    "field": "name"
  }
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  (v5/*: any*/)
],
v9 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PromoCardListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PromoCardList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PromoCardListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 2000
              },
              (v5/*: any*/)
            ],
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "brands(first:2000,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "CategoryConnection",
            "kind": "LinkedField",
            "name": "categories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "categories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "SubcategoryConnection",
            "kind": "LinkedField",
            "name": "subcategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubcategoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subcategory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "subcategories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PromoCard",
            "kind": "LinkedField",
            "name": "promoCard",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "files",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "images",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v9/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "brandId",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "adenCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sku",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "finalPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categoryIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subcategoryIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "promoCard",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductImage",
                        "kind": "LinkedField",
                        "name": "mainImage",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [
              "orderBy",
              "filterBy"
            ],
            "handle": "connection",
            "key": "PromoCardList_products",
            "kind": "LinkedHandle",
            "name": "products"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7cc666cb6556b6c510eb9a66361441af",
    "id": null,
    "metadata": {},
    "name": "PromoCardListPaginationQuery",
    "operationKind": "query",
    "text": "query PromoCardListPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $orderBy: OrderBy\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...PromoCardList_viewer\n    id\n  }\n}\n\nfragment GenPromoCardPdf_viewer on Admin {\n  id\n}\n\nfragment PromoCardList_viewer on Admin {\n  ...GenPromoCardPdf_viewer\n  brands(first: 2000, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  categories(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  subcategories(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  promoCard {\n    files\n    images\n  }\n  products(first: $count, after: $cursor, orderBy: $orderBy, filterBy: $filterBy) {\n    totalCount(filterBy: $filterBy)\n    edges {\n      node {\n        id\n        brandId\n        name\n        adenCode\n        sku\n        finalPrice\n        status\n        categoryIds\n        subcategoryIds\n        promoCard\n        mainImage {\n          id\n          thumbnail\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd43102c73c356803f3416e164d677db5';

module.exports = node;
