import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
} from "react-relay";
import moment from "moment";
import { get } from "lodash";
import { Button } from "antd";

const csvHeaders = [
  "branch_code",
  "name",
  "address",
  "city",
  "postcode",
  "state",
  "phone",
  "fax",
  "emails",
];

const exportStoreQuery = graphql`
  query ExportStoresQuery($filterBy: [FilterBy]) {
    viewer {
      stores(first: 9999, filterBy: $filterBy, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            branch_code: branchCode
            address
            city
            postcode
            state
            phone
            fax
            emails
          }
        }
      }
    }
  }
`;

const genCSV = (list) => {
  const timeNow = moment().format("YYYY-MM-DD-HH:mm:ss");

  const stores = list.map((i) => [
    csvHeaders.reduce((acc, h) => {
      const val = i[h];

      if (Array.isArray(val)) {
        acc.push(val.toString());
      } else {
        acc.push(val);
      }
      return acc;
    }, []),
  ]);

  const data = [csvHeaders].concat(stores).map((e) => e.flat().map((i) => {
    if (i != null) {
      const escape = String(i).replace(/"/g, '""'); // eslint-disable-line no-useless-escape
      return `"${escape}"`;
    }
    return `""`;
  }
  ).join(",")
  ).join("\n");

  const csv = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
  const link = document.createElement("a");
  link.setAttribute("href", csv);
  link.setAttribute("download", `Export-Stores-${timeNow}.csv`);

  document.body.appendChild(link);

  link.click();
};

const CreateCsv = ({ queryReference, disposeQuery }) => {
  const { viewer } = usePreloadedQuery(exportStoreQuery, queryReference) || {};
  const stores = get(viewer, 'stores.edges', []).map(({ node }) => node);

  useEffect(() => {
    genCSV(stores);
    disposeQuery();
  }, []);

  return null;
}

const ExportStores = (props) => {
  const { gridApi } = props;
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(exportStoreQuery,);

  const onClick = () => {
    const storeFilters = gridApi.getFilterModel();

    const filterBy = Object.keys(storeFilters).map((i) => {
      const f = storeFilters[i];

      return {
        field: i,
        filter: String(f.filter),
        filterType: f.filterType,
        type: f.type,
      };
    });

    loadQuery({ filterBy }, { fetchPolicy: 'store-and-network' });
  };

  return (
    <>
      <Button onClick={onClick}>Download</Button>
      {queryReference && (
        <React.Suspense fallback={null}>
          <CreateCsv queryReference={queryReference} disposeQuery={disposeQuery} />
        </React.Suspense>
      )}
    </>
  );
};

ExportStores.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  gridApi: PropTypes.shape({
    getFilterModel: PropTypes.func.isRequired,
  }).isRequired,
};

export default ExportStores;
