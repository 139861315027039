import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { toGlobalId } from '~/helper';
import { getPageSize } from '~/components/grid';

const routes = (
  <Route
    path="/url"
  >
    <Route
      path="/rewrite"
      getComponent={() => (
        import(/* webpackChunkName: "UrlRewriteList" */'./UrlRewriteList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_UrlRewriteList_Query ($count: Int!, $cursor: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...UrlRewriteList_viewer
          }
        }
      `}
      prepareVariables={() => ({
        count: getPageSize(),
        orderBy: {field: "from", direction: "asc"},
      })}
    >
      <Route />

      <Route
        path="/new"
        getComponent={() => (
          import(/* webpackChunkName: "UrlRewriteNew" */'./UrlRewriteNew').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_UrlRewriteNew_Query {
            viewer {
              ...UrlRewriteNew_viewer
            }
          }
        `}
      />

      <Route
        path="/:id"
        getComponent={() => (
          import(/* webpackChunkName: "UrlRewriteView" */'./UrlRewriteView').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={graphql`
          query routes_UrlRewriteView_Query ($ids: [ID]) {
            viewer {
              ...UrlRewriteView_viewer
            }
          }
        `}
        prepareVariables={(params) => {
          const globalId = toGlobalId('UrlRewrite', params.id);
          return { ids: [globalId] };
        }}
      />
    </Route>
  </Route>
);

export default routes;
