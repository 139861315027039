import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateCmsBannerMutation($input: UpdateCmsBannerInput!) {
    updateCmsBanner(input:$input) {
      cmsBanner {
        title
        link
        homeBig
        homeSmall
        categoryBig
        categorySmall
        startDate
        endDate
        urlPaths
        position
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
