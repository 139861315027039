import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { fromGlobalId, toGlobalId } from '~/helper';

export default class DropdownFilter extends Component {
  static propTypes = {
    dropdownData: PropTypes.shape({
    }).isRequired,
    globalId: PropTypes.bool,
    parentFilterInstance: PropTypes.func.isRequired,
  }

  static defaultProps = {
    globalId: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.getFilteredValue(props),
    };
  }

  getFilteredValue = (props) => {
    const { dropdownData } = props;
    const currentParentModel = props.currentParentModel();
    const filtervalue = get(currentParentModel, 'filter');

    if (filtervalue) {
      const headerName = get(props, "filterParams.colDef.headerName");
      const encodeId = toGlobalId(headerName, filtervalue);

      if (dropdownData[filtervalue]) {
        return filtervalue;
      } else if (dropdownData[encodeId]) {
        return encodeId;
      }
    }

    return '';
  }

  valueChanged = (event) => {
    const currentValue = event.target.value;
    const { globalId } = this.props;

    this.setState({
      currentValue
    },
    () => {
      this.props.parentFilterInstance( (instance) => {
        const value = globalId ? fromGlobalId(currentValue).id : currentValue;
        const type = instance.simpleFilterParams.defaultOption || 'equals';
        instance.onFloatingFilterChanged(type, value);
      });
    });
  };

  render() {
    const { dropdownData } = this.props;

    return (
      <select value={this.state.currentValue} onChange={this.valueChanged}>
        <option value="" />
        {Object.keys(dropdownData).map((k) => {
          const data = dropdownData[k];
          return <option value={k} key={k}>{data}</option>;
        })}
      </select>
    );
  }
}
