import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../../helper';
import { AddPurchaseLimitMutation } from './mutations';
import PurchaseLimitForm from './PurchaseLimitForm';

class PurchaseLimitNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      productId: formValues.productId.key,
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/promotion/purchase-limit/${fromGlobalId(resp.addPurchaseLimit.purchaseLimitEdge.node.id).id}`;
      },
    };

    AddPurchaseLimitMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New PurchaseLimit" />

        <h1>New PurchaseLimit</h1>
        <PurchaseLimitForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(PurchaseLimitNew, {
  viewer: graphql`
    fragment PurchaseLimitNew_viewer on Admin {
      id
      ...PurchaseLimitForm_viewer
    }
  `,
});
