import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Affix, Alert, Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useFormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const FormItem = Form.Item;
const { Option } = Select;

const isValidJSONString = (value) => {
  try {
    JSON.parse(value);
  } catch (e) {
      return false;
  }

  return true;
}

const FooterForm = (props) => {
  const [form] = Form.useForm();
  const { setLoading, handleDisableBtn, renderSaveButton } = useFormBase();

  const { match, onSubmit } = props;
  const footer = get(props, 'footer', {});

  useEffect(() => {
    form.resetFields();
  }, [footer]);

  return (
    <Form form={form} onFinish={(values) => { onSubmit(values, { setLoading }); }}>
      <Affix>
        <div>
          <Presence match={match} disableButton={handleDisableBtn} />
          {renderSaveButton()}
        </div>
      </Affix>

      <Alert
        style={{margin: '10px'}}
        banner
        message={
          <>
            <span><b>Internal Link</b>: Paths direct users to pages within sydneytools. Path means without <i>https://sydneytools.com.au</i> and just <i>/category/by-brand/milwaukee</i></span>
            <br />
            <span><b>External Link</b>: Links take users to an external web page which is outside of sydneytools, e.g. <i>https://www.milwaukeetool.com.au/</i></span>
            <br />
            <span><b>Properties</b>: Link properties(must be in JSON format, eg: &#123;&quot;target&quot;:&quot;_blank&quot;&#125;), if you have no idea what it is, talk to your admin first.</span>
          </>
        }
      />

      <FormItem
        name="id"
        initialValue={footer.id}
        hidden
      >
        <Input />
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Name"
        name="name"
        initialValue={footer.name}
        rules={[{ required: true, message: 'required' }]}
      >
        <Input placeholder="Name" />
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Position"
        name="position"
        extra="Ordering Position, Higher Numbers Appear First"
        initialValue={get(footer, 'position', 0)}
        rules={[{ required: true, message: 'required' }]}
      >
        <InputNumber placeholder="Position" />
      </FormItem>

      <Form.List
        name="urls"
        initialValue={!footer.id ? [{ type: "link" }] : get(footer, 'urls', [])}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {

              const prefixSelector = (
                <FormItem
                  {...restField}
                  name={[name, "type"]}
                  rules={[{ required: true, message: 'required' }]}
                  noStyle
                >
                  <Select style={{ width: '125px' }} placeholder="type">
                    <Option value="link">Internal Link</Option>
                    <Option value="href">External Link</Option>
                  </Select>
                </FormItem>
              )

              return (
                <FormItem key={key} noStyle>
                  <FormItem
                    {...formItemLayout}
                    {...restField}
                    label="Name"
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <Input />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    {...restField}
                    label="Url"
                    name={[name, 'url']}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <Input addonBefore={prefixSelector} />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Properties"
                  >
                    <Row gutter={8}>
                      <Col xs={24} sm={12}>
                        <FormItem
                          {...restField}
                          name={[name, 'properties']}
                          rules={[{validator: (rule, value) => {
                            if (!isEmpty(value) && !isValidJSONString(value)) {
                              return Promise.reject(new Error('Not a valid JSON String'));
                            }
                            return Promise.resolve();
                          }}]}
                        >
                          <Input />
                        </FormItem>
                      </Col>

                      <Col xs={12} sm={9}>
                        <FormItem
                          {...restField}
                          label="Position"
                          name={[name, 'position']}
                          rules={[{ required: true, message: 'required' }]}
                        >
                          <InputNumber placeholder="Position" />
                        </FormItem>
                      </Col>

                      <Col xs={12} sm={3}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  </FormItem>
                </FormItem>
              )
            })}

            <FormItem  {...formItemLayout} style={{ flexDirection: 'row-reverse' }}>
              <Button type="dashed" onClick={() => add({ type: "link" })} block icon={<PlusOutlined />}>
                Add Url
              </Button>
            </FormItem>
          </>
        )}
      </Form.List>

      <FormItem
        {...formItemLayout}
        label="Status"
        name="status"
        rules={[{ required: true, message: 'required' }]}
        initialValue={get(footer, 'status') ? 1 : 0}
      >
        <Select placeholder="Status">
          <Option value={1}>Enabled</Option>
          <Option value={0}>Disabled</Option>
        </Select>
      </FormItem>
    </Form>
  )
}

FooterForm.propTypes = {
  match: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
}

FooterForm.defaultProps = {
  match: {},
}

export default FooterForm;
