import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { UpdateComboFreebieMutation } from './mutations';
import ComboFreebieForm from './ComboFreebieForm';

class ComboFreebieView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      comboFreebies: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, formValues) => {
    const values = {
      ...formValues,
      status: !!formValues.status,
      bases: formValues.bases.map(p => p.key),
      skins: formValues.skins.map(p => p.key),
      freebies: formValues.freebies.map(t => ({ ...t, productId: t.productId.key })),
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateComboFreebieMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const comboFreebie = get(viewer, 'comboFreebies.edges[0].node', {});

    return (
      <div>
        <Helmet title={comboFreebie.name} />
        <h1>{comboFreebie.name}</h1>
        <ComboFreebieForm
          key={comboFreebie.id}
          match={match}
          viewer={viewer}
          entity={comboFreebie}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(ComboFreebieView, {
  viewer: graphql`
    fragment ComboFreebieView_viewer on Admin {
      ...ComboFreebieForm_viewer
      comboFreebies(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            startDate
            endDate
            status
            bases(first: 999) {
              edges {
                node {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            skins(first: 999) {
              edges {
                node {
                  id
                  name
                  mainImage {
                    id
                    url
                  }
                }
              }
            }
            freebies(first: 999) {
              edges {
                node {
                  quantity
                  product {
                    id
                    name
                    mainImage {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
