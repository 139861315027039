import React from 'react';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { Divider, Tabs } from 'antd';
import { DatePicker } from '~/components/form';
import BySubcategoryReport from './BySubcategoryReport';
import { TIMEZONE } from './helper';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class BySubcategoryMgrReport extends BySubcategoryReport {
  constructor(props) {
    super(props);

    const { match, viewer } = props;

    const initVariables = match.route.prepareVariables(match.params, match);
    const { filterBy } = initVariables;
    const { filter } = filterBy.find(item => item.field === 'branches') || {};
    this.branchCodeMgr = (filter || "").split(",").filter(i => i);
    this.stores = get(viewer, 'stores.edges', []).filter(({ node }) => this.branchCodeMgr.includes(node.branchCode));
    const mgrBranches = this.stores.map(store => ({
      code: store.node.branchCode,
      name: store.node.name
    }));

    this.state = {
      ...this.state,
      mgrLocations: [],
      _branches: mgrBranches,
    };
  }

  /**
   * When user load this page, `routes_App_Query` and `routes_BySubcategoryReport_Query` will make an request, one after another to the server.
   * `prepareVariables` for this current route will complete first, before `setRoles` in App.js gets to update the roles in the memory.
   * Also because `prepareVariables` get call multiple times, after `setRoles` completed,
   * it will have the correct `initVariables` in the memory, but not the correct fetched data.
   * This happens when the manager's store(s) are modified.
   *
   * Hence it's necessary to call `refetch` to fetch the correct data.
   */
  componentDidMount() {
    this.refetch();
  }

  onMrgLocationChange = (locations) => {
    if (isEmpty(locations)) {
      this.setState({mgrLocations: [], locations: this.branchCodeMgr}, () => {this.refetch()});
    } else {
      this.setState({mgrLocations: locations});
      this.onLocationChange(locations);
    }
  }

  render() {
    const { viewer } = this.props;
    const barChartReport = get(viewer, 'barChartReport.barchart', []);
    const { brandId, dateRange, dataSource, loading, operators, orderBy, subcategoryId, categoryId, activeKey } = this.state;
    const { _branches, mgrLocations } = this.state;
    const { clickHouseData } = this.getConditions(this.state);

    return (
      <div>
        {this.renderHeader()}

        <RangePicker
          key={dateRange}
          value={dateRange}
          onChange={this.onDateChange}
          disabledDate={(current) => current && current < moment().startOf('day').subtract(30, 'days')}
          timezone={TIMEZONE}
        />

        {this.renderDropdown(this.inputs.dataSource, dataSource, { disabled: true })}

        {this.renderDropdown(this.inputs.category, categoryId)}

        {this.renderDropdown(this.inputs.subcategory, subcategoryId)}

        {this.renderDropdown(this.inputs.brand, brandId)}

        {this.renderGlobalFilter("Supplier", this.state.suppliers, this.onSupplierChange, get(viewer, "suppliers", []))}

        {this.renderGlobalFilter("Opeator", operators, this.onOperChange, get(viewer, "staff", []))}

        {this.renderGlobalFilter("Store", mgrLocations, this.onMrgLocationChange, _branches)}

        {this.renderSortBy(orderBy)}

        <Divider />

        {this.renderLoading(loading)}

        {clickHouseData ? this.renderTotal(barChartReport) : this.renderOnlineTotal(barChartReport)}

        {clickHouseData && (
          <Tabs activeKey={activeKey} onChange={this.onActiveKeyChange}>
            <TabPane tab="By Operator" key="By Operator">
              {!loading && activeKey === "By Operator" && this.renderTablebyEntity(barChartReport)}
            </TabPane>
          </Tabs>
        )}
      </div>
    )
  }
};

export default BySubcategoryMgrReport;
