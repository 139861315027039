import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdatePriceMatchMutation($input: UpdatePriceMatchInput!) {
    updatePriceMatch(input:$input) {
      priceMatch {
        matchedPrice
        expiryDate
        code
        status
        decline {
          reason
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
