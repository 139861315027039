import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DatePicker as AntdDatePicker } from 'antd';

const { RangePicker: AntdRangePicker } = AntdDatePicker;

// https://github.com/ant-design/ant-design/issues/47490
const DatePicker = (props) => {
  const { defaultValue, value, onChange, timezone, ...rest } = props;

  const [inputValue, setInputValue] = useState(defaultValue || value);

  useEffect(() => {
    setInputValue(defaultValue || value);
  }, [defaultValue, value]);

  const getEnsureDate = (date, dateString) => {
    if (date && timezone) {
      return moment.tz(dateString, timezone);
    } else if (date) {
      return moment(dateString);
    }

    return date;
  };

  const handleOnChange = (date, dateString) => {
    const ensureDate = getEnsureDate(date, dateString);

    setInputValue(ensureDate);
    onChange(ensureDate, dateString);
  };

  return (
    <AntdDatePicker value={inputValue} onChange={handleOnChange} {...rest} />
  );
};

DatePicker.propTypes = {
  defaultValue: PropTypes.shape({}),
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
  timezone: PropTypes.string,
};

DatePicker.defaultProps = {
  defaultValue: undefined,
  value: null,
  onChange: () => { },
  timezone: undefined,
};

const RangePicker = (props) => {
  const { defaultValue, value, onChange, timezone, ...rest } = props;
  const [inputValue, setInputValue] = useState(defaultValue || value);

  useEffect(() => {
    setInputValue(defaultValue || value);
  }, [defaultValue, value]);

  const getEnsureDates = (dates, dateStrings) => {
    if (dates && timezone) {
      return [moment.tz(dateStrings[0], timezone), moment.tz(dateStrings[1], timezone)];
    } else if (dates) {
      return [moment(dateStrings[0]), moment(dateStrings[1])];
    }

    return dates;
  };

  const handleOnChange = (dates, dateStrings) => {
    const ensureDates = getEnsureDates(dates, dateStrings);

    setInputValue(ensureDates);
    onChange(ensureDates, dateStrings);
  };

  return (
    <AntdRangePicker value={inputValue} onChange={handleOnChange} {...rest} />
  )
};

RangePicker.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  timezone: PropTypes.string,
};

RangePicker.defaultProps = {
  defaultValue: undefined,
  value: null,
  onChange: () => { },
  timezone: undefined,
};

DatePicker.MonthPicker = AntdDatePicker.MonthPicker;
DatePicker.QuarterPicker = AntdDatePicker.QuarterPicker;
DatePicker.RangePicker = RangePicker;
DatePicker.TimePicker = AntdDatePicker.TimePicker;
DatePicker.WeekPicker = AntdDatePicker.WeekPicker;
DatePicker.YearPicker = AntdDatePicker.YearPicker;

export default DatePicker;
