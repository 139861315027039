import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, message, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UpdatePricesMutation, UpdatePriceDropsMutation} from './mutations';
import UpdatePricesSchedule from './UpdatePricesSchedule';

const { Option } = Select;

const headers = [[
  'brand',
  'model',
  'second_model',
  'effective_date',
  'new_price',
]];

const priceDropHeaders = [[
  'brand',
  'model',
  'second_model',
  'new_price',
  'start_date',
  'end_date',
]];

class UpdatePrices extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    priceDrop: PropTypes.bool,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    priceDrop: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      mode: 'dry-run',
    }
    this.ref = React.createRef();
  }

  getEncodedUri = (priceDrop) => {
    let csvHeaders = headers;

    if (priceDrop) {
      csvHeaders = priceDropHeaders;
    }

    const csvContent = `data:text/csv;charset=utf-8,${ csvHeaders.map(e => e.join(",")).join("\n") }`;

    return encodeURI(csvContent);
  }

  getFileName = (priceDrop) => {
    if (priceDrop) {
      return "price_drop_template.csv";
    }
    return "price_template.csv";
  }

  handleSubmit = () => {
    const { fileList } = this.ref.current;

    if (fileList.length > 1) {
      message.error('One import file at a time');
      return;
    }

    if (fileList.length === 0) {
      message.error('Please upload a csv file.');
      return;
    }

    const file = fileList[0].originFileObj;
    const name = 'price0';
    const { mode } = this.state;

    const values = {
      mode,
      prices: name,
    };

    const uploadables = {
      [name]: file
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      uploadables,
      onCompleted: (resp) => {
        const { result } = resp.updatePrices || resp.updatePriceDrops;

        if (mode === "dry-run") {
          message.success("Dry Run Done")
        }
        else if (mode === "real-run") {
          message.success("Prices have been updated")
        }

        if (result) {
          let csvHeaders = [
            'id',
            'model',
            'second_model',
            'reason',
            'provided_price',
            'price',
            'special_price',
            'special_price_start_date',
            'special_price_end_date',
            'flyer_price',
            'flyer_price_start_date',
            'flyer_price_end_date'
          ];

          if (this.props.priceDrop) {
            csvHeaders = [
              'id',
              'model',
              'second_model',
              'reason',
              'provided_price',
              'flyer_price_start_date',
              'flyer_price_end_date',
              'preview_price_before_promo',
              'preview_price_start_date',
              'preview_price_after_promo',
            ];
          }

          const prices = result.map(i => [csvHeaders.reduce((a, h) => {
              a.push(i[h]);
              return a;
            }, [])]);

          const data = [csvHeaders].concat(prices).map(e => e.join(",")).join("\n");

          const csv = `data:text/csv;charset=utf-8,${ data }`;
          const uri = encodeURI(csv);

          const link = document.createElement("a");
          link.setAttribute("href", uri);
          link.setAttribute("download", `${mode}.csv`);
          document.body.appendChild(link);

          link.click();
        }
      }
    };

    if (this.props.priceDrop) {
      UpdatePriceDropsMutation.commit(mutation);
    } else {
      UpdatePricesMutation.commit(mutation);
    }
  }

  render() {
    const { viewer, priceDrop } = this.props;

    return (
      <div style={{display: 'inline-block'}}>
        {!priceDrop && (
          <UpdatePricesSchedule viewer={viewer} />
        )}

        <a href={this.getEncodedUri(priceDrop)} download={this.getFileName(priceDrop)} style={{margin: '0 10px'}} >Template</a>

        <Upload
          className="importProduct"
          accept=".csv"
          beforeUpload={() => false}
          listType="picture"
          ref={this.ref}
        >
          <Button>
            <UploadOutlined /> Upload
          </Button>
        </Upload>

        <Button
          style={{marginLeft: '10px'}}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>

        <Select
          style={{ width: '100px', margin: '0 10px' }}
          defaultValue={this.state.mode}
          onChange={mode => {
            this.setState({ mode });
          }}
        >
          <Option value="dry-run">
            Dry Run
          </Option>

          <Option value="real-run">
            Real Run
          </Option>
        </Select>
      </div>
    );
  }
}

export default createFragmentContainer(UpdatePrices, {
  viewer: graphql`
    fragment UpdatePrices_viewer on Admin {
      id
      ...UpdatePricesSchedule_brand_viewer
      ...UpdatePricesSchedule_viewer
    }
  `,
});
