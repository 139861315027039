import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, isEmpty } from 'lodash';
import { message } from 'antd';

import AttributeForm from './AttributeForm';
import { RenameAttributeOptionMutation, RemoveAttributeOptionMutation, UpdateAttributeMutation } from './mutations';

class AttributeView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      allAttributes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      attributes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  removeOption = (id, option, onCompleted = () => {}) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id, option } },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted,
    };
    RemoveAttributeOptionMutation.commit(mutation);
  }

  renameOption = (id, option, newOption, onCompleted = () => {}) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id, option, newOption } },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted,
    };
    RenameAttributeOptionMutation.commit(mutation);
  }

  handleSubmit = (form, formValues) => {
    const uploadables = {};

    const values = {
      ...formValues,
      options: formValues.table.map(({ option }) => option),
      dictates: formValues.table.flatMap(({ option, dictates }) =>
        (get(dictates, 'attributes') || []).map((d) => ({
          id: d.id,
          options: d.options || null,
          required: d.required,
          value: option,
        }))
      ),
      images: formValues.table.flatMap(({ option, images }) => {
        return {
          option,
          imageOptions: (images || []).map((image, index) => {
            const { id, file, brandIds, categoryIds, subcategoryIds } = image;
            // escape double quote
            const name = `image_${option}_${index}`.replace('"', '_');

            const d = { id };

            if (!isEmpty(brandIds)) {
              d.brandIds = brandIds.map(({ key }) => key);
            }

            if (!isEmpty(categoryIds)) {
              d.categoryIds = categoryIds.map(({ key }) => key);
            }

            if (!isEmpty(subcategoryIds)) {
              d.subcategoryIds = subcategoryIds.map(({ key }) => key);
            }

            if (file) {
              d.image = name;
              uploadables[name] = file[0].originFileObj;
            }

            return d;
          }),
        }
      }).filter(({ imageOptions }) => !isEmpty(imageOptions)),
    };

    delete values.code;
    delete values.table;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        form.resetFields();
        message.success('Saved');
      },
    };
    if (Object.keys(uploadables).length) {
      mutation.uploadables = uploadables;
    }
    UpdateAttributeMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const attribute = get(viewer, 'attributes.edges[0].node', {});

    return (
      <div>
        <Helmet title={attribute.name} />
        <h1>{attribute.name}</h1>
        <AttributeForm
          key={attribute.id}
          match={match}
          viewer={viewer}
          entity={attribute}
          renameOption={this.renameOption}
          removeOption={this.removeOption}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(AttributeView, {
  viewer: graphql`
    fragment AttributeView_viewer on Admin {
      ...AttributeForm_viewer
      attributes(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            code
            options
            dictates
            multi
            visibleToFrontend
            includeInSearch
            position
            images(first: 9999) {
              edges {
                node {
                  id
                  imageUrl
                  option
                  brands(first: 9999) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  categories(first: 9999) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  subcategories(first: 9999) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            ...AttributeHistory_attribute
            ...WebsiteRef_attribute
          }
        }
      }
    }
  `,
});
