import { message } from 'antd';

const checkDuplicated = (content) => {
  content.forEach((a, i) => {
    content.forEach((b, j) => {
      if (i !== j) {
        a.actions.value.forEach(opt => {
          const found = b.actions.value.find(option => option.key === opt.key);
          if (found) {
            message.error(`${found.title} is duplicated in Groups ${i+1} and ${j+1}`);
            throw new Error("duplicated product");
          }
        });
      }
    });
  });
};

module.exports = {
  checkDuplicated,
};
