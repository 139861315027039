import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, Divider, Form, Input, InputNumber } from 'antd';
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { fromGlobalId } from '~/helper';
import { SelectProduct } from '~/components/form';

const { Item: FormItem } = Form;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const BundleProduct = (props) => {
  const form = Form.useFormInstance();

  const { viewer, product } = props;

  const initialValue = product.bundles ? product.bundles.edges.slice(0).map(({ node: b }) => ({
    ...b,
    productId: {
      status: get(b, 'product.status'),
      key: get(b, 'product.id', ''),
      label: (
        <div title={get(b, 'product.name')}>
          <img width="20" height="20" src={get(b, 'product.mainImage.url')} alt="" />
          {get(b, 'product.name')}
        </div>
      ),
    },
  })) : [{}];

  return (
    <Form.List name="bundles" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => {
            const b = form.getFieldValue(["bundles", name]);

            return (
              <div key={key}>
                <Divider />
                {b.id && (
                  <FormItem
                    name="id"
                    hidden
                  >
                    <Input />
                  </FormItem>
                )}
                {b.product &&
                  <a target="_blank" href={`/product/${fromGlobalId(b.product.id).id}`} rel="noopener noreferrer" ><LinkOutlined /></a>
                }
                <FormItem
                  {...formItemLayout}
                  label="Child Product"
                  name={[name, "productId"]}
                  rules={[{ required: true, message: 'required' }]}
                  style={{ display: 'inline-block', width: '550px' }}
                >
                  <SelectProduct
                    style={{ width: '500px' }}
                    placeholder="Child"
                    labelInValue
                    showSearch
                    filterOption={false}
                    viewer={viewer}
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="SKU"
                  style={{ display: 'inline-block', width: '100px' }}
                >
                  {get(b, 'product.sku')}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Status"
                  style={{ display: 'inline-block', width: '100px' }}
                >
                  {get(b, 'product.status')}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Sellable"
                  style={{ display: 'inline-block', width: '145px' }}
                >
                  {get(b, 'product.sellable', '').toString()}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Qty"
                  name={[name, "quantity"]}
                  rules={[{ required: true, message: 'required' }]}
                  style={{ display: 'inline-block', width: '145px' }}
                >
                  <InputNumber min={1} />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Position"
                  name={[name, "position"]}
                  rules={[{ required: true, message: 'required' }]}
                  style={{ display: 'inline-block', width: '165px' }}
                >
                  <InputNumber min={0} />
                </FormItem>

                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    style={{ cursor: 'pointer' }}
                    disabled={fields.length === 1}
                    onClick={() => remove(name)}
                  />
                ) : null}
              </div>
            )
          })}
          <Button onClick={() => add({})}>
            <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  )
};

BundleProduct.propTypes = {
  viewer: PropTypes.shape({
  }).isRequired,
  product: PropTypes.shape({
    bundles: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default BundleProduct;
