import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { fromGlobalId } from '../../helper';
import { Grid, iDGetter, valueGetter } from '../grid';

const entityName = 'Category';

const BOOLEANS = {
  true: 'True',
  false: 'False',
};

class CategoryList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    const dropDownFilter = {
      floatingFilterComponent: 'DropdownFilter',
      floatingFilterComponentParams: { dropdownData: BOOLEANS, suppressFilterButton: true },
    }

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/category/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        colId: 'id', headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: true, filter: false,
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 200,
      },
      {
        colId: 'urlSlug', headerName: 'URL', valueGetter: valueGetter.bind(this, 'urlSlug'), width: 200,
      },
      { colId: 'position', headerName: 'Position', valueGetter: valueGetter.bind(this, 'position') },
      { colId: 'displayMode', headerName: 'Display Mode', valueGetter: valueGetter.bind(this, 'displayMode'), width: 120 },
      {
        colId: 'includeInMenu',
        headerName: 'Show In Menu',
        valueGetter: valueGetter.bind(this, 'includeInMenu'),
        ...dropDownFilter,
        width: 120
      },
      {
        colId: 'includeInSearch',
        headerName: 'Show In Search',
        valueGetter: valueGetter.bind(this, 'includeInSearch'),
        ...dropDownFilter,
        width: 125
      },
      {
        colId: 'includeInFilter',
        headerName: 'Show In Filter',
        valueGetter: valueGetter.bind(this, 'includeInFilter'),
        ...dropDownFilter,
        width: 120
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: valueGetter.bind(this, 'status'),
        ...dropDownFilter
      },
    ];

    this.datasource = Grid.getDataSource.call(this, 'categories');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/category/new"><Button>Add Category</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  CategoryList, {
    viewer: graphql`
    fragment CategoryList_viewer on Admin {
      categories(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "CategoryList_categories") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            urlSlug
            description
            includeInMenu
            includeInSearch
            includeInFilter
            position
            aliases
            displayMode
            status
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.categories;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query CategoryListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...CategoryList_viewer
      }
    }
  `,
  },
);
