import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Divider } from 'antd';

import { fromGlobalId } from '../../../helper';
import { Grid, currencyFormatter, dateTimeGetter, iDGetter, valueGetter } from '../../grid';

const entityName = 'Sales Quote';

export const RECOVERY_STATUSES = {
  1: 'Recovered',
  2: 'Lost',
  3: 'N/A',
  4: 'Missing Contact',
  5: 'In Progress',
};

class QuoteList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      salesQuotes: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/sales/quote/${fromGlobalId(params.data.node.id).id}`}>View</Link>;
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false, width: 80
      },
      { colId: 'customer.firstname', headerName: 'First Name', valueGetter: valueGetter.bind(this, 'customer.firstname') },
      { colId: 'customer.lastname', headerName: 'Last Name', valueGetter: valueGetter.bind(this, 'customer.lastname') },
      { colId: 'customer.email', headerName: 'Email', valueGetter: valueGetter.bind(this, 'customer.email'), width: 180 },
      {
        colId: 'subtotal',
        headerName: 'Sub Total',
        filter: 'agNumberColumnFilter',
        valueGetter: valueGetter.bind(this, 'subtotal'),
        valueFormatter: currencyFormatter,
        width: 110,
      },
      {
        colId: 'grand_total',
        headerName: 'Grand Total',
        filter: 'agNumberColumnFilter',
        valueGetter: valueGetter.bind(this, 'grandTotal'),
        valueFormatter: currencyFormatter,
        width: 110,
      },
      {
        colId: 'recoveryStatus',
        headerName: 'Recovery Status',
        valueGetter: valueGetter.bind(this, 'recoveryStatus'),
        filter: 'agNumberColumnFilter',
        valueFormatter: params => RECOVERY_STATUSES[params.value],
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: RECOVERY_STATUSES, suppressFilterButton: true },
        width: 150,
      },
      { colId: 'insertedAt', headerName: 'Created At', valueGetter: dateTimeGetter.bind(this, 'insertedAt'), filter: false, width: 150 },
      { colId: 'updatedAt', headerName: 'Updated At', valueGetter: dateTimeGetter.bind(this, 'updatedAt'), filter: false, width: 150 },
      { colId: 'deletedAt', headerName: 'Deleted At', valueGetter: dateTimeGetter.bind(this, 'deletedAt'), hide: true, filter: false, width: 150 },
    ];

    this.datasource = Grid.getDataSource.call(this, 'salesQuotes');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  QuoteList, {
    viewer: graphql`
    fragment QuoteList_viewer on Admin {
      salesQuotes(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "QuoteList_salesQuotes") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            subtotal
            grandTotal
            insertedAt
            updatedAt
            deletedAt
            recoveryStatus
            customer {
              id
              firstname
              lastname
              email
            }
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.salesQuotes;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor, orderBy }, fragmentVariables) {
      const { filterBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query QuoteListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...QuoteList_viewer
      }
    }
  `,
  },
);
