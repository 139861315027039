import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { Select } from 'antd';

const { Option } = Select;

class SelectOrder extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      searchOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }

  fetchOrder = (value) => {
    this.props.relay.refetch({ query: value });
  }

  render() {
    const { viewer } = this.props;
    const searchOrders = viewer.searchOrders.edges;

    return (
      <Select
        onSearch={this.fetchOrder}
        {...this.props}
      >
        {searchOrders.map((d) => {
          const o = d.node;
          return (
            <Option key={o.id} title={o.name}>
              {o.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}
export default createRefetchContainer(
  SelectOrder, {
    viewer: graphql`
    fragment SelectOrder_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      searchOrders: salesOrders(first: 20, query: $query) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  },
  graphql`
  query SelectOrderRefetchQuery($query: String) {
    viewer {
      searchOrders: salesOrders(first: 20, query: $query) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`,
);
