import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { Helmet } from 'react-helmet';
import { Button, Divider } from 'antd';

import { fromGlobalId } from '~/helper';
import { Grid, iDGetter, valueGetter } from '~/components/grid';

const entityName = 'Gift Card';

class GiftCardList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      giftCards: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.columnDefs = [
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return <Link to={`/gift-card/setup/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>;
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false,
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 200
      },
      {
        colId: 'amount', headerName: 'Amount', valueGetter: valueGetter.bind(this, 'amount')
      },
      {
        colId: 'expiryMonths', headerName: 'Expiry Months', valueGetter: valueGetter.bind(this, 'expiryMonths'), width: 150
      },
    ];

    this.datasource = Grid.getDataSource.call(this, 'giftCards');
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/gift-card/setup/new"><Button>Add {entityName}</Button></Link>
        <Grid
          columnDefs={this.columnDefs}
          datasource={this.datasource}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  GiftCardList, {
    viewer: graphql`
    fragment GiftCardList_viewer on Admin {
      giftCards(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "GiftCardList_giftCards") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            name
            amount
            expiryMonths
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.giftCards;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filterBy, orderBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query GiftCardListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...GiftCardList_viewer
      }
    }
  `,
  },
);
