import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveProductMutation($input: RemoveProductInput!) {
    removeProduct(input:$input) {
      removedProductId
    }
  }
`;

const commit = buildMutation(mutation, {
});
export default { commit };
