import React from 'react';
import RemoveSearchTerms from './RemoveSearchTerms';

const bulkActions = [
  {
    title: 'Delete',
    node: <RemoveSearchTerms />,
  },
];

export default bulkActions;
