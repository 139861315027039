/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SearchProduct_viewer$ref = any;
export type SearchProductRefetchQueryVariables = {|
  query?: ?string
|};
export type SearchProductRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SearchProduct_viewer$ref
  |}
|};
export type SearchProductRefetchQuery = {|
  variables: SearchProductRefetchQueryVariables,
  response: SearchProductRefetchQueryResponse,
|};
*/


/*
query SearchProductRefetchQuery(
  $query: String
) {
  viewer {
    ...SearchProduct_viewer_1Qr5xf
    id
  }
}

fragment SearchProduct_viewer_1Qr5xf on Admin {
  getProductSuggestion(query: $query)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchProductRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "SearchProduct_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchProductRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "getProductSuggestion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c5e101abeb5a6e1bd152061415c7fad",
    "id": null,
    "metadata": {},
    "name": "SearchProductRefetchQuery",
    "operationKind": "query",
    "text": "query SearchProductRefetchQuery(\n  $query: String\n) {\n  viewer {\n    ...SearchProduct_viewer_1Qr5xf\n    id\n  }\n}\n\nfragment SearchProduct_viewer_1Qr5xf on Admin {\n  getProductSuggestion(query: $query)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67310ca779272c67b94a644c5cbbb579';

module.exports = node;
